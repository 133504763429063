import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { EnquiryStatusService } from './enquiry-status.service';
import { NavbarService} from '../navbar/navbar.service';
import * as moment from 'moment';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { config } from '../../configs/config';

@Component({
  selector: 'app-enquiry-status',
  templateUrl: './enquiry-status.component.html',
})
export class EnquiryStatusComponent implements OnInit {

  customer_id;
  constructor(
    private localService: LocalStorageService,
    private myEnquiryService: EnquiryStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private navbarService: NavbarService,
    private customerLoginStorageService:CustomerLoginStorageService,
    private checkoutApiService: CheckoutApiService
    ) { }
  title = 'app-pagination';
  data: any = [];
  total_count: any;
  activePage = 0;
  CONFIG = config;


  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.customer_id = this.customerLoginStorageService.getCustomerData().userid;
    this.getAllOrders(1);
  }

  getOrderDetails(e) {
    this.router.navigate(['my-order-details', e.target.id]);
  }

  getAllOrders(i) {
    let requestBody = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id": this.customer_id
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 5,
          "pageSize": i
        },
        "sorting": true,
        "sortingDetails": {
          "id":-1
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETALL_ORDERS).subscribe(
      resp => {
        var elem = resp.result.data;
        this.total_count=resp.result.total_count
        this.data=[]
        for (var i = 0; i < elem.length; i++) {
          if(elem[i].system_label.toUpperCase() !== "PENDING" && elem[i].system_label.toUpperCase() !== "AWAITING PAYMENT"){
            elem[i].created_on = moment(elem[i].created_on).format('DD MMM, YYYY');
            this.data.push(elem[i])
          }
          
        }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

  cancelOrder(i: any) {
    this.getOrderStatus("CANCELLED").then(
      respata => {
        console.log("111")
        let res:any = respata;
        let requestBody = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "id": this.data[i].id
            },
          },
          payload: {
            "new_value": {
              "status": res,
              "system_label": 'Cancelled'
            },
            "old_value": {
              "status": this.data[i].status,
              "system_label": this.data[i].system_label
            },
            // shipping_carrier : this.data[i].shipping.shipping_carrier.shipping_carrier_name
          }
        }
        this.appService.postApiCall(requestBody, ENDPOINTS.UPDATE_ORDER).subscribe(
          resp => {
            if (resp) {
              this.getAllOrders(this.activePage)
            }
          },
          err => {
            if (err.error.message) {
              console.log(err.error.message)
            }
            else {
              console.log('Something bad happened; Please try again!')
            }
          });
      }
    )
    
  }

  displayActivePage(activePageNumber: number): void {
    console.log(activePageNumber)
    this.activePage = activePageNumber;
    this.getAllOrders(this.activePage)
  }

  getOrderStatus(label){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_ORDERSTATUS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderapiData:any = res.data;
            orderapiData.forEach(element => {
              if(element.system_label.toUpperCase() === label){
                resolve(element.custom_label);
              }
            });
          }
        }
      )
    })
  }
}
