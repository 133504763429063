<section>
    <div *ngIf="editorderData">
        <div class="customer-container" style="padding-bottom: 0px !important;">
            <div *ngIf="cartProducts.length > 0">
                <div class="row checkout-orderdetails-card" style="padding: 0px !important;">
                    <div class="col-md-2">
                        <h6>ITEMS</h6>
                    </div>
                    <div *ngIf="CONFIG.title !== 'Nesma'" class="col-md-4">
                        <h5></h5>
                    </div>
                    <div *ngIf="CONFIG.title === 'Nesma'" class="col-md-3">
                        <h5></h5>
                    </div>
                    <div class="col-md-2">
                        <h6>PRICE</h6>
                    </div>
                    <div *ngIf="CONFIG.title === 'Nesma'" class="col-md-2">
                        <h6>QTY</h6>
                    </div>
                    <div *ngIf="CONFIG.title !== 'Nesma'" class="col-md-2">
                        <h6>QTY</h6>
                    </div>
                    <div class="col-md-2">
                        <h6>SUB TOTAL</h6>
                    </div>
                    <div *ngIf="CONFIG.title === 'Nesma'" class="col-md-1">
                        <h6>EDIT</h6>
                    </div>
                </div>
                <div *ngFor="let product of cartProducts;let i= index">
                    <div *ngIf="CONFIG.title !== 'Nesma'" class="row checkout-orderdetails-card">
                        <div class="col-md-2">
                            <img class="orderdetails-image" *ngIf="product.image_source !== ''" [src]="product.image_source" alt="food item image" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';">
                            <img class="orderdetails-image" *ngIf="product.image_source === ''" src="assets/images/No_Image_old.png">
                        </div>
                        <div class="col-md-4">
                            <p>{{product.category}}</p>
                            <h5>{{product.product_name}}</h5>
                        </div>
                        <div class="col-md-2">
                            <p><span>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}</span>{{product.cost}}</p>
                        </div>
                        <div class="col-md-2">
                            <div class="orderdetails-btn d-sm-inline-flex">
                                <p class="order-actions" (click)="decrementQuantity(product, i)">-</p>
                                <input type="number" style="width:45px;text-align:center;" *ngIf="CONFIG.title === 'ShubhCards'"(change)="ChangeValue(i)"[(ngModel)]="product.quantity">
                                <p *ngIf="CONFIG.title !== 'ShubhCards'">{{product.quantity}}</p>
                                <p class="order-actions" (click)="incrementQuantity(product, i)">+</p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <p><span>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}</span>{{product.cost * product.quantity}}</p>
                        </div>
                    </div>
                    <div *ngIf="CONFIG.title === 'Nesma'" class="row checkout-orderdetails-card">
                        <div class="col-md-2">
                            <img class="orderdetails-image" *ngIf="product.product.assets && product.product.assets[0].image_source !== ''" [src]="product.product.assets[0].image_source" alt="food item image" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';">
                            <img class="orderdetails-image" *ngIf="product.image_source === ''" src="assets/images/No_Image_old.png">
                        </div>
                        <div class="col-md-3">
                            <p>{{product.product.categories[0].categories}}</p>
                            <h5>{{product.product.name}}</h5>
                        </div>
                        <div class="col-md-2">
                            <p><span>₹</span>{{product.package_total}}</p>
                        </div>
                        <div class="col-md-2">
                            <div class="orderdetails-btn d-sm-inline-flex">
                                <p class="order-actions"></p>
                                {{product.package_quantity}}
                                <p class="order-actions"></p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <p><span>₹</span>{{product.package_total * product.package_quantity}}</p>
                        </div>
                        <div class="col-md-1">
                            <p class="text-primary" style="padding: 3px 6px; background-color: rgb(218, 218, 218);border-radius: 50%;cursor: pointer;" (click)="redirectPAge()"><i class="fa fa-pencil" aria-hidden="true"></i></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="checkout-orders">
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              + Add Delivery Instructions
                            </button>
                          </h2>
                    </div>
                    <div id="collapseOne" class="collapse" [class.show]="orderInstructionsForm.value.orderInstructions" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            <form [formGroup]= "orderInstructionsForm">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Please write your delivery instructions </p>
                                        <textarea class="form-control" formControlName='orderInstructions' [class.is-invalid] = "validateorderInstructionsForm && f.orderInstructions.errors"></textarea>
                                        <div class="invalid-feedback" *ngIf="f.orderInstructions.errors">
                                            <div *ngIf="f.orderInstructions.errors.maxlength">Delivery Instructions should not have more than 1000 charachters</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                      </div>
                </div>
            </div>
            <div *ngIf="showOrderComments">
                <div class="accordion" id="accordionExample1">
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                  + Add Order Notes & Summary
                                </button>
                              </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" [class.show]="orderNotesForm.value.orderNotes" aria-labelledby="headingTwo" data-parent="#accordionExample1">
                            <div class="card-body">
                                <form [formGroup]= "orderNotesForm">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p>Please write notes of your order</p>
                                            <textarea class="form-control" formControlName='orderNotes' [class.is-invalid] = "validateordernotesForm && f1.orderNotes.errors" rows="6"></textarea>
                                            <div class="invalid-feedback" *ngIf="f1.orderNotes.errors">
                                                <div *ngIf="f1.orderNotes.errors.maxlength">Delivery Note should not have more than 1000 charachters</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-right:15px; padding-bottom: 20px !important;">
                <div class="col-md-4 offset-md-8">
                    <button class="btn btn-block btn-primary btn-primary1" (click)="saveOrderDetails()">CONTINUE</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf = "!editorderData" style="padding: 10px 10px 15px 20px;">
        <!-- <div class="customer-container"> -->
            <div class="row">
                <div class="col-md-10">
                    <p>{{cartProducts.length}} items in total</p>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-secondary btn-secondary1" (click)="editOrderDetails()">CHANGE</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">               
                        <div class="row">
                            <div class="col-md-6">                    
                                <div *ngIf = "orderInstructionsForm.value.orderInstructions">
                                    <div style="margin-top: 10px !important;"><b class="shipping-font"><i>Delivery Instructions:</i></b></div>
                                    <p class="shipping-font">{{orderInstructionsForm.value.orderInstructions}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div *ngIf = "orderNotesForm.value.orderNotes">
                                    <div style="margin-top: 10px !important;"><b class="shipping-font"><i>Order Notes & Comments:</i></b></div>
                                    <p class="shipping-font">{{orderNotesForm.value.orderNotes}}</p>
                                </div>
                            </div>
                        </div>             
                </div>
            </div>
        <!-- </div> -->
    </div>
</section>
