<section id="category-listing" *ngIf="config.title !== 'BombayHardware'">
    <div class="d-flex justify-content-center company-bg">
        <img class="member-img" src="../../assets/images/n2.png">
    </div>
    <div style="padding: 40px 80px;">
        <div class="row" style="margin-left: 65px!important">
            <div class="am-category" *ngFor="let category of allCategories | paginate: { itemsPerPage: pageSize, currentPage: page, id: 'fooo', totalItems:pageCount}" style="background-image: url({{imgPath}}); background-repeat: no-repeat;background-size: contain;">
                <div class="am-category-op text-center" (click)="getCategoryProducts(category)">
               
                <div class="am-category-title">{{category.name | uppercase}}</div>
            </div>
            </div>
        </div>
        <!-- <div style="margin-bottom: 50px !important;">
            <pagination-controls id="fooo" class="current" style="float:right;margin-top: 15px;font-size: 1rem;" (pageChange)="getPageCategories($event)"></pagination-controls>
        </div> -->
    </div>
</section>


<section id="category-listing" *ngIf="config.title == 'BombayHardware'">
    <!-- <div class="d-flex justify-content-center company-bg1"> -->
        <div class="d-flex justify-content-center company-bg1" style="height: 260px;">
        <!-- <img class="member-img" src="../../assets/images/bombayhardware/bombayhardware-category.png" style="width: 50%!important;"> -->
        <img class="member-img" src="../../assets/images/bombayhardware/bombay-003.jpg" style="width: 90%!important;height: 260px!important;">

    </div>
    <div style="padding: 40px 80px;">
        <!-- <h2>Parent Category</h2> -->
        <div class="row" style="margin-left: 65px!important">
            <!-- <div class="am-category" *ngFor="let category of allCategories | paginate: { itemsPerPage: pageSize, currentPage: page, id: 'fooo', totalItems:pageCount}" style="background-image: url({{imgPath}}); background-repeat: no-repeat;background-size: contain;"> -->
                <div *ngFor="let category of categoryList | paginate: { itemsPerPage: pageSize, currentPage: page, id: 'fooo', totalItems:pageCount}">
                <div class="am-category" style="background-image: url({{category.image}}); background-repeat: no-repeat;background-size: contain;">
                <div class="am-category-op text-center" (click)="getCategoryProducts1(category.name)">
               
                <div class="am-category-title">{{category.name | uppercase}}</div>
            </div>
            </div>
            </div>
        </div>


            <!-- <div *ngFor="let cate of allCategories">
                     <span *ngIf="cate.subcategories">
                        <h2>Sub Category</h2>
                        <div class="row" style="margin-left: 65px!important">
                        <div *ngFor="let cate1 of cate.subcategories">
                            <div class="am-category" style="background-image: url({{cate1.image}}); background-repeat: no-repeat;background-size: contain;">
        
                            <div class="am-category-op text-center" (click)="getCategoryProducts1(cate1.name)">
                           
                            <div class="am-category-title">{{cate1.name | uppercase}}</div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </span>
                    </div> -->
        </div>
                    <!-- <span *ngIf="category1.subcategories">
            <div class="am-category" *ngFor="let category2 of category1.subcategories" style="background-image: url({{imgPath}}); background-repeat: no-repeat;background-size: contain;">
                <div class="am-category-op text-center" (click)="getCategoryProducts1(category2.name)">
               
                <div class="am-category-title">{{category2.name | uppercase}}</div>
            </div>
            </div>
            </span>
            <span *ngIf="category2.subcategories">
            <div class="am-category" *ngFor="let category3 of category2.subcategories" style="background-image: url({{imgPath}}); background-repeat: no-repeat;background-size: contain;">
                <div class="am-category-op text-center" (click)="getCategoryProducts1(category3.name)">
               
                <div class="am-category-title">{{category3.name | uppercase}}</div>
            </div>
            </div>
            </span> -->
        <!-- <div style="margin-bottom: 50px !important;">
            <pagination-controls id="fooo" class="current" style="float:right;margin-top: 15px;font-size: 1rem;" (pageChange)="getPageCategories($event)"></pagination-controls>
        </div> -->
</section>