import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Output, EventEmitter, Input } from '@angular/core';

import { AppService } from '../../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { CustomerAddressService } from './customer-address.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { NavbarService} from '../../navbar/navbar.service';

const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/;
// const namePattern = /^[a-zA-Z']+(\s+)+[a-zA-Z']?$/;

@Component({
  selector: 'app-customer-address',
  templateUrl: './customer-address.component.html'
})
export class CustomerAddressComponent implements OnInit {

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  isDeliveyAvailable
  addAddressFlag: boolean = false;
  zoom
  flat
  landmark
  city
  mobile = ""
  address
  name
  mail
  pincode
  state
  country

  availableSavedAddress: any = [];
  kms

  lat
  lng

  edit = {
    isEdit: false,
    index: -1
  }

  geocoder
  isdefault

  options = {
    types: [
    ],
    componentRestrictions: { country: 'IN' },

  }

  outlet = {
    name:"",
    longitude:"",
    latitude:""
  }

  deliVeryDetails = {
    display:"",
    type:"",
    amount:0,
    tax:0
  }

  showalertmsg: boolean = false;
  // ALERT SERVICE SETTINGS
  options1 = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  selectedAddress:any = "";

  errors = {
    address: { error: false, message: "Enter Location" },
    flat: { error: false, message: "Enter Flat No/Building Name/Street Name" },
    mobile: { error: false, message: "Enter Mobile Number" },
    mail: { error: false, message: "Enter Mail" },
    name: { error: false, message: "Enter Full Name" }
  }

  checkedAddressIndex:any = "";
  pickdropcall:any = false;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private appService: AppService,
    private shippingAddressService: CustomerAddressService,
    private alertService: AlertsService,
    private navbarService: NavbarService,
    ) { }

  ngOnInit(): void {
    this.pickdropcall = this.shippingAddressService.getPickdropCall() !== "" ? true:false;
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.shippingAddressService.clearEvent();
    this.addAddressFlag = false;
    this.isDeliveyAvailable = false;
    if(this.shippingAddressService.getSelectedAddress() === ""){
      this.getCustomerAddresses();
    }
    else{
      this.getSelectedAddressfromLocal();
    }
    this.validateDistance();
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder
    });
    this.zoom = 15;
    // console.log("Addressess: ", this.availableSavedAddress);
    // if (this.availableSavedAddress && this.availableSavedAddress.length == 0) {
    //   this.addAddressFlag = true;
    //   this.resetLocation()
    //   this.zoom = 14
    //   this.zoom = 16
    // }
    // else{
    //   console.log("Addressess: ", this.availableSavedAddress);
    // }

    this.shippingAddressService.currentData.subscribe(currentData => {
      if(currentData === 'getData' && this.availableSavedAddress.length === 0){
        this.getCustomerAddresses();
        this.outlet = this.shippingAddressService.getOutLetData();
      }
    })

  }

  getSelectedAddressfromLocal(){
    this.outlet = this.shippingAddressService.getOutLetData();
    this.selectedAddress = this.shippingAddressService.getSelectedAddress();
    this.checkedAddressIndex = this.selectedAddress.id;
    this.getCustomerAddresses();
    this.getDeliveryCharges();
    this.notify.emit({"Type":"Address", "value":this.selectedAddress});
  }

  getCustomerAddresses() {
    this.showalertmsg = true;
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id": Number(this.shippingAddressService.getCustomerData())
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_CUSTOMER_ADDRESSES).subscribe(resp => {
      if(resp.success === 1){
        // console.log(requestBody, resp)
        this.availableSavedAddress = resp.result.data;
        if (this.availableSavedAddress && this.availableSavedAddress.length == 0) {
          this.addAddressFlag = true;
          this.resetLocation()
          this.zoom = 14
          this.zoom = 16
        }
        else if(this.availableSavedAddress.length !== 0){
          this.addAddressFlag = false;
        }
        if(this.checkedAddressIndex === ""){
          this.checkDefaultAddress();
        }
      }
      else{
        if(resp.message){
          this.alertService.error(resp.message, this.options1)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options1)
        }
      }
    },
    err => {
      if (err.error.error.message) {
        this.alertService.error(err.error.error.message, this.options1)
      }
      else{
        this.alertService.error('Something bad happened; Please try again!', this.options1)
      }
    })
  }

  setLocation(lat,lng){
    this.lat = lat;
    this.lng = lng;
    this.zoom = 15
    this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          results[0].address_components.forEach(element => {
            if (element.types.includes("postal_code")) {
              this.pincode = element.long_name
            }
            if (element.types.includes("country")) {
              this.country = element.long_name
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.state = element.long_name
            }
            if (element.types.includes("administrative_area_level_2")) {
              this.city = element.long_name
            }
            /*
            if(element.types.includes("street_number")){
              this.flat =  element.long_name
              if(element.types.includes("route")){
                this.flat= this.flat+','+element.long_name
              }
            }
            else if(element.types.includes("route")){
              this.flat=element.long_name

            }
            if(element.types.includes("sublocality")){
              this.landmark= element.long_name
              if(element.types.includes("locality")){
                this.landmark=this.landmark+","+element.long_name
              }
            }
            else if(element.types.includes("locality")){
              this.landmark=element.long_name
            }
            */
          });


          this.address = results[0].formatted_address
          this.zoom = 16
          if (this.city.toLowerCase() != 'chennai') {
            this.isDeliveyAvailable = false;
          }
          else {
            this.validateDistance()
          }


        }
        else {
          window.alert("No address found")
        }
      }
    })

  }



  selectLocation(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.zoom = 15
    this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          results[0].address_components.forEach(element => {
            if (element.types.includes("postal_code")) {
              this.pincode = element.long_name
            }
            if (element.types.includes("country")) {
              this.country = element.long_name
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.state = element.long_name
            }
            if (element.types.includes("administrative_area_level_2")) {
              this.city = element.long_name
            }
            /*
            if(element.types.includes("street_number")){
              this.flat =  element.long_name
              if(element.types.includes("route")){
                this.flat= this.flat+','+element.long_name
              }
            }
            else if(element.types.includes("route")){
              this.flat=element.long_name

            }
            if(element.types.includes("sublocality")){
              this.landmark= element.long_name
              if(element.types.includes("locality")){
                this.landmark=this.landmark+","+element.long_name
              }
            }
            else if(element.types.includes("locality")){
              this.landmark=element.long_name
            }
            */
          });


          this.address = results[0].formatted_address
          this.zoom = 16
          if (this.city.toLowerCase() != 'chennai') {
            this.isDeliveyAvailable = false;
          }
          else {
            this.validateDistance()
          }


        }
        else {
          window.alert("No address found")
        }
      }
    })

  }

  public handleAddressChange(address: any) {
    this.isDeliveyAvailable = false
    this.address = address.formatted_address;
    this.lat = address.geometry.location.lat()
    this.lng = address.geometry.location.lng()
    this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {

          results[0].address_components.forEach(element => {

            if (element.types.indexOf("postal_code") != -1) {
              this.pincode = element.long_name
            }
            if (element.types.indexOf("country") != -1) {
              this.country = element.long_name
            }
            if (element.types.indexOf("administrative_area_level_1") != -1) {
              this.state = element.long_name
            }

            if (element.types.indexOf("administrative_area_level_2") != -1) {
              this.city = element.long_name
            }

          });


          this.address = results[0].formatted_address
          this.zoom = 16
          if (this.city.toLowerCase() != 'chennai') {
            this.isDeliveyAvailable = false
          }
          else {
            this.validateDistance()
          }


        }
        else {
          window.alert("No address found")
        }
      }
    })



  }


  validateDistance() {
    this.outlet = this.shippingAddressService.getOutLetData();

    var restaurant
    if(this.outlet.name !== ""){
      var latitude:number = +this.outlet.latitude;
      var longitude:number = +this.outlet.longitude
      restaurant = new google.maps.LatLng(latitude, longitude);
      var selectedLocation = new google.maps.LatLng(this.lat, this.lng);
      const distance = google.maps.geometry.spherical.computeDistanceBetween(restaurant, selectedLocation)
      this.kms = distance / 1000;

      this.isDeliveyAvailable = false;

      // if (this.kms > 8.0) {
      //   this.isDeliveyAvailable = false;
      // }
      // else {
      //   this.isDeliveyAvailable = false;
      // }
    }
  }

  getDistance(lat, lon){
    var latitude:number = +this.outlet.latitude;
    var longitude:number = +this.outlet.longitude
    let restaurant = new google.maps.LatLng(latitude, longitude);
    let slocation = new google.maps.LatLng(lat, lon);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(restaurant, slocation)
    let kms = distance / 1000;
    return kms;
  }


  editAddress(index) {
    this.addAddressFlag = true;
    this.flat = this.availableSavedAddress[index].address1
    this.landmark = this.availableSavedAddress[index].landmark
    this.city = this.availableSavedAddress[index].city
    this.mobile = this.availableSavedAddress[index].phone_number
    this.name = this.availableSavedAddress[index].first_name
    this.mail = this.availableSavedAddress[index].email
    this.lat = Number(this.availableSavedAddress[index].latitude)
    this.lng = Number(this.availableSavedAddress[index].longitude)
    this.isdefault = this.availableSavedAddress[index].is_preferred_address

    this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14
          this.address = results[0].formatted_address;
          this.zoom = 16
          if (this.city.toLowerCase() != 'chennai') {
            this.isDeliveyAvailable = false
          }
          else {
            this.validateDistance()
          }


        }
        else {
          window.alert("No address found")
        }
      }
    })

    this.state = this.availableSavedAddress[index].state
    this.country = this.availableSavedAddress[index].country
    this.pincode = this.availableSavedAddress[index].zipcode

    this.edit.isEdit = true;
    this.edit.index = index;

    this.errors.address.error = false
    this.errors.flat.error = false
    this.errors.mobile.error = false
    this.errors.mail.error = false
    this.errors.name.error = false
  }

  deleteAddress(index) {
    // this.availableSavedAddress.splice(index, 1)
    if(this.selectedAddress.id === this.availableSavedAddress[index].id){
      this.selectedAddress = "";
      this.notify.emit("remove");
    }
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {},
      "extras": {
        "find": {
          "id": this.availableSavedAddress[index].id
        }
      }
    }
    // console.log("JSON", requestBody);
    this.appService.postApiCall(requestBody, ENDPOINTS.DELETE_CUSTOMER_ADDRESSES).subscribe(
      resp => {
        // console.log(resp);
        if(resp.status_code == 200 && resp.success == 1 ){
          this.availableSavedAddress.splice(index, 1);
          if(this.availableSavedAddress.length === 0){
            this.deliVeryDetails.amount = 0;
            this.deliVeryDetails.tax = 0;
            this.deliVeryDetails.display = "Delivery Charges";
            this.notify.emit({"Type":"DeliveryCharge", "value":this.deliVeryDetails})
          }
        }
      },
      err => {
        // console.log("Error");
      });
  }


  formValidation() {
    this.errors.address.error = false
    this.errors.flat.error = false
    this.errors.mobile.error = false
    this.errors.mail.error = false
    this.errors.name.error = false

    var reMob = new RegExp("^[+]*[(]{0,1}[1-9]{1}[0-9]{9}")
    var reName = new RegExp("^[a-zA-Z][a-z A-Z]*$")

    if (this.address == "") {
      this.errors.address.error = true;
    }
    if (this.flat == "") {
      this.errors.flat.error = true;
    }
    if (this.mobile == "") {
      this.errors.mobile.message = "Enter Mobile Number"
      this.errors.mobile.error = true
    } else if (this.mobile.length != 10 ) {

      this.errors.mobile.message = "Mobile number must be 10 characters long."
      this.errors.mobile.error = true
    }
    else if(!reMob.test(this.mobile)){
      this.errors.mobile.message="Please enter a valid Mobile"
      this.errors.mobile.error=true
    }

    if (this.mail == "") {
      this.errors.mail.message="Enter Mail"
      this.errors.mail.error = true;
    }
    else if(this.mail.length >50){
      this.errors.mail.message = "Length of Mail exceeds 50 characters"
      this.errors.mail.error=true
    }
    else if(!emailPattern.test(this.mail)){
      this.errors.mail.message="Please enter a valid Email"
      this.errors.mail.error=true
    }

    if (this.name == "") {
      this.errors.name.message="Enter Full Name"
      this.errors.name.error = true;
    }
    else if(this.name.length >50){
      this.errors.name.message="Length of Name exceeds 50 characters"
      this.errors.name.error=true
    }
    else if(!reName.test(this.name)){
      this.errors.name.message="Please enter a valid Name"
      this.errors.name.error=true
    }
    else{
      var re = /'/gi;
      var re1 = / /gi;
      let f_name = this.name.replace(re, "")
      f_name = f_name.replace(re1, "")
      if(f_name.length === 0){
        this.errors.name.message="Please enter a valid Name"
        this.errors.name.error=true
      }
    }



  }


  saveAddress() {

    this.formValidation();
    if (!(this.errors.address.error || this.errors.flat.error || this.errors.mobile.error || this.errors.name.error || this.errors.mail.error)) {
      //save address logic
      var data = {
        customer_id:this.shippingAddressService.getCustomerData(),
        alias:"",
        first_name:this.name,
        last_name:"",
        company_name:"",
        entire_location:this.address,
        address1:this.flat,
        landmark: this.landmark,
        city: this.city,
        state: this.state,
        country: this.country,
        country_code:"104",
        zipcode: this.pincode,
        email: this.mail,
        phone_number: this.mobile,
        is_preferred_address:this.isdefault,
        address_type:"",
        longitude:this.lng,
        latitude:this.lat
      }

      // if (this.isdefault) {
      //   this.availableSavedAddress.forEach(element => {
      //     element.isdefault = false;
      //     this.updateAddressService(element);
      //   })
      // }

      if (this.edit.isEdit) {
        var tempData = this.availableSavedAddress[this.edit.index];
        this.updateAddressService(data, tempData.id);
      }
      else {
        this.addNewAddressService(data);
      }
    }
    else {
      console.log("Data is not valid");
      console.log("Address error: ", this.errors.address.error)
      console.log("Flat error: ", this.errors.flat.error)
      console.log("Mobile error: ", this.errors.mobile.error)
      console.log("Name error: ", this.errors.name.error)
      console.log("mail error: ", this.errors.mail.error)
    }
  }

  addNewAddressService(data) {
    this.showalertmsg = true;
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "create_address": data
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.CREATE_CUSTOMER_ADDRESSES).subscribe(
      resp => {
        if(resp.success === 1){
          this.updateDefaultAddress(resp.result.data[0].id)
          this.getCustomerAddresses();
          this.addAddressFlag = false;
          window.scroll(0,0)
          this.alertService.success("Address Added Successfully", this.options1)
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options1)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options1)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options1)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options1)
        }
      })
  }

  updateAddressService(data,id) {
    this.showalertmsg = true;
    let requestBody = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "payload":{
        "update_address": data
      },
      "extras": {
            "find":
                {
                    "id": id
                }
        }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.UPDATE_CUSTOMER_ADDRESSES).subscribe(
      resp => {
        if(resp.status_code == 200 && resp.success == 1){
          this.getCustomerAddresses();
          this.edit.isEdit = false;
          this.addAddressFlag = false;
          if(this.selectedAddress.id === id){
            this.getDeliveryCharges();
          }
          window.scroll(0,0)
          this.alertService.success("Address Updated Successfully", this.options1)
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options1)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options1)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options1)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options1)
        }
      })
  }

  resetLocation() {
    this.lat=null;
    this.lng=null;
    navigator.geolocation.getCurrentPosition(position => {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude

      this.setLocation(this.lat,this.lng)
    }, error => {}, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })
    // this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
    //   if (status === 'OK') {
    //     if (results[0]) {

    //       this.zoom=14
    //       this.address = results[0].formatted_address
    //       results[0].address_components.forEach(element => {

    //         if (element.types.includes("postal_code")) {
    //           this.pincode = element.long_name
    //         }
    //         if (element.types.includes("country")) {
    //           this.country = element.long_name
    //         }
    //         if (element.types.includes("administrative_area_level_1")) {
    //           this.state = element.long_name
    //         }
    //         if (element.types.includes("administrative_area_level_2")) {
    //           this.city = element.long_name
    //         }

    //       });


    //       this.address = results[0].formatted_address
    //       this.zoom = 16
    //       if (this.city.toLowerCase() != 'chennai') {
    //         this.isDeliveyAvailable = true
    //       }
    //       else {
    //         this.validateDistance()
    //       }
    //       console.log("completed")


    //     }
    //     else {
    //       window.alert("No address found")
    //     }
    //   }
    // })
  }


  addNewAddress() {
    this.addAddressFlag = true;

    this.address = ""
    this.flat = ""
    this.landmark = ""
    this.city = ""
    this.mobile = ""
    this.name = ""
    this.mail = ""
    this.isdefault = false
    this.state = ""
    this.country = ""
    this.pincode = ""

    this.lat=null;
    this.lng=null;

    navigator.geolocation.getCurrentPosition(position => {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude
    }, error => {}, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
    )
    this.geocoder.geocode({ 'location': { lat: this.lat, lng: this.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {

          results[0].address_components.forEach(element => {

            if (element.types.includes("postal_code")) {
              this.pincode = element.long_name
            }
            if (element.types.includes("country")) {
              this.country = element.long_name
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.state = element.long_name
            }

            if (element.types.includes("administrative_area_level_2")) {
              this.city = element.long_name
            }

          });


          this.address = results[0].formatted_address
          this.zoom = 16
          if (this.city.toLowerCase() != 'chennai') {
            this.isDeliveyAvailable = false
          }
          else {
            this.validateDistance()
          }

          // console.log("completed")


        }
        else {
          window.alert("No address found")
        }
      }
    })
  }
  cancelAddresses() {

    this.errors.address.error = false
    this.errors.flat.error = false
    this.errors.mobile.error = false
    this.errors.mail.error = false
    this.errors.name.error = false

    this.addAddressFlag = false;
    if (this.edit.isEdit) {
      this.edit.isEdit = false;
    }
  }

  updateDefaultAddress(addressId){
    this.availableSavedAddress.forEach(element => {
          if(element.id !== addressId){
            element.is_preferred_address = false;
            var data = {
              customer_id:this.shippingAddressService.getCustomerData(),
              alias: element.alias,
              first_name: element.first_name,
              last_name: element.last_name,
              company_name: element.company_name,
              entire_location: element.entire_location,
              address1: element.address1,
              landmark:  element.landmark,
              city:  element.city,
              state:  element.state,
              country:  element.country,
              country_code: element.country_code,
              zipcode:  element.zipcode,
              email:  element.email,
              mobile:  element.mobile,
              is_preferred_address: element.is_preferred_address,
              address_type: element.address_type,
              longitude: element.longitude,
              latitude: element.latitude
            }
            this.updateAddressService(data, element.id);
          }
        })
  }

  checkDefaultAddress(){
    this.availableSavedAddress.forEach((element,index) => {
      if(element.is_preferred_address){
        this.checkedAddressIndex = element.id;
        this.selectedAddress = element;
        this.notify.emit({"Type":"Address", "value":this.selectedAddress});
        if(this.outlet.latitude){
          this.getDeliveryCharges();
        }

      }
    });
  }
  setSelectedAddress(address){
    this.selectedAddress = address;
    this.notify.emit({"Type":"Address", "value":this.selectedAddress});
    this.getDeliveryCharges();
  }

  passShippingData(){
    // console.log(this.selectedAddress)
    if(this.selectedAddress !== ""){
      this.shippingAddressService.setSelectedAddress1(this.selectedAddress);
      this.notify.emit('pass');
      this.getDeliveryCharges();
    }
    else{
      this.showalertmsg = true;
      this.alertService.warn('Add/Select Shippping Address to Proceed', this.options1)
    }
  }

  cancelSave(){
    this.addAddressFlag = false;
  }

  getDeliveryCharges(){
    // console.log('apicalllll')
    this.outlet = this.shippingAddressService.getOutLetData();
    this.showalertmsg = true;
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "delivery_rate": {
          "sendFrom": "Chennai",
          "pickupLatitude": this.outlet.latitude,
          "pickupLongitude": this.outlet.longitude,
          "dropLatitude": this.selectedAddress.latitude.toString(),
          "dropLongitude": this.selectedAddress.longitude.toString(),
          "distance":this.getDistance(this.selectedAddress.latitude, this.selectedAddress.longitude)
        }
      }
    }
    if(this.pickdropcall === true){
      // console.log('apicalllll2')
      this.appService.postApiCall(requestData, ENDPOINTS.PICKANDDROP).subscribe(
        resp => {
          if(resp.success === 1){
              this.deliVeryDetails.amount = resp.result.data.deliveryCharge;
              this.deliVeryDetails.display = "Delivery Charges"
              this.notify.emit({"Type":"DeliveryCharge", "value":this.deliVeryDetails})
          }
          else if(resp.success === 0 && resp.result.data.deliveryCharge){
            this.deliVeryDetails.amount = resp.result.data.deliveryCharge;
            this.deliVeryDetails.display = "Delivery Charges"
            this.notify.emit({"Type":"DeliveryCharge", "value":this.deliVeryDetails})
          }
          else{
            this.getFlatRateShipping();
          }
        },
        err => {
          this.getFlatRateShipping();
          if (err.error.error.message) {
            // console.log(err.error.error.message)
          }
          else{
            // console.log('Something bad happened; Please try again!')
          }
        })
    }
    else{
      this.getFlatRateShipping();
    }
  }
  getFlatRateShipping(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_SHIPMENTMETHODS).subscribe(
      resp => {
        if(resp.success === 1){
          let shippingFeeData = resp.result.data[0];
          this.deliVeryDetails.type = shippingFeeData.flat_rate.type;
          this.deliVeryDetails.display = shippingFeeData.flat_rate.display_name;
          this.deliVeryDetails.amount = Number(shippingFeeData.flat_rate.settings.rate);
          this.notify.emit({"Type":"DeliveryCharge", "value":this.deliVeryDetails})
        }
        else{
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else{
          // console.log('Something bad happened; Please try again!')
        }
      })
  }

  backbutton(){
    this.notify.emit('back');
  }
}
