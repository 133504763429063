// Home Slider
export let HomeSlider: any = {
    loop: true,
    nav: true,
    dots: true,
    navSpeed: 300,
    navContainerClass: 'owl-nav',
    autoplay: true,
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
};


// Product Slider
export let ProductSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    margin: 35,
    nav: true,
    navContainerClass: 'owl-nav',
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
    responsive: {
        991: {
            items: 3
        },
        767: {
            items: 2
        },
        420: {
            items: 2
        },
        0: {
            items: 1
        }
    }
};


// Product Slider
export let ReletedProductSlider: any = {
    loop: false,
    dots: false,
    navSpeed: 300,
    margin: 35,
    nav: true,
    navContainerClass: 'owl-nav',
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
    responsive: {
        991: {
            items: 4
        },
        767: {
            items: 4
        },
        420: {
            items: 2
        },
        0: {
            items: 1
        }
    }
};


// Product Details Main Slider
export let ProductDetailsMainSlider: any = {
    items: 1,
    nav: true,
    dots: false,
    autoplay: false,
    slideSpeed: 300,
    loop: true,
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
};

// Product Details Thumb Slider
export let ProductDetailsThumbSlider: any = {
    items: 3,
    loop: false,
    margin: 10,
    dots: false
};

