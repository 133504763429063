<!----------variants------>
<form >
    <div style="height:50px">
    <div class="d-flex justify-content-start mt-1 "
        *ngIf="(product.product_type == 'Variable' || product.type == 'Variable')&& product.variant != undefined">
        <div *ngFor="let variant of product.variant.option_values;let i=index; " value="variant.id">

            <div class="checkRadioContainer">
                <label>
                    <input type="radio" name="variant.id"  [checked]="(i == 0)" value="variant.id" (click)="showPrice(i,variant,product.id)">

                    <span><i class="fa fa-shopping-bag" size="40">
                            <p style="font-size:8px !important; font-weight: bolder;cursor: pointer;padding-top: 2px;">
                                {{variant.option}}</p>
                        </i>
                    </span>
                </label>
            </div>
        </div>
    </div>
    </div>

</form>