<div *ngIf="config.title == 'EarthyWorthy'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="tab-image image-1" [class.earthy-tabimage]="config.title == 'EarthyWorthy'">
                    <a>
                        <div class="background-image" (click)="categoryListing(item.title)"
                            style="background-image: url({{item.image}});">
                            <div class="earthy-feature-collections"></div>
                        </div>
                        <div class="bg-content">
                            <h2 class="titleproper" *ngIf="item.title && config.title == 'EarthyWorthy'"
                                style="font-size: 1.3rem; text-transform: uppercase; font-weight: 500;"> {{ item.title}}
                            </h2>
                            <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="config.title == 'BBold'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="tab-image image-1" [class.earthy-tabimage]="config.title == 'BBold'">
                    <a>
                        <div class="background-image" (click)="categoryListing(item.title)"
                            style="background-image: url({{item.image}});">
                            <div class="bbold-feature-collections"></div>
                        </div>
                        <div class="bg-content">
                            <h2 class="titleproper" *ngIf="item.title && config.title == 'BBold'"
                                style="font-size: 2.7rem; text-transform: uppercase; font-weight: 550;color: #010101; "> {{ item.title}}
                            </h2>
                            <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="config.title == 'Aiema'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="tab-image image-1" style="background-image: linear-gradient(to right top, #009b95, #008794, #00718d, #005c82, #004771, #004771, #004771, #004771, #005c82, #00718d, #008794, #009b95);">
                    <a>
                        <div class="background-image" (click)="categoryListing1(item.title)"
                            style="background-image: url({{item.image}});background-size: 100% 110%;">
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- <div *ngIf="config.title == 'BombayHardware'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="tab-image image-1" style="background-image: linear-gradient(to right top, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A, #AD342A);">
                    <a>
                        <div class="background-image" (click)="categoryListing1(item.title)"
                            style="background-image: url({{item.image}});background-size: 100% 110%;">
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div> -->

<!-- <div *ngIf="config.title == 'BombayHardware'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="col-md-12" style="margin-left: 100px;">
                    <div class="tab-image image-1" [class.bombayhardware-tabimage]="config.title == 'BombayHardware'">
                        <a (click)="categoryListing(item.title)">
                            <div class="background-image"
                                style="background-image: url({{item.image}});">
                                <div class="subhcards-feature-collections"></div>
                            </div>
                            <div class="bg-content">
                                <h2 class="titleproper" *ngIf="item.title && config.title == 'BombayHardware'"
                                    style="font-size: 1.4rem; text-transform: uppercase; font-weight: 500; font-family: Lucida Handwriting;">
                                    {{ item.title}}
                                </h2>
                                <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>  -->

<div class="row" *ngIf="config.title == 'BombayHardware'">
    <ng-container *ngFor="let item of collections; let i = index">
            <div class="col-sm-4 col-md-2" >
                <div class="tab-image1 image-1">
                    <a (click)="categoryListing(item.title)" >
                    <div class="background-image1" style="background-image: url({{item.image}});"></div>
                    <div class="bg-content">
                            <h2 class="titleproper" *ngIf="item.title" style="font-size: 2.4rem;color: #AD342A;font-weight: 750"></h2>
                            <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem;color: #AD342A">{{item.subtitle}}</p>
                    </div>
                </a>
            </div>
            </div>
    </ng-container>
    </div>

    <div class="row" *ngIf="config.title == 'BombayHardware'">
        <ng-container *ngFor="let item of collections1; let i = index">
                <div class="col-sm-4 col-md-3" >
                    <div class="tab-image image-1">
                        <a (click)="categoryListing(item.title)" >
                        <div class="background-image" style="background-image: url({{item.image}});"></div>
                        <div class="bg-content">
                                <h2 class="titleproper" *ngIf="item.title" style="font-size: 2.4rem;color: #AD342A;font-weight: 750"></h2>
                                <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem;color: #AD342A">{{item.subtitle}}</p>
                        </div>
                    </a>
                </div>
                </div>
        </ng-container>
        </div>

        <div class="row" *ngIf="config.title == 'BombayHardware'">
            <ng-container *ngFor="let item of collections2; let i = index">
                    <div class="col-sm-4 col-md-4" >
                        <div class="tab-image image-1">
                            <a (click)="categoryListing(item.title)" >
                            <div class="background-image" style="background-image: url({{item.image}});"></div>
                            <div class="bg-content">
                                    <h2 class="titleproper" *ngIf="item.title" style="font-size: 2.4rem;color: #AD342A;font-weight: 750"></h2>
                                    <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem;color: #AD342A">{{item.subtitle}}</p>
                            </div>
                        </a>
                    </div>
                    </div>
            </ng-container>
            </div>

    

<div *ngIf="config.title == 'LuckMe'">
    <div class="row" id="luckmebrands">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="tab-image image-1" [class.luckme-tabimage]="config.title == 'LuckMe'">
                    <a>
                        <div class="background-image" (click)="brandListing(item.title)"
                            style="background-image: url({{item.image}});">
                            <div class="luckme-feature-collections"></div>
                        </div>
                        <div class="bg-content">
                            <!-- <h2 class="titleproper" *ngIf="item.title"   style="font-size: 1.3rem; text-transform: uppercase; font-weight: 500;"> {{ item.title}} </h2> -->
                            <!-- <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}</p> -->
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>

 <div *ngIf="config.title == 'ShubhCards'">
    <div class="row" id="shubhcards">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="col-md-12" style="margin-left: 100px;">
                    <div class="tab-image image-1" [class.shubcards-tabimage]="config.title == 'ShubhCards'">
                        <a>
                            <div class="background-image" (click)="categoryListing(item.title)"
                                style="background-image: url({{item.image}});">
                                <div class="subhcards-feature-collections"></div>
                            </div>
                            <div class="bg-content">
                                <h2 class="titleproper" *ngIf="item.title && config.title == 'ShubhCards'"
                                    style="font-size: 1.4rem; text-transform: uppercase; font-weight: bold;color:black; font-family: Lucida Handwriting;">
                                    {{ item.title}}
                                </h2>
                                <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div> 


<div *ngIf="config.title == 'Deera'">
    <div class="row">
        <ng-container *ngFor="let item of collections; let i = index">
            <div [class]="getClass(i)">
                <div class="col-md-12" style="margin-left: 100px;">
                    <!-- <h2 class="titleproper"
                    style="font-size: 1.4rem; text-transform: uppercase; font-weight: bold;color:black; font-family: Lucida Handwriting;text-align: center;">
                    {{ item.title}}
                </h2> -->
                <h2 class="titleproper"
                style="font-size: 1.4rem; text-transform: uppercase; font-weight: bold;color:black;text-align: center;">
                {{ item.title}}
            </h2>
                    <div class="tab-image image-1" [class.shubcards-tabimage1]="config.title == 'Deera'">
                        <a>
                            <div class="background-image" (click)="categoryListing(item.title)"
                                style="background-image: url({{item.image}});">
                             <div class="feature-collections"></div>
                            </div>
                            <div class="bg-content">
                                <!-- <h2 class="titleproper"
                                    style="font-size: 1.4rem; text-transform: uppercase; font-weight: bold;color:black; font-family: Lucida Handwriting;">
                                    {{ item.title}}
                                </h2> -->
                                <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div> 