<section>
    <!-- LOGGED IN USER -->
    <div *ngIf="screenType === 'loggedIn'" style="padding: 10px 10px 8px 20px;">
        <div class="row">
            <div class="col-md-10">
                <p class="shipping-font">{{emailormobile}}</p>
            </div>
            <div class="col-md-2">
                <button class="btn btn-secondary btn-secondary1" (click)="changeForm('loggedOut')">CHANGE</button>
            </div>
        </div>
    </div>
    <!-- LOGGED OUT OPTION -->
    <div *ngIf="screenType === 'loggedOut'" style="padding: 10px 10px 15px 20px;">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex justify-content-start">
                    <p class="shipping-font" *ngIf="userIdType === 'email'" style="color: #777;">Email</p>
                    <p class="shipping-font" *ngIf="userIdType === 'mobile'" style="color: #777;">Mobile</p>
                    <label class="shipping-font" style="padding-left: 10px;">{{emailormobile}}</label>
                </div>
                <a class="login-link-color" style="font-weight: 500; cursor: pointer; font-size: 14px;" (click)="logout()">SignOut and Signin to another account</a>
                <button class="btn btn-block btn-primary btn-primary1" (click)="nextTab()">CONTINUE</button>
            </div>
        </div>
    </div>
    <div *ngIf="screenType !== 'loggedIn' && screenType !== 'loggedOut'">
        <div class="customer-container">
            <!-- INITIAL FORM -->
            <div *ngIf="screenType === 'initial'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "initialForm">
                            <div *ngIf="hideMobile">
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialForm && f.email.errors" placeholder="Email/Mobile No">
                                        <span class="checkout-label">Email/Mobile No*</span>
                                        <div class="invalid-feedback" *ngIf="f.email.errors">
                                            <div *ngIf="f.email.errors.required">Please enter Email/Mobile Number.</div>
                                            <div *ngIf="f.email.errors.incorrect">Please enter a valid Email/Mobile Number.</div>
                                        </div> 
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="!hideMobile">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-9" style="padding-right: 0px !important;">
                                            <label>
                                                <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialForm && f.email.errors" placeholder="Email/Mobile No" readonly>
                                                <span class="checkout-label">Email/Mobile No*</span>
                                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                                    <div *ngIf="f.email.errors.required">Please enter Email/Mobile Number.</div>
                                                    <div *ngIf="f.email.errors.incorrect">Please enter a valid Email/Mobile Number.</div>
                                                </div> 
                                            </label> 
                                        </div>
                                        <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                            <label>
                                                <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                            </label>                                   
                                        </div> 
                                    </div>                 
                                </div>
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateInitialForm && f.mobile.errors" placeholder="Mobile No">
                                        <span class="checkout-label">Mobile No*</span>
                                        <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                            <div *ngIf="f.mobile.errors.required">Please enter Mobile Number.</div>
                                            <div *ngIf="f.mobile.errors.incorrect">Please enter a valid Mobile Number.</div>
                                            <div *ngIf="f.mobile.errors.exists">This Mobile Number already registered with us. Try to Login!</div>
                                        </div> 
                                    </label>
                                </div>
                                <button class="btn btn-block btn-primary btn-primary1" (click)="verifyUserDetails()" [disabled]="continue1"><P *ngIf="continue1" class="spinner-border spinner-border-sm">CONTINUE</button>
                            </div>
                            <button *ngIf="hideMobile" class="btn btn-block btn-primary btn-primary1" (click)="verifyUser()" [disabled]="continue2"><P *ngIf="continue2" class="spinner-border spinner-border-sm"></P>CONTINUE</button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- LOGIN FORM -->
            <div *ngIf="screenType === 'login'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "passwordForm">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validatePasswordForm && f1.mobileoremail.errors" placeholder="Email/Mobile No" readonly>
                                            <span class="checkout-label">Email/Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showLoginpaswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validatePasswordForm && f1.password.errors" placeholder="Password">
                                            <span class="checkout-label">Password*</span>
                                            <div class="invalid-feedback" *ngIf="f1.password.errors">
                                                <div *ngIf="f1.password.errors.required">Please enter the password</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showLoginPassword()" [class]="showLoginpaswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <div class="d-flex justify-content-end mt-1">
                            <a class="forgot-link fcolor" (click)="changeForm('forgotPassword')"> Forgot password?</a>
                        </div>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinwithPassword()">SIGNIN</button>
                        <div class="text-center" style="padding: 5px 0px;">
                            <p>OR</p>
                        </div>
                        <button class="btn btn-block btn-primary btn-secondary1" (click)="requesttogetOTP()">REQUEST OTP</button>
                    </div>
                </div>
            </div>
            <!-- OTP LOGIN -->
            <div *ngIf="screenType === 'otploginwithoutpswd'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "otpLoginWithoutPswd">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.mobileoremail.errors" placeholder="Email/Mobile No" readonly>
                                            <span class="checkout-label">Email/Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f6.otp.errors">
                                                <div *ngIf="f6.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('otplogin1')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small *ngIf="userIdType === 'mobile'" class="help-text">Please enter the OTP that has been sent to your Registered Mobile Number</small>
                                <small *ngIf="userIdType === 'email'" class="help-text">Please enter the OTP that has been sent to your Registered Email</small>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showotpwithoutpswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.password.errors" placeholder="Set Password">
                                            <span class="checkout-label">Set Password</span>
                                            <div class="invalid-feedback" *ngIf="f6.password.errors">
                                                <div *ngIf="f6.password.errors.maxlength && !f5.password.errors.minlength">Password should not be more than 20 characters</div>
                                                <div *ngIf="f6.password.errors.minlength && !f5.password.errors.maxlength">Password should not be less than 6 characters</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showOTPWithoutPswd()" [class]="showotpwithoutpswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinwithOTPWTPassword()">SIGNIN</button>
                    </div>
                </div>
            </div>
            <!-- OTP LOGIN FORM WITHOUT PASSWORD -->
            <div *ngIf="screenType === 'requestOTPLoginForm'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "requestOTPLoginForm">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validaterequestOTPLoginForm && f7.mobileoremail.errors" placeholder="Email/Mobile No" readonly>
                                            <span class="checkout-label">Email/Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validaterequestOTPLoginForm && f7.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f7.otp.errors">
                                                <div *ngIf="f7.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('otplogin2')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small *ngIf="userIdType === 'mobile'" class="help-text">Please enter the OTP that has been sent to your Registered Mobile Number</small>
                                <small *ngIf="userIdType === 'email'" class="help-text">Please enter the OTP that has been sent to your Registered Email</small>
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinwithOTPPassword()">SIGNIN</button>
                    </div>
                </div>
            </div>
            <!-- FORGOT PASSWORD -->
            <div *ngIf="screenType === 'forgotPassword'">
                <div class="row">
                    <div class="col-md-7">
                        <form class="checkout-forms" [formGroup]= "forgotPasswordForm">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validateForgotPasswordForm && f5.mobileoremail.errors" placeholder="Email/Mobile No" readonly>
                                            <span class="checkout-label">Email/Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateForgotPasswordForm && f5.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f5.otp.errors">
                                                <div *ngIf="f5.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('otp1')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small class="help-text">Please enter the OTP that has been sent to your Registered Mobile Number</small>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showForgotPswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateForgotPasswordForm && f5.password.errors" placeholder="Set Password">
                                            <span class="checkout-label">Set Password*</span>
                                            <div class="invalid-feedback" *ngIf="f5.password.errors">
                                                <div *ngIf="f5.password.errors.maxlength && !f5.password.errors.minlength">Password should not be more than 20 characters</div>
                                                <div *ngIf="f5.password.errors.minlength && !f5.password.errors.maxlength">Password should not be less than 6 characters</div>
                                                <div *ngIf="f5.password.errors.required">Please set the password for your account</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showForgotPassword()" [class]="showForgotPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="forgotPassword()">SIGNIN</button>
                    </div>
                </div>
            </div>
            <!-- INITIAL REGISTRATION FORM -->
            <div *ngIf="screenType === 'initialRegistration'">
                <div class="row">
                    <div class="col-md-6" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "initialRegistration">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateInitialRegistration && f2.mobile.errors" placeholder="Mobile No" readonly>
                                            <span class="checkout-label">Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div *ngIf="userIdType === 'email'">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-9" style="padding-right: 0px !important;">
                                            <label>
                                                <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialRegistration && f2.email.errors" placeholder="Email" readonly>
                                                <span class="checkout-label">Email*</span>
                                            </label> 
                                        </div>
                                        <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                            <label>
                                                <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                            </label>                                   
                                        </div> 
                                    </div>                 
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateInitialRegistration && f2.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f2.otp.errors">
                                                <div *ngIf="f2.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('forgot')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small class="help-text">Please enter the OTP that has been sent to your Mobile Number</small>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showregistrationPswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateInitialRegistration && f2.password.errors" placeholder="Set Password">
                                            <span class="checkout-label">Set Password*</span>
                                            <div class="invalid-feedback" *ngIf="f2.password.errors">
                                                <div *ngIf="f2.password.errors.maxlength && !f2.password.errors.minlength">Password should not be more than 20 characters</div>
                                                <div *ngIf="f2.password.errors.minlength && !f2.password.errors.maxlength">Password should not be less than 6 characters</div>
                                                <div *ngIf="f2.password.errors.incorrect">Please set the password for your account</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showRegistrationPassword()" [class]="showregistrationPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <div *ngIf="enableBusinessUser">
                            <div style="margin-top: 25px;">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" value="individual" (change)="getUserType($event.target.value)" [checked]="customerType === 'individual'">
                                    <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline1">Individual User</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline custom-radios">
                                    <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" value="business" (change)="getUserType($event.target.value)">
                                    <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline2">Business User</label>
                                </div>
                            </div>
                        </div>
                        <button *ngIf="!is_mandatory.individual && customerType === 'individual'" class="btn btn-block btn-primary btn-primary1" (click)="signinWithOtp()">SIGNIN</button>
                        <button *ngIf="!is_mandatory.business && customerType === 'business'" class="btn btn-block btn-primary btn-primary1" (click)="signinWithOtp()">SIGNIN</button>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="is_mandatory.individual && customerType === 'individual'">
                            <form class="checkout-forms" [formGroup]= "individualRegistration">
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="name" [class.is-invalid] = "validateIndividualRegistration && f3.name.errors" placeholder="Name">
                                        <span class="checkout-label">Name*</span>
                                        <div class="invalid-feedback" *ngIf="f3.name.errors">
                                            <div *ngIf="f3.name.errors.required">Please enter Name.</div>
                                            <div *ngIf="f3.name.errors.pattern">Enter a valid Name</div>
                                            <div *ngIf="f3.name.errors.maxlength">Name should not have more than 80 characters</div>
                                            <div *ngIf="f3.name.errors.minlength">Name should not have less than 2 characters</div>
                                        </div> 
                                    </label>
                                </div>
                            </form>
                            <div *ngIf="registrationOfferDetails.inidividual && registrationOfferDetails.available">
                                <div class="row" style="margin-top: 30px;">
                                    <div class="col-md-8">
                                        <p>We are offering free sample to first 1000 users, Want one?</p>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="checkbox" id="availOffer" name="availOffer" class="text-primary" style="width: 15px !important;height: 15px !important;" (change)="availOffer()" [checked]="offerAvailed">
                                    </div>
                                </div>
                                <div *ngIf="selectedAddress" class="row" style="padding-left: 15px;">
                                    <p class="text-primary" style="padding-bottom: 5px; padding-top: 15px;"><i class="fa fa-truck" aria-hidden="true"></i> Free Samples will be delivered to: <a class="text-primary" style="cursor: pointer;color: #777;" (click)="editCustomerAddress()">(Change)</a></p>
                                    <p style="font-size: 13px;">{{selectedAddress.entire_location}}</p>
                                </div>
                            </div>
                            <button class="btn btn-block btn-primary btn-primary1" (click)="individualUserRegistration()" [disabled]="signup1"><P *ngIf="signup1" class="spinner-border spinner-border-sm"></P> SIGNUP</button>
                        </div>
                        <div *ngIf="is_mandatory.business && customerType === 'business'">
                            <form class="checkout-forms" [formGroup]= "businessRegistration">
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="name" [class.is-invalid] = "validateBusinessRegistration && f4.name.errors" placeholder="Name">
                                        <span class="checkout-label">Name*</span>
                                        <div class="invalid-feedback" *ngIf="f4.name.errors">
                                            <div *ngIf="f4.name.errors.required">Please enter Name.</div>
                                            <div *ngIf="f4.name.errors.pattern">Enter a valid Name</div>
                                            <div *ngIf="f4.name.errors.maxlength">Name should not have more than 80 characters</div>
                                            <div *ngIf="f4.name.errors.minlength">Name should not have less than 2 characters</div>
                                        </div> 
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="company" [class.is-invalid] = "validateBusinessRegistration && f4.company.errors" placeholder="Company Name">
                                        <span class="checkout-label">Company Name</span>
                                        <div class="invalid-feedback" *ngIf="f4.company.errors">
                                            <div class="invalid-feedback" *ngIf="f4.company.errors">
                                                <div *ngIf="f4.company.errors.maxlength">Company Name should not be more than 80 characters</div>
                                                <div *ngIf="f4.company.errors.minlength">Company Name should not be less than 5 characters</div>
                                            </div>
                                        </div> 
                                    </label>                    
                                </div>
                                <div class="form-group">
                                    <label>
                                        <textarea class="form-control" formControlName="company_address" [class.is-invalid] = "validateBusinessRegistration && f4.company_address.errors" placeholder="Comapany Address"></textarea>
                                        <span class="checkout-label">Comapany Address</span>
                                        <div class="invalid-feedback" *ngIf="f4.company_address.errors">
                                            <div *ngIf="f4.company_address.errors.maxlength">Company Name should not be more than 300 characters</div>
                                            <div *ngIf="f4.company_address.errors.minlength">Company Name should not be less than 5 characters</div>
                                        </div> 
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="gst_no" [class.is-invalid] = "validateBusinessRegistration && f4.gst_no.errors" placeholder="GST Number">
                                        <span class="checkout-label">GST Number</span>
                                        <div class="invalid-feedback" *ngIf="f4.gst_no.errors">
                                            <div *ngIf="f4.gst_no.errors.maxlength && !f4.gst_no.errors.minlength">Enter a valid GST Number.</div>
                                            <div *ngIf="f4.gst_no.errors.minlength && !f4.gst_no.errors.maxlength">Enter a valid GST Number.</div>
                                        </div> 
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <input type="text" class="form-control" formControlName="pan_no" [class.is-invalid] = "validateBusinessRegistration && f4.pan_no.errors" placeholder="GST Number">
                                        <span class="checkout-label">PAN Number</span>
                                        <div class="invalid-feedback" *ngIf="f4.pan_no.errors">
                                            <div *ngIf="f4.pan_no.errors.maxlength">Enter a valid PAN Number.</div>
                                            <div *ngIf="f4.pan_no.errors.minlength">Enter a valid PAN Number.</div>
                                        </div> 
                                    </label>
                                </div>
                            </form>
                            <div *ngIf="registrationOfferDetails.business && registrationOfferDetails.available">
                                <div class="row" style="margin-top: 30px;">
                                    <div class="col-md-8">
                                        <p>We are offering free sample to first 1000 users, Want one?</p>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="checkbox" id="availOffer" name="availOffer" class="text-primary" style="width: 15px !important;height: 15px !important;" (change)="availOffer()" [checked]="offerAvailed">
                                    </div>
                                </div>
                                <div *ngIf="selectedAddress" class="row" style="padding-left: 15px;">
                                    <p class="text-primary" style="padding-bottom: 5px; padding-top: 15px;"><i class="fa fa-truck" aria-hidden="true"></i> Free Samples will be delivered to: <a class="text-primary" style="cursor: pointer;color: #777;" (click)="editCustomerAddress()">(Change)</a></p>
                                    <p style="font-size: 13px;">{{selectedAddress.entire_location}}</p>
                                </div>
                            </div>
                            <button class="btn btn-block btn-primary btn-primary1" (click)="businessUserRegistration()" [disabled]="signup2"><P *ngIf="signup2" class="spinner-border spinner-border-sm"></P> SIGNUP</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- EMAIL UPDATE FORM1 -->
            <div *ngIf="screenType === 'updateEmailForm1'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "updateEmailForm1">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm1 && f8.email.errors" placeholder="Email" readonly>
                                            <span class="checkout-label">Email*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm1 && f8.mobile.errors" placeholder="Mobile No" readonly>
                                            <span class="checkout-label">Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateUpdateEmailForm1 && f8.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f8.otp.errors">
                                                <div *ngIf="f8.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('updateEmail1')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small class="help-text">Please enter the OTP that has been sent to your Email</small>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showUpdateEmailForm1 ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateUpdateEmailForm1 && f8.password.errors" placeholder="Set Password">
                                            <span class="checkout-label">Set Password*</span>
                                            <div class="invalid-feedback" *ngIf="f8.password.errors">
                                                <div *ngIf="f8.password.errors.maxlength && !f8.password.errors.minlength">Password should not be more than 20 characters</div>
                                                <div *ngIf="f8.password.errors.minlength && !f8.password.errors.maxlength">Password should not be less than 6 characters</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showUpdateEmailForm1M()" [class]="showUpdateEmailForm1 ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm1()">SIGNIN</button>
                    </div>
                </div>
            </div>
            <!-- EMAIL UPDATE FORM3 -->
            <div *ngIf="screenType === 'updateEmailForm3'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "updateEmailForm3">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm3 && f9.email.errors" placeholder="Email" readonly>
                                            <span class="checkout-label">Email*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm3 && f9.mobile.errors" placeholder="Mobile No" readonly>
                                            <span class="checkout-label">Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-10" style="padding-right: 0px !important;">
                                        <label>
                                            <input [type]="showUpdateEmailForm3 ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateUpdateEmailForm3 && f9.password.errors" placeholder="Password">
                                            <span class="checkout-label">Password*</span>
                                            <div class="invalid-feedback" *ngIf="f9.password.errors">
                                                <div *ngIf="f9.password.errors.required">Please enter the password</div>
                                            </div>
                                        </label> 
                                    </div>
                                    <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <i (click)="showUpdateEmailForm3M()" [class]="showUpdateEmailForm3 ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm3()">SIGNIN</button>
                        <div class="text-center" style="padding: 5px 0px;">
                            <p>OR</p>
                        </div>
                        <button class="btn btn-block btn-primary btn-secondary1" (click)="requesttogetOTPpswd()">REQUEST OTP</button>
                    </div>
                </div>
            </div>
            <!-- EMAIL UPDATE FORM2 -->
            <div *ngIf="screenType === 'updateEmailForm2'">
                <div class="row">
                    <div class="col-md-7" style="border-right: 1px solid rgb(235, 234, 234);">
                        <form class="checkout-forms" [formGroup]= "updateEmailForm2">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm2 && f10.email.errors" placeholder="Email" readonly>
                                            <span class="checkout-label">Email*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm2 && f10.mobile.errors" placeholder="Mobile No" readonly>
                                            <span class="checkout-label">Mobile No*</span>
                                        </label> 
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="changeForm('initialstate')">Change?</a>
                                        </label>                                   
                                    </div> 
                                </div>                 
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-9" style="padding-right: 0px !important;">
                                        <label>
                                            <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateUpdateEmailForm2 && f10.otp.errors" placeholder="OTP">
                                            <span class="checkout-label">OTP*</span>
                                            <div class="invalid-feedback" *ngIf="f10.otp.errors">
                                                <div *ngIf="f10.otp.errors.required">Please enter OTP.</div>
                                            </div> 
                                        </label>
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                        <label>
                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;" (click)="resendCode('updateEmail2')">Resend?</a>
                                        </label>                                   
                                    </div>
                                </div>
                                <small class="help-text">Please enter the OTP that has been sent to your Email</small>
                            </div>
                        </form>
                        <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm2()">SIGNIN</button>
                    </div>
                </div>
            </div>
            <!-- CUSTOMER ADDRESS FOR FREE SAMPLE -->
            <div *ngIf="launchAddress">
                <div class="modal-content status-model-container text-center" style="margin-top: 20px;">
                    <div class="modal-body" style="border: none !important;">
                        <h4 class="text-primary">Add Shipping Address to deliver Free Sample</h4>
                        <div style="padding: 15px 15px;">
                            <form class="checkout-forms" [formGroup]="shippingForm">
                                <div class="row">
                                    <div class="col-md-4">
                                        <button class="btn btn-block btn-primary btn-primary1" (click)="getCurrentLocation()" style="margin-top: 0px !important; margin-bottom: 5px !important;"><i class="fa fa-map-marker"></i> USE MY LOCATION</button>
                                    </div>
                                </div>
                                <small *ngIf="location_bloked" style="font-size: 12px; color: #c21212; margin-top: 5px !important;">Oops! It seems like you have blocked accesing your location. <br><a style="font-size: 12px; color: blue !important;" href="https://support.google.com/chrome/answer/142065?hl=en" target="_blank">Click here to get instructions to reactivate the location sharing.</a></small>
                                <small *ngIf="!is_deliverable" style="font-size: 14px; color: #c21212; margin-top: 10px !important; margin-bottom: 10px;">Sorry, We can't deliver to your Location</small>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <input ngx-google-places-autocomplete [options]='optionss' id="enterlocation" type="text" class="form-control" formControlName="location" [class.is-invalid]="validateShippingForm && f11.location.errors" placeholder="Enter Location" #placesRef="ngx-places" (onAddressChange)="changeAddress($event)">
                                                <span class="checkout-label">Enter Location*</span>
                                                <div class="invalid-feedback" *ngIf="f11.location.errors">
                                                    <div *ngIf="f11.location.errors.required">Please select your location from Maps.</div>
                                                    <div *ngIf="f11.location.errors.incorrect"></div>
                                                    <div *ngIf="f11.location.errors.incorrect1">Please enter a valid Address.</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="flat_no" [class.is-invalid]="validateShippingForm && f11.flat_no.errors" placeholder="Flat No/Building Name/Street Name">
                                                <span class="checkout-label">Flat No/Building Name/Street Name*</span>
                                                <div class="invalid-feedback" *ngIf="f11.flat_no.errors">
                                                    <div *ngIf="f11.flat_no.errors.required">Please enter the Flat No/Building Name/Street Name.</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="landmark" [class.is-invalid]="validateShippingForm && f11.landmark.errors" placeholder="Flat No/Building Name/Street Name">
                                                <span class="checkout-label">Landmark</span>
                                                <div class="invalid-feedback" *ngIf="f11.landmark.errors">
                                                    <!-- <div *ngIf="f.landmark.errors.required">Please select your location from Maps.</div> -->
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="city" [class.is-invalid]="validateShippingForm && f11.city.errors" placeholder="City" readonly>
                                                <span class="checkout-label">City</span>
                                                <div class="invalid-feedback" *ngIf="f11.city.errors">
                                                    <!-- <div *ngIf="f.city.errors.required">Please enter the city.</div> -->
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="mobile" [class.is-invalid]="validateShippingForm && f11.mobile.errors" placeholder="Mobile Number">
                                                <span class="checkout-label">Mobile Number*</span>
                                                <div class="invalid-feedback" *ngIf="f11.mobile.errors">
                                                    <div *ngIf="f11.mobile.errors.required">Please enter Mobile Number.</div>
                                                    <div *ngIf="f11.mobile.errors.pattern && (!f11.mobile.errors.maxlength || !f11.mobile.errors.minlength)">Enter a valid Mobile Number</div>
                                                    <div *ngIf="!f11.mobile.errors.pattern && f11.mobile.errors.maxlength">Enter a valid Mobile Number</div>
                                                    <div *ngIf="!f11.mobile.errors.pattern && f11.mobile.errors.minlength">Enter a valid Mobile Number</div>
                                                    <div *ngIf="f11.mobile.errors.incorrect">Enter a valid Mobile Number</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="email" [class.is-invalid]="validateShippingForm && f11.email.errors" placeholder="Email">
                                                <span class="checkout-label">Email</span>
                                                <div class="invalid-feedback" *ngIf="f11.email.errors">
                                                    <div *ngIf="f11.email.errors.pattern">Enter a valid Email</div>
                                                    <div *ngIf="f11.email.errors.maxlength">Email should not be more than 50 Charchters</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>
                                                <input type="text" class="form-control" formControlName="full_name" [class.is-invalid]="validateShippingForm && f11.full_name.errors" placeholder="Full Name">
                                                <span class="checkout-label">Full Name*</span>
                                                <div class="invalid-feedback" *ngIf="f11.full_name.errors">
                                                    <div *ngIf="f11.full_name.errors.required">Please enter Name.</div>
                                                    <div *ngIf="f11.full_name.errors.pattern">Enter a valid Name</div>
                                                    <div *ngIf="f11.full_name.errors.maxlength">Name should not be more than 50 Charchters</div>
                                                    <div *ngIf="f11.full_name.errors.incorrect">Enter a valid Name</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <agm-map [latitude]="latitude" [longitude]="longitude"  [streetViewControl]=false [zoom]="zoom" (mapClick)="selectLocation($event)">
                                            <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                            <button>Current Location</button>
                                        </agm-map>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-md-4">
                                    <button class="btn btn-block btn-primary btn-primary1" (click)="saveAddress()" [disabled]="saveaddSpinner"><P *ngIf="saveaddSpinner" class="spinner-border spinner-border-sm"></P> SAVE</button>
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-block btn-primary btn-secondary1" style="margin-top: 20px;" (click)="cancelSave()">CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>        
                  </div>
            </div>
        </div>
    </div>
</section>
