<div class="faq-page">
  <br>
<br>
<h2 class="text-center text-primary"> How can we help you?</h2>
<br>
<form class="example">
  <div class="row">
    <div class="col-sm-1"></div>
    <input class="col-sm-10" type="text" placeholder="&#xF002;  Search..  " name="search" (keyup)="filter($event)">
    <!-- <button type="submit"><i class="fa fa-search"></i></button> -->
    <div class="col-sm-1"></div>
  </div>
</form>

<br>
<br>

<div class="container">
  <div *ngFor="let item of dataOptions;let i = index;">
    <button class="accordion" (click)="toggleAccordian($event, i)" id="{{item.title}}"> {{item.title}} </button>
    <div class="panel" *ngFor="let child of item.childProperties" hide="!item.isActive">
      <p> {{child.description}} </p>
    </div>
    <!-- <hr> -->
  </div>
  
</div>

<br><br><br>
</div>