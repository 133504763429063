import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html'
})
export class ProductReviewComponent implements OnInit {
    productRating : FormGroup;
    customerRating : FormGroup;
    customer_id;
    parameters: any;
    orderdetails: any=[];
    payment_information: any=[];
    summaryShippingInfo;
    reviewData: any;
    productListArray: any =[];
    products: boolean = false;
    total: any;
    subtotal: any;
    shipping: any;
    tax: any;
    orderedOn: any;
    orderId: any;
    orderStatus: any;
    id : any;
    product_id : any

    review_list : any = []

    total_rating : number = 0;
    product_rating : any;
    one_star : number = 0;
    two_star : number = 0;
    three_star : number = 0;
    four_star : number = 0;
    five_star : number = 0;

  width1 : number = 0;
  width2 : number=0;
  width3 : number=0;
  width4 : number=0;
  width5 : number=0;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localStorageService : LocalStorageService) { }

  ngOnInit(): void {

    this.productRating = new FormGroup({
      rating: this.formBuilder.control(0, [])
    })

    this.customerRating = new FormGroup({
      rating: this.formBuilder.control(0, [])
    })
    this.route.queryParams.subscribe(params => {
      this.parameters = this.route.snapshot.params;
      this.id = this.parameters['id']
    }
    )

    this.getReviewDetails()
  }

  getReviewDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "product_id" : this.id
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody,ENDPOINTS.GETALLREVIEW,).subscribe(
      resp => {
      
        if (resp) {
          
          let review = resp.result.data;
          this.reviewData = resp.result.data;
          let reviewList = resp.result.data;
          reviewList.forEach(e=>{
            if(e.status == "Approved"){
              this.review_list.push(e)
            }
          })

          if(review !== undefined && review.length !== 0){
          this.total_rating = review.length
          // this.productRating.controls.rating.setValue(this.review_list[0].rating)
          console.log("total", this.total_rating)
           review.forEach(element => {
             if(element.status == "Approved"){
             if(element.rating == 1){
               this.one_star = this.one_star + 1
             }
             else if(element.rating == 2){
              this.two_star = this.two_star + 1
            }
            else if(element.rating == 3){
              this.three_star = this.three_star + 1
            }
            else if(element.rating == 4){
              this.four_star = this.four_star + 1
            }
            else if(element.rating == 5){
              this.five_star = this.five_star + 1
            }
          }
           });

           this.width1 = (this.one_star*100)/this.total_rating
           this.width2 = (this.two_star*100)/this.total_rating
           this.width3 = (this.three_star*100)/this.total_rating
           this.width4 = (this.four_star*100)/this.total_rating
           this.width5 = (this.five_star*100)/this.total_rating

           this.product_rating = (((1*this.one_star) + (2*this.two_star) + (3*this.three_star) + (4*this.four_star) + (5*this.five_star)) / ((this.one_star) + (this.two_star) + (this.three_star) + (this.four_star) + (this.five_star))).toFixed(1)
           this.productRating.controls.rating.setValue(parseInt(this.product_rating).toString())
          }


        
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }


}
