import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { ENDPOINTS } from '../../app.config';
import { CheckoutApiService } from '../checkout-api.service';
import {config} from '../../../configs/config';

@Component({
  selector: 'app-checkout-header',
  templateUrl: './checkout-header.component.html'
})
export class CheckoutHeaderComponent implements OnInit {

  logoImage:any;
  bannerMessage:string;
  config = config;

  constructor(
    private checkoutApiService: CheckoutApiService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.getLogoDetails();
    this.getBannerMessage();
  }

  getLogoDetails(){
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.STOREGALLERY).then(
      respData =>{
        let resp:any = respData;
        if(resp.success){
          resp = resp.data[0];
          console.log()
          this.logoImage = resp.logo.logo_image_source;
        }
        else{

        }
    })
  }

  getBannerMessage(){
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.PROMOTIONS_BANNER).then(
      respData => {
        let resp:any = respData
        if(resp.success){
          resp = resp.data[0];
          this.bannerMessage = resp.eligible_banner_message;
          // this.bannerMessage = "GET 5% DISCOUNT ON ORDERING FOOD BEFORE 5 HRS."
        }
      }
    )
  }

  navigateHome(){
    this.router.navigate([''])
  }

}
