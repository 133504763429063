<section>
    <app-alerts *ngIf="showAlerts"></app-alerts>
    <div *ngIf="cartItems.length === 0" class="cart-new-container">
        <div class="text-center">
            <div><img class="cart-nw-img" src="../../assets/images/emptyart3.png"></div>
            <div class="cart-em-header">Your cart is empty</div>
            <div><p style="color: rgb(110, 110, 110); padding-top: 10px; font-size: 0.95rem;">Looks like you have not made your choice yet...</p></div>
            <div><button class="btn btn-primary custom-btn" [routerLink]="['/product-listing']">Back to Menu</button></div>
            <!-- style="background-color: #f44336 !important; opacity: 0.8;" -->
        </div>
    </div>
    <div *ngIf="cartItems.length > 0">
        <div class="row cart-mn-container">
            <div class="col-md-8">
                <div>
                    <h4 class="pb-2" style="font-weight: lighter;">MY CART</h4>
                </div>
                <div>
                    <div *ngFor="let item of cartItems; let i = index">
                        <div *ngIf="item.product">
                            <div class="card mb-3 cart-new-card">
                                <div class="row no-gutters">
                                    <div class="col-md-3">
                                        <img class="card-img cart-new-image" alt="productImage" *ngIf="item.product.assets.length > 0 && item.product.assets[0].image_source !== ''" [src]="item.product.assets[0].image_source">
                                        <img class="card-img cart-new-image" alt="productImage" *ngIf="item.product.assets.length === 0 || item.product.assets[0].image_source === ''" src="../../assets/images/vendor/No_Image_old2.png">
                                    </div>
                                    <div class="col-md-9">
                                        <div class="card-body cart-body">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <h5 class="card-title" style="margin-bottom: 6px !important;">{{item.name}}</h5>
                                                </div>
                                                <div class="col-md-3">
                                                    <div *ngIf="item.customized" class="cart-customize" (click)="customizeItem(item,i)">Customize...</div>
                                                </div>
                                            </div>
                                            <p class="cp-price" style="padding-top: 0px; padding-bottom: 0px;"><i class="fa fa-rupee"></i>{{item.customized ? item.package_total : item.product.pricing.raw.price}}</p>
                                            <p class="card-text"><small class="text-muted" *ngIf="item.bundle_products.length > 0 || item.bundle_products.length > 0">(Served with {{getproductNameList(item.bundle_products) | titlecase}}</small><small class="text-muted" *ngIf="item.add_onproducts.length === 0 && item.bundle_products.length > 0">)</small><span><small class="text-muted" *ngIf="item.add_onproducts.length > 0 || item.add_onproducts.length > 0"> & Add-ons: {{getproductNameList(item.add_onproducts) | titlecase}})</small></span></p>
                                        </div>
                                        <div class="cart-footer">
                                            <div class="row" style="height: 100%;">
                                                <div class="col-md-6 text-center cart-a">
                                                    <p class="cart-btn" (click)="removecartItem(i)">REMOVE</p>
                                                </div>
                                                <div class="col-md-6 text-center">
                                                    <div class="cart-b">
                                                        <span> <i class="fa fa-minus" (click)="decreaseItem(item.id, 'package', i)" style="font-size:12px;cursor:pointer;"></i></span>
                                                        <span class="inq-sp-2 pl-3 pr-3">{{item.package_quantity}}</span>
                                                        <span><i class="fa fa-plus" (click)="addToCart(item.product)"style="font-size:12px;cursor:pointer;"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 border-left pl-3">
                <h4 class="pb-2" style="font-weight: lighter;">PRICING DETAILS</h4>
                <div class="">
                    <div class="row" style="font-weight: lighter; font-size: 20px;">
                        <div class="col-md-8">
                            SUBTOTAL({{cartItems.length}} Items)
                        </div>
                        <div class="col-md-4" *ngIf="cartItems.length > 0">
                            ₹ {{getTotalAmount(cartItems)}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"  style="font-weight: lighter; font-size: 13px; padding-top: 15px; color: #777;">
                           Extra Charges(Shipping charges, Tax) may apply in checkout.
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-top: 35px;">
                            <button class="btn btn-primary btn-block" (click)="goToCheckout()">PROCEED TO CHECKOUT</button>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</section>
<div>
    <button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
        Launch demo modal
    </button>
    <div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedProduct">
                            <div class="m-title">{{selectedProduct.name | titlecase}}</div>
                            <div class="m-price"><i class="fa fa-rupee"></i>{{selectedProduct.pricing.raw.price + getBundleAmount('obj', selectedProduct.bundle_products ? selectedProduct.bundle_products : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div *ngIf="selectedProduct && selectedProduct.bundle_products && selectedProduct.bundle_products.make_bundle && selectedProduct.bundle_products.products.length > 0">
                                <div class="options-title">Default Add-on</div>
                                <div *ngFor="let sproduct of selectedProduct.bundle_products.products; let i = index">
                                    <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i" [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                                    <label class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0, sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div *ngIf="selectedProduct && selectedProduct.customise_products && selectedProduct.customise_products.customised && selectedProduct.customise_products.customisedProducts.length > 0">
                                <!-- <div class="options-title">Add-ons <span class="rating-text">(optional)</span></div> -->
                                <div class="options-title">Pick Your Choice Of {{selectedProduct.customise_products.customisedProducts[0].product_name.includes('Sambar')?"Sambar":"Chutney"}} (Upto {{maxselecteditems}} of {{selectedProduct.customise_products.customisedProducts.length}} items) </div>
                                <app-alerts *ngIf="showAlerts"></app-alerts>
                                <div *ngIf="selectedProduct.customise_products.customisedProducts.length>5" style="-moz-column-count:2; /* Firefox */
                                    -webkit-column-count:2; /* Safari and Chrome */
                                    column-count:2;">
    
    <div *ngFor="let aproduct of selectedProduct.customise_products.customisedProducts; let i = index">
        <input class="largerCheckbox" type="checkbox" [id]="'aproduct'+i" [name]="aproduct.product_id" [value]="aproduct.product_id" (click)="getaddonItem($event, 'aproduct'+i)">
        <label class="option-product">{{aproduct.product_name.includes('-')?aproduct.product_name.substring(0, aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
            <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
        </label><br>
    </div>
    
    
    
    </div>
    
    
    
    <div *ngIf="selectedProduct.customise_products.customisedProducts.length<=5">
    
        <div *ngFor="let aproduct of selectedProduct.customise_products.customisedProducts; let i = index">
            <input class="largerCheckbox" type="checkbox" [id]="'aproduct'+i" [name]="aproduct.product_id" [value]="aproduct.product_id" (click)="getaddonItem($event, 'aproduct'+i)">
            <label class="option-product">{{aproduct.product_name.includes('-')?aproduct.product_name.substring(0, aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
            </label><br>
        </div>
    
    </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-around">
                            <div *ngIf="selectedProduct" class="m-total">Total: <span style="padding-left: 2px;">₹{{selectedProduct.pricing.raw.price + getBundleAmount('obj', selectedProduct.bundle_products ? selectedProduct.bundle_products : "") + getAddOnProductAmount('array', addonIds)}}</span></div>
                            <button type="button" class="btn m-btn" data-dismiss="modal" (click)="addItemstocart()">ADD ITEM</button>
                        </div>
                    </div>
                    <button style="display: none;" type="button" id="cancelBtn" class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <button id="launch-model1" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel1">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" id="updateclose" class="close" (click)="updateclose()" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" id="updatehideclose" style="display:none;"class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedPackageProduct">
                            <div class="m-title">{{selectedPackageProduct.name | titlecase}}</div>
                            <div class="m-price"><i
                                    class="fa fa-rupee"></i>{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.bundle_products && selectedPackageProduct.product.bundle_products.make_bundle">
                                <div class="options-title">Default Add-on</div>
                                <div *ngIf="selectedPackageProduct.product.bundle_products.products.length > 0">
                                    <div
                                        *ngFor="let sproduct of selectedPackageProduct.product.bundle_products.products; let i = index">
                                        <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i"
                                            [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                                        <label
                                            class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0,
                                            sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.customise_products && selectedPackageProduct.product.customise_products.customised && selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                <!-- <div class="options-title">Add-ons <span class="rating-text">(optional)</span></div> -->
                                <div class="options-title">Pick Your Choice Of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts[0].product_name.includes('Sambar')?"Sambar":"Chutney"}}
                                    (Upto {{maxselecteditems}} of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts.length}}
                                    items) </div>
                                <app-alerts *ngIf="showaddonalert"></app-alerts>
                                
                                <div
                                    *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                    <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length>5" style="-moz-column-count:2; /* Firefox */
                                        -webkit-column-count:2; /* Safari and Chrome */
                                        column-count:2;">
                                         <div
                                         *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                         <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                             [name]="aproduct.product_id" [value]="aproduct.product_id"
                                             (click)="updateaddonItem($event, 'aproducts'+i)"
                                             [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.product_id)">
                                         <label class="option-product">
                                             {{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                             aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                             <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                         </label><br>
                                     </div>
                                        </div>
                                        
                                        <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length<=5">
                                            <div
                                            *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                            <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                                [name]="aproduct.product_id" [value]="aproduct.product_id"
                                                (click)="updateaddonItem($event, 'aproducts'+i)"
                                                [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.product_id)">
                                            <label class="option-product">
                                                {{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                                aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                                <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                            </label><br>
                                        </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex justify-content-around">
                        <div *ngIf="selectedPackageProduct" class="m-total">Total: <span
                                style="padding-left: 2px;">₹{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "") + getAddOnProductAmount('array',
                                customizedIds)}}</span></div>
                        <button type="button" class="btn m-btn"
                            (click)="updateItemstoCart()">UPDATE ITEM</button>
                    </div>
                </div>
                <button style="display: none;" type="button" id="cancelBtn1"
                    class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
