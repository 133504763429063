import { Component, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService} from '../navbar/navbar.service';
import { AlertsService } from '../shared/alerts/alerts.service';

import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-order-cancellation',
  templateUrl: './order-cancellation.component.html'
})
export class OrderCancellationComponent implements OnInit {

  orderId:any;
  orderNumber:any="";
  reason:any = "";
  showalertmsg:boolean = false;
  activateOrder:any = "";

  // ALERT SERVICE SETTINGS
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  constructor(
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private alertService: AlertsService,
    private appService: AppService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    // this.showalertmsg = true;
    this.loaderService.emitLoading();
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    if(this.route.snapshot.queryParamMap){
      this.orderId = Number(this.route.snapshot.queryParamMap.get('id'));
      this.reason = this.route.snapshot.queryParamMap.get('reason');
      // this.alertService.error(this.reason, this.options)
      //this.validateOrder(this.orderId);
       this.getAllOrderDetails();
    }
    this.loaderService.emitComplete();
  }

  getAllOrderDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": this.orderId
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETORDERDETAILS).subscribe(
      resp => {
        var orderData = resp.result.data[0];
        this.orderNumber = orderData.order_number
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

  validateOrder(orderId){
    let requestData = {
      domain_name:ORG_DETAILS.DOMAIN_NAME,
      orderid:orderId,
      confirmationpage:0,
      failurepage:1
    }
    this.appService.postApiCall(requestData, ENDPOINTS.ORDER_ACTIVATION).subscribe(
      resp => {
        if(resp.success === 1){
          this.activateOrder = "success";
          this.loaderService.emitComplete();
          this.getAllOrderDetails();
        }
        else{
          this.activateOrder = "failure";
          this.loaderService.emitComplete();
        }
      },
      err => {
        this.activateOrder = "failure";
        this.loaderService.emitComplete();
      })
  }

}
