import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import {AppService} from './app.service';
import {CartService} from './cart-page/cart-page.service';
import {ENDPOINTS, ORG_DETAILS} from './app.config';
import {LocalStorageService} from './auth-service/local-storage.service';
import {Router,NavigationStart,NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AlertsService } from './shared/alerts/alerts.service';
import { FooterService } from './footer/footer.service';
import {HeaderService} from './header/header-two/header.service';
import {config} from 'src/configs/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'inq-sam';
  currentUrl : any ="home";
  navURL : any ='/'
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  config = config;

  taxratedId = {
    rateId:0,
    productTaxId:0,
    handlingTaxId:0,
    shippingTaxId:0
  }

  ordertaxdetails = {
    rateId:0,
    productTaxId:0,
    handlingTaxId:0,
    shippingTaxId:0,
    productTaxClass:"",
    handlingTaxClass:"",
    shippingTaxClass:""
  }

  shippingCarriers = {
    shipping_carrier_id : 0,
    shipping_carrier_name : "",
    api_integration_id : 0,
    api_integration_name : ""
  }

  pickdropcall:boolean = false;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFunction() {
    var mybutton = document.getElementById("myBtn");
    if (document.body.scrollTop > 1200 || document.documentElement.scrollTop > 1200) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  constructor(
    public appService : AppService,
    private router : Router,
    private cartService : CartService,
    private localService : LocalStorageService,
    private alertService: AlertsService,
    private footerService:FooterService,
    private headerService : HeaderService,
    private localStorage: LocalStorageService,
    private deviceService: DeviceDetectorService)
  {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {

      this.navURL=event.url
      this.footerService.updateAdminFormEvent('clearSearchTerm');


      if(!event.url.split('/')[1].startsWith('productListing'))
      {
        this.headerService.updateAdminFormEvent('clearSearchTerm');
      }

      if (event.url === '/terms-and-condition'){
        this.currentUrl = 'terms-and-condition';
      }
      else if (event.url === '/privacy-policy'){
        this.currentUrl = 'privacy-policy';
      }
      else if (event.url === '/checkout-new'){
        this.currentUrl = "checkout"
      }
      else if (event.url === '/knowily'){
        this.currentUrl = "knowily"
      }
      else if(event.url === "/cancellation-policy"){
        this.currentUrl = "cancellation"
      }
      else if(event.url === "/return-policy"){
        this.currentUrl = "returnPolicy"
      }
      else{
        this.currentUrl = 'home';
      }
      if(this.deviceService.isMobile() || this.deviceService.isTablet()){
        if (event.url === '/terms-and-condition'){
          this.currentUrl = 'terms-and-condition';
        }
        else if (event.url === '/privacy-policy'){
          this.currentUrl = 'privacy-policy';
        }
        else{
         // window.location.href = "https://labes-common-app-a2fa5.web.app/"
         // window.location.href = "http://earthyworthypp.ecbee.net/"

        }
      }
    });

    let userName = this.localStorage.get('UserName');
    let userId = this.localStorage.get('UserId');
    if(userName && userId) {
      this.appService.userLoggedIn = true;
      this.appService.loggedin_user = true;
      this.appService.userName = userName
    }

  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander($event: any) {
  // //  this.checkoutLocalstorageService.clearMasterdata();
  // }

  ngOnInit()
  {
    // this.appService.apkkey = config.pattern1[0]+config.pattern3[config.pattern3.length-1].toUpperCase()+config.pattern3[config.pattern3.length-2]+config.pattern3[config.pattern3.length-3]+config.pattern1[1]+config.pattern2[0]+config.pattern3[config.pattern3.length-4].toUpperCase()+config.pattern3[config.pattern3.length-5]+config.pattern3[config.pattern3.length-6]+config.pattern1[2]+config.pattern2[1]+config.pattern3[config.pattern3.length-7].toUpperCase()+config.pattern3[config.pattern3.length-8]+config.pattern3[config.pattern3.length-9]+config.pattern1[3]+config.pattern2[2]+String.fromCharCode(config.pattern3[0].charCodeAt(0) + 1)+String.fromCharCode(config.pattern3[1].charCodeAt(0) + 1)+String.fromCharCode(config.pattern3[2].charCodeAt(0) + 1).toUpperCase()+config.pattern1[4]+config.pattern2[3]+String.fromCharCode(config.pattern3[3].charCodeAt(0) + 1)+String.fromCharCode(config.pattern3[4].charCodeAt(0) + 1)+String.fromCharCode(config.pattern3[5].charCodeAt(0) + 1).toUpperCase()+config.pattern1[5]+'dsgVKYp';
    //FETCH STORE SETTING FOR SHOW SUBTOTAL CONFIG

    this.getTaxRatesandSettings();
    this.getShippingCarriers();


    this.getStoreSetting();

    this.appService.trackSession();


    this.cartService.updateCartDetails()

    //UPDATE CART DETAILS
    if( localStorage.getItem('cart'))
    {
    this.appService.cartProducts.results= JSON.parse(localStorage.getItem('cart'))
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.calculateSubTotal();
    }
    else
    {
      this.appService.cartProducts.results= []
    }

   if(this.localService.get("show_subtotal"))
   {
     if(this.localService.get("show_subtotal") === "true")
     {
      this.appService.showSubTotal = true;
     }
   }
  }

  getStoreSetting()
  {
    let obj = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
      }

    this.appService.postApiCall(obj,ENDPOINTS.STORE_SETTING_GET_ALL).subscribe(resp=>
      {
        if(resp && resp.result && resp.result.data && resp.result.data.length > 0)
        {
          let resp_data = resp.result.data[0]

          if(resp_data['others']['cart_settings']['show_subtotal'])
          {
            this.localService.set('show_subtotal',resp_data['others']['cart_settings']['show_subtotal'])
            this.appService.showSubTotal = resp_data['others']['cart_settings']['show_subtotal'];

          }
          else
          {
            this.localService.set('show_subtotal',"false")
          }
          if(resp_data['others']['cart_settings']['cart_icon_option'])
          {
            this.localService.set('cart_icon_option',resp_data['others']['cart_settings']['cart_icon_option'])
          }
          else
          {
            this.localService.set('cart_icon_option',"Show Item Count")
          }
          if(resp_data['others']['cart_settings']['max_cart_qty'] && resp_data['others']['cart_settings']['max_cart_qty'] !== "")
          {
              this.localService.set("max_cart_qty",resp_data['others']['cart_settings']['max_cart_qty'])
          }
          else
          {
            this.localService.set("max_cart_qty",10)
          }
          if(resp_data['others']['cart_settings']['min_order_quantity'] && resp_data['others']['cart_settings']['min_order_quantity'] !== "")
          {
              this.localService.set("min_order_qty",resp_data['others']['cart_settings']['min_order_quantity'])
          }
          else
          {
            this.localService.set("min_order_qty",0)
          }
          if(resp_data['others']['cart_settings']['min_order_amount'] && resp_data['others']['cart_settings']['min_order_amount'] !== "")
          {
              this.localService.set("min_order_amt",resp_data['others']['cart_settings']['min_order_amount'])
          }
          else
          {
            this.localService.set("min_order_amt",1000)
          }
          if(resp_data['others']['price_settings'])
          {
            this.localService.set('price_strikeout_in_detail_screen',resp_data['others']['price_settings']['is_enable_price_strikeout_in_detail_screen_enabled'])
            this.localService.set('price_strikeout_in_home_screen',resp_data['others']['price_settings']['is_enable_price_strikeout_in_home_screen_enabled'])
            this.localService.set('price_strikeout_in_list_screen',resp_data['others']['price_settings']['is_enable_price_strikeout_in_list_screen_enabled'])
            this.localService.set('show_price_key',resp_data['others']['price_settings']['show_price_key'])
            this.localService.set('strike_out_price_key',resp_data['others']['price_settings']['strike_out_price_key'])

          }
          // else
          // {
          //   this.localService.set('show_subtotal',"false")
          // }

        }
      },err=>{
        this.localService.set('show_subtotal',"false");
        this.localService.set('cart_icon_option',"Show Item Count")
        this.localService.set("max_cart_qty",10)
        this.localService.set("min_order_qty",0)
        this.localService.set("min_order_amt",1000)
      })
  }

  getTaxRatesandSettings(){
    let settingData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(settingData, ENDPOINTS.TAXSETTINGS).subscribe(
      resp => {
        if(resp.success === 1){
          let taxSettings = resp.result.data[0];
          let shippingLabel = taxSettings.class.tax_class_shipping;
          let handlingLabel = taxSettings.class.tax_class_handling_fee;
          let normalLabel = taxSettings.class.tax_class_product;
          // TAX RATES
          let requestData = {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "user_id": ORG_DETAILS.USER_ID,
            "extras": {
              "find": {
              }
            }
          }
          this.localService.set("HandlingTax",0)
          this.localService.set("NormalTax",0)
          this.localService.set("ShippingTax", 0);
          this.appService.postApiCall(requestData, ENDPOINTS.GET_TAXZONES).subscribe(
            resp => {
              if(resp.success === 1){
                let taxRates = resp.result.data[0];
                this.taxratedId.rateId =taxRates.id;
                this.ordertaxdetails.rateId = taxRates.id;
                taxRates.tax_rates[0].tax_class_rates.forEach(element => {
                  if(element.tax_class_name === handlingLabel){
                    this.localService.set("HandlingTax",Number(element.tax_class_rate_percent))
                    this.taxratedId.handlingTaxId = element.tax_class_id;
                    this.ordertaxdetails.handlingTaxId = element.tax_class_id;
                    this.ordertaxdetails.handlingTaxClass = handlingLabel;
                  }
                  if(element.tax_class_name === normalLabel){
                    this.localService.set("NormalTax",Number(element.tax_class_rate_percent))
                    this.taxratedId.productTaxId = element.tax_class_id;
                    this.ordertaxdetails.productTaxId = element.tax_class_id;
                    this.ordertaxdetails.productTaxClass = normalLabel;
                  }

                  if(element.tax_class_name === shippingLabel){
                    this.localService.set("ShippingTax", Number(element.tax_class_rate_percent));
                    this.taxratedId.shippingTaxId = element.tax_class_id;
                    this.ordertaxdetails.shippingTaxId = element.tax_class_id;
                    this.ordertaxdetails.shippingTaxClass = shippingLabel;
                  }
                });

                this.localService.set('TAXIDS', JSON.stringify(this.taxratedId));
                this.localService.set('ORDERTAXLABELS', JSON.stringify(this.ordertaxdetails));
              }
              else{
                this.alertService.error(resp.message, this.options)
              }
            },
            err => {
              if (err.error.error.message) {
                this.alertService.error(err.error.error.message, this.options)
                // console.log(err.error.error.message)
              }
              else{
                console.log('Something bad happened; Please try again!')
              }
            })
        }
        else{
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  getShippingCarriers(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        }
      }
    }

    this.appService.postApiCall(requestData, ENDPOINTS.GETSHIPPINGCARRIERS).subscribe(
      resp => {
        if(resp.success === 1){
           let carrierdata = resp.result.data[0];
           this.shippingCarriers.shipping_carrier_id = carrierdata.id;
           this.shippingCarriers.shipping_carrier_name = carrierdata.carrier_name;
           this.shippingCarriers.api_integration_id = carrierdata.trackable_integrator_reference_id;
           this.shippingCarriers.api_integration_name = carrierdata.trackable_integrator_reference_name;
           let requestData1 = {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "user_id": ORG_DETAILS.USER_ID,
            "extras": {
              "find": {
                "id": Number(this.shippingCarriers.api_integration_id.toString())
              }
            }
          }

          this.appService.postApiCall(requestData1, ENDPOINTS.GET_PAYMENTSETTINGS).subscribe(
            resp => {
              if(resp.success === 1){
                let settingsData = resp.result.data[0];
                if(carrierdata.trackable_integrator_reference_name === "PickDrop" && settingsData.name === "PickDrop"){
                  this.pickdropcall = true;
                  this.localService.set('PickDropCall', this.pickdropcall)
                }
              }
              else{
                if(resp.message){
                  console.log(resp.message);
                }
              }
            },
            err => {
              if (err.error.error.message) {
                console.log(err.error.error.message)
              }
              else{
                console.log('Something bad happened; Please try again!')
              }
            })
        }
        else{
          if(resp.message){
            console.log(resp.message);
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })

  }

  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
