<section>
    <div class="container-fluid bg-login">
        <div class="checkout-alerts">
            <app-alerts *ngIf="checkoutAlerts"></app-alerts>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-md-10 login-card">
                    <div class="row">
                        <div class="col-md-5 detail-part text-center">
                            <h3>Register</h3>
                            <p class="login-sub-text">Get access to your Orders, <br>Wishlist and Recommendations</p>
                            <img src="https://d18xkwaipu1whh.cloudfront.net/TamsAsset/login_img.jpg">
                        </div>
                        <div class="col-md-7 logn-part">
                            <div class="customer-container" style="margin-right: 30px !important;">
                                <!-- INITIAL FORM -->
                                <div *ngIf="screenType === 'initial'">
                                    <form class="checkout-forms" [formGroup]= "initialRegistration">
                                        <div *ngIf="hideMobile">
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="memberId" (change)="captureMemberId($event)" [class.is-invalid] = "validateInitialRegistration && f.memberId.errors" placeholder="Member Id">
                                                    <span class="checkout-label">Member Id*</span>
                                                    <div class="invalid-feedback" *ngIf="f.memberId.errors">
                                                        <div *ngIf="f.memberId.errors.required">Please enter MemberId.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="nameOfOrganization" [class.is-invalid] = "validateInitialRegistration && f.nameOfOrganization.errors" placeholder="Name Of Organization">
                                                    <span class="checkout-label">Name Of Organization*</span>
                                                    <div class="invalid-feedback" *ngIf="f.nameOfOrganization.errors">
                                                        <div *ngIf="f.nameOfOrganization.errors.required">Please enter Name Of Organization.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="ownerName" [class.is-invalid] = "validateInitialRegistration && f.ownerName.errors" placeholder="Owner/MD/CEO Name">
                                                    <span class="checkout-label">Owner/MD/CEO Name*</span>
                                                    <div class="invalid-feedback" *ngIf="f.ownerName.errors">
                                                        <div *ngIf="f.ownerName.errors.required">Please enter Owner Name.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateInitialRegistration && f.mobile.errors" placeholder="Mobile">
                                                    <span class="checkout-label">Mobile*</span>
                                                    <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                                        <div *ngIf="f.mobile.errors.required">Please enter Mobile.</div>
                                                        <div *ngIf="f.mobile.errors.maxlength">
                                                            Mobile can't be more than 10 characters long.
                                                          </div>
                                                          <div *ngIf="f.mobile.errors.pattern">
                                                            Mobile Number is invalid.
                                                          </div>
                                                    </div> 
                                                    <div *ngIf="showmsg3===true" style="color:red!important;">Mobile Number is invalid.</div>
                                                </label>
                                            </div>
                                            <div class="col-md-3">
                                               
                                                    <button class="btn btn-block btn-primary btn-primary1" (click)="verifyMobileNumber()">Send OTP</button>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateInitialRegistration && f.otp.errors" placeholder="OTP">
                                                    <span class="checkout-label">OTP*</span>
                                                    <div class="invalid-feedback" *ngIf="f.otp.errors">
                                                        <div *ngIf="f.otp.errors.required">Please enter OTP.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialRegistration && f.email.errors" placeholder="Email">
                                                    <span class="checkout-label">Email*</span>
                                                    <div class="invalid-feedback" *ngIf="f.email.errors">
                                                        <div *ngIf="f.email.errors.required">Please enter Email.</div>
                                                        <div *ngIf="f.email.errors.maxlength">
                                                            Email can't be more than 100 characters long.
                                                          </div>
                                                          <div *ngIf="f.email.errors.pattern">
                                                            Invalid email.
                                                          </div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="password" [class.is-invalid] = "validateInitialRegistration && f.password.errors" placeholder="Password">
                                                    <span class="checkout-label">Password*</span>
                                                    <div class="invalid-feedback" *ngIf="f.password.errors">
                                                        <div *ngIf="f.password.errors.required">Please enter Password.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                  
                                        </div>
                                        <button class="btn btn-block btn-primary btn-primary1" (click)="registerMember()"> Register</button>
                                    </form>
                                </div>
                             
                                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  




  

