import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productDetailsPipe'
})
export class ProductDetailsPipePipe implements PipeTransform {

  transform(value: String, arg1:String): String {
    if(value.toUpperCase() === 'ITR'){
      return "ITR"
    }
    else{
      return value.replace(/\sItr/g," ITR");
    }
    
  }

}
