import { Component, OnInit, NgZone } from '@angular/core';
// import { AuthTokenService } from '../auth-service/auth-token.service';
import { LocalStorageService } from '../auth-service/local-storage.service'
import { Router,NavigationEnd, ActivatedRoute } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterService } from './footer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertsService } from '../shared/alerts/alerts.service';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { config }  from 'src/configs/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  subscribeForm: FormGroup;
  validateForm: boolean = false;
  footer1: any;
  footer2: any;
  pickupTimings: any;
  showalertmsg: boolean = false;
  logoImage : any
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
config=config;
  footer = config.footer;
  showBookTableBtn:boolean;
  showCertificate:boolean;
  mailtoexp = "";
  calltoexp = "";
  mailtoexp1 = "";
  calltoexp1 = "";
  license:any;
  constructor(private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private footerService: FooterService) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.subscribeForm = new FormGroup({
      email: this.formBuilder.control('', [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
    })
    this.pickupTimings=JSON.parse(this.localService.get('BranchName'))
    this.getOutletData()
    this.getLogo()
    // TO CLEAR THE SEARCH TERM FROM OTHER EVENTS
    this.footerService.currentData.subscribe(
      currentData => {
        if (currentData === 'clearSearchTerm') {
          this.subscribeForm.controls.email.setValue('');
          this.validateForm=false;
        }
      }
    )
  }
  get f() { return this.subscribeForm.controls; }
  subscribe() {
    this.showalertmsg = true;
    if (this.subscribeForm.valid) {
      this.validateForm = false;

      var subscribeFormData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        "payload": {
          "customerSubcribe": {
            "has_also_subscribed": true,
            "email": this.subscribeForm.value.email
          }
        },
        "extras": {
          "find": {
            "email": this.subscribeForm.value.email
          }
        }

      }

      this.appService.postApiCall(subscribeFormData, ENDPOINTS.SUBSCRIBE).subscribe(resp => {
        if (resp) {
          if (resp.success == 1) {
            this.alertService.success('Subscribed Succesfully!', this.options);
          }
          else {
            this.alertService.warn(resp.message, this.options);
          }
        }
      },
        err => {
          if (err.error.message) {
            this.alertService.error(err.error.message, this.options);
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    else {
      this.validateForm = true;
    }

  }
  getOutletData(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
          this.license=resp.result.data[0].license_no;
          this.footer1=resp.result.data[0]
          this.footer2=resp.result.data[1]
          this.mailtoexp = "mailto:"+this.footer2.email;
          this.calltoexp = "tel:"+this.footer2.phone;
          this.mailtoexp1 = "mailto:"+this.footer1.email;
          this.calltoexp1 = "tel:"+this.footer1.phone;
          if(resp.result.data[0].allow_table_booking.toLowerCase() != "no"){
            this.showBookTableBtn=true; 
          }
          else{
            this.showBookTableBtn=false;
          }
          if(resp.result.data[0].certification.toLowerCase() == "fssai"){
            this.showCertificate=true;
          }
          else{
            this.showCertificate=false;
          }
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }        
        
        //hiding book  a table//
       
        
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
  bookYourTable(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.router.navigate([`/table-booking`]);
  }
  scrollFunction(){
    window.scrollTo({top: 0, behavior: 'smooth'});

  }

  openBranchLocation(data) {
    if(this.config.title ==="Haven"){
      if(data=="branch1"){
        window.open("https://www.google.com/maps/search/?api=1&query=12.998522415328523-80.26819132906674&query_place_id=ChIJR8yiRvpnUjoR1DwzsUQ9jg4", '_blank');

      } else if(data=="branch2")
      {
        window.open("https://www.google.com/maps/search/?api=1&query=12.990560265017589-80.21944681971591&query_place_id=ChIJzSX6kmJnUjoRaI8ynQ36FAo", '_blank');

      }
    }
}

  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }

}
