import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';

import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { CustomerLoginService } from '../checkout-new/checkout-login/customer-login.service';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';
import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { AlertsService } from '../shared/alerts/alerts.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AppService } from '../app.service';
import { NavbarService} from '../navbar/navbar.service';
import { LoaderService } from '../shared/loader/loader.service';

const numPattern = /^[0-9]*$/;
const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
const namePattern = /^[a-zA-Z\s.]*$/;

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html'
})
export class MemberRegisterComponent implements OnInit {

 
  BUSINESS_CUSTOMER:string = "BUSINESS CUSTOMER";
  INDIVIDUAL_CUSTOMER:string = "INDIVIDUAL CUSTOMER";
  checkoutAlerts:boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  backToUrl = "/";

  screenType = "initial";
  hideMobile = true;
  userIdType = "";
  loggedinAs = "";
  emailormobile = "";
  showLoginpaswd:boolean = false;
  showregistrationPswd:boolean = false;
  showForgotPswd:boolean = false;
  showotpwithoutpswd:boolean = false;
  showUpdateEmailForm1:boolean = false;
  showUpdateEmailForm3:boolean = false;
  customerType:string = "individual";
  enableBusinessUser:boolean = false;
  emailExist:boolean = false;
  passwordExists:boolean = false;
  resultMessage: any;
  yahoo:boolean = false;

  forgotMobile:any;
  is_mandatory = {
    individual:null,
    business:null
  }
  otpResendDuration:Number
  optionss = {
    types: [],
    componentRestrictions: { country: 'IN' },
  };

  validateInitialForm:boolean = false;
  validatePasswordForm:boolean = false;
  validateInitialRegistration:boolean = false;
  validateIndividualRegistration:boolean = false;
  validateBusinessRegistration:boolean = false;
  validateForgotPasswordForm:boolean = false;
  validateOtpLoginWithoutPswd:boolean = false;
  validaterequestOTPLoginForm:boolean = false;
  validateUpdateEmailForm1:boolean = false;
  validateUpdateEmailForm2:boolean = false;
  validateUpdateEmailForm3:boolean = false;

  initialForm: FormGroup;
  passwordForm: FormGroup;
  initialRegistration: FormGroup;
  individualRegistration: FormGroup;
  businessRegistration: FormGroup;
  forgotPasswordForm: FormGroup;
  otpLoginWithoutPswd: FormGroup;
  requestOTPLoginForm: FormGroup;
  updateEmailForm1: FormGroup;
  updateEmailForm2: FormGroup;
  updateEmailForm3: FormGroup;

  // REGISTRATION OFFER DETAILS
  registrationOfferDetails = {
    available:false,
    inidividual:false,
    business:false,
    product_ids:[]
  }

  validateShippingForm:boolean = false;
  location_bloked:boolean = false;
  is_deliverable:boolean = true;
  pickupLatitude:any;
  pickupLongitude:any;
  offerAvailed = false;
  shippingForm: FormGroup;
  latitude;
  longitude;
  zoom;
  geocoder;
  enabledistanceValidation = false;
  distance = 0;
  validAddress:any = "";
  selectedAddress:any = null;

   // BUTTON SPINNERS
   continue1:boolean = false;
   continue2:boolean = false;
   signup1:boolean = false;
   signup2:boolean = false;
   resendMsg:string;
   saveaddSpinner:boolean = false;
   productId:any;
   product:any;
   owner:any = "";
   memberId:any = "";
   email:any = "";
   mobile:any = "";
   name:any = "";

   // FOR OFFER
   individualCustomerRegistration:boolean = false;
   businessCustomerRegistration:boolean = false;
   offerOrderId:any = "";
   showmsg3: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private checkoutApiService: CheckoutApiService,
    private customerLoginService: CustomerLoginService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private alertService: AlertsService,
    private appService: AppService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private loaderService: LoaderService,   
    private mapsAPILoader: MapsAPILoader,
  
  ) {
    if (this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null) {
      if (this.router.getCurrentNavigation().extras.state) {
        this.resultMessage = this.router.getCurrentNavigation().extras.state.result;
      }
    }
   }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params['params'].url) {
        this.backToUrl = params['params'].url;
      }
    })

    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder
    });
    this.zoom = 15;

    this.initialRegistration = new FormGroup({
      nameOfOrganization:this.formBuilder.control('',[Validators.required]),
      memberId:this.formBuilder.control('',[Validators.required]),
      ownerName:this.formBuilder.control('',[Validators.required]),
      mobile: this.formBuilder.control('',[Validators.required,Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      // email: this.formBuilder.control('', [Validators.required, Validators.maxLength(100), Validators.pattern('^(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z]+\s?,)*(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+)$')]),
      email: this.formBuilder.control('', [Validators.required, Validators.maxLength(100), Validators.pattern('^(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z]+\s?,)*(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+)$')]),

      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required]),
    })

    this.checkoutAlerts = true;

  }

  captureMemberId(evt){
    console.log("evt.target.value"+evt.target.value)
    this.memberId = evt.target.value;
    let requestData ={
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "memberId": this.memberId
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 2
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "",
          "sortorder": 1
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.PRODUCT_DETAILS).subscribe(
      resp => {
        if (resp.success == 1){
          if(resp.result.data.length > 0){
            // window.scrollTo(0,0)
            this.product = resp.result.data[0]
            this.owner = this.product.company_profile['ceo/md/owner name']
            this.initialRegistration.controls.ownerName.setValue(this.product.company_profile['ceo/md/owner name']);
            this.initialRegistration.controls.mobile.setValue(this.product.contact_details['mobile']);
            this.initialRegistration.controls.email.setValue(this.product.contact_details['email']);
            // this.initialRegistration.controls.nameOfOrganization.setValue(this.product.name);
            // this.establised = this.product.company_profile['established_since']
            this.memberId = this.product.memberId;
          }
        }
      })
  }

  ngAfterViewInit(): void {
    this.alertService.clear();
    if (this.resultMessage !== "") {
      window.scrollTo(0,0);
      this.alertService.success(this.resultMessage, this.options);
      this.cdr.detectChanges();
    }
  }

 get f(){
    return this.initialRegistration.controls;
  }


verifyMobileNumber(){
  if(this.initialRegistration.controls.mobile.valid){
    console.log("222")
    this.loaderService.emitLoading();
    this.showmsg3=false;
    this.validateInitialRegistration = true;
  // if(this.initialRegistration.controls.mobile.value.length !== 10){
  //   this.continue1 = false;
  //   this.validateInitialRegistration = true;
  //   this.initialRegistration.controls['mobile'].setErrors({'incorrect':true});
  // }
  this.sendOTP(this.initialRegistration.controls.mobile.value).then(
    respData => {
      let res:any = respData;
      if(res){
        this.otpResendDuration = new Date().getTime() + 5*60*1000;
        this.resendMsg=this.initialRegistration.controls.mobile.value;
        this.loaderService.emitComplete();
        this.alertService.success('OTP has been sent to '+this.initialRegistration.controls.mobile.value, this.options);
      }
    }
  )
} else {
  console.log("333")
  this.initialRegistration.controls['mobile'].setErrors({'pattern':true});
  this.showmsg3=true;
  this.continue1 = false;
}
}

// registerMember(){
//   if(this.initialRegistration.valid){
//     this.validateInitialRegistration = false;
//     this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
//       respData => {
//         let res:any = respData;
//         if(res.status){
//           let customer_type;
//           let requestData = {
//             "domain_name": ORG_DETAILS.DOMAIN_NAME,
//             nameOfOrganization:this.initialRegistration.controls.name.value,
//             memberId:this.initialRegistration.controls.memberId.value,
//             ownerName:this.initialRegistration.controls.ownerName.value,
//             mobile: this.initialRegistration.controls.mobile.value,
//             email: this.initialRegistration.controls.email.value,
//             password: this.initialRegistration.controls.password.value,
//           }
//           this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
//             respData1 => {
//               let res1:any = respData1;
//               if(res1.success === 1){
//                 this.customerLoginStorageService.setCustomerData({
//                   type: this.customerType === "individual" ? "individual customer" : "business customer",
//                   userid:res1.user.id,
//                   userName:res1.user.name ? res1.user.name:"",
//                   mobileNo: this.initialRegistration.controls.mobile.value ? this.initialRegistration.controls.mobile.value:"",
//                   email: this.initialRegistration.controls.email.value ? this.initialRegistration.controls.email.value : "",
//                   subscribed:false,
//                   logintype:this.loggedinAs
//                 })
//                 this.customerLoginStorageService.setCustomerObject({
//                   customer_id:res1.user.id,
//                   customer_name:res1.user.name,
//                   phone_number: this.initialRegistration.controls.mobile.value,
//                   is_email_opt_in:false
//                 })
               
//                 this.emailormobile = this.initialRegistration.controls.mobile.value;
//                 this.userIdType = "mobile";
//                 this.screenType = "loggedIn";
                
//               }
//               else{
//                 this.alertService.error(res1.message, this.options);
//               }

//             }
//           )
//         }
//         else{
//           this.alertService.error(res.msg, this.options);
//         }
//       }
//     )
//   }
//   else{
//     this.validateInitialRegistration = true;
//   }
// }


registerMember(){
  console.log("111")
  if(this.initialRegistration.controls.otp.value === ""){
    console.log("pass")
    this.validateInitialRegistration = true;
    this.initialRegistration.controls['otp'].setErrors({'incorrect':true});
  }
  var myemail = this.initialRegistration.controls.email.value;

  if (/@yahoo.co.in\s*$/.test(myemail)) {
     console.log("it ends in @yahoo");
     this.yahoo=true;
  } 
  if(this.yahoo===true){
    this.initialRegistration.controls['email'].setErrors({'incorrect':false});
  }
 if(this.initialRegistration.valid){
    this.loaderService.emitLoading();
    console.log("222")
    this.signup1 = true;
    this.validateInitialRegistration = false;
    // if(this.individualRegistration.valid){
      // this.validateIndividualRegistration = false;
      this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
        respData1 => {
          console.log("333")
          let res1:any = respData1;
          if(res1.status){
            this.localStorageService.set('UserName', this.initialRegistration.value.name);
            this.appService.userName = this.initialRegistration.value.name;
            let user = {
              nameOfOrganization: this.initialRegistration.controls.nameOfOrganization.value,
              email: this.initialRegistration.controls.email.value,
              mobile: this.initialRegistration.controls.mobile.value,
              password: this.initialRegistration.controls.password.value,
              memberId:this.initialRegistration.controls.memberId.value,
              ownerName:this.initialRegistration.controls.ownerName.value,
              state: "Member",
              status:"Approve"
            }
            let registrationData = {
              domain_name: ORG_DETAILS.DOMAIN_NAME,
              user_id: ORG_DETAILS.USER_ID,
              payload: {
                create_member: user
              }
            }
            // LOGIN FORM AUTOMATIC FILL
         
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.ADD_MEMBER, registrationData).then(
              respData => {
                let res:any = respData;
                if(res.success){
                  // this.alertService.success(res.message, this.options);
                  this.alertService.success("Member Has Been Registered Successfully", this.options);
                  setTimeout(() => {
                    this.router.navigateByUrl(this.backToUrl);
                 }, 2000);
                  //  this.router.navigateByUrl(this.backToUrl);
                  // this.router.navigate(['/'], { queryParams: { type:'MemberRegister', },relativeTo:this.route,  skipLocationChange: false })
                  // if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                  //   this.individualCustomerRegistration = true;
                  // }
                  // this.signinwithPassword();
                  this.signup1 = false;
                  // this.changeForm('initialstate')
                  this.hideMobile = true;
                  this.loaderService.emitComplete();
                  // FOR REGISTERD USER OFFER
                  // if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                  //   let registerData = res.data[0];
                  //   this.registrationOfferOrderService.setCustomerDetails({
                  //     userid : registerData.id,
                  //     userName : registerData.name,
                  //     type : registerData.customer_type,
                  //     subscribed : false,
                  //     mobileNo : registerData.phone_number
                  //   }).then(
                  //     respDataL => {
                  //       let respL:any = respDataL;
                  //       if(respDataL){
                  //         this.registrationOfferInitiationApiService.initiateSampleOrder().then(
                  //           respDataO =>{
                  //             let resO:any = respDataO;
                  //             this.offerOrderId = resO;
                  //             if(resO){
                  //               this.openMsgModel1()
                  //               this.clearRegisterOffers();
                  //             }
                  //           }
                  //         )
                  //       }
                  //     }
                  //   )
                  //   this.selectedAddress.customer_id = registerData.id;
                  //   this.addAddress(this.selectedAddress);
                  // }
                }
                else{
                  this.alertService.error(res.message, this.options);
                }
            })
          }
          else{
            this.signup1 = false;
            this.alertService.error(res1.msg, this.options);
          }
      })
    // }
 
    



    
  }
  else{
    this.validateInitialRegistration = true;
  }
}

  
  sendOTP(mobileNo){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
                "countrycode": "+91",
                "mobile": mobileNo,
                "otppurpose": "login"
            }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            resolve(true);
          }
          else{
            resolve(false);
          }
        }
      )
    })
  }


  verifyOTP(mobile, otp){
    return new Promise((resolve, reject) => {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "data": {
          "otp": {
              "userotp": otp,
              "mobile": mobile
          }
        }
      }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
      respdata => {
        let res:any = respdata;
        if(res.success === 1){
          resolve({status:true, msg:res.message});
        }
        else{
          resolve({status:false, msg:res.message});
        }
    })
    })
  }

 

}
