import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';
import * as moment from 'moment';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html'
})
export class AddReviewComponent implements OnInit {
  customer_id;
  parameters: any;
  orderdetails: any=[];
  payment_information: any=[];
  summaryShippingInfo;
  productList: any = [{ name: 'prod', price: 200 }];
  productListArray: any =[];
  products: boolean = false;
  total: any;
  subtotal: any;
  shipping: any;
  tax: any;
  orderedOn: any;
  orderId: any;
  orderStatus: any;
  id : any;
  customer_details : any = [];
  i:any;
  pid:any;
  showalertmsg : boolean = false;
  editScreen : boolean = false;
  review_id : any;
  
  addReviewForm: FormGroup;
  validateForm : boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localStorageService : LocalStorageService,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    this.addReviewForm = new FormGroup({
      rating: this.formBuilder.control('', [Validators.required]),
      title : this.formBuilder.control('', [Validators.required,Validators.minLength(3),Validators.maxLength(120)]),
      comments: this.formBuilder.control('', [Validators.minLength(3),Validators.maxLength(2000)]),
    })
    this.route.queryParams.subscribe(params => {
      this.parameters = this.route.snapshot.params;
      this.id = this.parameters['id']
      this.i = this.parameters['i']
      this.pid = this.parameters['pid']
    }
    )

    this.customer_id = this.localStorageService.get('UserId');
    console.log(this.customer_id)
    if(this.customer_id !== undefined && this.customer_id !== NaN){
      this.getProfileDetails(this.customer_id)
    }
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getAllOrderDetails(this.parameters);
    this.getReviewDetails()
  }

  get f() { return this.addReviewForm.controls; }

  getAllOrderDetails(params: any) {
    let requestBody = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          // "customer_id": this.customer_id,
          "id": Number(params['id'])
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETALL_ORDERS).subscribe(
      resp => {

        var elem = resp.result.data[0];

        console.log(resp)

        // this.customer_details["name"] = elem.customer_name
        // this.customer_details["mail"] = elem.email

        let productListArray=[];
        if(elem !== undefined){
        this.orderedOn = moment(elem.created_on).format('DD MMM, YYYY');
        this.orderId = elem.id
        this.orderStatus = elem.status
        }
        this.productList = resp.result.data[0].product_details.products;
        console.log("res",this.productList,resp)
        this.payment_information = resp.result.data[0].payment_method;
        this.orderdetails = resp.result.data[0].order_summary;
        // if(resp.result.data[0].shipping_addresses.length){
        //   this.summaryShippingInfo = resp.result.data[0].shipping_addresses[0];
        // }
        this.subtotal = 0;
        for (let i = 0; i < resp.result.data[0].product_details.products.length; i++) {
          this.subtotal += resp.result.data[0].product_details.products[i].price;
          productListArray.push(this.productList[i].product_id);
        }
        this.getProductArray(productListArray);
        if (resp.result.data[0].product_details.products.length > 0) {
          this.products = true;
        }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }
  getProductArray(productArray) {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "sorting": true,
        "sortingDetails": {
          "sortorder": 1,
          "sortfield": "name"
        },
        "find": {
          "id": productArray
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.Get_PRODUCT_ARRAY).subscribe(
      resp => {
        this.productListArray=resp.result.data

        console.log("r",this.productListArray)
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

  addReview(){
    this.showalertmsg =true
    // // this.validateRating()
    console.log(this.addReviewForm)
    if(this.addReviewForm.valid){
      this.validateForm = false;
      let formData = {}
      formData = this.addReviewForm.value
      formData['rating'] = Number(this.addReviewForm.value.rating)

      formData['product_id'] = this.pid;
      formData['product_name'] = this.productList[this.i].product_name;
      // formData['posted_by'] = this.customer_details.first_name !== undefined ? this.customer_details.first_name : '';
      // formData['email'] = this.customer_details.email !== undefined ? this.customer_details.email : '';
      formData['status'] = 'unapproved'
      var date  = new Date()

      console.log("this.customer_id",this.customer_id)
      formData['customer_id'] = this.customer_id
      formData['reviewed_date'] = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ' '+date.getHours() + ":" + date.getMinutes();
      

      // console.log(formData)
    
      if(!this.editScreen){
        let FormData = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: ORG_DETAILS.USER_ID,
          payload: {​​​​​​​​
            add_review :formData
          ​​​​​​​​}​​​​​​​​
      }
  
  
        this.appService.postApiCall(FormData, ENDPOINTS.ADDREVIEW).subscribe(resp => {
          if (resp) {
            if (resp.success == 1) {
              window.scrollTo(0,0)
              this.clear()
              this.alertService.success(resp.message, this.options);
              this.router.navigate(['/my-order-details',this.parameters['id']], {state: { result: "Thanks for the rating" }, relativeTo:this.route,  skipLocationChange: false })
              // this.router.navigate(['/my-order-details',this.parameters['id']])
            }
            else {
              window.scrollTo(0,0)
              this.alertService.warn(resp.message, this.options);
              
            }
          }
        },
          err => {
            if (err.error.message) {
              window.scrollTo(0,0)
              this.alertService.error(err.error.message, this.options);
            }
            else {
              window.scrollTo(0,0)
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        )
      }
      else{
        let FormData = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: ORG_DETAILS.USER_ID,
          payload: {​​​​​​​​
            add_review :formData
          ​​​​​​​​}​​​​​​​​,
          extras : {
            find : {
              id : this.review_id
            }
          }
      }
  
  
        this.appService.postApiCall(FormData, ENDPOINTS.EDITREVIEW).subscribe(resp => {
          if (resp) {
            if (resp.success == 1) {
              window.scrollTo(0,0)
              this.alertService.success(resp.message, this.options);
              this.router.navigate(['/my-order-details',this.parameters['id']], {state: { result: "Thanks for the rating" }, relativeTo:this.route,  skipLocationChange: false })
              // this.router.navigate(['/my-order-details',this.parameters['id']], {state: { result: resp.message }, skipLocationChange: false })
              // this.router.navigate(['/my-order-details',this.parameters['id']])
              // this.router.navigate(['/my-order-details',this.parameters['id']], { state: { result: resp.message }, relativeTo: this.activatedRoute, skipLocationChange: false });
              // this.alertService.success(resp.message, this.options);
            }
            else {
              window.scrollTo(0,0)
              this.alertService.warn(resp.message, this.options);
              
            }
          }
        },
          err => {
            if (err.error.message) {
              window.scrollTo(0,0)
              this.alertService.error(err.error.message, this.options);
            }
            else {
              window.scrollTo(0,0)
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        )
      }
      
    }
    else{
      this.validateForm = true;
    }
  }

  getProfileDetails(id) {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id" : id
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody,ENDPOINTS.GET_CUSTOMER,).subscribe(
      resp => {
      
        if (resp) {
          
          this.customer_details = resp.result.data[0];
        
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }

  getReviewDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id" : this.customer_id,
          "product_id" : this.pid
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody,ENDPOINTS.GETALLREVIEW,).subscribe(
      resp => {
      
        if (resp) {
          
          let review = resp.result.data[0];

          if(review !== undefined && review.length !== 0){
            this.editScreen = true;
            this.review_id = review.id
            // this.addReviewForm.patchValue(review)

            this.addReviewForm.setValue({
              rating : review.rating.toString(),
              title : review.title,
              comments : review.comments,
            })
          }
        
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }

  clear(){
    this.addReviewForm.reset({
      rating: 0,
      title : '',
      comments: '',
    })
  }

  


}

