<section id="customize-listing">
    <app-alerts *ngIf="showAlerts"></app-alerts>
    <div *ngIf="!loadErroPage" class="product-customize">
        <div class="row">
            <div class="col-md-3" style="padding-right: 0px !important;">
                <div *ngFor="let category of allCategories" class="menu-container d-flex justify-content-end"
                    style="padding-top: 12px;">
                    <div class="menu-content">
                        <div (click)="getSelectedCategory(category)"
                            [class.single-category]="!category.subcategories || category.subcategories.length === 0"
                            [class.selected-item]="selectedCategory.name === category.name" class="menu-item"><span
                                *ngIf="category.category_icon"><img
                                    [class.selected-item]="selectedCategory.name === category.name"
                                    class="category-icon" src="{{category.category_icon}}"></span>{{category.name}}
                            <span *ngIf="category.subcategories && category.subcategories.length > 0"><i
                                    class="fa fa-angle-down" aria-hidden="true"></i></span></div>
                        <div [class]="(selectedCategory && (selectedCategory.name === category.name)) ? 'show-menu':'hide-menu'"
                            *ngIf="category.subcategories && category.subcategories.length > 0">
                            <div *ngFor="let subcategory of category.subcategories"
                                class="sub-menu-container d-flex justify-content-end">
                                <div [class.sub-selected-item]="selectedSubCategory && selectedSubCategory.name === subcategory.name"
                                    class="sub-menu-item" (click)="getSubCategory(subcategory)">{{subcategory.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" style="padding-left: 0px !important;">
                <div class="category-products-container">
                    <div *ngIf="selectedCategoryProducts.length > 0" class="menus-title">{{selectedSubCategory ?
                        selectedSubCategory.name : selectedCategory.name}}
                        <span
                            *ngIf="selectedSubCategory ? selectedSubCategory.category_icon : selectedCategory.category_icon">
                            <img class="selected-icon"
                                src="{{selectedSubCategory ? selectedSubCategory.category_icon : selectedCategory.category_icon}}">
                        </span>
                    </div>
                    <div class="product-list-container">
                        <div *ngIf="selectedCategoryProducts.length > 0">
                            <div
                                *ngFor="let categoryProduct of selectedCategoryProducts | paginate: { itemsPerPage: pageSize, currentPage: page, id: 'foo', totalItems:pageCount }">
                                <div class="category-product-card">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="cp-title">{{categoryProduct.name | titlecase}}</div>
                                            <div class="inner-star-icon">
                                                <!--<span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span  [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                 <a class="rating-text" routerLink="/product-review/{{categoryProduct.id}}"> {{categoryProduct.reviews ? categoryProduct.reviews.total_reviews : 0}} customer reviews</a> -->
                                            </div>
                                            <div class="cp-price"><i
                                                    class="fa fa-rupee"></i>{{categoryProduct.pricing.raw.price +
                                                getBundleAmount('obj', categoryProduct.bundle_products ?
                                                categoryProduct.bundle_products : "")}} </div>
                                            <div class="cp-description" *ngIf="categoryProduct.description">
                                                {{removehtmltag(categoryProduct.description.short_desc)}}</div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="cp-image-container">
                                                <img class="cp-image"
                                                    *ngIf="categoryProduct.assets.length > 0 && categoryProduct.assets[0].image_source !== ''"
                                                    [src]="categoryProduct.assets[0].image_source">
                                                <img class="cp-image"
                                                    *ngIf="categoryProduct.assets.length === 0 || categoryProduct.assets[0].image_source === ''"
                                                    src="../../assets/images/vendor/No_Image_old2.png">
                                                <div class="cp-button-container">
                                                    <button
                                                        *ngIf="!getselectedProductIds().includes(categoryProduct.id)"
                                                        class="btn cp-button"
                                                        (click)="CheckPincode(categoryProduct)">ADD</button>
                                                    <button
                                                        *ngIf="getselectedProductIds().includes(categoryProduct.id)"
                                                        class="btn cp-button" class="btn cp-button">
                                                        <span>
                                                            <i class="fa fa-minus"
                                                                (click)="decreaseItem(categoryProduct.id, 'normal', 1)"
                                                                style="font-size:12px;cursor:pointer;"></i>
                                                        </span>
                                                        <span
                                                            class="inq-sp-2  pl-1 pr-1">{{getproductQuantity(categoryProduct.id)}}</span>
                                                        <span><i class="fa fa-plus" (click)="addToCart(categoryProduct)"
                                                                style="font-size:12px;cursor:pointer;"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="text-center customise-text"
                                                *ngIf="categoryProduct.customise_products && categoryProduct.customise_products.customised">
                                                customisable</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls id="foo" class="current"
                                style="float:right;margin-top: 15px;font-size: 1rem;"
                                (pageChange)="getPageProducts($event)"></pagination-controls>
                        </div>
                        <div *ngIf="selectedCategoryProducts.length === 0">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg"
                                class="img-fluid1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div *ngIf="selectedProducts.length > 0" class="menus-title ">Cart Items</div>
                <div class="cart-container">
                    <div *ngIf="selectedProducts.length > 0">
                        <div *ngFor="let sps of selectedProducts; let i = index">
                            <div class="row" style="padding-top: 18px;">
                                <div class="col-md-6">
                                    <span class="cart-item-name">{{sps.name | titlecase}}</span><br>
                                    <span *ngIf="sps.customized" (click)="customizeItem(sps,i)"
                                        class="customize-txt">customize ></span>
                                </div>
                                <div class="col-md-4" style="padding-right:0px !important;">
                                    <button class="btn cp-sm-btn btn-sm">
                                        <span> <i class="fa fa-minus" (click)="decreaseItem(sps.id, 'package', i)"
                                                style="font-size:12px;cursor:pointer;"></i></span>
                                        <span class="inq-sp-2  pl-1 pr-1">{{sps.package_quantity}}</span>
                                        <span><i class="fa fa-plus" (click)="addToCart(sps.product)"
                                                style="font-size:12px;cursor:pointer;"></i></span>
                                    </button>
                                </div>
                                <div class="col-md-2 rating-text" style="padding-left:8px !important">
                                    ₹{{(sps.package_total + getAddOnProductAmount("array",sps.add_onproducts)) *
                                    sps.package_quantity}}</div>
                            </div>
                        </div>

                        <button type="button" style="margin-top: 35px;" class="btn btn-outline-dark btn-block"
                            (click)="redirectPage('cart')">VIEW CART</button>
                        <div class="text-center mt-1 mb-1"><small style="font-size: 0.95rem;">(or)</small></div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-block"
                                    style="background-color: #023D4F !important;border: 1px solid #023D4F !important;"
                                    (click)="redirectPage('checkout')">PROCEED TO CHECKOUT</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedProducts.length === 0">
                        <div
                            style="border-left: 1px solid rgb(216, 214, 214);padding-top: 20px; padding-left: 15px;padding-bottom: 60px;">
                            <img class="customize-empty-cart" src="../../assets/images/emptycart1.png"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loadErroPage">
        <div class="text-center">
            <img class="error-img" src="../../assets/images/errorpage.png">
            <div>
                <h4>Something Went Wrong!</h4>
            </div>
            <p class="pb-1" style="color: rgb(136, 136, 133);">Please try again to load the data.</p>
            <button class="btn btn-primary custom-btn" (click)="reloadCurrentRoute()">Try Again</button>
        </div>
    </div>
</section>
<div>
    <button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" class="close" id="addclose" data-dismiss="modal" aria-label="Close" (click)="addclose(false)">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" class="close" id="hideaddclose" style="display:none;" data-dismiss="modal" aria-label="Close" (click)="addclose(true)">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedProduct">
                            <div class="m-title">{{selectedProduct.name | titlecase}}</div>
                            <div class="m-price"><i class="fa fa-rupee"></i>{{selectedProduct.pricing.raw.price +
                                getBundleAmount('obj', selectedProduct.bundle_products ? selectedProduct.bundle_products
                                : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div
                                *ngIf="selectedProduct && selectedProduct.bundle_products && selectedProduct.bundle_products.make_bundle && selectedProduct.bundle_products.products.length > 0">
                                <div class="options-title">Default Add-on</div>
                                <div *ngFor="let sproduct of selectedProduct.bundle_products.products; let i = index">
                                    <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i"
                                        [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                                    <label
                                        class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0,
                                        sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div
                                *ngIf="selectedProduct && selectedProduct.customise_products && selectedProduct.customise_products.customised && selectedProduct.customise_products.customisedProducts.length > 0">
                                <!-- <div class="options-title">Add-ons <span class="rating-text">(optional)</span></div> -->
                                <div class="options-title">Pick Your Choice Of
                                    {{selectedProduct.customise_products.customisedProducts[0].product_name.includes('Sambar')?"Sambar":"Chutney"}}
                                    (Upto {{maxselecteditems}} of
                                    {{selectedProduct.customise_products.customisedProducts.length}} items) </div>
                                <app-alerts *ngIf="showaddonalert"></app-alerts>
                                <div *ngIf="selectedProduct.customise_products.customisedProducts.length>5" style="-moz-column-count:2; /* Firefox */
                                -webkit-column-count:2; /* Safari and Chrome */
                                column-count:2;">
                                    <div
                                        *ngFor="let aproduct of selectedProduct.customise_products.customisedProducts; let i = index">


                                        <input class="largerCheckbox" type="checkbox" [id]="'aproduct'+i"
                                            [name]="aproduct.product_id" [value]="aproduct.product_id"
                                            (click)="getaddonItem($event, 'aproduct'+i)">
                                        <label
                                            class="option-product">{{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                            aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                            <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                        </label><br>


                                    </div>

                                </div>
                                <div *ngIf="selectedProduct.customise_products.customisedProducts.length<=5">
                                    <div
                                        *ngFor="let aproduct of selectedProduct.customise_products.customisedProducts; let i = index">


                                        <input class="largerCheckbox" type="checkbox" [id]="'aproduct'+i"
                                            [name]="aproduct.product_id" [value]="aproduct.product_id"
                                            (click)="getaddonItem($event, 'aproduct'+i)">
                                        <label
                                            class="option-product">{{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                            aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                            <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                        </label><br>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-around">
                            <div *ngIf="selectedProduct" class="m-total">Total: <span
                                    style="padding-left: 2px;">₹{{selectedProduct.pricing.raw.price +
                                    getBundleAmount('obj', selectedProduct.bundle_products ?
                                    selectedProduct.bundle_products : "") + getAddOnProductAmount('array',
                                    addonIds)}}</span></div>
                            <button type="button" class="btn m-btn"  (click)="addItemstocart()">ADD
                                ITEM</button>
                        </div>

                    </div>
                    <button style="display: none;" type="button" id="cancelBtn"
                        class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <button id="launch-model1" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel1">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" id="updateclose" class="close" (click)="updateclose()" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" id="updatehideclose" style="display:none;"class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedPackageProduct">
                            <div class="m-title">{{selectedPackageProduct.name | titlecase}}</div>
                            <div class="m-price"><i
                                    class="fa fa-rupee"></i>{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.bundle_products && selectedPackageProduct.product.bundle_products.make_bundle">
                                <div class="options-title">Default Add-on</div>
                                <div *ngIf="selectedPackageProduct.product.bundle_products.products.length > 0">
                                    <div
                                        *ngFor="let sproduct of selectedPackageProduct.product.bundle_products.products; let i = index">
                                        <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i"
                                            [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                                        <label
                                            class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0,
                                            sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.customise_products && selectedPackageProduct.product.customise_products.customised && selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                <!-- <div class="options-title">Add-ons <span class="rating-text">(optional)</span></div> -->
                                <div class="options-title">Pick Your Choice Of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts[0].product_name.includes('Sambar')?"Sambar":"Chutney"}}
                                    (Upto {{maxselecteditems}} of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts.length}}
                                    items) </div>
                                <app-alerts *ngIf="showaddonalert"></app-alerts>
                                
                                <div
                                    *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                    <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length>5" style="-moz-column-count:2; /* Firefox */
                                        -webkit-column-count:2; /* Safari and Chrome */
                                        column-count:2;">
                                         <div
                                         *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                         <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                             [name]="aproduct.product_id" [value]="aproduct.product_id"
                                             (click)="updateaddonItem($event, 'aproducts'+i)"
                                             [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.product_id)">
                                         <label class="option-product">
                                             {{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                             aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                             <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                         </label><br>
                                     </div>
                                        </div>
                                        
                                        <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length<=5">
                                            <div
                                            *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                            <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                                [name]="aproduct.product_id" [value]="aproduct.product_id"
                                                (click)="updateaddonItem($event, 'aproducts'+i)"
                                                [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.product_id)">
                                            <label class="option-product">
                                                {{aproduct.product_name.includes('-')?aproduct.product_name.substring(0,
                                                aproduct.product_name.lastIndexOf('-')):aproduct.product_name}}
                                                <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                                            </label><br>
                                        </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex justify-content-around">
                        <div *ngIf="selectedPackageProduct" class="m-total">Total: <span
                                style="padding-left: 2px;">₹{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "") + getAddOnProductAmount('array',
                                customizedIds)}}</span></div>
                        <button type="button" class="btn m-btn"
                            (click)="updateItemstoCart()">UPDATE ITEM</button>
                    </div>
                </div>
                <button style="display: none;" type="button" id="cancelBtn1"
                    class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- <div>
    <button id="pincode-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#pincodeModel">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="pincodeModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                    <div class="model-content m-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                    
                            <div class="form-group">
                              <input type="text" class="form-control" style="width: 90%" [(ngModel)]="pincode" placeholder="Please Enter Pincode">
                              <p id="error" style="color:red;display: none;"> Sorry,we do not deliver to this pincode</p>
                       
                            </div>
                            <div class="d-flex justify-content-around" style="margin-bottom:10px">
                                <button type="button" class="btn m-btn" (click)="validatepincode()">ACCEPT</button>
                        
                            </div>
                       
                     
                            
                     
                   
                </div>
            </div>
        </div>
    </div>
</div> -->
