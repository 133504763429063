import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductListComponent } from './product-list/product-list.component';

//cart page
import { CartPageComponent } from './cart-page/cart-page.component';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component'
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TableBookingComponent } from './table-booking/table-booking.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyOrderDetailsComponent } from './my-order-details/my-order-details.component';
import { AuthGuard } from './shared/auth_guard/auth_guard.service';
import {CartGuard} from './shared/auth_guard/protect_cart.service';
import { NavbarComponent } from './navbar/navbar.component';
import { GiftMealComponent } from './gift-meal/gift-meal.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderCancellationComponent } from './order-cancellation/order-cancellation.component';
import { TermsAndConditionComponent } from './static-pages/terms-and-condition/terms-and-condition.component';
import { ShippingDeliveryComponent } from './static-pages/shipping-delivery/shipping-delivery.component';
import { PrivacyPoicyComponent } from './static-pages/privacy-poicy/privacy-poicy.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ProfileDetailsComponent } from './my-account/profile-details/profile-details.component';
import { AddReviewComponent} from './add-review/add-review.component';
import { ProductReviewComponent } from './product-review/product-review.component';
import { environment } from 'src/environments/environment';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { CheckoutNewComponent } from './checkout-new/checkout-new.component';
import { LoginNewComponent } from './login-new/login-new.component';
//home screen2 routes
import {HomeTwoComponent} from './home-two/home-two.component'
import { ProdutQRListingComponent } from './produt-qr-listing/produt-qr-listing.component';
import { CancellationPolicyComponent } from './static-pages/cancellation-policy/cancellation-policy.component';
import { ReturnPolicyComponent } from './static-pages/return-policy/return-policy.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { ServicesComponent } from './static-pages/services/services.component';
import { EarthyWorthyAboutusComponent } from './static-pages/earthy-worthy-aboutus/earthy-worthy-aboutus.component';
import { ProductListCustomizeComponent } from './product-list-customize/product-list-customize.component';
import { CartNewService } from './cart-new/cart-new.service'
import { WishlistComponent } from './wishlist/wishlist.component';
import { CartNewComponent } from './cart-new/cart-new.component';
import { ComapnyProductDetailsComponent } from './comapny-product-details/comapny-product-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CategoryListingsComponent } from './category-listings/category-listings.component';
import { MemberRegisterComponent } from './member-register/member-register.component';
import { EnquiryStatusComponent } from './enquiry-status/enquiry-status.component';



const routes: Routes = [
  { path: "", component: HomeComponent, data: {name: 'default', roles: ['haven', 'tams','mayan', 'nesma','dosapark']}, canActivate: [AuthGuard] },
  { path: "", component: HomeTwoComponent, data: {name: 'default', roles: ['earthyworthy','bbold', 'deera','shubhcards','aiema','bombayhardware']}, canActivate: [AuthGuard] },
  { path: "add-review/:id/:pid/:i", data: {name: 'default', roles: ['all']}, component: AddReviewComponent, canActivate: [AuthGuard] },
  { path : 'product-review/:id', data: {name: 'default', roles: ['all']}, component : ProductReviewComponent, canActivate: [AuthGuard] },
  { path: "product", data: {name: 'default', roles: ['all']}, component: ProductListComponent, canActivate: [AuthGuard] },
  { path: "confirmation", data: {name: 'default', roles: ['all']}, component: OrderConfirmationComponent, canActivate: [AuthGuard]},
  { path: "confirmation:id", data: {name: 'default', roles: ['all']}, component: OrderConfirmationComponent, canActivate: [AuthGuard]},
  { path: "cancellation", data: {name: 'default', roles: ['all']}, component: OrderCancellationComponent, canActivate: [AuthGuard]},
  { path: "cancellation:id:details", data: {name: 'default', roles: ['all']}, component: OrderCancellationComponent, canActivate: [AuthGuard]},
  { path: "forgot-password", data: {name: 'default', roles: ['all']}, component: UserForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: "cart", data: {name: 'default', roles: ['all']}, component: CartPageComponent, canActivate: [AuthGuard] },
  { path: 'about-us', data: {name: 'default', roles: ['haven', 'tams','mayan', 'aiema','bombayhardware','shubhcards', 'deera','dosapark','bbold','nesma']}, component: AboutUsComponent, canActivate: [AuthGuard] },
  { path: 'about-us', data: {name: 'default', roles: ['earthyworthy']}, component: EarthyWorthyAboutusComponent, canActivate: [AuthGuard] },
  { path: 'FAQs', data: {name: 'default', roles: ['all']}, component: FaqsComponent, canActivate: [AuthGuard] },
  { path: 'table-booking', data: {name: 'default', roles: ['haven']}, component: TableBookingComponent, canActivate: [AuthGuard] },
  { path: 'my-orders', data: {name: 'default', roles: ['all']}, component: MyOrdersComponent, canActivate: [AuthGuard] },
  { path: 'my-order-details/:id', data: {name: 'default', roles: ['all']}, component: MyOrderDetailsComponent, canActivate: [AuthGuard] },
  { path: "cart", data: {name: 'default', roles: ['all']}, component: CartPageComponent, canActivate: [AuthGuard] },
  { path: "gift-meal", data: {name: 'default', roles: ['haven']}, component: GiftMealComponent, canActivate: [AuthGuard] },
  { path : 'terms-and-condition', data: {name: 'default', roles: ['all']}, component : TermsAndConditionComponent, canActivate: [AuthGuard]},
  { path : 'shipping-and-delivery', data: {name: 'default', roles: ['all']}, component : ShippingDeliveryComponent, canActivate: [AuthGuard]},
  { path : 'privacy-policy', data: {name: 'default', roles: ['all']}, component : PrivacyPoicyComponent , canActivate: [AuthGuard]},
  { path : 'cancellation-policy', data: {name: 'default', roles: ['all']}, component : CancellationPolicyComponent , canActivate: [AuthGuard]},
  { path : 'return-policy', data: {name: 'default', roles: ['all']}, component : ReturnPolicyComponent , canActivate: [AuthGuard]},
  { path : 'my-account', data: {name: 'default', roles: ['all']}, component : MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'checkout-new',data: {name: 'default', roles: ['dosapark','haven', 'nesma', 'tams','earthyworthy','bbold', 'luckme', 'murgasen',  'deera','shubhcards', 'mayan']}, component: CheckoutNewComponent,canActivate: [CartGuard] },
  { path: 'login-new',data: {name: 'default', roles: ['all']}, component: LoginNewComponent },
  { path: 'member-register',data: {name: 'default', roles: ['all']}, component: MemberRegisterComponent },
  {
    path: 'home-two',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./home-two/home-two.module').then(m => m.HomeModule)
  },
  { path : 'product-details/:id', component : ProductDetailsComponent, data: {name: 'default', roles: ['tams','haven','earthyworthy','bbold','mayan', 'deera','shubhcards','bombayhardware']}, canActivate: [AuthGuard] },
  { path: "productListing", data: {name: 'default', roles: ['all']}, component: ProductListingComponent, canActivate: [AuthGuard] },
  { path: "knowily", data: {name: 'default', roles: ['tams']}, component: ProdutQRListingComponent, canActivate: [AuthGuard] },

  { path: "contact-us", data: {name: 'default', roles: ['all']}, component: ContactUsComponent, canActivate: [AuthGuard] },
  { path: "wishlist/:id", data: {name: 'default', roles: ['all']}, component: WishlistComponent, canActivate: [AuthGuard] },
  { path: "wishlist", data: {name: 'default', roles: ['all']}, component: WishlistComponent, canActivate: [AuthGuard] },
  { path: "services", data: {name: 'default', roles: ['all']}, component: ServicesComponent, canActivate: [AuthGuard] },
  { path: "product-listing", data: {name: 'default', roles: ['nesma']}, component: ProductListCustomizeComponent, canActivate: [AuthGuard] },
  { path: "cart-new", data: {name: 'default', roles: ['nesma']}, component: CartNewComponent, canActivate: [AuthGuard] },
  { path: "company-details/:id", data: {name : 'default', roles: ['aiema','bombayhardware']}, component: ComapnyProductDetailsComponent, canActivate: [AuthGuard]},
  { path: "company-details1/:id", data: {name : 'default', roles: ['aiema','bombayhardware']}, component: CompanyDetailsComponent, canActivate: [AuthGuard]},
  { path: "category-listing", data: {name : 'default', roles: ['aiema','bombayhardware']}, component: CategoryListingsComponent, canActivate: [AuthGuard]},
  { path: "enquiry-status", data: {name : 'default', roles: ['aiema','bombayhardware']}, component: EnquiryStatusComponent, canActivate: [AuthGuard]},

];


@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true
    }
  ],
  exports: [RouterModule]
 })
export class AppRoutingModule { }


export function initApp(injector: Injector) {
  return () => {
    return new Promise<void>((resolve) => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter((r:any) => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      resolve();
    });
  };
}
