<section>   
    <div *ngIf="!deliveryDataExisits">
        <div class="customer-container">
            <div *ngIf="deliveryInfo">
                <div style="margin-top: 5px; margin-bottom: 15px;">
                    <div *ngIf="deliveryInfo.enableDelivery" class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" value="Delivery" (change)="getDeliveyType($event.target.value)" [checked]="deliverType === 'Delivery'">
                        <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline1">{{deliveryInfo.deliveryDisplayName}}</label>
                    </div>
                    <div *ngIf="deliveryInfo.enablePickinStore" class="custom-control custom-radio custom-control-inline custom-radios">
                        <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" value="PickUp In Store" [checked]="deliverType === 'PickUp In Store'" (change)="getDeliveyType($event.target.value)">
                        <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline2">{{deliveryInfo.pickinStoreDisplayName}}</label>
                    </div>
                </div>
            </div>
            <form [formGroup]= "deliveryForm">
                <div class="row">
                    <div class="col-md-6" style="border-right: 1px solid rgb(235, 234, 234);">
                        <div class="checkout-forms floating-forms">
                            <div class="floating-label"> 
                                
                            <select class="floating-select form-control"  formControlName="outlet" [class.is-invalid] = "validateDeliveryForm && f.outlet.errors">
                                <option value="" *ngIf="outlets.length!=1">Select Restaurant</option>
                                <option *ngFor="let outlet of outlets;let i=index;" [attr.selected]="outlets.length==1?i==0:null"  [value]="outlet.id">{{outlet.name}}</option>
                              </select>
                              <label>Restaurant</label>
                                
                                <!-- <span class="highlight"></span> -->
                                
                                <div class="invalid-feedback" *ngIf="f.outlet.errors">
                                    <div *ngIf="f.outlet.errors.required">Please select the Restaurant</div>
                                </div>
                            </div>
                            <div class="floating-label" >  
                                <select class="floating-select form-control" formControlName="preferredTime" [class.is-invalid] = "validateDeliveryForm && f.preferredTime.errors" (change)="getPreferredTimings($event)">
                                  <option value="">Select Preferred Delivery Timings</option>
                                  <option [value]="details" *ngFor="let details of deliverydetails.delivery_preferred_time" >{{details}}</option>
                                  
                                </select>
                               
                                <span class="highlight"></span>
                                <label>Preferred Delivery Timings</label>
                                <div class="invalid-feedback" *ngIf="f.preferredTime.errors">
                                    <div *ngIf="f.preferredTime.errors.required">Please select your preferred timings to deliver food.</div>
                                    <div *ngIf="f.preferredTime.errors.incorrect">Sorry we can't deliver food now. Please choose some other time.</div>
                                </div>
                            </div>
                        </div>
                        <div class="checkout-forms floating-forms">
                            <button class="btn btn-block btn-primary btn-primary1 mt-1" (click)="saveDeliveryInfo()">SAVE</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="preferredTimings === 'Later'||preferredTimings === 'Pre Order'">
                            <div class="custom-datepicker">
                                <label>Preferred Date to deliver the Food</label>
                                <!-- <input type="date" id="dateformat" class="form-control"  formControlName="deliverDate" (onChange)="dateChanged()"> -->
                                <dp-date-picker class="form-control"  theme="dp-material" [config]="datePickerConfig"  placeholder="Select Deliver Date" formControlName="deliverDate" (onChange)="dateChanged()" #dayPicker></dp-date-picker>
                              
                                 <div class="invalid-feedback" *ngIf="f.deliverDate.errors">
                                    <div *ngIf="f.deliverDate.errors.incorrect">Please select your preferred Date to deliver Food.</div>
                                </div>
                            </div>
                            <div class="checkout-forms floating-forms">
                                <div class="floating-label">  
                                    <select class="floating-select form-control" formControlName="deliverTime" [class.is-invalid] = "validateDeliveryForm && f.deliverTime.errors" [(ngModel)]="deliveryInformation.delivery_time">
                                        <option value="">Select Preferred Time</option>
                                        <option *ngFor="let slot of slotRange" value="{{slot}}">{{slot}}</option>
                                    </select>
                                    <p style="color:red" *ngIf="slotRange.length==0">Sorry, Slots not available for the day</p>
                                    <span class="highlight"></span>
                                    <label>Preferred Time</label>
                                    <div class="invalid-feedback" *ngIf="f.deliverTime.errors&&slotRange.length!=0">
                                        <div *ngIf="f.deliverTime.errors.incorrect">Please select your preferred Time to deliver Food.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="deliveryDataExisits" style="padding: 10px 10px 8px 20px;">
        <div class="row">
            <div class="col-md-10">
                <span class="shipping-font" *ngIf="deliveryInformation.delivery_preferred_time === 'Now'||deliveryInformation.delivery_preferred_time ==='Express Delivery 90 Mins'">Your food will be delivered from <b class="shipping-font">{{deliveryInformation.outlet_name}}</b></span>
                <span class="shipping-font" *ngIf="deliveryInformation.delivery_preferred_time === 'Later'||deliveryInformation.delivery_preferred_time ==='Pre Order'">Your food will be delivered from <b class="shipping-font">{{deliveryInformation.outlet_name}}</b> on <b class="shipping-font">{{deliveryInformation.delivery_date}}</b> at <b class="shipping-font">{{deliveryInformation.delivery_time}}</b></span>
            </div>
            <div class="col-md-2">
                <button class="btn btn-secondary btn-secondary1" (click)="editDeliveryInfo()">CHANGE</button>
            </div>
        </div>
    </div>
</section>
