
    <!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
        <div class="container-fluid header sticky-header background-filter" *ngIf="configs.title != 'Aiema'">
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer earth-worthy-logo" height="50" alt="Logo"
                                [routerLink]="['/']">
                    <div class="header-row-one">
                        <div class="search-bar-group" style="margin-right: 10px !important;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input">
                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div *ngIf="configs.title!=='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                            <div *ngIf="configs.title==='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div>
                                                    <a class="dropdown-item" (click)="navigateToList1('GI')"  style="cursor:pointer;font-size: 14px !important;">GI</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('GP')"  style="cursor:pointer;font-size: 14px !important;">GP</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('MS')"  style="cursor:pointer;font-size: 14px !important;">MS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('RHS&SHS')"  style="cursor:pointer;font-size: 14px !important;">RHS&SHS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Seamless')"  style="cursor:pointer;font-size: 14px !important;">Seamless</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SpiralWeilded')"  style="cursor:pointer;font-size: 14px !important;">SpiralWeilded</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('UPVC')"  style="cursor:pointer;font-size: 14px !important;">UPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('CPVC')"  style="cursor:pointer;font-size: 14px !important;">CPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('PVC')"  style="cursor:pointer;font-size: 14px !important;">PVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SWR')"  style="cursor:pointer;font-size: 14px !important;">SWR</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Flats')"  style="cursor:pointer;font-size: 14px !important;">Flats</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Angles')"  style="cursor:pointer;font-size: 14px !important;">Angles</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Pumps')"  style="cursor:pointer;font-size: 14px !important;">Pumps</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                <!-- <li>
                                    <a href="javascript:;">
                                        <span class="lnr lnr-heart">
                                            <span class="count">
                                                <span class="count-number">0</span>
                                            </span>
                                        </span>
                                        <span class="menu-title">
                                            Wishlist
                                        </span>
                                    </a>
        
                                </li> -->
        
                                <!-- <li *ngIf="appService.userLoggedIn"> -->
                                    <!-- <a href="javascript:;">
                                                    <span class="lnr lnr-user">
                                                    </span>  
                                                    <span class="menu-title" (click)= "showLogout()">
                                                        My Account                                                
                                                    </span> -->
                                    
        
                                    <!-- </a> -->
                                <!-- </li> -->
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:20px !important">
                                    <a href="javascript:;"  style="text-decoration: none !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu background-filter" style="border: 1px solid white" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a *ngIf= "configs.title !== 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            <a *ngIf= "configs.title === 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row" *ngIf="configs.title != 'Aiema' && configs.title != 'BBold'">
            <app-menu></app-menu>
        </div>
        <div class="container-fluid navbar-row" *ngIf="configs.title == 'BBold'" style="background-color:#010101">
            <app-menu></app-menu>
        </div>
    <!-- </div> -->
<div *ngIf="configs.title === 'Aiema'">
    <div class="earthy-worthy-section earthy-worthy-menu">
        <div class="container-fluid header sticky-header" >
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer earth-worthy-logo" height="50" alt="Logo" style="width: 72px!important; height: 63px!important;"
                                [routerLink]="['/']">
                    <div class="header-row-one" style="width: 89%;">
                        <div class="search-bar-group" style="margin-right: 10px !important;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input" style="margin-right:25px">
                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                <li *ngIf="!appService.userLoggedIn" style="margin-right:20px !important">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                            class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                            </span>
                                            User/Member Login
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <a class="logout-cutom dropdown-item" routerLink="login-new">User Login/Register</a>
                                            <a class="logout-cutom dropdown-item" routerLink="member-register">Member Register</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="float-right">
                            <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                        </div>
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
            <app-menu></app-menu>
        </div>
    </div>
</div>