import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDeliveryOrderService {

  deliveryObj = {
    outlet_id : "",
    outlet_name : "",
    outlet_latitude: "",
    outlet_longitude: "",
    delivery_preferred_time: "",
    delivery_date:"",
    delivery_time:"",
    delivery_type: ""
  }

  selectedDateTime:any = "";

  constructor() { }

  setDeliveryObject(deliveryObject){
    this.deliveryObj = deliveryObject;
  }
}
