import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';

import { CartNewService } from './cart-new.service';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import {AlertsService} from '../shared/alerts/alerts.service';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Component({
  selector: 'app-cart-new',
  templateUrl: './cart-new.component.html'
})
export class CartNewComponent implements OnInit {

  cartItems:any = [];
  allProducts:any = [];
  selectedProduct:any;
  customizedIndex:any;
  selectedPackageProduct:any;
  customizedIds:any = [];
  customizedtemp:any =[];
  previousupdateprod;
  addonIds:any = [];
  enabledisableupdatebutton:boolean=false;
  temp1:any=[];
  maxselecteditems;
  acceptanceTiming:any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  showAlerts = true;
  showaddonalert=true;
  maxQuantity:any = 0;

  constructor(
    private cartNewService: CartNewService,
    public appService:AppService,
    private alertService : AlertsService,
    private router : Router,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    if(this.localStorageService.get('max_cart_qty')){
      this.maxQuantity = Number(this.localStorageService.get('max_cart_qty'))
    }
    this.getAcceptanceTimings();
    this.getAllProducts();
    if(this.cartNewService.getCartItems() !== null){
      this.cartItems = this.cartNewService.getCartItems()
    }
    else{
      this.cartItems = [];
    }

    this.cartNewService.currentData.subscribe(
      currentData => {
        let curData:any = currentData;
        if(curData.type === "cartData"){
          this.cartItems = curData.value;
        }
      }
    )
  }

  getproductNameList(productidsList){
    if(productidsList.length > 0 ){
      let productsName = "";
      productidsList.forEach(proId => {
        if(this.allProducts.find(ap => ap.id === proId)){
          productsName = productsName +  this.allProducts.find(ap => ap.id === proId).name
        }
      });
      return productsName;
    }
    else{
      return "";
    }
  }

  getAllProducts(){
    let requestData = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: null,
      extras: {
        find:{},
        pagination: false,
          paginationDetails: {
            limit:10,
            pagesize: 20
          },
        sorting:false,
        sortingDetails: {
          sortfield: "id",
          sortorder: -1
        }
      }
      
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
      resp => {
        let res:any = resp;
        if(res.status_code === 200 && res.success === 1){
          this.allProducts = res.result.data;
        }
      }
    )
  }

  decreaseItem(id, type, index){
    if(type === "normal"){
      let spindex = this.cartItems.findIndex(pd => pd.id === id);
      if(this.cartItems[spindex].quantity === 1){
        this.cartItems.splice(spindex, 1)
      }
      else{
        this.cartItems[spindex].quantity -= 1;
        this.cartItems[spindex].package_quantity -= 1;
      }
    }
    else if(type === "package"){
      if(this.cartItems[index].quantity === 1){
        this.cartItems.splice(index, 1)
      }
      else{
        this.cartItems[index].quantity -= 1;
        this.cartItems[index].package_quantity -= 1;
      }
    }
    this.updateProductstoCart();
  }

  updateProductstoCart(){
    this.cartNewService.updateToCart(this.cartItems);
  }

  addToCart(product){
    let totalItems = this.cartItems.length > 0 ? this.cartItems.map(function (sp){return sp.package_quantity}).reduce((a,b) => a + b) : 0;
    if(totalItems >= this.maxQuantity){
      this.showAlerts=true;
      this.alertService.error("Sorry, you can not order more than "+this.maxQuantity + " in an order.",this.options)
    }
    else{
      if(product.customise_products && product.customise_products.customised){
        this.selectedProduct = product
        document.getElementById('launch-model').click();
      }
      else{
        if(this.cartItems.find(pd => pd.id === product.id) !== undefined){
          let spindex =this.cartItems.findIndex(pd => pd.id === product.id);
         this.cartItems[spindex].quantity += 1;
         this.cartItems[spindex].package_quantity += 1;
        }
        else{
          let bundle_amount = 0;
          if(product.bundle_products && product.bundle_products.make_bundle && product.bundle_products.chargable && product.bundle_products.products.length > 0){
            bundle_amount = this.getBundleAmount('arrays',  product.bundle_products.products.map(function (sp){return sp.product_id}))
          }
         this.cartItems.push({
            id: product.id,
            name: product.name,
            quantity: 1,
            package_quantity: 1,
            package_total: product.pricing.raw.price + bundle_amount,
            product_cost: product.pricing.raw.price,
            bundle_products: product.bundle_products && product.bundle_products.make_bundle && product.bundle_products.products.length > 0 ? product.bundle_products.products.map(function (sp){return sp.product_id}): [],
            add_onproducts: [],
            customized:false,
            bundle_chargable: product.bundle_products && product.bundle_products.make_bundle && product.bundle_products.chargable,
            product:product
          })
        }
      }
      this.updateProductstoCart(); 
    }    
  }

  getBundleAmount(type, bundleObj):any{
    return 0;
    // if(type==='obj'){
    //   if(bundleObj){
    //     if(bundleObj.make_bundle && bundleObj.products && bundleObj.products.length > 0 && bundleObj.chargable){
    //       let totalBundleAmount = 0
    //       bundleObj.products.forEach(element => {
    //         totalBundleAmount += this.allProducts.find(pd => pd.id === element.product_id) !== undefined ? this.allProducts.find(pd => pd.id === element.product_id).pricing.raw.price : 0;
            
    //       });
    //       return totalBundleAmount;
    //     }
    //     else{
    //       return 0;
    //     }
    //   }
    //   else return 0;
    // }
    // else if(type === "arrays"){
    //   if(bundleObj && bundleObj.length > 0){
    //     let totalBundleAmount = 0;
    //     bundleObj.forEach(element => {
    //       totalBundleAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0 
    //     });
    //     return totalBundleAmount;
    //   }
    //   else{
    //     return 0;
    //   }
    // }
  }

  getTotalAmount(products){
    return this.cartNewService.findTotalAmount(products)
  }
  enableupdate()
{
  if(this.customizedtemp.length+this.customizedIds.length<this.maxselecteditems)
  {
    this.enabledisableupdatebutton=false;
  }
  else{
    this.enabledisableupdatebutton=true;
  }
  // if(this.customizedIds.length<this.maxselecteditems)
  // {
  //   this.enabledisableupdatebutton=false;
  // }
  // else{
  //   this.enabledisableupdatebutton=true;
  // }
}

  updateItemstoCart(){
    //this.customizedIds=this.customizedIds.concat(this.customizedtemp)
   
    if(Number(this.customizedIds.length)<this.maxselecteditems)
    {
      this.showAlerts=false;
      this.alertService.error("Minimum Add-on should be "+this.maxselecteditems + " for this item",this.options)
      
      return false;
    }
    // if(this.customizedIds.length<this.maxselecteditems)
    // {
    //   this.alertService.error("Minimum Add-on should be "+this.maxselecteditems + " for this item",this.options)
    //   return false;
    // }
    // if (!((this.cartItems[this.customizedIndex].add_onproducts.length === this.customizedIds.length) && (this.cartItems[this.customizedIndex].add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))){
    //   if(this.cartItems.find((sp, index) => {return (sp.id === this.selectedPackageProduct.id) && (index !== this.customizedIds)})){
    //     if(this.cartItems.find((sp, indx) => {return (sp.id === this.selectedProduct.id)&& (indx !== this.customizedIds) && (sp.add_onproducts.length === this.customizedIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))})){
    //       let eindex = this.cartItems.findIndex((sp, indx) => {return (sp.id === this.selectedProduct.id)&& (indx !== this.customizedIds) && (sp.add_onproducts.length === this.customizedIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))})
    //       if(eindex >= 0){
    //         this.cartItems[eindex].quantity = this.cartItems[eindex].quantity + this.cartItems[this.customizedIndex].quantity;
    //         this.cartItems[eindex].package_quantity = this.cartItems[eindex].package_quantity + this.cartItems[this.customizedIndex].package_quantity;
    //         this.cartItems.splice(this.customizedIndex, 1)
    //       }
    //       else{
    //         this.cartItems[this.customizedIndex].add_onproducts = this.customizedIds;
    //       }
    //     }
    //     else{
    //       this.cartItems[this.customizedIndex].add_onproducts = this.customizedIds;
    //     }
    //   }
    //   else{
    //     this.cartItems[this.customizedIndex].add_onproducts = this.customizedIds;
    //   }
    // }
    this.cartItems[this.customizedIndex].add_onproducts = this.customizedIds;
    this.updateProductstoCart();
    document.getElementById('updatehideclose').click();
  }

  customizeItem(sp, i){
    document.getElementById('launch-model1').click();
    this.customizedIndex = i;
    this.selectedPackageProduct = sp;
    this.customizedIds = sp.add_onproducts ? sp.add_onproducts : [];
    this.maxselecteditems=sp.product.customise_products.no_of_products;
  }

  getAddOnProductAmount(type, addonObj):any{
    return 0;
    // if(type="array"){
    //   if(addonObj && addonObj.length > 0){
    //     let totalAddOnAmount = 0
    //     addonObj.forEach(element => {
    //       totalAddOnAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0;
    //     });
    //     return totalAddOnAmount
    //   }
    //   else{
    //     return 0;
    //   }
    // }
  }
  updateclose()
  {
    if(Number(this.customizedIds.length)<this.maxselecteditems)
    {
      this.showAlerts=false;
      this.alertService.error("Minimum Add-on should be "+this.maxselecteditems + " for this item",this.options)
      
      return false;
    }
    else
    {
      this.cartItems[this.customizedIndex].add_onproducts = this.customizedIds;
      this.updateProductstoCart();
      document.getElementById('updatehideclose').click();
    }
   
  }
  getaddonItem(e,id){
    this.temp1 = this.temp1.filter(sid => sid.name ===this.selectedProduct.name);
    let docid = document.getElementById(id) as HTMLInputElement;
    if(docid.checked){
      let obj = this.temp1.find(o => o.name ===this.selectedProduct.name);
    if(obj==undefined)
    {
      this.temp1.push({'name':this.selectedProduct.name,'values':[Number(e.target.value)]})
    }
    else{

      obj['values'].push(Number(e.target.value));
    }
    
    this.addonIds=this.temp1.find(o => o.name ===this.selectedProduct.name);
    this.addonIds=this.addonIds.values;
      if(this.addonIds.length>this.maxselecteditems)
{
  this.alertService.error("Sorry, you cannot Add more than "+this.maxselecteditems + " items",this.options)
  obj['values'].splice(obj['values'].indexOf(Number(e.target.value)),1)
 // this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
  return false;
}
    }
    else{
      //this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
      let obj = this.temp1.find(o => o.name ===this.selectedProduct.name);
     obj['values'].splice(obj['values'].indexOf(Number(e.target.value)),1)
    }
  }

  updateaddonItem(e,id){
 

    
    let docid = document.getElementById(id) as HTMLInputElement;
    if(docid.checked){
      this.customizedIds.push(Number(e.target.value))
      if(this.customizedIds.length>this.maxselecteditems)
      {
        this.showAlerts=false;
        this.alertService.error("Sorry, you cannot Add more than "+this.maxselecteditems + " items",this.options)
        
        this.customizedIds = this.customizedIds.filter(sid => sid !== Number(e.target.value))
        return false;
      }
    }
    else{
      this.customizedIds = this.customizedIds.filter(sid => sid !== Number(e.target.value))
    }
  }
  addItemstocart(){
    let bundle_amount = 0;
    if(this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable && this.selectedProduct.bundle_products.products.length > 0){
      bundle_amount = this.getBundleAmount('arrays',  this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}))
    }
    if(this.cartItems.find(sp => sp.id === this.selectedProduct.id)){
      if(this.cartItems.find(sp => {return (sp.id === this.selectedProduct.id) && (sp.add_onproducts.length === this.addonIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.addonIds.sort()[index])))})){
        let sindex = this.cartItems.findIndex(sp => {return (sp.id === this.selectedProduct.id) && (sp.add_onproducts.length === this.addonIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.addonIds.sort()[index])))})
        if(sindex >= 0){
          this.cartItems[sindex].quantity = this.cartItems[sindex].quantity + 1;
          this.cartItems[sindex].package_quantity = this.cartItems[sindex].package_quantity + 1;
        }
        else{
          this.cartItems.push({
            id: this.selectedProduct.id,
            name: this.selectedProduct.name,
            quantity: 1,
            package_quantity: 1,
            package_total: this.selectedProduct.pricing.raw.price + bundle_amount + this.getAddOnProductAmount('array', this.addonIds),
            product_cost: this.selectedProduct.pricing.raw.price,
            bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
            add_onproducts: this.addonIds,
            customized:true,
            bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
            product:this.selectedProduct
          })
        }
      }
      else{
        this.cartItems.push({
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          quantity: 1,
          package_quantity: 1,
          package_total: this.selectedProduct.pricing.raw.price + bundle_amount+ this.getAddOnProductAmount('array', this.addonIds),
          product_cost: this.selectedProduct.pricing.raw.price,
          bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
          add_onproducts: this.addonIds,
          customized:true,
          bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
          product:this.selectedProduct
        })
      }
    }
    else{
      this.cartItems.push({
        id: this.selectedProduct.id,
        name: this.selectedProduct.name,
        quantity: 1,
        package_quantity: 1,
        package_total: this.selectedProduct.pricing.raw.price + bundle_amount + this.getAddOnProductAmount('array', this.addonIds),
        product_cost: this.selectedProduct.pricing.raw.price,
        bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
        add_onproducts: this.addonIds,
        customized:true,
        bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
        product:this.selectedProduct
      })
    }
    if(this.addonIds.length > 0){
      this.selectedProduct.customise_products.customisedProducts.forEach((element,i) => {
        let docid = document.getElementById('aproduct'+i) as HTMLInputElement;
        docid.checked = false;
      });
    }
    this.addonIds = [];
    this.updateProductstoCart();
  }

  removecartItem(index){
    this.cartItems.splice(index,1);
    this.cartNewService.updateToCart(this.cartItems);
  }

  goToCheckout()
  {
    var today = moment();
    this.showAlerts = true;
    console.log("Acceptance timings", this.acceptanceTiming)
    var timeAvailable :boolean ;

    if(this.acceptanceTiming && this.acceptanceTiming.length === 0)
    {
      this.router.navigate(['/checkout-new'])
      return
    }
    else
    {
        this.acceptanceTiming.forEach(element => {
            let from = moment(today.format('YYYY-MM-DD') +" "+ element.from,"YYYY-MM-DD hh:mm A" )
            let to = moment(today.format('YYYY-MM-DD') +" "+ element.to,"YYYY-MM-DD hh:mm A")

            if (today >= from && today <= to)
            {
              timeAvailable = true
              return
            }
        });
    }
    if(timeAvailable === true)
    {
      this.router.navigate(['/checkout-new'])
    }
    else
    {
      // this.alertService.error('Restaurant opening soon',this.options)
      this.alertService.error('Currently orders are not being accepted!!! We are open from (11:30 AM to 3:30 PM and 6:30 PM to 10:00 PM) for taking orders',this.options)
    }

  }

  getAcceptanceTimings(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if(resp.success === 1 && resp.result.data.length > 0){

          this.acceptanceTiming = resp.result.data[0].order_acceptance_timing;
        }
        else if(resp.success === 1 && resp.result.data.length === 0){

          this.acceptanceTiming = [];
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }

}
