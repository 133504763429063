import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckoutOrderdetailsOrderService {

  constructor() { }

  deliveryInstructions:any = "";
  deliveryNotes:any = "";

  setDeliveryNotes(instrcutions, notes){
    this.deliveryInstructions = instrcutions;
    this.deliveryNotes = notes;
  }
}
