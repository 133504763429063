import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductListComponent } from './product-list/product-list.component';
import { HomeComponent } from './home/home.component';
import { MiniCartComponent } from './shared/mini-cart/mini-cart.component';
import { UserForgotPasswordComponent} from './user-forgot-password/user-forgot-password.component'
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component'
import { StaticPagesComponent } from './static-pages/static-pages.component';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TableBookingComponent} from './table-booking/table-booking.component';
import { MyOrdersComponent} from './my-orders/my-orders.component';
import { MyOrderDetailsComponent} from './my-order-details/my-order-details.component';
import { AppPaginationComponent } from './app-pagination/app-pagination.component';
import { FooterLuckmeComponent } from './footer/footer-luckme/footer-luckme.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AlertsComponent} from './shared/alerts/alerts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductDetailsPipePipe } from './product-details/product-details-pipe.pipe';
import { NgxImageZoomModule } from 'ngx-image-zoom';

// Google Maps Integration
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';

//cart component
import {CartPageComponent} from './cart-page/cart-page.component';
import {AuthGuard} from './shared/auth_guard/auth_guard.service';
import {CartGuard} from './shared/auth_guard/protect_cart.service';

//gift meal component
import {GiftMealComponent} from './gift-meal/gift-meal.component';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderCancellationComponent } from './order-cancellation/order-cancellation.component';

import { TermsAndConditionComponent } from './static-pages/terms-and-condition/terms-and-condition.component';
import { PrivacyPoicyComponent } from './static-pages/privacy-poicy/privacy-poicy.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ProfileDetailsComponent } from './my-account/profile-details/profile-details.component';
// import { ManageAddressComponent } from './my-account/manage-address/manage-address.component';
// import { CustomerAddressesComponent } from './my-account/customer-addresses/customer-addresses.component';
import { CustomerAddressComponent } from './my-account/customer-address/customer-address.component';
import { ChangePasswordComponent } from './my-account/change-password/change-password.component';

import { DatePipe } from '@angular/common';
import { AddReviewComponent } from './add-review/add-review.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import { ProductReviewComponent } from './product-review/product-review.component';
import {ProductDetailsComponent} from './product-details/product-details.component';
import {ProductFilterComponent} from './product-filter/product-filter.component';

import { ProductListingComponent } from './product-listing/product-listing.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { HeaderTwoComponent } from './header/header-two/header-two.component';
import {MenuComponent} from './menu/menu.component';
import {SharedModule} from './shared-two/shared.module'
import { CheckoutNewComponent } from './checkout-new/checkout-new.component';
import { CheckoutHeaderComponent } from './checkout-new/checkout-header/checkout-header.component';
import { CheckoutDeliveryComponent } from './checkout-new/checkout-delivery/checkout-delivery.component';
import { CheckoutShippingComponent } from './checkout-new/checkout-shipping/checkout-shipping.component';
import { CheckoutOrderdetailsComponent } from './checkout-new/checkout-orderdetails/checkout-orderdetails.component';
import { CheckoutPaymentComponent } from './checkout-new/checkout-payment/checkout-payment.component';
import { OrderSummaryComponent } from './checkout-new/order-summary/order-summary.component';
import { CheckoutLoginComponent } from './checkout-new/checkout-login/checkout-login.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginNewComponent } from './login-new/login-new.component';
import { ProdutQRListingComponent } from './produt-qr-listing/produt-qr-listing.component';
import { SharedVariantsComponent } from './product-list/shared-variants/shared-variants.component';
import { CancellationPolicyComponent } from './static-pages/cancellation-policy/cancellation-policy.component';
import { ReturnPolicyComponent } from './static-pages/return-policy/return-policy.component';
import { ShippingDeliveryComponent } from './static-pages/shipping-delivery/shipping-delivery.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { YourlistComponent } from './wishlist/yourlist/yourlist.component';
import { ServicesComponent } from './static-pages/services/services.component';

import {LoaderComponent} from './shared/loader/loader.component';
import { EarthyWorthyAboutusComponent } from './static-pages/earthy-worthy-aboutus/earthy-worthy-aboutus.component';
import { ProductListCustomizeComponent } from './product-list-customize/product-list-customize.component';
import { CartNewComponent } from './cart-new/cart-new.component';
import { ComapnyProductDetailsComponent } from './comapny-product-details/comapny-product-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CategoryListingsComponent } from './category-listings/category-listings.component';
import { MemberRegisterComponent } from './member-register/member-register.component';
import { EnquiryStatusComponent } from './enquiry-status/enquiry-status.component';


@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    HomeComponent,
    NavbarComponent,
    ShippingAddressComponent,
    CartPageComponent,
    AlertsComponent,
    MiniCartComponent,
    UserForgotPasswordComponent,
    HeaderComponent,
    FooterComponent,
    StaticPagesComponent,
    AboutUsComponent,
    FaqsComponent,
    TableBookingComponent,
    MyOrdersComponent,
    MyOrderDetailsComponent,
    AppPaginationComponent,
    GiftMealComponent,
    LoaderComponent,
    AlertsComponent,
    OrderConfirmationComponent,
    OrderCancellationComponent,
    TermsAndConditionComponent,
    PrivacyPoicyComponent,
    MyAccountComponent,
    ProfileDetailsComponent,
    ChangePasswordComponent,
    CustomerAddressComponent,
    AddReviewComponent,
    ProductReviewComponent,
    ProductDetailsComponent,
    ProductListingComponent,
    FooterTwoComponent,
    HeaderTwoComponent,
    MenuComponent,
    ProductFilterComponent,
    CheckoutNewComponent,
    CheckoutHeaderComponent,
    CheckoutDeliveryComponent,
    CheckoutShippingComponent,
    CheckoutOrderdetailsComponent,
    CheckoutPaymentComponent,
    OrderSummaryComponent,
    CheckoutLoginComponent,
    ProductDetailsPipePipe,
    LoginNewComponent,
    ProdutQRListingComponent,
    SharedVariantsComponent,
    CancellationPolicyComponent,
    ReturnPolicyComponent,
    ShippingDeliveryComponent,
    ContactUsComponent,
    WishlistComponent,
    YourlistComponent,
    FooterLuckmeComponent,
    ServicesComponent,
    EarthyWorthyAboutusComponent,
    ProductListCustomizeComponent,
    CartNewComponent,
    ComapnyProductDetailsComponent,
    CompanyDetailsComponent,
    CategoryListingsComponent,
    MemberRegisterComponent,
    EnquiryStatusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgbModule,
    AmazingTimePickerModule,
    SharedModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB0Pd6TkW7Ip2rIDZsXgPhyfLprulvDn7U',
  
    }),
    GooglePlaceModule,
    DpDatePickerModule,
    NgxImageZoomModule
  ],
  providers: [AuthGuard,CartGuard,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
