import { Injectable } from '@angular/core';
import { AES, enc, mode } from 'crypto-js';
import * as crypto from 'crypto-js';

import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { OrderUpdationApiService } from '../order-related/order-updation-api.service';
import { OrderUpdationValuesService } from '../order-related/order-updation-values.service';
import { OrderInitiationValuesService } from '../order-related/order-initiation-values.service';
import { CustomerLoginStorageService } from '../checkout-login/customer-login-storage.service';
import { AppService } from '../../app.service';
import { ENDPOINTS,ORG_DETAILS } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutPaymentApiService {

  constructor(
    private checkoutApiService: CheckoutApiService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private orderUpdationApiService: OrderUpdationApiService,
    private orderUpdationValuesService: OrderUpdationValuesService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private appService: AppService,
    private orderInitiationValuesService: OrderInitiationValuesService
  ) { }

  getPaymentMethods(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_PAYMENTMETHODS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data)
          }
        }
      )
    })
  }

  getPaymentSettings(paymentMethodId){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id": paymentMethodId
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_PAYMENTSETTINGS, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data[0]);
          }
        }
      )
    })
  }

  getICICIHashCode(date){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "txndatetime":date,
        "chargetotal":this.checkoutCalculationsService.orderTotal.toString()
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.ICICPAYMENTHASH, requestData).then(
        respData => {
          let res:any = respData;
          resolve(res.hash)
        }
      )
    })

  }

  insertPaymentData(paymentObj){
    return new Promise((resolve, reject) => {
      let paymentObjt:any = paymentObj;
      paymentObjt.amount = this.checkoutCalculationsService.orderTotal.toString();
      if(this.customerLoginStorageService.getCustomerData()){
        paymentObjt.email = this.customerLoginStorageService.getCustomerData().email;
      }
      else{
        paymentObjt.email = this.customerLoginStorageService.getCustomer().email
      }
      // ENCRYPTION
      var now = new Date();
      var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
      // let _key = enc.Utf8.parse(this.appService.apkkey);
      let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
      let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
      paymentObjt["timestamp"] = new Date().getTime();
      let encrypted =AES.encrypt (
        JSON.stringify(paymentObjt), _key, {
            iv: _iv,
            format: crypto.format.Hex,
            mode: crypto.mode.CTR,
            padding: crypto.pad.NoPadding
        }).toString();
        let requestData = {
          payload: encrypted
        }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.PAYMENTDATAINSERTION, requestData).then(
          resData => {
            let res:any = resData;
            if(res.success){
              resolve(true);
            }
          })
    })
  }

  getOrderFlow(flowId, paymentMethodName, paymentId){
    return new Promise((resolve, reject) => {
      this.orderUpdationValuesService.getOrderFlow(flowId, paymentMethodName, paymentId).then(
        respData => {
          let res:any = respData;
          if(res){
            resolve(true);
          }
        }
      )
    })
  }

  updateOrderObj(paymentTye, title){
    return new Promise((resolve, reject) => {
      this.orderUpdationApiService.updateOrder(paymentTye, title).then(
        respData => {
          let res:any = respData;
          if(res){
            resolve(true);
          }
        }
      )
    })
  }

  getPayUlink(dataObj){
    return new Promise((resolve, reject) => {
      let pauData:any = dataObj;
      pauData.amount = this.checkoutCalculationsService.orderTotal.toString();
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.PAYU_LINK, pauData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.link)
          }
        }
      )
    })
  }

  getHDFClink(dataObj){
    return new Promise((resolve, reject) => {
      let hdfcData:any = dataObj;
      // var now = new Date();
            // var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
            let paymentObjt = {
              "domain_name":ORG_DETAILS.DOMAIN_NAME,
              "oid":hdfcData.oid,
              "timestamp":new Date().getTime()
            }
            // ENCRYPTION
            // let _key = enc.Utf8.parse(this.appService.apkkey);
            let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
            let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
            let encrypted =AES.encrypt (
              JSON.stringify(paymentObjt), _key, {
                  iv: _iv,
                  format: crypto.format.Hex,
                  mode: crypto.mode.CTR,
                  padding: crypto.pad.NoPadding
              }).toString();
              let requestData = {
                payload: encrypted
              }
              this.appService.postApiCall(requestData, ENDPOINTS.HDFC_PAYMENT).subscribe(
                respData => {
                  let resp:any  = respData
                  resolve(resp.data)
                },
                err => {
                  resolve(false)
                }
              )
    })
  }
}
