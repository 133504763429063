import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckoutPaymentApiService } from './checkout-payment-api.service';
import { CheckoutDeliveryOrderService } from '../checkout-delivery/checkout-delivery-order.service';
import { CustomerLoginStorageService } from '../checkout-login/customer-login-storage.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { ENDPOINTS,ORG_DETAILS } from 'src/app/app.config';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import { CheckoutPaymentService } from './checkout-payment.service';
import { OrderUpdationApiService } from '../order-related/order-updation-api.service';
import { LoaderService } from '../../shared/loader/loader.service';
import {config} from 'src/configs/config';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  providers: [DatePipe]
})
export class CheckoutPaymentComponent implements OnInit {

  paymentMethods:any = [];
  offlinePaymentMethods = [];
  onlinePaymentMethods = [];
  selectedPaymentMethod:any;
  selectedPaymentTitle:any;
  paymentSettingData = {
    storename:"",
    currency:"",
    timezone:"",
    mode:"",
    hash_algorithm:"",
    responseSuccessURL:"",
    responseFailURL:"",
    authenticateTransaction:"",
    PaymentgatewayURL:"",
    MobieNo:"",
    orderId:""
  }
  orderTotal:any = 0;
  hashVal:any = "";
  formattedData:any = "";
  payId:any = 0;
  paymentMethodType = ""

  user_name : string = '';
  hdfcForm = {
    action: "",
    requestData:"",
    accessVal:""
  }
  config = config;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkoutPaymentApiService: CheckoutPaymentApiService,
    public checkoutDeliveryOrderService: CheckoutDeliveryOrderService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private datePipe: DatePipe,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private localService: LocalStorageService,
    private checkoutPaymentService: CheckoutPaymentService,
    private orderUpdationApiService: OrderUpdationApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.getPaymentMethods();
    
    if(!this.localService.get("UserName") && this.localService.get("UserName")!= '' && this.localService.get("UserName") != null)
    {
      if(this.localService.get("UserName") != "NEW USER"){
        this.user_name = this.localService.get("UserName") 
      }
    }

    this.checkoutPaymentService.clearEvent();
    this.checkoutPaymentService.currentData.subscribe(
      currentData => {
        if(currentData === "submitPayment"){
          this.clickContinueButton();
        }
      }
    )
   
    
  }

  getPaymentMethods(){
    this.paymentMethods = [];
    this.checkoutPaymentApiService.getPaymentMethods().then(
      respData => {
        let res:any = respData[0];
        this.payId = res.id;
        if(res.offline_methods && res.offline_methods.length > 0){
          this.offlinePaymentMethods = res.offline_methods;
          res.offline_methods.forEach(element => {
            this.paymentMethods.push(element);
          });
        }
        if(res.online_methods && res.online_methods.length > 0){
          this.onlinePaymentMethods = res.online_methods;
          res.online_methods.forEach(element => {
            this.paymentMethods.push(element);
          });
        }
      }
    )
  }

  changePaymentMethod(paymentMethod, title){
    this.selectedPaymentMethod = paymentMethod;
    this.selectedPaymentTitle = title;
  }

  processPayment(){
    this.loaderService.emitLoading("Payment");
    if(this.offlinePaymentMethods.length > 0){
      this.offlinePaymentMethods.forEach(pmethod => {
        if(pmethod.name === this.selectedPaymentTitle){
          this.paymentMethodType = "Offline Method";
        }
      })
    }

    if(this.onlinePaymentMethods.length > 0){
      this.onlinePaymentMethods.forEach(pmethod => {
        if(pmethod.name === this.selectedPaymentTitle){
          this.paymentMethodType = "Online Method";
        }
      })
    }
    
    if(this.paymentMethodType === "Online Method"){
      this.orderTotal = this.checkoutCalculationsService.orderTotal.toString();
      if(this.selectedPaymentTitle.toUpperCase() === "ICICI"){
        this.checkoutPaymentApiService.getPaymentSettings(this.selectedPaymentMethod.settings.payment_integrator_reference_id).then(
          respData => {
            let res:any = respData;
            let settingsData = res.attributes;
            this.paymentSettingData.MobieNo = this.customerLoginStorageService.getCustomerData().mobileNo;
            this.paymentSettingData.orderId = this.checkoutLocalStorageService.getOrderId().toString();
            settingsData.forEach(element => {
              if(element.key === 'PaymentgatewayURL'){
                this.paymentSettingData.PaymentgatewayURL = element.value
              }
              if(element.key === 'storename'){
                this.paymentSettingData.storename = element.value
              }
              if(element.key === 'currency'){
                this.paymentSettingData.currency = element.value
              }
              if(element.key === 'timezone'){
                this.paymentSettingData.timezone = element.value
              }
              if(element.key === 'mode'){
                this.paymentSettingData.mode = element.value
              }
              if(element.key === 'hash_algorithm'){
                this.paymentSettingData.hash_algorithm = element.value
              }
              if(element.key === 'responseSuccessURL'){
                this.paymentSettingData.responseSuccessURL = ORG_DETAILS.DOMAIN_NAME + element.value;
              }
              if(element.key === 'responseFailURL'){
                this.paymentSettingData.responseFailURL = ORG_DETAILS.DOMAIN_NAME + element.value;
              }
              if(element.key === 'authenticateTransaction'){
                this.paymentSettingData.authenticateTransaction = element.value
              }
            });
            const datepipe: DatePipe = new DatePipe('en-IN')
            let formattedDate = datepipe.transform(new Date(), 'yyyy:MM:dd-HH:mm:ss');
            this.formattedData = datepipe.transform(new Date(), 'yyyy:MM:dd-HH:mm:ss');
            this.checkoutPaymentApiService.getICICIHashCode(formattedDate).then(
              respData1 => {
                let res1:any = respData1;
                this.hashVal = res1;
                let paymentInsertionData = {
                  "domain_name": ORG_DETAILS.DOMAIN_NAME,
                  customer_id : this.customerLoginStorageService.getCustomerData().userid.toString(),
                  order_id : this.checkoutLocalStorageService.getOrderId().toString(),
                  payment_method : this.selectedPaymentMethod.settings.payment_integrator_reference_id.toString(),
                  amount : "",
                  email : ""
                }
                this.orderUpdationApiService.getOrderUpdationObj().then(
                  respData => {
                    let res:any = respData;
                    if(res){
                      this.orderUpdation(this.selectedPaymentMethod.order_status_flow_id).then(
                        respDati => {
                          let resi:any = respDati;
                          if(resi){
                            var form = document.createElement("form");
                            var element1 = document.createElement("input");
                            form.method = "POST";
                            form.action = ORG_DETAILS.DOMAIN_NAME+":5621/api/payment/generateiciciweb";
                            form.target = '_self';
                            element1.value= this.checkoutLocalStorageService.getOrderId().toString();
                            element1.name="oid";
                            form.appendChild(element1);
                            document.body.appendChild(form);
                            this.loaderService.emitComplete();
                            form.submit();
                            // document.getElementById('icicibtn').click()  ;
                          }
                        }
                      ) 
                    }
                  })
              }
            )
          }
        )
      }
      else if(this.selectedPaymentTitle.toUpperCase() === "PAYU"){
        let paymentInsertionData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          customer_id : this.customerLoginStorageService.getCustomerData().userid.toString(),
          order_id : this.checkoutLocalStorageService.getOrderId().toString(),
          payment_method : this.selectedPaymentMethod.settings.payment_integrator_reference_id.toString(),
          amount : "",
          email : ""
        }
        this.orderUpdationApiService.getOrderUpdationObj().then(
        respData => {
          let res:any = respData;
          if(res){
            this.orderUpdation(this.selectedPaymentMethod.order_status_flow_id).then(
              respDatas => {
                let ress:any = respDatas;
                if(ress){
                  let payuData = {
                    domain_name:ORG_DETAILS.DOMAIN_NAME,
                    amount:"",
                    user_id:ORG_DETAILS.USER_ID,
                    firstname: this.user_name,
                    productinfo:"products",
                    phone:this.customerLoginStorageService.getCustomerData().mobileNo.toString(),
                    email:this.customerLoginStorageService.getCustomerData().email,
                    txnid:this.checkoutLocalStorageService.getOrderId().toString()
                    }
                    this.checkoutPaymentApiService.getPayUlink(payuData).then(
                      respData3 => {
                        let res3:any = respData3;
                        this.loaderService.emitComplete();
                        window.location.href = res3;
                      }
                    );
                }
              })
          }
        });
      }
      else if(this.selectedPaymentTitle.toUpperCase() === "HDFC"){
        let paymentInsertionData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          customer_id : this.customerLoginStorageService.getCustomerData().userid.toString(),
          order_id : this.checkoutLocalStorageService.getOrderId().toString(),
          payment_method : this.selectedPaymentMethod.settings.payment_integrator_reference_id.toString(),
          amount : "",
          email : ""
        }
        this.orderUpdationApiService.getOrderUpdationObj().then(
          respData => {
            let res:any = respData;
            if(res){
              this.orderUpdation(this.selectedPaymentMethod.order_status_flow_id).then(
                respDatas => {
                  let ress:any = respDatas;
                  if(ress){
                    let hdfcData = {
                      domain_name:ORG_DETAILS.DOMAIN_NAME,
                      oid:this.checkoutLocalStorageService.getOrderId().toString(),
                      amount:"",
                      }
                      this.checkoutPaymentApiService.getHDFClink(hdfcData).then(
                        respData3 => {
                          let res3:any = respData3;
                          if(res3 !== false){
                            var form = document.createElement("form");
                            var element1 = document.createElement("input"); 
                            var element2 = document.createElement("input");  
                            form.method = "POST";
                            form.action = res3.link;   
                            form.target = '_self'
                            element1.value=res3.encrequest;
                            element1.name="encRequest";
                            form.appendChild(element1);  
                            element2.value=res3.accesscode;
                            element2.name="access_code";
                            form.appendChild(element2);
                            document.body.appendChild(form);
                            this.loaderService.emitComplete();
                            form.submit();
                            this.checkoutLocalStorageService.removeOrderId();
                          }
                        }
                      );
                  }
                }
              )
          
            }
          }
        );
        
      }else if(this.selectedPaymentTitle.toUpperCase() === "STRIPE"){
        this.orderUpdationApiService.getOrderUpdationObj().then(
          respData => {
            let res:any = respData;
            if(res){
              this.orderUpdation(this.selectedPaymentMethod.order_status_flow_id).then(
                respDati => {
                  let resi:any = respDati;
                  if(resi){
                    var form = document.createElement("form");
                    var element1 = document.createElement("input");
                    form.method = "POST";
                    form.action = ORG_DETAILS.DOMAIN_NAME+":5621/api/payment/generatestripelinkweb";
                    form.target = '_self';
                    element1.value= this.checkoutLocalStorageService.getOrderId().toString();
                    element1.name="oid";
                    form.appendChild(element1);
                    document.body.appendChild(form);
                    this.loaderService.emitComplete();
                    form.submit();
                    // document.getElementById('icicibtn').click()  ;
                  }
                }
              ) 
            }
          })
      }
    }
    else{
      this.orderUpdationApiService.getOrderUpdationObj().then(
        respData => {
          let res:any = respData;
          if(res){
            this.orderUpdation(this.selectedPaymentMethod.order_status_flow_id).then(
              respOrder => {
                let reso:any = respOrder;
                if(reso){
                  this.loaderService.emitComplete();
                  this.router.navigate([`/confirmation`], {queryParams: {id: this.checkoutLocalStorageService.getOrderId()}, relativeTo:this.route,  skipLocationChange: false });
                }
              }
            )
          }
        }
      ); 
    }
  }

  orderUpdation(flowId){
    return new Promise((resolve, reject) => {
      this.checkoutPaymentApiService.getOrderFlow(flowId, this.selectedPaymentMethod.settings.title, this.payId).then(
        respData => {
          let res:any = respData;
          if(res){
            this.checkoutPaymentApiService.updateOrderObj(this.paymentMethodType, this.selectedPaymentMethod.settings.title).then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  resolve(true);
                }
              }
            )
          }
        }
      )
    })
  }

  clickContinueButton(){
    // this.changePaymentMethod(this.paymentMethods[0], this.paymentMethods[0].settings.title);
    // document.getElementById('paymentBtn').click();
  }

}
