<div *ngIf="configTitle !== 'Aiema'" class="background-filter" >
    <div class="filter-top">
        <div *ngIf="configTitle!=='BBold'" class="filter-clear">
            <h5 class="mt-2 fcolor">Filter</h5>
            <span class="btn btn-sm" (click)="clearAll()" style="cursor: pointer;font-size: 14px;color:#dc3545">Clear All</span>
        </div>
        <div *ngIf="configTitle==='BBold'"  class="filter-clear-bbold">
            <h4 class="mt-2 fcolor">Filters</h4>
            <span class="btn btn-sm" (click)="clearAll()" style="cursor: pointer;font-size: 14px;color:#dc3545">Clear All</span>
        </div>
    </div>
   
    
    <div *ngIf="categoriesList.length>0 && configTitle!=='BBold'" class="layerd-navigation">
        <div class="filter-collections-group">
            <div id="accordion">
                <h5 class="mb-0">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne" style="cursor: pointer;">Categories<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                    <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> -->
                    <!-- </button> -->
                </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <ng-container *ngIf="configTitle== 'Mahaveer'">
                        <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                            style="margin-bottom: 5px;">
                            <div class="wrapper" style="margin-left: 10px;">
                                <li><a [class.active]="active === category.name" class="sub-list font-weight-bolder"
                                        (click)="checkedevt(category.name, category.name)">{{category.name | titlecase |
                                        productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                class="fa fa-angle-down arrow-size"></i> </span></a></li>
    
                                <div *ngIf="category.subcategories" class="wrapper">
                                    <!-- FIRST LEVEL SUBCATEGORIES -->
                                    <div *ngFor="let subcategory of category.subcategories; index as j">
                                        <div class="wrapper">
                                            <li
                                                (click)="checkedevt(category.name+' > '+subcategory.name, subcategory.name)">
                                                <a [class.active]="active === subcategory.name"
                                                    class="sub-list">{{subcategory.name
                                                    | titlecase | productDetailsPipe: subcategory.name}}</a>
                                            </li>
                                        </div>
                                        <div *ngIf="subcategory.subcategories" class="wrapper">
                                            <!-- SECOND LEVEL SUBCATEGORIES -->
                                            <div class="wrapper">
                                                <div *ngFor="let subcategory2 of subcategory.subcategories; index as k">
                                                    <li
                                                        (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                        <a [class.active]="active === subcategory2.name"
                                                            class="sub-list">{{subcategory2.name | titlecase |
                                                            productDetailsPipe:
                                                            subcategory2.name}}</a>
                                                    </li>
                                                    <!-- THIRD LEVEL SUBCATEGORY -->
                                                    <div *ngIf="subcategory2.subcategories;" class="wrapper">
                                                        <div class="wrapper">
                                                            <div
                                                                *ngFor="let subcategory3 of subcategory2.subcategories; index as l">
                                                                <div class="wrapper">
                                                                    <li
                                                                        (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                        <a [class.active]="active === subcategory3.name"
                                                                            class="sub-list">{{subcategory3.name | titlecase
                                                                            |
                                                                            productDetailsPipe: subcategory3.name}}</a>
                                                                    </li>
                                                                    <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                    <div class="wrapper">
                                                                        <div
                                                                            *ngFor="let subcategory4 of subcategory3.subcategories; index as m">
                                                                            <div class="wrapper">
                                                                                <li><a [class.active]="active === subcategory4.name"
                                                                                        class="sub-list"
                                                                                        (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                        | titlecase | productDetailsPipe:
                                                                                        subcategory4.name}}</a></li>
                                                                                <!-- FIFTH LEVEL SUBCATEGORIES -->
                                                                                <div class="wrapper">
                                                                                    <div
                                                                                        *ngFor="let subcategory5 of subcategory4.subcategories;index as n">
                                                                                        <li><a [class.active]="active === subcategory5.name"
                                                                                                class="sub-list"
                                                                                                (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                | titlecase |
                                                                                                productDetailsPipe:
                                                                                                subcategory5.name}}</a></li>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="configTitle=='LuckMe' || configTitle=='Murgasen'">
                        <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                            style="margin-bottom: 5px;">
                            <div class="wrapper" style="margin-left: 10px; color: black;">
                                <!-- <li><a [class.active]="active === category.name" class="sub-list font-weight-bolder" (click)="checkedevt(category.name, category.name)">{{category.name | titlecase | productDetailsPipe}}<span *ngIf="category.subcategories"> <i class="fa fa-angle-down arrow-size"></i> </span></a></li> -->
                                <li><a [class.active]="active === category.name || categoryName===category.name"
                                        class="sub-list font-weight-bolder"
                                        (click)="checkedevt1(category.name, category.name)">{{category.name | titlecase |
                                        productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                class="fa fa-angle-down arrow-size"></i> </span></a></li>
                                <div *ngIf="categoryName===category.name" class="wrapper">
                                    <!-- FIRST LEVEL SUBCATEGORY -->
                                    <div *ngFor="let subcategory of subCategory2; index as j">
                                        <div class="wrapper">
                                            <li
                                                (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)">
                                                <a [class.active]="active === subcategory.name"
                                                    class="sub-list">{{subcategory.name
                                                    | titlecase | productDetailsPipe: subcategory.name}}</a>
                                            </li>
                                            <div *ngIf="categoryName1===subcategory.name" class="wrapper">
                                                <!-- SECOND LEVEL SUBCATEGORY -->
                                                <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                    <div class="wrapper">
                                                        <li
                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                            <a [class.active]="active === subcategory2.name"
                                                                class="sub-list">{{subcategory2.name | titlecase |
                                                                productDetailsPipe: subcategory2.name}}</a>
                                                        </li>
                                                        <!-- THIRD LEVEL SUBCATEGORY -->
                                                        <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                            <div class="wrapper">
                                                                <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                    <div class="wrapper">
                                                                        <li
                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                            <a [class.active]="active === subcategory3.name"
                                                                                class="sub-list">{{subcategory3.name |
                                                                                titlecase |
                                                                                productDetailsPipe: subcategory3.name}}</a>
                                                                        </li>
                                                                        <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                        <div *ngIf="categoryName3===subcategory3.name"
                                                                            class="wrapper">
                                                                            <div
                                                                                *ngFor="let subcategory4 of subCategory8; index as m">
                                                                                <div class="wrapper">
                                                                                    <li><a [class.active]="active === subcategory4.name"
                                                                                            class="sub-list"
                                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                            | titlecase |
                                                                                            productDetailsPipe:
                                                                                            subcategory4.name}}</a></li>
                                                                                    <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                    <div class="wrapper">
                                                                                        <div
                                                                                            *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                            <li><a [class.active]="active === subcategory5.name"
                                                                                                    class="sub-list"
                                                                                                    (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                    | titlecase |
                                                                                                    productDetailsPipe:
                                                                                                    subcategory5.name}}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
        
                    <!-- <ng-container *ngIf="configTitle=='BombayHardware'">
                        <div class="sublist-font" *ngFor="let category of allCategories; index as i"
                            style="margin-bottom: 5px;">
                            <div class="wrapper" style="margin-left: 10px; color: black;">
                                <li><a [class.active]="active === category.name || categoryName===category.name"
                                        class="sub-list font-weight-bolder"
                                        (click)="checkedevt1(category.name, category.name)">{{category.name | titlecase |
                                        productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                class="fa fa-angle-down arrow-size"></i> </span></a></li>
                                <div *ngIf="categoryName===category.name" class="wrapper"> -->
                                    <!-- FIRST LEVEL SUBCATEGORY -->
                                    <!-- <div *ngFor="let subcategory of subCategory2; index as j">
                                        <div class="wrapper">
                                            <li
                                                (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)">
                                                <a [class.active]="active === subcategory.name"
                                                    class="sub-list">{{subcategory.name
                                                    | titlecase | productDetailsPipe: subcategory.name}}</a>
                                            </li>
                                            <div *ngIf="categoryName1===subcategory.name" class="wrapper"> -->
                                                <!-- SECOND LEVEL SUBCATEGORY -->
                                                <!-- <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                    <div class="wrapper">
                                                        <li
                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                            <a [class.active]="active === subcategory2.name"
                                                                class="sub-list">{{subcategory2.name | titlecase |
                                                                productDetailsPipe: subcategory2.name}}</a>
                                                        </li> -->
                                                        <!-- THIRD LEVEL SUBCATEGORY -->
                                                        <!-- <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                            <div class="wrapper">
                                                                <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                    <div class="wrapper">
                                                                        <li
                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                            <a [class.active]="active === subcategory3.name"
                                                                                class="sub-list">{{subcategory3.name |
                                                                                titlecase |
                                                                                productDetailsPipe: subcategory3.name}}</a>
                                                                        </li> -->
                                                                        <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                        <!-- <div *ngIf="categoryName3===subcategory3.name"
                                                                            class="wrapper">
                                                                            <div
                                                                                *ngFor="let subcategory4 of subCategory8; index as m">
                                                                                <div class="wrapper">
                                                                                    <li><a [class.active]="active === subcategory4.name"
                                                                                            class="sub-list"
                                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                            | titlecase |
                                                                                            productDetailsPipe:
                                                                                            subcategory4.name}}</a></li> -->
                                                                                    <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                    <!-- <div class="wrapper">
                                                                                        <div
                                                                                            *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                            <li><a [class.active]="active === subcategory5.name"
                                                                                                    class="sub-list"
                                                                                                    (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                    | titlecase |
                                                                                                    productDetailsPipe:
                                                                                                    subcategory5.name}}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="configTitle=='BombayHardware'">
                            <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                            style="margin-bottom: 5px;">
                            <div class="wrapper" style="margin-left: 10px; color: black;">
                                                <li>
                                                    <a [id]='category.name' [class.active]="active === category.name || categoryName===category.name"
                                                    class="sub-list font-weight-bolder"
                                                    (click)="checkedevt1(category.name, category.name)">{{category.name | titlecase |
                                                    productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                            class="fa fa-angle-down arrow-size"></i> </span></a>
                                                        </li>
                                            <div class="wrapper" *ngIf="categoryName===category.name">


                                        <!-- FIRST LEVEL SUBCATEGORY -->
                                    
                                    <div *ngFor="let subcategory of subCategory2; index as j">
                                        <div class="wrapper">
                                            <li>
                                            <a [id]='subcategory.name' [class.active]="active === subcategory.name"
                                            (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)"
                                                class="sub-list">{{subcategory.name
                                                 | productDetailsPipe: subcategory.name}}<span *ngIf="subcategory.subcategories"> <i
                                                    class="fa fa-angle-down arrow-size"></i> </span> </a>
                                        </li>
                                            <div *ngIf="categoryName1===subcategory.name" class="wrapper">
                                                <!-- SECOND LEVEL SUBCATEGORY -->
                                                <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                    <div class="wrapper">
                                                        <li
                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                            <a [class.active]="active === subcategory2.name"
                                                                class="sub-list">{{subcategory2.name | titlecase |
                                                                productDetailsPipe: subcategory2.name}}<span *ngIf="subcategory2.subcategories"> <i
                                                                    class="fa fa-angle-down arrow-size"></i> </span></a>
                                                        </li>
                                                        <!-- THIRD LEVEL SUBCATEGORY -->
                                                        <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                            <div class="wrapper">
                                                                <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                    <div class="wrapper">
                                                                        <li
                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                            <a [class.active]="active === subcategory3.name"
                                                                                class="sub-list">{{subcategory3.name |
                                                                                titlecase |
                                                                                productDetailsPipe: subcategory3.name}}</a>
                                                                        </li>
                                                                        <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                        <div *ngIf="categoryName3===subcategory3.name"
                                                                            class="wrapper">
                                                                            <div
                                                                                *ngFor="let subcategory4 of subCategory8; index as m">
                                                                                <div class="wrapper">
                                                                                    <li><a [class.active]="active === subcategory4.name"
                                                                                            class="sub-list"
                                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                            | titlecase |
                                                                                            productDetailsPipe:
                                                                                            subcategory4.name}}</a></li>
                                                                                    <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                    <div class="wrapper">
                                                                                        <div
                                                                                            *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                            <li><a [class.active]="active === subcategory5.name"
                                                                                                    class="sub-list"
                                                                                                    (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                    | titlecase |
                                                                                                    productDetailsPipe:
                                                                                                    subcategory5.name}}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>




                    <ng-container *ngIf="configTitle=='ShubhCards' || configTitle=='Aiema' || configTitle=='Deera'">
                        <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                            style="margin-bottom: 5px;">
                            <div class="wrapper" style="margin-left: 10px; color: black;">
                                <!-- <li><a [class.active]="active === category.name" class="sub-list font-weight-bolder" (click)="checkedevt(category.name, category.name)">{{category.name | titlecase | productDetailsPipe}}<span *ngIf="category.subcategories"> <i class="fa fa-angle-down arrow-size"></i> </span></a></li> -->
                                <li><a [id]='category.name' [class.active]="active === category.name || categoryName===category.name"
                                        class="sub-list font-weight-bolder" 
                                        (click)="checkedevt1(category.name, category.name)" style="text-transform:uppercase">{{category.name | titlecase |
                                        productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                ></i> </span></a></li>
                                <div *ngIf="categoryName===category.name" class="wrapper">
                                    <!-- FIRST LEVEL SUBCATEGORY -->
                                    <div *ngFor="let subcategory of subCategory2; index as j">
                                        <div class="wrapper">
                                            <li
                                                (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)">
                                                <a [class.active]="active === subcategory.name"
                                                    class="sub-list">{{subcategory.name
                                                    | titlecase | productDetailsPipe: subcategory.name}}</a>
                                            </li>
                                            <div *ngIf="categoryName1===subcategory.name" class="wrapper">
                                                <!-- SECOND LEVEL SUBCATEGORY -->
                                                <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                    <div class="wrapper">
                                                        <li
                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                            <a [class.active]="active === subcategory2.name"
                                                                class="sub-list">{{subcategory2.name | titlecase |
                                                                productDetailsPipe: subcategory2.name}}</a>
                                                        </li>
                                                        <!-- THIRD LEVEL SUBCATEGORY -->
                                                        <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                            <div class="wrapper">
                                                                <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                    <div class="wrapper">
                                                                        <li
                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                            <a [class.active]="active === subcategory3.name"
                                                                                class="sub-list">{{subcategory3.name |
                                                                                titlecase |
                                                                                productDetailsPipe: subcategory3.name}}</a>
                                                                        </li>
                                                                        <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                        <div *ngIf="categoryName3===subcategory3.name"
                                                                            class="wrapper">
                                                                            <div
                                                                                *ngFor="let subcategory4 of subCategory8; index as m">
                                                                                <div class="wrapper">
                                                                                    <li><a [class.active]="active === subcategory4.name"
                                                                                            class="sub-list"
                                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                            | titlecase |
                                                                                            productDetailsPipe:
                                                                                            subcategory4.name}}</a></li>
                                                                                    <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                    <div class="wrapper">
                                                                                        <div
                                                                                            *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                            <li><a [class.active]="active === subcategory5.name"
                                                                                                    class="sub-list"
                                                                                                    (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                    | titlecase |
                                                                                                    productDetailsPipe:
                                                                                                    subcategory5.name}}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
    
    
    
        </div>
    </div>

    <div *ngIf="categoriesList.length>0 && configTitle==='BBold'" class="layerd-navigation filter-style">
        <div class="filter-collections-group">
            <div id="accordion">
                <h5 class="mb-0">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne" style="cursor: pointer;">Categories<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                    <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> -->
                    <!-- </button> -->
                </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">

                    <ng-container *ngIf="configTitle=='BBold'">
                        <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                        style="margin-bottom: 5px;">
                        <div class="wrapper" style="margin-left: 10px; color: black;">
                                            <li>
                                                <a [id]='category.name' [class.active]="active === category.name || categoryName===category.name"
                                                class="sub-list font-weight-bolder"
                                                (click)="checkedevt1(category.name, category.name)">{{category.name | titlecase |
                                                productDetailsPipe: category.name}}<span *ngIf="category.subcategories[0]"> <i
                                                        class="fa fa-angle-down arrow-size"></i> </span></a>
                                                    </li>
                                        <div class="wrapper" *ngIf="categoryName===category.name">


                                    <!-- FIRST LEVEL SUBCATEGORY -->
                                
                                <div *ngFor="let subcategory of subCategory2; index as j">
                                    <div class="wrapper show">
                                        <li>
                                        <a [id]='subcategory.name' [class.active]="active === subcategory.name"
                                        (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)"
                                            class="sub-list">{{subcategory.name
                                             | productDetailsPipe: subcategory.name}} <span *ngIf="subcategory.subcategories[0]"> <i
                                                class="fa fa-angle-down arrow-size"></i> </span></a>
                                    </li>
                                        <div *ngIf="categoryName1===subcategory.name" class="wrapper">
                                            <!-- SECOND LEVEL SUBCATEGORY -->
                                            <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                <div class="wrapper">
                                                    <li
                                                        (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                        <a [class.active]="active === subcategory2.name"
                                                            class="sub-list">{{subcategory2.name | titlecase |
                                                            productDetailsPipe: subcategory2.name}} <span *ngIf="subcategory2.subcategories[0]"> <i
                                                                class="fa fa-angle-down arrow-size"></i> </span></a>
                                                    </li>
                                                    <!-- THIRD LEVEL SUBCATEGORY -->
                                                    <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                        <div class="wrapper">
                                                            <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                <div class="wrapper">
                                                                    <li
                                                                        (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                        <a [class.active]="active === subcategory3.name"
                                                                            class="sub-list">{{subcategory3.name |
                                                                            titlecase |
                                                                            productDetailsPipe: subcategory3.name}} </a>
                                                                    </li>
                                                                    <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                    <div *ngIf="categoryName3===subcategory3.name"
                                                                        class="wrapper">
                                                                        <div
                                                                            *ngFor="let subcategory4 of subCategory8; index as m">
                                                                            <div class="wrapper">
                                                                                <li><a [class.active]="active === subcategory4.name"
                                                                                        class="sub-list"
                                                                                        (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                        | titlecase |
                                                                                        productDetailsPipe:
                                                                                        subcategory4.name}}</a></li>
                                                                                <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                <div class="wrapper">
                                                                                    <div
                                                                                        *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                        <li><a [class.active]="active === subcategory5.name"
                                                                                                class="sub-list"
                                                                                                (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                | titlecase |
                                                                                                productDetailsPipe:
                                                                                                subcategory5.name}}</a>
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                </div>
            </div>
    
    
    
        </div>
    </div>








    <div *ngIf="themes && configTitle === 'ShubhCards'">
        <div *ngIf="themes.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                        aria-controls="collapseThree" style="cursor: pointer;">Themes<i
                                                class="fa fa-angle-down arrow-size"></i>    </a>
                       
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of themes">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterTheme($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    <div *ngIf="configTitle !== 'ShubhCards' &&filters && configTitle !== 'Aiema' && configTitle !== 'Deera' && configTitle!=='BBold'">
        <div *ngIf="filters.length != 0" class="layerd-navigation">
            
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                        aria-controls="collapseTwo" style="cursor: pointer;">Brands<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of filters">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container fcolor"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilter($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="configTitle==='BBold'">
        <div *ngIf="filters.length != 0" class="layerd-navigation filter-style">
            
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                        aria-controls="collapseTwo" style="cursor: pointer;">Brands<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of filters">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container fcolor"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilter($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>

    <!-- <div *ngIf="configTitle === 'Deera'">
        <div *ngIf="filters.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                        aria-controls="collapseTwo" style="cursor: pointer;">Brands<i
                        class="fa fa-angle-down arrow-size"></i></a>
                       
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of filters">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilter($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div> -->
    <div *ngIf="size && configTitle === 'BombayHardware'">
        <div *ngIf="size.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                        aria-controls="collapseFive" style="cursor: pointer;">Size</a>
                       
                </div>
                <div id="collapseSeven" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of size">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterSize($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>


    <div *ngIf="size && configTitle === 'Deera'">
        <div *ngIf="size.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                        aria-controls="collapseSeven" style="cursor: pointer;">Size<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseSeven" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of size">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterSize($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="size && configTitle==='BBold'">
        <div *ngIf="size.length != 0" class="layerd-navigation filter-style">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                        aria-controls="collapseSeven" style="cursor: pointer;">Size<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseSeven" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of size">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important; color: #DCA8AC;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterSize($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>

    <!-- <div *ngIf="size && configTitle === 'Deera'">
        <div *ngIf="size.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                        aria-controls="collapseFive" style="cursor: pointer;">Size</a>
                       
                </div>
                <div id="collapseSeven" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of size">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterSize($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div> -->

    <div *ngIf="thickness && configTitle === 'BombayHardware'">
        <div *ngIf="size.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true"
                        aria-controls="collapseFive" style="cursor: pointer;">Thickness</a>
                       
                </div>
                <div id="collapseSeven" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of thickness">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterThickness($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <!-- <div *ngIf="configTitle === 'BombayHardware'">
        <div *ngIf="filters.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                        aria-controls="collapseTwo" style="cursor: pointer;">Thickness</a>
                       
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body"> -->
                        <!-- <ng-container *ngFor="let facet of filters">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilter($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container> -->
                        <!-- <ng-container>
    
                            <div class="collection-collapse-block" [class.open]="collapse">
                                <div class="pannel-title">
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
                                        <input type="checkbox" />
                                        5mm-10mm
                                         <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="collection-collapse-block" [class.open]="collapse">
                                <div class="pannel-title">
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
                                        <input type="checkbox" />
                                        11mm-15mm
                                         <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="collection-collapse-block" [class.open]="collapse">
                                <div class="pannel-title">
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
                                        <input type="checkbox" />
                                        16mm-20mm
                                         <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="collection-collapse-block" [class.open]="collapse">
                                <div class="pannel-title">
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
                                        <input type="checkbox" />
                                        21mm-25mm
                                         <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div> -->
   
    <div *ngIf="features && configTitle === 'ShubhCards'">
        <div *ngIf="features.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true"
                        aria-controls="collapseFive" style="cursor: pointer;">Features<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of features">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterFeature($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="prices && configTitle === 'ShubhCards'">
        <div *ngIf="prices.length != 0" class="layerd-navigation filter-style">
            <div class="filter-collections-group fcolor">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true"
                        aria-controls="collapseSix" style="cursor: pointer;">Prices <i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of prices" >
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterPrice($event,facet) "/>
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="prices && configTitle === 'BBold'">
        <div *ngIf="prices.length != 0" class="layerd-navigation filter-style">
            <div class="filter-collections-group fcolor">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true"
                        aria-controls="collapseSix" style="cursor: pointer;">Prices <i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of prices" >
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterPrice($event,facet) "/>
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    <div *ngIf="finish && configTitle === 'ShubhCards'">
        <div *ngIf="finish.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                        aria-controls="collapseFour" style="cursor: pointer;">Finish <i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of finish">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterFinish($event,facet)" />
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>

    <div *ngIf="color && configTitle === 'Deera'">
        <div *ngIf="color.length != 0" class="layerd-navigation">
            <div class="filter-collections-group fcolor">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true"
                        aria-controls="collapseNine" style="cursor: pointer;">Color<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseNine" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of color">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterColor($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="color && configTitle==='BBold'">
        <div *ngIf="color.length != 0" class="layerd-navigation filter-style">
            <div class="filter-collections-group fcolor">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true"
                        aria-controls="collapseNine" style="cursor: pointer;">Color<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseNine" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of color">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterColor($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>

    
    <div *ngIf="type && configTitle === 'Deera'">
        <div *ngIf="type.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true"
                        aria-controls="collapseTen" style="cursor: pointer;">Type<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseTen" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of type">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterType($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>


    
    <div *ngIf="material && configTitle === 'Deera'">
        <div *ngIf="material.length != 0" class="layerd-navigation">
            <div class="filter-collections-group">
                <div id="accordion">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true"
                        aria-controls="collapseEleven" style="cursor: pointer;">Material<i
                        class="fa fa-angle-down arrow-size"></i> </a>
                       
                </div>
                <div id="collapseEleven" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <ng-container *ngFor="let facet of material">
    
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
    
                                <div class="pannel-title">
    
    
    
                                    <label class="checkbox-container"
                                        style="text-transform: uppercase; font-size:12px !important;">
    
                                        <input type="checkbox" [id]='facet.name' (click)="appliedFilterMaterial($event,facet)" />
                                        <!-- <input type="checkbox"  (click)="appliedFilterColor($event,facet)"/> -->
    
                                        &nbsp;{{ facet.name }} <span class="count"></span>
                                        <span class="checkmark"></span>
                                    </label>
    
    
                                </div>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    
    <!-- <div class="layerd-navigation" >
        <div  class="filter-collections-group">
        <h5 class="sub-heading">Availability</h5>
        <div class="collection-collapse-block" [class.open]="collapse">
            <div class="pannel-title">
                <label  class="checkbox-container" style="text-transform: uppercase; font-size:12px !important;">
                                
                    <input 
                    type="checkbox" [(ngModel)]="values1"
                    id='outOfStock'    
                    (change)="onChange($event,'Include Out of Stock')"         
                    [value]=""             
                     />
                     &nbsp;Include Out of Stock
                    <span class="checkmark"></span>
                  </label>
            </div>
            </div>
    </div>
        
    </div>
     -->
    
    <!-- <div class="layerd-navigation" >
        <div  class="filter-collections-group">
        <h5 class="sub-heading">Availability</h5>
        <div class="collection-collapse-block" [class.open]="collapse">
            <div class="pannel-title">
                <label  class="checkbox-container" style="font-size: 14px !important;">
                    <input 
                    type="checkbox"  [(ngModel)]="values2"
                    id='day90'
                    (change)="onChange($event,'Last 90 days')"   
                    (click)="changeCheckecd($event,'Last 90 days')"            
                    [value]=""  
                    checked        
                     />
                     &nbsp;Last 90 days
                    <span class="checkmark"></span>
                  </label>
    
                  
            </div>
            </div>
    </div> -->
    
    <!-- </div> -->
    
    
    
    <div class="layerd-navigation" *ngIf="configTitle !== 'Aiema'&&configTitle !== 'ShubhCards' &&configTitle !== 'Deera'  &&configTitle !== 'BombayHardware' &&configTitle !== 'BBold'">
        <div class="filter-collections-group">
            <div class="collection-collapse-block border-0">
                <h5 class="collapse-block-title sub-heading">Availability</h5>
                <div class="collection-collapse-block-content">
                    <div class="collection-brand-filter">
                        <div>
                            <label class="checkbox-container" style="text-transform: uppercase; font-size:12px !important;">
    
                                <input type="checkbox" [(ngModel)]="values1" id='outOfStock'
                                    (change)="onChange($event,'Include Out of Stock')" [value]="" />
                                &nbsp;Include Out of Stock
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="collection-collapse-block border-0">
                            <h5 class="collapse-block-title sub-heading" style="margin-left:-30px">New Arrivals</h5>
                            <div>
                                <label class="checkbox-container" style="font-size: 14px !important;">
                                    <input type="checkbox" [(ngModel)]="values2" id='day90'
                                        (change)="onChange($event,'Last 90 days')"
                                        (click)="changeCheckecd($event,'Last 90 days')" [value]="" checked />
                                    &nbsp;Last 90 days
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="mt-2">
                                <label class="checkbox-container" style="font-size: 14px !important;">
    
                                    <input (change)="onChange($event,'Last 30 days')"
                                        (click)="changeCheckecd($event,'Last 30 days')" type="checkbox"
                                        [(ngModel)]="values3" id='day30' [value]="" />
                                    &nbsp;Last 30 days
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class="layerd-navigation" >
            <div class="filter-collections-group">
                <div class="collection-collapse-block border-0" >
                    <h5 class="collapse-block-title sub-heading">Price</h5>
    
                    <input type="range" min="0" max="10000" />
                </div>
            </div>
        </div> -->
</div>
<div *ngIf="configTitle === 'Aiema'">
    <div class="filter-top">
        <div class="filter-clear" style="display: flex;">
            <h5 class="mt-2" style="font-family: 'proxima-regular','sans-serif'!important; color: #d57c30;text-transform: uppercase;font-weight: 500;">FILTER BY:</h5>
            <span class="btn btn-sm" (click)="clearAll()" style="cursor: pointer;font-size: 14px;font-family: 'proxima-regular','sans-serif'!important; color: #999;">Clear Filters</span>
        </div>
    </div>
    <div *ngIf="categoriesList.length>0" class="layerd-navigation">
        <div class="filter-collections-group">
            <div id="accordion">
                <h5 class="mb-0">
                    <a class="sub-heading" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne" style="cursor: pointer;text-transform: capitalize;color: rgb(63, 63, 63) !important; font-size: 17px;font-weight: bold;font-family: 'proxima-regular','sans-serif'!important;">Categories</a>
                </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body" style="padding-left: 0px !important;padding-right: 0px !important;font-family: 'proxima-regular','sans-serif'!important;">
                    <ng-container>
                        <div class="sublist-font" *ngFor="let category of categoriesList; index as i"
                            style="margin-bottom: 6px;">
                            <div class="wrapper">
                                <li><a [class.active]="active === category.name || categoryName===category.name"
                                        class="sub-list" style="font-size: 13.5px !important; line-height: normal !important;color: #333333!important;font-family: 'proxima-regular','sans-serif'!important;"
                                        (click)="checkedevt1(category.name, category.name)">{{category.name | titlecase |
                                        productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i
                                                class="fa fa-angle-down arrow-size"></i> </span></a></li>
                                <div *ngIf="categoryName===category.name" class="wrapper">
                                    <!-- FIRST LEVEL SUBCATEGORY -->
                                    <div *ngFor="let subcategory of subCategory2; index as j">
                                        <div class="wrapper">
                                            <li
                                                (click)="checkedevt1(category.name+' > '+subcategory.name, subcategory.name)">
                                                <a [class.active]="active === subcategory.name"
                                                    class="sub-list">{{subcategory.name
                                                    | titlecase | productDetailsPipe: subcategory.name}}</a>
                                            </li>
                                            <div *ngIf="categoryName1===subcategory.name" class="wrapper">
                                                <!-- SECOND LEVEL SUBCATEGORY -->
                                                <div *ngFor="let subcategory2 of subCategory4; index as k">
                                                    <div class="wrapper">
                                                        <li
                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)">
                                                            <a [class.active]="active === subcategory2.name"
                                                                class="sub-list">{{subcategory2.name | titlecase |
                                                                productDetailsPipe: subcategory2.name}}</a>
                                                        </li>
                                                        <!-- THIRD LEVEL SUBCATEGORY -->
                                                        <div *ngIf="categoryName2===subcategory2.name" class="wrapper">
                                                            <div class="wrapper">
                                                                <div *ngFor="let subcategory3 of subCategory6; index as l">
                                                                    <div class="wrapper">
                                                                        <li
                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)">
                                                                            <a [class.active]="active === subcategory3.name"
                                                                                class="sub-list">{{subcategory3.name |
                                                                                titlecase |
                                                                                productDetailsPipe: subcategory3.name}}</a>
                                                                        </li>
                                                                        <!-- FOURTH LEVEL SUBCATEGORY -->
                                                                        <div *ngIf="categoryName3===subcategory3.name"
                                                                            class="wrapper">
                                                                            <div
                                                                                *ngFor="let subcategory4 of subCategory8; index as m">
                                                                                <div class="wrapper">
                                                                                    <li><a [class.active]="active === subcategory4.name"
                                                                                            class="sub-list"
                                                                                            (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name
                                                                                            | titlecase |
                                                                                            productDetailsPipe:
                                                                                            subcategory4.name}}</a></li>
                                                                                    <!-- FIFTH LEVEL SUBCATEGORY -->
                                                                                    <div class="wrapper">
                                                                                        <div
                                                                                            *ngFor="let subcategory5 of subCategory10;index as n">
                                                                                            <li><a [class.active]="active === subcategory5.name"
                                                                                                    class="sub-list"
                                                                                                    (click)="checkedevt1(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name
                                                                                                    | titlecase |
                                                                                                    productDetailsPipe:
                                                                                                    subcategory5.name}}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-6"></div>
</div>
