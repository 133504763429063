<app-alerts *ngIf="showAlerts"></app-alerts>
<!-- <section class="container">
    <p class="logo-header mt-3">Gift an Experience</p>
    <p class="text-black"> If there is one thing people are asked to talk about, it can be food. And in today’s abundant world where people can get almost everything they desire, food is still an experience the human can want more off. That is why we are named Haven Sampoorna – where Sampoorna means “fulfilment”. So, the next time you think of getting a gift for near and dear ones, call on the Heavenly fulfiller, Haven Sampoorna and we shall provide not just the meal but the experience that comes along with heavenly food. Be it with you or a surprise while you off on work travel, let us know your requirements and we shall provide it @ Haven Sampoorna. Thank you.
        </p>
    <p class="sub-header mt-3">Gift Form</p>
    <div class="row mt-2">
        <div class="col-sm-7">
            <form class="gift-form" [formGroup]="Default1">
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Name <span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Name"
                            [class.is-invalid]="validateForm && D1.name.errors" formControlName="name" />
                        <div class="invalid-feedback" *ngIf="D1.name.errors">
                            <div *ngIf="D1.name.errors.required">Name is required.</div>
                            <div *ngIf="D1.name.errors.minlength">Name is too short.</div>
                            <div *ngIf="D1.name.errors.maxlength">Name is too long.</div>
                            <div *ngIf="D1.name.errors.pattern">Name is invalid.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Email Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="email" placeholder="Enter Your Email"
                            [class.is-invalid]="validateForm && D1.email_address.errors"
                            formControlName="email_address" />
                        <div class="invalid-feedback" *ngIf="D1.email_address.errors">
                            <div *ngIf="D1.email_address.errors.required">Email Address is required.</div>
                            <div *ngIf="D1.email_address.errors.pattern">Email Address is invalid.</div>
                            <div *ngIf="D1.email_address.errors.maxlength">Email Address is too long.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Mobile Number<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Your Mobile Number"
                            [class.is-invalid]="validateForm && D1.mobile_number.errors"
                            formControlName="mobile_number" />
                        <div class="invalid-feedback" *ngIf="D1.mobile_number.errors">
                            <div *ngIf="D1.mobile_number.errors.required">Mobile Number is required.</div>
                            <div *ngIf="D1.mobile_number.errors.minlength">Mobile Number is too short.</div>
                            <div *ngIf="D1.mobile_number.errors.maxlength">Mobile Number is too long.</div>
                            <div *ngIf="D1.mobile_number.errors.pattern">Mobile Number is invalid.</div>

                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7"> -->
                        <!-- <input type="date" formControlName="date" class="form-control" 
                            [class.is-invalid]="validateForm && D1.date.errors" /> -->

                            <!-- <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  placeholder="Please select the date"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && D1.date.errors" ></dp-date-picker>
                            
                        <div class="invalid-feedback" *ngIf=D1.date.errors>
                            <div *ngIf="D1.date.errors.required">Date is required.</div>
                            <div *ngIf="D1.date.errors.previous_date">Gift a experience should be booked 5 days in advance.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Time<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7"> -->
                        <!-- <input class="form-control" type="input" (click)="pickTime()" placeholder="Time"
                            [class.is-invalid]="validateForm && D1.time.errors" formControlName="time" /> -->
                            <!-- <select class="custom-select" placeholder="Time" [class.is-invalid]="validateForm && D1.time.errors"
                                formControlName="time">
                                <option value="">Please select the time.</option>
                                <option *ngFor="let times of slots;">{{times}}</option>
                            </select>
                        <div class="invalid-feedback" *ngIf="D1.time.errors">
                            <div *ngIf="D1.time.errors.required">Time is required.</div>
                            <div *ngIf="D1.time.errors.previous_time">Previous time is not allowed.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">No of Persons<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter No of Persons"
                            [class.is-invalid]="validateForm && D1.no_of_persons.errors"
                            formControlName="no_of_persons" />
                        <div class="invalid-feedback" *ngIf="D1.no_of_persons.errors">
                            <div *ngIf="D1.no_of_persons.errors.required">Number of Persons is required.</div>
                            <div *ngIf="D1.no_of_persons.errors.minlength">Number of Persons is too short.</div>
                            <div *ngIf="D1.no_of_persons.errors.maxlength">Cannot accomodate.</div>
                            <div *ngIf="D1.no_of_persons.errors.pattern">Number of Persons is invalid.</div>

                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control" type="textarea" placeholder="Enter the Address"
                            [class.is-invalid]="validateForm && D1.address.errors" formControlName="address"></textarea>
                        <div class="invalid-feedback" *ngIf="D1.address.errors">
                            <div *ngIf="D1.address.errors.required">Address is required.</div>
                            <div *ngIf="D1.address.errors.minlength">Address is too short.</div>
                            <div *ngIf="D1.address.errors.maxlength">Address is too long.</div>

                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->

                <!-- <div class="text-center">
                    <button class="btn btn-primary buttons mb-1" (click)="saveForm()">SEND</button>
                </div> -->

                <!--end of button-->
            <!-- </form> -->

            <!--end of form-->
        <!-- </div>        -->
        <!-- <div class="col-md-5">
            <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Services Offered</h2>
                <ol style="padding-left: 10px;" >
                    <li *ngFor="let list of giftMealServices; let i=index"><span>{{i+1}}. </span>{{list.description}}</li>
                </ol>
            </div>
        </div>        -->
    <!-- </div>
</section> -->

<section class="container">
    <p class="logo-header mt-3">Gift an Experience</p>
    <!-- <p class="text-black"> If there is one thing people are asked to talk about, it can be food. And in today’s abundant world where people can get almost everything they desire, food is still an experience the human can want more off. That is why we are named Haven Sampoorna – where Sampoorna means “fulfilment”. So, the next time you think of getting a gift for near and dear ones, call on the Heavenly fulfiller, Haven Sampoorna and we shall provide not just the meal but the experience that comes along with heavenly food. Be it with you or a surprise while you off on work travel, let us know your requirements and we shall provide it @ Haven Sampoorna. Thank you.
        </p> -->
        <p class="text-black"> {{description}}
        </p>
    <p class="sub-header mt-3">Gift Form</p>
    <div class="row mt-2">
        <div class="col-md-8">
            <form class="gift-form" [formGroup]="Default1">
                
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="add_outlet" class="form-label">ChooseOutlet<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4"> 
                        <select class="custom-select" id="add_outlet"
                        [class.is-invalid]="D1.add_outlet.invalid  && validateForm" formControlName="add_outlet" (change)="handleOutlet($event)">
                        <option value="">Please Select</option>
                        <option *ngFor="let outlet of outletList;" [value]="outlet.name">{{outlet.name}}</option>
                      </select>
                      <div class="invalid-feedback" *ngIf="D1.add_outlet.errors">
                        <div *ngIf="D1.add_outlet.errors.required">Please select the outlet.</div>
                    </div>
                    <div *ngIf="addOutlet===false">Table Booking Is Not Allowed In This Outlet.Please Select Some Other Outlet</div>
             
                    </div>

                    <div class="col-md-2">
                        <label for="location" class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <input type="date" class="form-control" id="date"
                        [class.is-invalid]="D1.date.invalid  && validateForm" formControlName="date" [min]="mindeliveryDate" [max]="maxDate"  (change)="handleDate($event)" required>
                             <!-- <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && f.date.invalid" ></dp-date-picker> -->
                        <div class="invalid-feedback" *ngIf="D1.date.errors">
                            <div *ngIf="D1.date.errors.required">
                                Date is required.
                            </div>
            
                        </div>
                    </div>
            </div> 

            <div class="form-group row">
                <div class="col-md-2">
                    <label for="number" class="form-label">ChooseSlot<span class="required">*</span></label>
                </div>
                        <div class="col-md-9">
                    <span *ngFor="let settings of slots;" >
                        <button *ngIf="slotTime!==settings" class="btn-timeslot" (click)="setSlot(settings)" style="margin-bottom: 8px;">{{settings}}</button>
                        <button *ngIf="slotTime===settings" class="btn-timeslot1" (click)="setSlot(settings)" style="margin-bottom: 8px;">{{settings}}</button>
                    </span>    
                    
                 
                <div *ngIf="showmsg2===true" style="color:red!important;">Please Select Time Slot</div>
                </div>
            </div> 

            <div class="form-group row">
                <div class="col-md-2">
                    <label for="no_of_persons" class="form-label">No.Of Person<span class="required">*</span></label>
                </div>
                <div class="col-md-4"> 
              <input type="text" class="form-control" id="no_of_persons"
              [class.is-invalid]="D1.no_of_persons.invalid && validateForm" formControlName="no_of_persons" required>
              <div class="invalid-feedback" *ngIf="D1.no_of_persons.errors">
                <div *ngIf="D1.no_of_persons.errors.required">Please Add Person.</div>
                <div *ngIf="showmsg1===true">only for {{noOfPerson}} number's reservation is left</div>
            </div>
            <!-- <div *ngIf="showmsg1===true" style="color:red!important;">only for {{noOfPerson}} number's reservation is left</div> -->
                </div>

                <div class="col-md-2">
                    <label for="name" class="form-label">Name<span class="required">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control" id="name"
                    [class.is-invalid]="D1.name.invalid && validateForm" formControlName="name" required>
                <div class="invalid-feedback" *ngIf="D1.name.errors">
                    <div *ngIf="D1.name.errors.required">
                        Name is required.
                    </div>
                    <div *ngIf="D1.name.errors.maxlength">
                        Name can't be more than 80 characters long.
                    </div>
                    <div *ngIf="D1.name.errors.pattern">
                        Name is invalid.
                    </div>
                </div>
                </div>
        </div> 


        <div class="form-group row">
            <div class="col-md-2">
                <label for="content" class="form-label">Email<span class="required">*</span></label>
            </div>
            <div class="col-md-4"> 
                <input type="email" class="form-control" id="email_address"
                [class.is-invalid]="(D1.email_address.invalid && validateForm)"
                formControlName="email_address" maxlength="50" email>
            <div class="invalid-feedback" *ngIf="D1.email_address.errors">
                <div *ngIf="D1.email_address.errors.required">
                    Email is required.
                </div>
                <div *ngIf="D1.email_address.errors.maxlength">
                    Seneder Email can't be more than 50 characters long.
                </div>
                <div *ngIf="D1.email_address.errors.email || D1.email_address.errors.pattern">
                    Email must be a valid email address
                </div>
            </div>
            </div>
    </div> 


    <div class="form-group row">
        <div class="col-md-2">
            <label for="mobile_number" class="form-label">Mobile<span
                class="required">*</span></label>
        </div>
        <div class="col-md-4"> 
            <input type="text" class="form-control" id="mobile_number"
            [class.is-invalid]="D1.mobile_number.invalid && validateForm" formControlName="mobile_number">
        <div class="invalid-feedback" *ngIf="D1.mobile_number.errors">
            <div *ngIf="D1.mobile_number.errors.required">
                Mobile Number is required.
            </div>
            <div *ngIf="D1.mobile_number.errors.maxlength">
                Mobile Number can't be more than 10 characters long.
            </div>
            <div *ngIf="D1.mobile_number.errors.pattern">
                Mobile Number is invalid.
            </div>
        </div>
        <div *ngIf="showmsg3===true" style="color:red!important;">Mobile Number is invalid.</div>
        </div>

        <div class="col-md-2">
            <button class="btn btn-block btn-primary" (click)="verifyMobileNumber()">Send OTP</button>
        </div>
</div> 


<div class="form-group row">
    <div class="col-md-2">
        <label for="otp" class="form-label">OTP<span
            class="required">*</span></label>
    </div>
    <div class="col-md-4"> 
        <input type="text" class="form-control" id="otp"
        [class.is-invalid]="D1.otp.invalid && validateForm" formControlName="otp">
        <div class="invalid-feedback" *ngIf="D1.otp.errors">
            <div *ngIf="D1.otp.errors.required">Please enter OTP.</div>
        </div>
    </div>
</div>   
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Name <span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Name"
                            [class.is-invalid]="validateForm && D1.name.errors" formControlName="name" />
                        <div class="invalid-feedback" *ngIf="D1.name.errors">
                            <div *ngIf="D1.name.errors.required">Name is required.</div>
                            <div *ngIf="D1.name.errors.minlength">Name is too short.</div>
                            <div *ngIf="D1.name.errors.maxlength">Name is too long.</div>
                            <div *ngIf="D1.name.errors.pattern">Name is invalid.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Email Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="email" placeholder="Enter Your Email"
                            [class.is-invalid]="validateForm && D1.email_address.errors"
                            formControlName="email_address" />
                        <div class="invalid-feedback" *ngIf="D1.email_address.errors">
                            <div *ngIf="D1.email_address.errors.required">Email Address is required.</div>
                            <div *ngIf="D1.email_address.errors.pattern">Email Address is invalid.</div>
                            <div *ngIf="D1.email_address.errors.maxlength">Email Address is too long.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Mobile Number<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Your Mobile Number"
                            [class.is-invalid]="validateForm && D1.mobile_number.errors"
                            formControlName="mobile_number" />
                        <div class="invalid-feedback" *ngIf="D1.mobile_number.errors">
                            <div *ngIf="D1.mobile_number.errors.required">Mobile Number is required.</div>
                            <div *ngIf="D1.mobile_number.errors.minlength">Mobile Number is too short.</div>
                            <div *ngIf="D1.mobile_number.errors.maxlength">Mobile Number is too long.</div>
                            <div *ngIf="D1.mobile_number.errors.pattern">Mobile Number is invalid.</div>

                        </div>
                    </div>
                </div> -->

                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="mobile_number" class="form-label">Mobile<span
                            class="required">*</span></label>
                    </div>
                    <div class="col-md-6"> 
                        <input type="text" class="form-control" id="mobile_number"
                        [class.is-invalid]="D1.mobile_number.invalid && validateForm" formControlName="mobile_number">
                    <div class="invalid-feedback" *ngIf="D1.mobile_number.errors">
                        <div *ngIf="D1.mobile_number.errors.required">
                            Mobile Number is required.
                        </div>
                        <div *ngIf="D1.mobile_number.errors.maxlength">
                            Mobile Number can't be more than 15 characters long.
                        </div>
                        <div *ngIf="D1.mobile_number.errors.pattern">
                            Mobile Number is invalid.
                        </div>
                    </div>
                    </div>
            
                    <div class="col-md-2">
                        <button class="btn btn-block btn-primary" (click)="verifyMobileNumber()">Send OTP</button>
                    </div>
            </div>  -->


            <!-- <div class="form-group row">

                <div class="col-md-4">
                    <label for="otp" class="form-label">OTP<span
                        class="required">*</span></label>
                </div>
                <div class="col-md-7">
                    <input type="text" class="form-control" id="otp"
                    [class.is-invalid]="D1.otp.invalid && validateForm" formControlName="otp">
                    <div class="invalid-feedback" *ngIf="D1.otp.errors">
                        <div *ngIf="D1.otp.errors.required">Please enter OTP.</div>
                    </div>
                </div>
            </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7"> -->
                        <!-- <input type="date" formControlName="date" class="form-control" 
                            [class.is-invalid]="validateForm && D1.date.errors" /> -->
<!-- 
                            <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  placeholder="Please select the date"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && D1.date.errors" ></dp-date-picker>
                            
                        <div class="invalid-feedback" *ngIf=D1.date.errors>
                            <div *ngIf="D1.date.errors.required">Date is required.</div>
                            <div *ngIf="D1.date.errors.previous_date">Gift a experience should be booked 5 days in advance.</div>
                        </div>
                    </div>
                </div> -->

                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Time<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7"> -->
                        <!-- <input class="form-control" type="input" (click)="pickTime()" placeholder="Time"
                            [class.is-invalid]="validateForm && D1.time.errors" formControlName="time" /> -->
                            <!-- <select class="custom-select" placeholder="Time" [class.is-invalid]="validateForm && D1.time.errors"
                                formControlName="time">
                                <option value="">Please select the time.</option>
                                <option *ngFor="let times of slots;">{{times}}</option>
                            </select>
                        <div class="invalid-feedback" *ngIf="D1.time.errors">
                            <div *ngIf="D1.time.errors.required">Time is required.</div>
                            <div *ngIf="D1.time.errors.previous_time">Previous time is not allowed.</div>
                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->
                <!-- <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">No of Persons<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter No of Persons"
                            [class.is-invalid]="validateForm && D1.no_of_persons.errors"
                            formControlName="no_of_persons" />
                        <div class="invalid-feedback" *ngIf="D1.no_of_persons.errors">
                            <div *ngIf="D1.no_of_persons.errors.required">Number of Persons is required.</div>
                            <div *ngIf="D1.no_of_persons.errors.minlength">Number of Persons is too short.</div>
                            <div *ngIf="D1.no_of_persons.errors.maxlength">Cannot accomodate.</div>
                            <div *ngIf="D1.no_of_persons.errors.pattern">Number of Persons is invalid.</div>

                        </div>
                    </div>
                </div> -->
                <!--end of form-group row -->

                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control" type="textarea" placeholder="Enter the Address" rows="4"
                            [class.is-invalid]="validateForm && D1.address.errors" formControlName="address"></textarea>
                        <div class="invalid-feedback" *ngIf="D1.address.errors">
                            <div *ngIf="D1.address.errors.required">Address is required.</div>
                            <div *ngIf="D1.address.errors.minlength">Address is too short.</div>
                            <div *ngIf="D1.address.errors.maxlength">Address is too long.</div>

                        </div>
                    </div>
                </div>

                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Notes</label>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control" type="textarea" placeholder="Enter Note"
                            [class.is-invalid]="validateForm && D1.notes.errors" formControlName="notes"></textarea>
                    </div>
                </div>
                <!--end of form-group row -->

                <div class="text-center">
                    <button class="btn btn-primary buttons mb-1" (click)="saveForm()">SEND</button>
                </div>

                <!--end of button-->
            </form>

            <!--end of form-->
        </div>       
        <!-- <div class="col-md-5">
            <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Services Offered</h2>
                <ol style="padding-left: 10px;" >
                    <li *ngFor="let list of giftMealServices; let i=index"><span>{{i+1}}. </span>{{list.description}}</li>
                </ol>
            </div>
        </div>        -->
        <div class="col-md-4">
            <div class="card" style="height: 77%!important;">
                <div class="card-header" id="delivery-card" style="height: 100% !important;">
                    <img class="d-block w-100" src="https://ecbee-labes-images.s3.ap-south-1.amazonaws.com/Haven/StoreGallery/1624599039241%20-%20havenhome1.jpg" alt="" style="height:132px!important;">
                    <!-- <img class="d-block w-100" src="{{image}}" alt="" style="height:132px!important;"> -->

                    <h2 class="card-txt" style="color: #7e8552; margin-top: 7px;">Services Offered</h2>
                    <!-- <h4 class="card-txt" style="margin-top: 10px;">Rules For Gift Meal</h4> -->
                        <!-- <p class="text-black"> </p> -->
                        <ol style="padding-left: 10px;" >
                            <li *ngFor="let list of giftMealServices; let i=index" style="font-weight: bold;font-size: 16px;"><span>{{i+1}}. </span>{{list.description}}</li>
                        </ol>
                </div>
            </div>
        </div>
    </div>
</section>

