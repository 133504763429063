import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { ProfileDetailsService } from '../profile-details/profile-details.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import { WINDOW_PROVIDERS } from 'ngx-owl-carousel-o/lib/services/window-ref.service';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html'
})


export class ProfileDetailsComponent implements OnInit {
  userRegistartionForm: FormGroup;
  otpLoginForm : FormGroup;
  otpResendDuration : number;
  validateForm: boolean = false;
  vaidateotpLoginForm : boolean = false;
  showalertmsg: boolean = true;
  disable : boolean = false;
  constName : any;
  hide: boolean = false;
  passdata: any;
  showEdit : boolean = true;
  Password: any;
  password: any;
  formDataJson: any;
  queryParams: any;
  otpForm : boolean = false;
  userName : any;
  profileDetails:any;
  m:string = "";
  fm:string = ''
  id:number;
  mobile
  otpType = ''

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  userRegistartionFormData: any;
  countryCodes = [
    {'name': "IN +91", 'value': "+91"}
  ]
  backToUrl = "/";

  constructor(
    private router: Router,
    private ProfileDetailsService: ProfileDetailsService,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params['params'].url) {
        this.backToUrl = params['params'].url;
      }
    })
    this.hide = false;
    this.userRegistartionForm = new FormGroup({
      first_name: this.formBuilder.control('', [Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      // last_name: this.formBuilder.control('', [Validators.maxLength(40), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email: this.formBuilder.control('', [Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
      country_code: this.formBuilder.control('+91'),
      phone_number: this.formBuilder.control('', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+]*[(]{0,1}[1-9]{1}[0-9]{9}"), Validators.required]),
      gender : this.formBuilder.control(''),
      male : this.formBuilder.control('Male'),
      female : this.formBuilder.control('Female'),
    })

    this.otpLoginForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    })

    this.initial();
    this.id = Number(this.localService.get('UserId'));

    if(this.id !== undefined){
      this.getProfileDetails(this.id)
    }

  }

  get f() { return this.userRegistartionForm.controls; }
  get f4() { return this.otpLoginForm.controls;}

  onedit(){
    this.userRegistartionForm.get('first_name').enable();
    this.userRegistartionForm.get('email').enable();
    this.userRegistartionForm.get('country_code').enable();
    this.userRegistartionForm.get('phone_number').enable();
    this.userRegistartionForm.get('gender').enable();
    this.userRegistartionForm.get('male').enable();
    this.userRegistartionForm.get('female').enable();
    this.hide=true;
    this.showEdit = false;
    this.disable = false
  }
  initial(){
    this.userRegistartionForm.get('first_name').disable();
    this.userRegistartionForm.get('email').disable();
    this.userRegistartionForm.get('country_code').disable();
    this.userRegistartionForm.get('phone_number').disable();
    this.userRegistartionForm.get('gender').disable();
    this.userRegistartionForm.get('male').disable();
    this.userRegistartionForm.get('female').disable();
    this.hide=false;
    this.disable = true
  }

  genderChange(e){
    if(e.target.value === 'Male'){
      this.userRegistartionForm.controls.gender.setValue("Male")
      this.m ="edit"
      this.fm =""
    }
    else if(e.target.value === 'Female'){
      this.userRegistartionForm.controls.gender.setValue("Female")
      this.fm ="edit"
      this.m =""
    }
  }

  changeMobile(){
    if(this.otpForm){
      this.otpResendDuration = undefined;
      this.otpForm = false;
      this.hide = true;
    }
  }

  formCheck(){
    if (this.userRegistartionForm.valid) {
      let mobileNumber = this.userRegistartionForm.value.phone_number
      this.validateForm = false;
      this.hide = false;
      if(this.profileDetails.phone_number != mobileNumber){
        // this.otpForm = true;
        // this.initial();
        // this.otpType = "mobile"
        this.mobileCheck()
        // this.sendOtptoUser(mobileNumber);
      }
      else if(this.profileDetails.email != this.userRegistartionForm.value.email && this.userRegistartionForm.value.email != ""){
        // this.otpForm = true;
        // this.initial();
        // this.otpType = "email"
        // this.sendOTPToEmail(this.userRegistartionForm.value.email);
        this.emailCheck()
      }
      else{
        this.profileUpdate();
        this.reset();
        this.setLocalService();
      }
    }
    else{
      this.validateForm = true;
    }
  }

  sendOtp() {
      let mobileNumber = this.userRegistartionForm.value.phone_number
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "phone_number": mobileNumber.toString()
          }
        }
      }
      // API CALL TO FIND EXISTING/NEW USER
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {
          if(resp){
            window.scrollTo(0,0);
            if(resp.success === 1 && resp.status_code === 200){
              this.sendOtptoUser(mobileNumber);
            }
            else if(resp.success === 0){
              if(resp.message){
                window.scrollTo(0,0);
                this.alertService.error(resp.message, this.options);
              }
            }
            else if(resp.message && resp.status_code !== 200){
              window.scrollTo(0,0);
              this.alertService.error(resp.message, this.options);
            }
            else{
              window.scrollTo(0,0);
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
          else{
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        },
        err => {
          if (err.error.message) {
            // console.log(err.error.error.message)
          }
          else {
            window.scrollTo(0,0);
            // console.log('Something bad happened; Please try again!')
          }
        })
    
  }

  resendCode() {
    if(this.otpType == 'mobile'){
      var mobileOrEmail = this.userRegistartionForm.value.phone_number
    }
    else if(this.otpType == 'email'){
      var mobileOrEmail = this.userRegistartionForm.value.email
    }

    this.showalertmsg = true;
    if(this.otpResendDuration === undefined)
    {
      if(this.otpType == 'mobile'){
        this.sendOtptoUser(mobileOrEmail);
      }
      else if(this.otpType == 'email'){
        this.sendOTPToEmail(mobileOrEmail)
      }
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else if(this.otpResendDuration < new Date().getTime())
    {
      if(this.otpType == 'mobile'){
        this.sendOtptoUser(mobileOrEmail);
      }
      else if(this.otpType == 'email'){
        this.sendOTPToEmail(mobileOrEmail)
      }
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else
    {
      window.scrollTo(0,0);
      if(this.otpType == 'mobile'){
        this.alertService.error("OTP already sent to this number "+ mobileOrEmail, this.options)
      }
      else if(this.otpType == 'email'){
        this.alertService.error("OTP already sent to this email "+ mobileOrEmail, this.options)
      }
    }
  }

  sendOtptoUser(mobileNo) {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "organization_id": ORG_DETAILS.ORGANIZATION_ID,
      "store_id": ORG_DETAILS.STORE_ID,
      "data": {
        "otp": {
          "countrycode": "+91",
          "mobile": mobileNo,
          "otppurpose": "login"
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SEND_OTP).subscribe(
      resp => {
        if(resp && resp.success === 1)
        {
          window.scrollTo(0,0)
          this.alertService.success('OTP has been sent to your registered Mobile Number.', this.options);
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
        }
      },
      err => {
        if (err.error.message) {
          // console.log(err.error.error.message)
        }
        else {
          // console.log('Something bad happened; Please try again!')
        }
      })
  }

  mobileCheck(){
    let mobileNumber = this.userRegistartionForm.value.phone_number
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "phone_number": this.userRegistartionForm.value.phone_number
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GETALL_CUSTOMER).subscribe(resp=>{
      if(resp.result.data.length == 0){
        this.otpForm = true;
        this.initial();
        this.otpType = "mobile"
        this.sendOtptoUser(mobileNumber);
      }
      else{
        window.scroll(0,0);
        this.alertService.warn("Mobile number is already registered with another account.",this.options)
        this.reset();
      }
    })
  }

  emailCheck(){
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "email": this.userRegistartionForm.value.email
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GETALL_CUSTOMER).subscribe(resp=>{
      if(resp.result.data.length == 0){
        this.otpForm = true;
        this.initial();
        this.otpType = "email"
        this.sendOTPToEmail(this.userRegistartionForm.value.email);
      }
      else{
        window.scroll(0,0);
        this.alertService.warn("Email is already registered with another account.",this.options)
        this.reset();
      }
    })
  }


  profileUpdate() {
    if (this.userRegistartionForm.valid) {
      this.validateForm = false;
      let formData = {}
      formData["email"] = this.userRegistartionForm.value.email
      formData["first_name"] = this.userRegistartionForm.value.first_name
      // formData["last_name"] = this.userRegistartionForm.value.last_name
      formData["phone_number"] = this.userRegistartionForm.value.phone_number
      formData["gender"] = this.userRegistartionForm.value.gender
      this.userRegistartionFormData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          customer_creation: formData
        },
        extras: {
          find: {
            id : this.id
          },
      }
    }
      this.appService.postApiCall(this.userRegistartionFormData, ENDPOINTS.UPDATE_CUSTOMER).subscribe(resp => {
        if (resp) {
          if (resp.success == 1) {
            this.initial();
            this.getProfileDetails(this.id)
            window.scrollTo(0,0)
            this.alertService.success(resp.message, this.options);
          }
          else {
            this.alertService.warn(resp.message, this.options);
          }
        }
      },
        err => {
          if (err.error.message) {
            this.alertService.error(err.error.message, this.options);
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    else {
      this.validateForm = true;
    }
  }

  OTPLogin() {
    if (this.otpLoginForm.valid) {
      this.showalertmsg = true;
      this.vaidateotpLoginForm = false;
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "data": {
          "otp": {
            "userotp": this.otpLoginForm.controls.otp.value,
            "mobile": this.userRegistartionForm.controls.phone_number.value
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        resp => {
          // console.log(resp)
          if (resp.success === 1) {
              this.onedit()
              this.profileUpdate();
              this.otpLogging();
              this.reset();
              this.setLocalService();
          }
          else {
            window.scrollTo(0,0)
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.message) {
            window.scrollTo(0,0)
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            window.scrollTo(0,0)
          }
        })
    }
    else {
      this.vaidateotpLoginForm = true;
    }
  }

  otpLogging(){
    let requestData = {
      "username": this.userRegistartionForm.controls.phone_number.value.toString()
    }
    this.appService.postApiCall(requestData, ENDPOINTS.OTP_LOGIN).subscribe(
      resp => {
        if (resp.success === 1) {
          // this.customerService.setCustomerData(this.userRegistartionForm.value.phone_number, resp.user.id)
          this.localService.set('UserId', resp.user.id);
          this.localService.set('domainName', resp.user.domain_name);
          this.appService.loggedin_user = true;
          this.appService.userLoggedIn = true;
          this.userName = this.userRegistartionForm.value.first_name
          this.appService.userName = this.userName;
          if(this.userName){
            this.localService.set('UserName', this.userName);
            this.appService.userName = this.userName;
          }
          else{
            this.localService.set('UserName', 'New User');
            this.appService.userName = 'New User';
          } 
        }
        else {
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          // console.log('Something bad happened; Please try again!')
        }
      })
  } 

  getProfileDetails(id) {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id" : id
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody,ENDPOINTS.GET_CUSTOMER,).subscribe(
      resp => {
        if (resp) {
          this.profileDetails = resp.result.data[0];
          if(this.profileDetails !== undefined ){
            this.userRegistartionForm.setValue({
              first_name: this.profileDetails.first_name != undefined ? this.profileDetails.first_name : this.appService.userName,
              // name : this.profileDetails.name === "" ? this.appService.userName : this.profileDetails.name,
              // last_name: this.profileDetails.last_name === undefined ? '' : this.profileDetails.last_name,
              email: this.profileDetails.email === undefined ? '' : this.profileDetails.email,
              country_code: this.profileDetails.country_code === undefined ? '+91' : this.profileDetails.country_code,
              phone_number: this.profileDetails.phone_number=== undefined ? '' : this.profileDetails.phone_number,
              gender : this.profileDetails.gender === undefined ? '' : this.profileDetails.gender,
              male : "Male",
              female : "Female"
            })
            this.m = this.profileDetails.gender === "Male" ? "edit" : ""
            this.fm = this.profileDetails.gender === "Female" ? "edit" : ""
            this.initial()
            this.constName = (this.profileDetails.first_name === undefined && this.profileDetails.name === "") ? 'New User' : (this.profileDetails.first_name !== undefined ? this.profileDetails.first_name : this.appService.userName)
            this.localService.set('UserName', this.constName);
            this.appService.userName = this.constName;
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }

  reset(){
    this.userRegistartionForm.reset({
      first_name: '',
      // last_name: '',
      email: '',
      country_code: '+91',
      phone_number: '',
      gender : '',
      male : 'Male',
      female : 'Female',
    })

    this.otpLoginForm.reset({
      otp: '',
    })

    this.otpForm = false;
    this.hide = false;
    this.showEdit = true;
    this.otpResendDuration = undefined
    this.vaidateotpLoginForm = false;
    this.validateForm = false;
    this.getProfileDetails(this.id)
  }

  setLocalService(){
    this.localService.set('UserName', this.constName);
    this.appService.userName = this.constName;
  }

  sendOTPToEmail(email){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
                "email": email
            }
        }
      }
      this.appService.postApiCall(requestData,ENDPOINTS.SEND_OTP_EMAIL).subscribe(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            window.scrollTo(0,0)
            this.alertService.success('OTP has been sent to your registered Email.', this.options);
            this.otpResendDuration = new Date().getTime() + 5*60*1000;
          }
        }
      )
  }

  verifyOTPEmail(){

    var otp = this.otpLoginForm.controls.otp.value
    var email = this.userRegistartionForm.controls.email.value
    
    // console.log(otp,email)
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
              "email": email,
              "userotp": otp
            }
          }
        }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        respdata => {
          // console.log(respdata)
          let res:any = respdata;
          if(res.success === 1){
            this.onedit()
            this.profileUpdate();
            // this.otpLogging();
            this.reset();
            this.setLocalService();
          }
          else {
            window.scrollTo(0,0)
            this.alertService.error(res.message, this.options)
          }
      })
  }


}














