import { Injectable, resolveForwardRef } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutShippingOrderService } from './checkout-shipping-order.service';
import { CheckoutDeliveryOrderService } from '../checkout-delivery/checkout-delivery-order.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import { CheckoutDeliveryStorageService } from '../checkout-delivery/checkout-delivery-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CheckoutShippingApiService {

  distance = 0;
  selectedLogistics = "";
  fixedShippingData:any = {};
  enableFixedShipping:boolean  = false;
  selectedShippingMethod = {
    logisticName:"",
    logisticCharge:0
  }

  constructor(
    private checkoutApiService: CheckoutApiService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private checkoutShippingOrderService: CheckoutShippingOrderService,
    private checkoutDeliveryOrderService: CheckoutDeliveryOrderService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private checkoutSettingsService: CheckoutSettingsService,
    private checkoutDeliveryStorageService: CheckoutDeliveryStorageService
  ) { }

  getShippingCarrierSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETSHIPPINGCARRIERS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let shippingCarriers:any = res.data[0];
            let requestData1 = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                "find": {
                  "id": Number(shippingCarriers.trackable_integrator_reference_id)
                }
              }
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_PAYMENTSETTINGS, requestData1).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success){
                  let paymentSettings:any = res1.data[0];
                  if(paymentSettings.name.toUpperCase() === shippingCarriers.carrier_name.toUpperCase() && shippingCarriers.carrier_name.toUpperCase() === "PICKDROP"){
                    resolve("PICKDROP")
                  }
                  else if(paymentSettings.name.toUpperCase() === shippingCarriers.carrier_name.toUpperCase() && shippingCarriers.carrier_name.toUpperCase() === "DUNZO"){
                    resolve("DUNZO")
                  }
                  else if(paymentSettings.name.toUpperCase() === shippingCarriers.carrier_name.toUpperCase() && shippingCarriers.carrier_name.toUpperCase() === "DELHIVERY"){
                    resolve("DELHIVERY")
                  }
                  else{
                    resolve("FLATRATE")
                  }
                  // resolve("DUNZO")
                }
              }
            )
          }
        }
      )
    })
  }

  getPickdropcharges(deliverObj){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {
          "delivery_rate": deliverObj
        }
      }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.PICKANDDROP, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){
          resolve(Number(res.data.deliveryCharge))
        }
        else{
          resolve("");
        }
      }
  )
    })
  }

  getFlatRateCharges(){
    return new Promise((resolve, reject) =>{
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_SHIPMENTMETHODS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let shippingmethodDetails:any = res.data[0];
            if(shippingmethodDetails.flat_rate.is_enabled){
              resolve({diaplayName: shippingmethodDetails.flat_rate.display_name, type: shippingmethodDetails.flat_rate.type, rate: Number(shippingmethodDetails.flat_rate.settings.rate)})
            }
          }
        }
      )
    })
  }

  getDelhiveryCharges(weight, oPin, dPin){
    return new Promise((resolve, reject) => {
      this.getTotalproductsWeight().then(
        wrespdata => {
          let wresp:any = wrespdata;
          let requestData = {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "user_id": ORG_DETAILS.USER_ID,
            "payload": {
                "invoice_charge": {
                    "md": "S",
                    "cgm": wresp.toString(),
                    "o_pin": oPin,
                    "ss": "Delivered",
                    "d_pin": dPin
                }
            }
          }
          this.checkoutApiService.checkoutAPICall(ENDPOINTS.DELHIVERY_CHARGES, requestData).then(
            respData => {
              let res:any = respData;
              if(res.success){
                resolve(res.data[0].total_amount)
              }
              else{
                resolve("");
              }
            }
          )
        }
      )
      
    })				
  }

  getDunzoCharges(deliveryObj){
    return new Promise((resolve, reject) => {
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          delivery_quote: {
            pickup_details: [
              {
                lat: deliveryObj.pickup.latitude,
                lng: deliveryObj.pickup.longitude,
                reference_id: deliveryObj.oid
              }
            ],
            optimised_route:true,
            drop_details: [
              {
                lat: deliveryObj.drop.latitude,
                lng: deliveryObj.drop.longitude,
                reference_id: deliveryObj.oid
              }
            ]
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.DUNZO_CHARGES, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.estimated_price != undefined){
              resolve(res.data.estimated_price)
            }
          }
          else{
            resolve("");
          }
        }
      )
    })
  }

  getFreeshippingSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_SHIPMENTMETHODS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let shipmethods = res.data[0];
            if(shipmethods.free_shipping){
              resolve({freeShipping:shipmethods.free_shipping.is_enabled, displayName: "Delivery Charges", minOrder: shipmethods.free_shipping.min_order_amount})
            }
            else{
              resolve({freeShipping:false, displayName: "", minOrder: ""})
            }
            
          }
        }
      )
    })
  }

  getFreeShippingFromProducts(){
    return new Promise ((resolve, reject) => {
      let cartProducts = this.checkoutInitialdataService.cartProducts;
      let shippingList = []
      cartProducts.forEach(product => {
        if(product.product.shipping){
          if(product.product.shipping.is_free_shipping){
            shippingList.push(true)
          }
          else{
            shippingList.push(false)
          }
        }
      });
      if(shippingList.length > 0){
        if(shippingList.includes(false)){
          resolve(false)
        }
        else{
          resolve(true)
        }
      }
      else{
        resolve(false);
      }
      
    })
  }

  getRestaurentdetailsforShipping(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.OUTLET).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.length > 0){
              resolve (res.data[0])
            }
          }
        }
      )
    })
  }

  getDeliveryCharges(){
    return new Promise((resolve, reject) => {
      this.getShippingCarrierSettings().then(
        respData => {
          let res:any = respData;
          this.getFixedShippingSettings().then(
            frespData => {
              let fres:any = frespData;
              if(fres && this.fixedShippingData.fixed_shippping_price){
                let kmsList = [];
                kmsList = this.fixedShippingData.fixed_shipping_price_list.map(function (fsp){return fsp.km});
                let fspflag = false;
                kmsList.forEach(element => {
                  if(Math.ceil(this.distance) === element){
                    fspflag = true;
                  }
                });
                // console.log("fspflag", fspflag, res, kmsList, Math.ceil(res))
                if(fspflag){
                  let selectedShippingPrice;
                  this.fixedShippingData.fixed_shipping_price_list.forEach(element => {
                    if(element.km === Math.ceil(this.distance)){
                      selectedShippingPrice = element;
                      this.enableFixedShipping = true;
                      if(res === "PICKDROP"){
                        let deliveryObj;
                        if(this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude !== ""){
                          deliveryObj = {
                            "sendFrom": "Chennai",
                            "pickupLatitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude,
                            "pickupLongitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_longitude,
                            "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                            "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                            "distance":this.distance
                          }
                          this.getPickdropcharges(deliveryObj).then(
                            resData2 => {
                              let res2:any = resData2;
                              if(res2 !== ""){
                                this.selectedShippingMethod.logisticName = res;
                                this.selectedShippingMethod.logisticCharge = res2;
                              }
                              else{
                                this.getFlatRateCharges().then(
                                  resData3 => {
                                    let res3:any = resData3;
                                    var charge = 0;
                                    var dTax = 0;
                                    if(res3.type.toUpperCase() === "PER ORDER"){
                                      charge = res3.rate
                                    }
                                    else{
                                      let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                      charge = res3.rate * cartItems.length
                                    }
                                    if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                      dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                    }
                                    this.selectedShippingMethod.logisticName = "Flat Rate";
                                    this.selectedShippingMethod.logisticCharge = charge;
                                  }
                                )
                              }
                            }
                          )
                        }
                        else{
                          this.getRestaurentdetailsforShipping().then(
                            respData1 =>{
                              let res1:any = respData1;
                              deliveryObj = {
                                "sendFrom": "Chennai",
                                "pickupLatitude": res1.latitude,
                                "pickupLongitude": res1.longitude,
                                "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                                "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                                "distance":this.distance
                              }
                              this.getPickdropcharges(deliveryObj).then(
                                resData2 => {
                                  let res2:any = resData2;
                                  if(res2 !== ""){
                                    this.selectedShippingMethod.logisticName = res;
                                    this.selectedShippingMethod.logisticCharge = res2;
                                  }
                                  else{
                                    this.getFlatRateCharges().then(
                                      resData3 => {
                                        let res3:any = resData3;
                                        var charge = 0;
                                        var dTax = 0;
                                        if(res3.type.toUpperCase() === "PER ORDER"){
                                          charge = res3.rate
                                        }
                                        else{
                                          let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                          charge = res3.rate * cartItems.length
                                        }
                                        if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                          dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                        }
                                        this.selectedShippingMethod.logisticName = "Flat Rate";
                                        this.selectedShippingMethod.logisticCharge = charge;
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          )
                        }
                        
                      }
                      else if(res === "DUNZO"){
                        this.getRestaurentdetailsforShipping().then(
                          respData1 =>{
                            let res1:any = respData1;
                            let dunzoObj = {
                              oid: this.checkoutLocalStorageService.getOrderId().toString(),
                              pickup:{
                                latitude: res1.latitude,
                                longitude: res1.longitude,
                              },
                              drop:{
                                latitude: this.checkoutShippingOrderService.shippingAddress.latitude,
                                longitude: this.checkoutShippingOrderService.shippingAddress.longitude,
                              }
                            }
                            this.getDunzoCharges(dunzoObj).then(
                              drespData => {
                                let dres:any = drespData;
                                if(dres !== ""){
                                  this.selectedShippingMethod.logisticName = res;
                                  this.selectedShippingMethod.logisticCharge = dres;
                                }
                                else{
                                  this.getFlatRateCharges().then(
                                    resData3 => {
                                      let res3:any = resData3;
                                      var charge = 0;
                                      var dTax = 0;
                                      if(res3.type.toUpperCase() === "PER ORDER"){
                                        charge = res3.rate
                                      }
                                      else{
                                        let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                        charge = res3.rate * cartItems.length
                                      }
                                      if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                        dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                      }
                                      this.selectedShippingMethod.logisticName = "Flat Rate";
                                      this.selectedShippingMethod.logisticCharge = charge;
                                    }
                                  )
                                }
                              })
                          }
                        )
                      }
                      else if(res === "DELHIVERY"){
                        let ozipcode
                        if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
                          ozipcode = this.checkoutDeliveryStorageService.getDeliveryInfo().zipcode;
                        }
                        else{
                          this.getRestaurentdetailsforShipping().then(
                            respData1 =>{
                              let res1:any = respData1;
                              ozipcode = res1.zipcode;
                              let dzipcode = this.checkoutShippingOrderService.shippingAddress.zipcode;
                              let weight = "500";
                              this.getDelhiveryCharges(weight, ozipcode, dzipcode).then(
                                dhrespData => {
                                let dhres:any = dhrespData;
                                if(dhres !== ""){
                                  this.selectedShippingMethod.logisticName = res;
                                  this.selectedShippingMethod.logisticCharge = dhres;
                                }
                            else{
                              this.getFlatRateCharges().then(
                                resData3 => {
                                  let res3:any = resData3;
                                  var charge = 0;
                                  var dTax = 0;
                                  if(res3.type.toUpperCase() === "PER ORDER"){
                                    charge = res3.rate
                                  }
                                  else{
                                    let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                    charge = res3.rate * cartItems.length
                                  }
                                  if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                    dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                  }
                                  this.selectedShippingMethod.logisticName = "Flat Rate";
                                  this.selectedShippingMethod.logisticCharge = charge;
                                }
                              )
                            }
                          }
                        )}
                          )
                        }
                      }
                      else{
                        this.getFlatRateCharges().then(
                          resData3 => {
                            let res3:any = resData3;
                            var charge = 0;
                            var dTax = 0;
                            if(res3.type.toUpperCase() === "PER ORDER"){
                              charge = res3.rate
                            }
                            else{
                              let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                              charge = res3.rate * cartItems.length
                            }
                            if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                              dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                            }
                            this.selectedShippingMethod.logisticName = "Flat Rate";
                            this.selectedShippingMethod.logisticCharge = charge;
                          }
                        )
                      }
                    }
                  });
                  resolve({deliveryCharge:selectedShippingPrice.price_for_km, deliveryTax:0, deliverType:res})
                }
                else{
                  if(res === "PICKDROP"){
                    let deliveryObj;
                    if(this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude !== ""){
                      deliveryObj = {
                        "sendFrom": "Chennai",
                        "pickupLatitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude,
                        "pickupLongitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_longitude,
                        "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                        "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                        "distance":this.distance
                      }
                      this.getPickdropcharges(deliveryObj).then(
                        resData2 => {
                          let res2:any = resData2;
                          if(res2 !== ""){
                            resolve({deliveryCharge:res2, deliveryTax:0, deliverType:res})
                          }
                          else{
                            this.getFlatRateCharges().then(
                              resData3 => {
                                let res3:any = resData3;
                                var charge = 0;
                                var dTax = 0;
                                if(res3.type.toUpperCase() === "PER ORDER"){
                                  charge = res3.rate
                                }
                                else{
                                  let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                  charge = res3.rate * cartItems.length
                                }
                                if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                  dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                }
                                resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                              }
                            )
                          }
                        }
                      )
                    }
                    else{
                      this.getRestaurentdetailsforShipping().then(
                        respData1 =>{
                          let res1:any = respData1;
                          deliveryObj = {
                            "sendFrom": "Chennai",
                            "pickupLatitude": res1.latitude,
                            "pickupLongitude": res1.longitude,
                            "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                            "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                            "distance":this.distance
                          }
                          this.getPickdropcharges(deliveryObj).then(
                            resData2 => {
                              let res2:any = resData2;
                              if(res2 !== ""){
                                resolve({deliveryCharge:res2, deliveryTax:0, deliverType:res})
                              }
                              else{
                                this.getFlatRateCharges().then(
                                  resData3 => {
                                    let res3:any = resData3;
                                    var charge = 0;
                                    var dTax = 0;
                                    if(res3.type.toUpperCase() === "PER ORDER"){
                                      charge = res3.rate
                                    }
                                    else{
                                      let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                      charge = res3.rate * cartItems.length
                                    }
                                    if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                      dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                    }
                                    resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                                  }
                                )
                              }
                            }
                          )
                        }
                      )
                    }
                    
                  }
                  else if(res === "DUNZO"){
                    this.getRestaurentdetailsforShipping().then(
                      respData1 =>{
                        let res1:any = respData1;
                        let dunzoObj = {
                          oid: this.checkoutLocalStorageService.getOrderId().toString(),
                          pickup:{
                            latitude: res1.latitude,
                            longitude: res1.longitude,
                          },
                          drop:{
                            latitude: this.checkoutShippingOrderService.shippingAddress.latitude,
                            longitude: this.checkoutShippingOrderService.shippingAddress.longitude,
                          }
                        }
                        this.getDunzoCharges(dunzoObj).then(
                          drespData => {
                            let dres:any = drespData;
                            if(dres !== ""){
                              resolve({deliveryCharge:dres, deliveryTax:0, deliverType:res})
                            }
                            else{
                              this.getFlatRateCharges().then(
                                resData3 => {
                                  let res3:any = resData3;
                                  var charge = 0;
                                  var dTax = 0;
                                  if(res3.type.toUpperCase() === "PER ORDER"){
                                    charge = res3.rate
                                  }
                                  else{
                                    let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                    charge = res3.rate * cartItems.length
                                  }
                                  if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                    dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                  }
                                  resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                                }
                              )
                            }
                          })
                      }
                    )
                  }
                  else if(res === "DELHIVERY"){
                    let ozipcode
                    if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
                      ozipcode = this.checkoutDeliveryStorageService.getDeliveryInfo().zipcode;
                    }
                    else{
                      this.getRestaurentdetailsforShipping().then(
                        respData1 =>{
                          let res1:any = respData1;
                          ozipcode = res1.zipcode;
                          let dzipcode = this.checkoutShippingOrderService.shippingAddress.zipcode;
                          let weight = "500";
                          this.getDelhiveryCharges(weight, ozipcode, dzipcode).then(
                            dhrespData => {
                            let dhres:any = dhrespData;
                            if(dhres !== ""){
                              resolve({deliveryCharge:dhres, deliveryTax:0, deliverType:res})
                            }
                        else{
                          this.getFlatRateCharges().then(
                            resData3 => {
                              let res3:any = resData3;
                              var charge = 0;
                              var dTax = 0;
                              if(res3.type.toUpperCase() === "PER ORDER"){
                                charge = res3.rate
                              }
                              else{
                                let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                charge = res3.rate * cartItems.length
                              }
                              if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                              }
                              resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                            }
                          )
                        }
                      }
                    )}
                      )
                    }
                  }
                  else{
                    this.getFlatRateCharges().then(
                      resData3 => {
                        let res3:any = resData3;
                        var charge = 0;
                        var dTax = 0;
                        if(res3.type.toUpperCase() === "PER ORDER"){
                          charge = res3.rate
                        }
                        else{
                          let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                          charge = res3.rate * cartItems.length
                        }
                        if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                          dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                        }
                        resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                      }
                    )
                  }
                }
              }
              else{
                if(res === "PICKDROP"){
                  let deliveryObj;
                  if(this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude !== ""){
                    deliveryObj = {
                      "sendFrom": "Chennai",
                      "pickupLatitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude,
                      "pickupLongitude": this.checkoutDeliveryOrderService.deliveryObj.outlet_longitude,
                      "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                      "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                      "distance":this.distance
                    }
                    this.getPickdropcharges(deliveryObj).then(
                      resData2 => {
                        let res2:any = resData2;
                        if(res2 !== ""){
                          resolve({deliveryCharge:res2, deliveryTax:0, deliverType:res})
                        }
                        else{
                          this.getFlatRateCharges().then(
                            resData3 => {
                              let res3:any = resData3;
                              var charge = 0;
                              var dTax = 0;
                              if(res3.type.toUpperCase() === "PER ORDER"){
                                charge = res3.rate
                              }
                              else{
                                let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                charge = res3.rate * cartItems.length
                              }
                              if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                              }
                              resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                            }
                          )
                        }
                      }
                    )
                  }
                  else{
                    this.getRestaurentdetailsforShipping().then(
                      respData1 =>{
                        let res1:any = respData1;
                        deliveryObj = {
                          "sendFrom": "Chennai",
                          "pickupLatitude": res1.latitude,
                          "pickupLongitude": res1.longitude,
                          "dropLatitude": this.checkoutShippingOrderService.shippingAddress.latitude.toString(),
                          "dropLongitude": this.checkoutShippingOrderService.shippingAddress.longitude.toString(),
                          "distance":this.distance
                        }
                        this.getPickdropcharges(deliveryObj).then(
                          resData2 => {
                            let res2:any = resData2;
                            if(res2 !== ""){
                              resolve({deliveryCharge:res2, deliveryTax:0, deliverType:res})
                            }
                            else{
                              this.getFlatRateCharges().then(
                                resData3 => {
                                  let res3:any = resData3;
                                  var charge = 0;
                                  var dTax = 0;
                                  if(res3.type.toUpperCase() === "PER ORDER"){
                                    charge = res3.rate
                                  }
                                  else{
                                    let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                    charge = res3.rate * cartItems.length
                                  }
                                  if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                    dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                  }
                                  resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                                }
                              )
                            }
                          }
                        )
                      }
                    )
                  }
                  
                }
                else if(res === "DUNZO"){
                  this.getRestaurentdetailsforShipping().then(
                    respData1 =>{
                      let res1:any = respData1;
                      let dunzoObj = {
                        oid: this.checkoutLocalStorageService.getOrderId().toString(),
                        pickup:{
                          latitude: res1.latitude,
                          longitude: res1.longitude,
                        },
                        drop:{
                          latitude: this.checkoutShippingOrderService.shippingAddress.latitude,
                          longitude: this.checkoutShippingOrderService.shippingAddress.longitude,
                        }
                      }
                      this.getDunzoCharges(dunzoObj).then(
                        drespData => {
                          let dres:any = drespData;
                          if(dres !== ""){
                            resolve({deliveryCharge:dres, deliveryTax:0, deliverType:res})
                          }
                          else{
                            this.getFlatRateCharges().then(
                              resData3 => {
                                let res3:any = resData3;
                                var charge = 0;
                                var dTax = 0;
                                if(res3.type.toUpperCase() === "PER ORDER"){
                                  charge = res3.rate
                                }
                                else{
                                  let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                                  charge = res3.rate * cartItems.length
                                }
                                if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                  dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                                }
                                resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                              }
                            )
                          }
                        })
                    }
                  )
                }
                else if(res === "DELHIVERY"){
                  let ozipcode
                  if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
                    ozipcode = this.checkoutDeliveryStorageService.getDeliveryInfo().zipcode;
                  }
                  else{
                    this.getRestaurentdetailsforShipping().then(
                      respData1 =>{
                        let res1:any = respData1;
                        ozipcode = res1.zipcode;
                        let dzipcode = this.checkoutShippingOrderService.shippingAddress.zipcode;
                        let weight = "500";
                        this.getDelhiveryCharges(weight, ozipcode, dzipcode).then(
                          dhrespData => {
                          let dhres:any = dhrespData;
                          if(dhres !== ""){
                            resolve({deliveryCharge:dhres, deliveryTax:0, deliverType:res})
                          }
                      else{
                        this.getFlatRateCharges().then(
                          resData3 => {
                            let res3:any = resData3;
                            var charge = 0;
                            var dTax = 0;
                            if(res3.type.toUpperCase() === "PER ORDER"){
                              charge = res3.rate
                            }
                            else{
                              let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                              charge = res3.rate * cartItems.length
                            }
                            if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                              dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                            }
                            resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                          }
                        )
                      }
                    }
                  )}
                    )
                  }
                }
                else{
                  this.getFlatRateCharges().then(
                    resData3 => {
                      let res3:any = resData3;
                      var charge = 0;
                      var dTax = 0;
                      if(res3.type.toUpperCase() === "PER ORDER"){
                        charge = res3.rate
                      }
                      else{
                        let cartItems = this.checkoutLocalStorageService.getLocalCartItems()
                        charge = res3.rate * cartItems.length
                      }
                      if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                        dTax = (charge * this.checkoutSettingsService.shippingTax.rate)/100
                      }
                      resolve({deliveryCharge:charge, deliveryTax:dTax, deliverType:"Flat Rate"})
                    }
                  )
                }
              }
            })
          
        }
      )
    })
  }

  getFixedShippingSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETSHIPPING_SETTINGS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            this.fixedShippingData = res.data[0];
            resolve(true);
          }
        }
      )
    })
  }

  getTotalproductsWeight(){
    return new Promise((resolve, reject) => {
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            let totalWeight = 0;
            if(this.checkoutInitialdataService.cartProducts && this.checkoutInitialdataService.cartProducts.length > 0){
              this.checkoutInitialdataService.cartProducts.forEach(product => {
                if(ORG_DETAILS.NAME==='ShubhCards')
                    {
                      var card_weight=Number(product.product.card_weight.replace('gm', ''));
                      totalWeight = totalWeight + (card_weight * product.quantity)
                    }
                    if(ORG_DETAILS.NAME==='Deera')
                    {
                      // var card_weight=Number(product.product.card_weight.replace('gm', ''));
                      var product_weight= Number(product.product.shipping.weight_to_consider);
                      console.log("product_weighttt"+product_weight);
                      totalWeight = totalWeight + (product_weight * product.quantity)
                    }
                    else {
                      totalWeight = totalWeight + (product.product.shipping.weight * product.quantity)
                    }
              });
              resolve(totalWeight)
            }
            else{
              resolve(totalWeight)
            }
          }
        }
      )
    })
  }

  getDistance(olat, olan, dlat, dlan){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {},
        "find": {
          "origin": olat+","+olan,
          "destination": dlat+","+dlan
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_LOCATION_DISTANCE, requestData).then(
        respData => {
          let resp:any = respData;
          if(resp.success){
            if(resp.data.rows.length > 0){
              resolve(resp.data.rows[0].elements[0].distance.value/1000)
            }
          }
          else{
            resolve(false);
          }
        }
      )
    })
  }

}
