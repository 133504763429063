<!-- <div [innerHtml] = "content"></div> -->
<!DOCTYPE html>
<html>
  <head><title>privacy policy</title></head>
  <body>
    <div class="paddingTB60  background-filter">
      <div class="container-fluid text-center">
          
          <h3 class="fcolor">Privacy Policy</h3>
          <hr>
        </div>
    
    <div style="margin-left: 5px;" id = "privacy-content"></div>
    </div>
  </body>
</html>

<!-- <div class="paddingTB60">
    <div class="container-fluid text-center">
        <br>
        <h1>Privacy Policy</h1>
        <br>
        <div class="container-fluid text-center" style="width:765px;" id="accordion" *ngFor="let d of data;let i = index;">
          <div class="form-group row">  
            <div class="input-group">
              
              <a data-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapseOne" ><button style="border: 1px solid #d3d3d3;"  id={{i}} (click)="show1(i)" >{{d.title}}</button></a>
              <div class="input-group-append" style="padding-right:5px;">
                
                <a data-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapseOne" ><span class="input-group-text" id={{i}} (click)="show1(i)"><i style="color: white;padding-left:10px;"
                [class]="showContent[i] ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"></i></span></a>
              </div>
            </div>
        
            <div style="width:760px;" id="collapse{{i}}" class="collapse" >
              <div class="card" style="margin-top: 0;" >
                <small class="form-text text-muted text-muted-color">
                {{d.content}}
                </small>
              </div>
            </div>
            <br>
        </div>

       
  </div>
</div> -->