import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../auth-service/local-storage.service'

import { HeaderService } from '../../header/header-two/header.service';
// import { AppService } from '../../app.service';
import * as metaData from '../../../assets/data/default/header-footer.json';
// import * as metaData from '../../../assets/data/deera/header-footer.json';
import { config }  from 'src/configs/config';

@Component({
  selector: 'app-luckme-footer',
  templateUrl: './footer-luckme.component.html'
})
export class FooterLuckmeComponent implements OnInit {
  windowscroll()
  {
    window.scrollTo(0,0)
  }

  copyRightText:any = "";
  config=config;

  onLineShopping = metaData.footer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private localService: LocalStorageService
    // private appService : AppService
  ) { }

  ngOnInit(): void {
    // this.getCopyRightText();
  }

  navigateToProduct(link :any){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if (config.title == 'ShubhCards' || config.title == 'Deera') {
  
      this.localService.set('is_category_filter','true')
      this.localService.set('category_filter_name',link)
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


    }
   this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   // this.router.navigate([`/productListing`], { queryParams: {terms:link, type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  // productListing(){
	// 	this.router.navigate([`/productListing`], { queryParams: {term:'', type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

  //   }

  

  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }

  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
}
