import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, 
RouterStateSnapshot,Router } from '@angular/router';
import {AppService} from '../../app.service';
import {LocalStorageService} from '../../auth-service/local-storage.service';

@Injectable()
export class CartGuard implements CanActivate {

constructor(private appService : AppService,private localService : LocalStorageService,private routerUrl : Router) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
{
    this.localService.set("InCheckout","true")
    this.localService.set('session_expiry',new Date().getTime() + 24*60*1000)
    
    if(!this.localService.get("cart"))
    {
        this.routerUrl.navigate(["/product"],{skipLocationChange : false})
        return false   
    }
    else if(JSON.parse(this.localService.get("cart")).length == 0)
    {
        this.routerUrl.navigate(["/product"],{skipLocationChange : false})
        return false
    }
    else
    {
        return true
    }
    
}

}