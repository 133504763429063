import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { ProfileDetailsService } from './profile-details/profile-details.service';
import { ENDPOINTS } from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';

import { ChangePasswordComponent } from './change-password/change-password.component';
import {ProfileDetailsComponent} from './profile-details/profile-details.component'
import {CustomerAddressComponent} from './customer-address/customer-address.component'
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html'
})
export class MyAccountComponent implements OnInit {
  @ViewChild (ChangePasswordComponent) changePassword : ChangePasswordComponent;
  @ViewChild (ProfileDetailsComponent) profileDetailsComponent : ProfileDetailsComponent;
  @ViewChild (CustomerAddressComponent) customerAddressComponent : CustomerAddressComponent;

  userRegistartionForm: FormGroup;
  validateForm: boolean = false;
  showalertmsg: boolean = true;

  hide: boolean = false;

  passdata: any;

  Password: any;
  password: any;
  formDataJson: any;
  queryParams: any;

  profileDetails:any;

  id:number;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(
    private router: Router,
    private ProfileDetailsService: ProfileDetailsService,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localService: LocalStorageService,
    // private authTokenService: AuthTokenService
  ) { }

  ngOnInit(): void {


  }

  passCheck(){
    
    this.id = Number(this.localService.get('UserId'));

    if(this.id !== undefined && this.id !== NaN){
      this.changePassword.getProfileDetails(this.id)
    }
    this.changePassword.reset()
      this.changePassword.passCheck();
    }

    profileCheck(){
      this.profileDetailsComponent.reset()
    }
  
    manageAddress(){
      this.customerAddressComponent.addAddressFlag = false;
    }

}
