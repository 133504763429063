import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html'
})
export class ReturnPolicyComponent implements OnInit {

  content:any = "";

  constructor(
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.getTermsAndCondition();
  }

  getTermsAndCondition() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_RETURNPOLICY).subscribe(
      resp => {
        if (resp.result.data[0].length !== 0) {
          let data:any = resp.result.data[0]
          this.content = data.content;
          // document.getElementById('return-content').innerHTML = this.content;
          document.getElementById('return-content').innerHTML = resp.result.data[0].content;

        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
      }
    )
  }

}
