<section>
    <div class="order-summary">
        <div class="card summary-card">
            <div class="card-body">
                <p class="summary-header">ORDER SUMMARY</p>
                <div class="summary-container summary-label">
                    <!-- SUB TOTAL -->
                    <div *ngIf="SummaryData.subTotal.show">
                        <div class="row summary-row">
                            <div *ngIf="SummaryData.itemCount > 1" class="col-md-9">
                                <p>{{SummaryData.subTotal.displayName}} ({{SummaryData.itemCount}} items)</p>
                            </div>
                            <div *ngIf="SummaryData.itemCount === 1" class="col-md-9">
                                <p>{{SummaryData.subTotal.displayName}} ({{SummaryData.itemCount}} item)</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.subTotal.amount}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- SHIPPING CHARGES -->
                    <div *ngIf="SummaryData.shippingCharges.show">
                        <div class="row summary-row">
                            <div class="col-md-9">
                                <p>{{SummaryData.shippingCharges.displayName}}</p>
                            </div>
                            <div class="col-md-3">
                                <p *ngIf="!SummaryData.shippingCharges.free">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.shippingCharges.amount}}</p>
                                <p *ngIf="SummaryData.shippingCharges.free" class="text-primary">FREE</p>
                            </div>
                        </div>
                    </div>
                    <!-- HANDLING FEE -->
                    <div *ngIf="SummaryData.handlingFee.show">
                        <div class="row summary-row">
                            <div class="col-md-9">
                                <p>{{SummaryData.handlingFee.displayName}}</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.handlingFee.amount}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- DISCOUNT AMOUNT -->
                    <div *ngIf="SummaryData.discount.show">
                        <div class="row summary-row">
                            <div class="col-md-9">
                                <p>{{SummaryData.discount.displayName}}</p>
                            </div>
                            <div class="col-md-3">
                                <p class="text-primary">- {{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.discount.amount}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- TAX -->
                    <div *ngIf="SummaryData.tax.show && SummaryData.tax.amount > 0">
                        <div class="row summary-row">
                            <div class="col-md-9">
                                <p>{{SummaryData.tax.displayName}}</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.tax.amount}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- TOTAL -->
                     <div *ngIf="SummaryData.total.show">
                        <div class="row summary-row summary-total-show">
                            <div class="col-md-9">
                                <p>{{SummaryData.total.displayName}}</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{SummaryData.total.amount}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="bannerMsg !==''">
            <div class="card summary-card">
                <div class="customer-container">
                    <div class="rainbow rainbow_text_animated" style="font-size: 1.5rem !important;" [innerHTML]="bannerMsg"></div>
                </div>
            </div>
        </div>
    </div>
</section>
