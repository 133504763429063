import { Injectable } from '@angular/core';
import {ORG_DETAILS} from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class OrderUpdationService {

  confirmationId:any;

  // CUSTOMER
  customerObj = {
    customer_id:"",
    customer_name:"",
    customer_type:"",
    phone_number:"",
    is_email_opt_in : false
  }

  customerId:any = "";

  // DELIVERY INFO
  deliveryObj = {
    outlet_id : "",
    outlet_name : "",
    outlet_latitude: "",
    outlet_longitude: "",
    delivery_preferred_time: "",
    delivery_date:"",
    delivery_time:"",
    delivery_type: ""
  }

  // SHIPPING
  shippingAddress = {
    first_name:"",
    last_name:"",
    company_name:"",
    entire_location:"",
    address1:"",
    landmark:"",
    city:"",
    country:"",
    country_code:"",
    state:"",
    zipcode:"",
    email:"",
    phone_number:"",
    latitude:"",
    longitude:""
  }

  billingAddress = {
    first_name:"",
    last_name:"",
    company_name:"",
    entire_location:"",
    address1:"",
    landmark:"",
    city:"",
    country:"",
    country_code:"",
    state:"",
    zipcode:"",
    email:"",
    phone_number:"",
    latitude:"",
    longitude:""
  }

  shippingMethodObj = {
    name:"",
    delivery_charge:0,
    pay_to_logistics:0,
    total_shipping_price: 0
  }

  shippingCarrierObject = {
    shipping_carrier_id:"",
    shipping_carrier_name:"",
    api_integration_id:"",
    api_integration_name:""
  }

  deliveryInstructionObject = {
    delivery_instruction:""
  }

  shippingObj = {
    shipping_address_count:0,
    fixed_shippping_price: false,
    pay_to_logistics:0,
    free_shipping:false,
    shipped_date:"",
    items_total:0,
    items_shipped:0,
    items_total_quantity:0,
    shipping_addresses:[],
    billing_address:this.billingAddress,
    delivery_instructions:[],
    shipping_method:this.shippingMethodObj,
    shipping_carrier:this.shippingCarrierObject,
    deliveries:[],
    geo_info:{},
    customer_comment:"",
    notes : ""
  }

  // ORDER REVIEWS
  orderReviewObj = {
    base_cost:{},
    derived_cost:{},
    order_taxes:[],
    order_summary:{}
  }

  // PAYMENT
  paymentObj = {
    status_flow:{},
    invoice_id:"",
    invoice_path:"",
    payment_status:"Pending",
    payment_method:"",
    payment_method_id:"",
    order_currency_code:"",
    base_currency_code:"",
    currency_exchange_rate:null,
  }

  // PRODUCT DETAILS
  productObj = {
    product_id:"",
    product_name:"",
    sku:"",
    quantity:0,
    image_source:"",
    price:0,
    price_inc_tax:0,
    price_ex_tax:0,
    discount_percentage:0,
    discount_amount:0,
    tax_percentage:0,
    tax_amount:0,
    total_amount:0,
    variant:{
      variant_id:"",
      variant_name:"",
      options:{
        option_key:"",
        option_value:""
      }
    }
  }

  productsObj = {
    products:[]
  }

  otherDeatilsObj = {
    order_source : "Web",
    order_reference : ORG_DETAILS.DOMAIN_NAME,
    order_reference_id : "",
    is_digital_order : false,
    is_manual_order : false,
    is_registration_offer:false,
    cart_id : "",
    store_credit_amount : 0,
    gift_certificate_amount : 0,
    coupon_code : "",
    coupon_discount : 0,
    refunded_amount : 0,
  }

  scheduled_details = {
    cooking_time:"",
    display_time:"",
    scheduled_time:0,
    update_status:""
  }

  // ORDER OBJECT
  orderObj = {
    customer_id: this.customerId,
    status : "",
    status_id : "",
    system_label : "Awaiting Payment",
    orderConfirmDate : new Date(),
    customer: this.customerObj,
    delivery: this.deliveryObj,
    shipping: this.shippingObj,
    order_review: this.orderReviewObj,
    payment: this.paymentObj,
    product_details: this.productsObj,
    other_details: this.otherDeatilsObj,
    scheduled_details: this.scheduled_details
  }

  old_value = {
    status: "",
    system_label: "Pending"
  }
  
  new_value = {
      status: "",
      system_label: "Awaiting Payment"
  }

  new_value1 = {
    status: "",
    system_label: "Confirmed"
  }

  constructor() { }
  
}
