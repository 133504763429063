<!DOCTYPE html>
<html>
  <head><title>Shipping and delivery</title></head>
  <body>
    <div class="paddingTB60  background-filter">
      <div class="container-fluid text-center">
          
          <h3 class="fcolor">Shipping and Delivery</h3>
        <hr>
        </div>
    
    <div style="margin-left: 5px;" id= "terms-content"></div>
    </div>
  </body>
</html>


<!--  -->