import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
// import { AuthTokenService } from 'src/app/auth-services/auth-token.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileDetailsService {

  constructor(
    private _http: HttpClient,
    private router: Router,
    // private authTokenService: AuthTokenService
    ) { }

    getall(Api,data): Observable<any> {
      // if(this.authTokenService.isTokenExpired()){
      //   this.router.navigate([`/login`], {skipLocationChange: false});
      // }
      // else{
        return this._http.post(Api, data, { headers: new HttpHeaders().set('Content-Type', 'application/json').set('authorization', 'Bearer ' )});
      // }
  
    }

    performActionButtons(api,data): Observable<any> {
      // if(this.authTokenService.isTokenExpired()){
      //   this.router.navigate([`/login`], {skipLocationChange: false});
      // }
      // else{
        return this._http.post(api, data, { headers: new HttpHeaders().set('Content-Type', 'application/json').set('authorization', 'Bearer ' )});
      // }
    }
  
  }
