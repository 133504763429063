<div class="row-fluid">
    <div class="column grid-image">
        <ul class="tyers-grid">
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/2nd-tyre.png" class="img-fluid">
                    </a>
                </div>
                <div class="tyers-grid-content">
                            <a>
                                <h4>Truck pattern Tyers - TO659A</h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 3,0000</span> -->
                            </div>
                </div>
            </li>
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/5th-tyre.png" class="img-fluid">
                </a>
                </div>
                <div class="tyers-grid-content">
                            <a><h4>Truck pattern Tyers - TO659A</h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 13,225</span> -->
                            </div>
                </div>
            </li>
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/3rd-tyre.png" class="img-fluid">
                    </a>
                </div>
                <div class="tyers-grid-content">
                    <a><h4>Truck pattern Tyers - TO659A</h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 4,730</span> -->
                            </div>
                </div>
            </li>
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/6th-tyre.png" class="img-fluid">
                    </a>
                </div>
                <div class="tyers-grid-content">
                    <a><h4>Truck pattern Tyers - TO659A</h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 4,930</span> -->
                            </div>
                </div>
            </li>
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/4th-tyre.png" class="img-fluid">
                    </a>
                </div>
                <div class="tyers-grid-content">
                    <a><h4>OTR pattern-Siteking HD Tyers </h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 10,730</span> -->
                            </div>
                </div>
            </li>
            <li>
                <div class="trers-grid-image">
                    <a>
                    <img src="assets/images/NoPath.png" class="img-fluid">
                    </a>
                </div>
                <div class="tyers-grid-content">
                    <a><h4>Truck pattern Tyers - TO659A</h4></a>
                            <div class="price-weapper">
                                <!-- <span class="price">&#8377; 3,0000</span> -->
                            </div>
                </div>
            </li>
           
        </ul>
    </div>
    <div class="column single-image">
        <div class="tyres-image">
            <div class="trers-single-image">
                <a>
                <img src="assets/images/2nd-tyre.png" class="img-fluid">
                </a>
            </div>
            <div class="tyers-grid-content">
                <a> <h4>Truck pattern Tyers - TO659A</h4></a>
                        <div class="price-weapper">
                            <!-- <span class="price">&#8377; 3,0000</span> -->
                        </div>
            </div>
        </div>
    </div>
   
</div>