<div *ngIf="CONFIG.title !== 'Nesma'">
    <div class="min-cart-product-wrapper popup-content">
        <div class="row justify-content-center" *ngFor="let cart of appService.cartProducts.results; let i = index;" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-10">
                <h6>{{cart.product_name}}</h6>
                <p style="font-size: small;">{{cart.quantity }} <span style="font-size: smaller;padding-right: 5px;">&#x292C;</span>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{cart.cost}}</p>
            </div>
            <div class="col-2">
                <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p>
            </div>
            
        </div>
        <div class="row" style="padding-top: 10px;">
        <div class="col-10">Total Items</div>
        <div class="col-2"><h6>{{appService.totalCartItems}}</h6></div>
    </div>
    <div class="text-center mr-2 mt-2">
        <div class="btn-group">
        <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button>
        <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button>
    </div>
    </div>
    </div>
</div>
<div *ngIf="CONFIG.title === 'Nesma'">
    <div class="min-cart-product-wrapper popup-content" *ngIf="cartData.length > 0">
        <div class="row justify-content-center" *ngFor="let cart of cartData; let i = index;" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-10">
                <h6>{{cart.name | titlecase}}</h6>
                <p style="font-size: small;">{{cart.package_quantity }} <span style="font-size: smaller;padding-right: 5px;">&#x292C;</span>₹{{cart.package_total}}</p>
            </div>
            <div class="col-2">
                <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removecartItem(i)">&#x292C;</p>
            </div>
        </div>
        <div class="row" style="padding-top: 10px;">
            <div class="col-10">Total Items</div>
            <div class="col-2"><h6>{{cartData.length}}</h6></div>
        </div>
        <div class="text-center mr-2 mt-2">
            <div class="btn-group">
            <button class="btn btn-secondary inq-btn-st"  routerLink="/cart-new">View Cart</button>
            <button [disabled]="!(cartData.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button>
        </div>
    </div>
</div>