import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-best-products',
  templateUrl: './best-products.component.html',
 
})
export class BestProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
