<section>
<div *ngIf="initialLoad">
    <div *ngIf="!readOnlyAddress">
        <div *ngIf="customerAddresses.length > 0">
            <div *ngFor="let customerAddress of customerAddresses;let i= index">
                <div *ngIf="customerAddress.id !== editAddressId">
                    <div class="shipping-card" style="font-size: 13px !important;" [class.shipping-card-active] = "customerAddress.id === selectedAddressId">
                        <div class="customer-container">
                            <div class="row">
                                <div class="col-sm-1" style="padding-right:0px !important">
                                    <input type="radio" name="selectedAddress" [checked]="customerAddress.id === selectedAddressId" (click)="setSelectedAddress(customerAddress)">
                                </div>
                                <div class="col-sm-9" style="padding-left:0px !important">
                                    <span class="shipping-font"><b class="shipping-font">{{customerAddress.first_name}} - {{customerAddress.phone_number}}</b></span>
                                    <div>
                                        <p class="shipping-font">{{customerAddress.entire_location}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-2" *ngIf="customerAddress.id === selectedAddressId">
                                    <div class="clickable-tag" style="float: right;" (click)="editCustomerAddress(customerAddress)">EDIT</div>
                                </div>
                            </div>
                            <div *ngIf="customerAddress.id === selectedAddressId">
                                 <small *ngIf="is_exist_deliverable" style="font-size: 14px; color: #c21212; margin-top: 10px !important; margin-bottom: 10px;">Sorry, We can't deliver to your Location</small>
                                <div class="row">
                                    <div class="col-md-4">
                                        <button class="btn btn-block btn-primary btn-primary1" (click)="deliverHere()">DELIVER HERE</button>
                                    </div>
                                </div>
                                <div *ngIf="billingAddressEnable">
                                    <div class="row" style="margin-top: 10px !important;">
                                        <div class="col-md-1" style="padding-right: 0px !important;">
                                            <input type="checkbox" id="defaultCheck2" [checked]="sameAsShipping" [(ngModel)]="sameAsShipping" (change)="showBillingAddress()">
                                        </div>
                                        <div class="col-md-10" style="padding-left: 0px !important;">
                                            <p style="font-size: 14px;" class="text-primary">Billing Address is same as Shipping Address</p>
                                        </div>
                                    </div>
                                    <div *ngIf="!sameAsShipping">
                                        <div *ngFor="let billingAddress of customerAddresses">
                                            <div *ngIf="billingAddress.id !== selectedAddressId">
                                                <div class="customer-container">
                                                    <div class="row">
                                                        <div class="col-sm-1" style="padding-right:0px !important">
                                                            <input type="radio" name="selectedBillingAddress" [checked]="billingAddress.id === selectedBillingAddressId" (click)="setSelectedBillingAddress(billingAddress)">
                                                        </div>
                                                        <div class="col-sm-9" style="padding-left:0px !important">
                                                            <span class="shipping-font1"><b class="shipping-font1">{{billingAddress.first_name}} - {{billingAddress.phone_number}}</b></span>
                                                            <div>
                                                                <p class="shipping-font1">{{billingAddress.entire_location}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="customerAddress.id === editAddressId">
                    <div class="customer-container" style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; border-radius:5px;">
                        <form class="checkout-forms" [formGroup]="shippingForm">
                            <div class="row">
                                <div class="col-md-4">
                                    <button class="btn btn-block btn-primary btn-primary1" (click)="getCurrentLocation()" style="margin-top: 0px !important; margin-bottom: 5px !important;"><i class="fa fa-map-marker"></i> USE MY LOCATION</button>
                                </div>
                            </div>
                            <small *ngIf="location_bloked" style="font-size: 12px; color: #c21212; margin-top: 5px !important;">Oops! It seems like you have blocked accesing your location. <br><a style="font-size: 12px; color: blue !important;" href="https://support.google.com/chrome/answer/142065?hl=en" target="_blank">Click here to get instructions to reactivate the location sharing.</a></small>
                             <p id="isdeliverable" style="font-size: 14px; color: #c21212; margin-top: 10px !important; margin-bottom: 10px;display:none;">Sorry, We can't deliver  to your Location</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>
                                            <input ngx-google-places-autocomplete [options]='options' id="enterlocation" type="text" class="form-control" formControlName="location" [class.is-invalid]="validateShippingForm && f.location.errors" placeholder="Enter Location" #placesRef="ngx-places" (onAddressChange)="changeAddress($event)">
                                            <span class="checkout-label">Enter Location*</span>
                                            <div class="invalid-feedback" *ngIf="f.location.errors">
                                                <div *ngIf="f.location.errors.required">Please select your location from Maps.</div>
                                                <div *ngIf="f.location.errors.incorrect"></div>
                                                <div *ngIf="f.location.errors.incorrect1">Please enter a valid Address.</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="flat_no" [class.is-invalid]="validateShippingForm && f.flat_no.errors" placeholder="Flat No/Building Name/Street Name">
                                            <span class="checkout-label">Flat No/Building Name/Street Name*</span>
                                            <div class="invalid-feedback" *ngIf="f.flat_no.errors">
                                                <div *ngIf="f.flat_no.errors.required">Please enter the Flat No/Building Name/Street Name.</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="landmark" [class.is-invalid]="validateShippingForm && f.landmark.errors" placeholder="Flat No/Building Name/Street Name">
                                            <span class="checkout-label">Landmark</span>
                                            <div class="invalid-feedback" *ngIf="f.landmark.errors">
                                                <!-- <div *ngIf="f.landmark.errors.required">Please select your location from Maps.</div> -->
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="city" [class.is-invalid]="validateShippingForm && f.city.errors" placeholder="City" readonly>
                                            <span class="checkout-label">City</span>
                                            <div class="invalid-feedback" *ngIf="f.city.errors">
                                                <!-- <div *ngIf="f.city.errors.required">Please enter the city.</div> -->
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="mobile" [class.is-invalid]="validateShippingForm && f.mobile.errors" placeholder="Mobile Number">
                                            <span class="checkout-label">Mobile Number*</span>
                                            <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                                <div *ngIf="f.mobile.errors.required">Please enter Mobile Number.</div>
                                                <div *ngIf="f.mobile.errors.pattern && (!f.mobile.errors.maxlength || !f.mobile.errors.minlength)">Enter a valid Mobile Number</div>
                                                <div *ngIf="!f.mobile.errors.pattern && f.mobile.errors.maxlength">Enter a valid Mobile Number</div>
                                                <div *ngIf="!f.mobile.errors.pattern && f.mobile.errors.minlength">Enter a valid Mobile Number</div>
                                                <div *ngIf="f.mobile.errors.incorrect">Enter a valid Mobile Number</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid]="validateShippingForm && f.email.errors" placeholder="Email">
                                            <span class="checkout-label">Email</span>
                                            <div class="invalid-feedback" *ngIf="f.email.errors">
                                                <div *ngIf="f.email.errors.pattern">Enter a valid Email</div>
                                                <div *ngIf="f.email.errors.maxlength">Email should not be more than 50 Charchters</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label>
                                            <input type="text" class="form-control" formControlName="full_name" [class.is-invalid]="validateShippingForm && f.full_name.errors" placeholder="Full Name">
                                            <span class="checkout-label">Full Name*</span>
                                            <div class="invalid-feedback" *ngIf="f.full_name.errors">
                                                <div *ngIf="f.full_name.errors.required">Please enter Name.</div>
                                                <div *ngIf="f.full_name.errors.pattern">Enter a valid Name</div>
                                                <div *ngIf="f.full_name.errors.maxlength">Name should not be more than 50 Charchters</div>
                                                <div *ngIf="f.full_name.errors.incorrect">Enter a valid Name</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check" style="margin-left: 0px !important;padding-left: 8px !important; margin-top: 20px !important">
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="form-check-input" type="checkbox" id="defaultCheck1" formControlName="default_shipping">
                                            </div>
                                            <div class="col-md-10">
                                                <p style="font-size: 14px;">It's my default Shipping address</p>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div class="col-md-6">
                                    <agm-map [latitude]="latitude" [longitude]="longitude"  [streetViewControl]=false [zoom]="zoom" (mapClick)="selectLocation($event)">
                                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                        <button>Current Location</button>
                                    </agm-map>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-4">
                                <button class="btn btn-block btn-primary btn-primary1" (click)="updateAddress()">UPDATE</button>
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-block btn-primary btn-secondary1" style="margin-top: 20px;" id="updatecancelbtn" (click)="cancelSave()">CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <div *ngIf="addingAddress && initialLoad">
            <div class="customer-container" style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; border-radius:5px;">
                <form class="checkout-forms" [formGroup]="shippingForm">
                    <div class="row">
                        <div class="col-md-4">
                            <button class="btn btn-block btn-primary btn-primary1" (click)="getCurrentLocation()" style="margin-top: 0px !important; margin-bottom: 5px !important;"><i class="fa fa-map-marker"></i> USE MY LOCATION</button>
                        </div>
                    </div>
                    <small *ngIf="location_bloked" style="font-size: 12px; color: #c21212; margin-top: 5px !important;">Oops! It seems like you have blocked accesing your location. <br><a style="font-size: 12px; color: blue !important;" href="https://support.google.com/chrome/answer/142065?hl=en" target="_blank">Click here to get instructions to reactivate the location sharing.</a></small>
                     <p id="isdeliverable" style="font-size: 14px; color: #c21212; margin-top: 10px !important; margin-bottom: 10px;display: none;">Sorry, We can't deliver to your Location</p>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>
                                    <input ngx-google-places-autocomplete [options]='options' id="enterlocation" type="text" class="form-control" formControlName="location" [class.is-invalid]="validateShippingForm && f.location.errors" placeholder="Enter Location" #placesRef="ngx-places" (onAddressChange)="changeAddress($event)">
                                    <span class="checkout-label">Enter Location*</span>
                                    <div class="invalid-feedback" *ngIf="f.location.errors">
                                        <div *ngIf="f.location.errors.required">Please select your location from Maps.</div>
                                        <div *ngIf="f.location.errors.incorrect"></div>
                                        <div *ngIf="f.location.errors.incorrect1">Please enter a valid Address.</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="flat_no" [class.is-invalid]="validateShippingForm && f.flat_no.errors" placeholder="Flat No/Building Name/Street Name">
                                    <span class="checkout-label">Flat No/Building Name/Street Name*</span>
                                    <div class="invalid-feedback" *ngIf="f.flat_no.errors">
                                        <div *ngIf="f.flat_no.errors.required">Please enter the Flat No/Building Name/Street Name.</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="landmark" [class.is-invalid]="validateShippingForm && f.landmark.errors" placeholder="Flat No/Building Name/Street Name">
                                    <span class="checkout-label">Landmark</span>
                                    <div class="invalid-feedback" *ngIf="f.landmark.errors">
                                        <!-- <div *ngIf="f.landmark.errors.required">Please select your location from Maps.</div> -->
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="city" [class.is-invalid]="validateShippingForm && f.city.errors" placeholder="City" readonly>
                                    <span class="checkout-label">City</span>
                                    <div class="invalid-feedback" *ngIf="f.city.errors">
                                        <!-- <div *ngIf="f.city.errors.required">Please enter the city.</div> -->
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="mobile" [class.is-invalid]="validateShippingForm && f.mobile.errors" placeholder="Mobile Number">
                                    <span class="checkout-label">Mobile Number*</span>
                                    <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                        <div *ngIf="f.mobile.errors.required">Please enter Mobile Number.</div>
                                        <div *ngIf="f.mobile.errors.pattern && (!f.mobile.errors.maxlength || !f.mobile.errors.minlength)">Enter a valid Mobile Number</div>
                                        <div *ngIf="!f.mobile.errors.pattern && f.mobile.errors.maxlength">Enter a valid Mobile Number</div>
                                        <div *ngIf="!f.mobile.errors.pattern && f.mobile.errors.minlength">Enter a valid Mobile Number</div>
                                        <div *ngIf="f.mobile.errors.incorrect">Enter a valid Mobile Number</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid]="validateShippingForm && f.email.errors" placeholder="Email">
                                    <span class="checkout-label">Email</span>
                                    <div class="invalid-feedback" *ngIf="f.email.errors">
                                        <div *ngIf="f.email.errors.pattern">Enter a valid Email</div>
                                        <div *ngIf="f.email.errors.maxlength">Email should not be more than 50 Charchters</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="full_name" [class.is-invalid]="validateShippingForm && f.full_name.errors" placeholder="Full Name">
                                    <span class="checkout-label">Full Name*</span>
                                    <div class="invalid-feedback" *ngIf="f.full_name.errors">
                                        <div *ngIf="f.full_name.errors.required">Please enter Name.</div>
                                        <div *ngIf="f.full_name.errors.pattern">Enter a valid Name</div>
                                        <div *ngIf="f.full_name.errors.maxlength">Name should not be more than 50 Charchters</div>
                                        <div *ngIf="f.full_name.errors.incorrect">Enter a valid Name</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-check" style="margin-left: 0px !important;padding-left: 8px !important; margin-top: 20px !important">
                                <div class="row">
                                    <div class="col-md-1">
                                        <input class="form-check-input" type="checkbox" id="defaultCheck1" formControlName="default_shipping">
                                    </div>
                                    <div class="col-md-10">
                                        <p style="font-size: 14px;">It's my default Shipping address</p>
                                    </div>
                                </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <agm-map [latitude]="latitude" [longitude]="longitude"  [streetViewControl]=false [zoom]="zoom" (mapClick)="selectLocation($event)">
                                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                <button>Current Location</button>
                            </agm-map>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-block btn-primary btn-primary1" (click)="saveAddress()">SAVE</button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-block btn-primary btn-secondary1" id="savecancelbtn" style="margin-top: 20px;" (click)="cancelSave()">CANCEL</button>
                    </div>
                </div>
            </div>
            
        </div>
        <div *ngIf="!addingAddress">
            <div class="add-new-address">
                <div class="row">
                    <div class="col-md-4">
                        <div class="clickable-tag" (click)="openAddressForm()">+ ADD NEW ADDRESS</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!initialLoad">
<p></p>
</div>
<div *ngIf="readOnlyAddress" style="padding: 10px 10px 8px 20px;">
    <!-- <div class="customer-container"> -->
        <div class="row">
            <div class="col-md-10">
                <div><b class="shipping-font">DELIVERY ADDRESS</b></div>
                <p class="shipping-font">{{selectedAddress.entire_location}}</p>
                <div *ngIf="billingAddressEnable">
                    <div style="margin-top: 15px!important"><b class="shipping-font">BILLING ADDRESS</b></div>
                    <p *ngIf="!sameAsShipping" class="shipping-font">{{selectedBillingAddress.entire_location}}</p>
                    <p *ngIf="sameAsShipping" class="shipping-font">Same as Shipping Address</p>
                </div>
            </div>
            <div class="col-md-2">
                <button class="btn btn-secondary btn-secondary1" (click)="editShippingAddress()">CHANGE</button>
            </div>
        </div>
    <!-- </div> -->
</div>
</section>