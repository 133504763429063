import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html'
})
export class FaqsComponent implements OnInit {
  data: any =
    [
      {
        "title": "Parent One",
        "childProperties":
          [
            { "description": "Property One" },
            { "description": "Property Two" }
          ]
      },
      {
        "title": "Parent Two",
        "childProperties":
          [
            { "description": "Property Three" },
            { "description": "Property Four" },
            { "description": "Property Five" },
          ]
      },
      {
        "title": "Parent Three",
        "childProperties":
          [
            { "description": "Property Six" },
            { "description": "Property Seven" },
            { "description": "Property Eight" },
          ]
      }
    ]
    dataOptions: any;
  constructor(
    private localService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getAllFAQs();
  }
  getAllFAQs(){
    window.scrollTo(0,0)
    this.data=[];
    let requestBody = {
      // domain_name: this.localService.get('domainName'),
      // user_id: this.localService.get('UserId'),
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 20
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.FAQS_GET_ALL).subscribe(
      resp => {
        var elem=resp.result.data;
        for(var i=0;i<elem.length;i++){
         
          for(var j=0;j<elem[i].faq_details.length;j++){
            var dataObject={
              "title": elem[i].faq_details[j].title,
              "childProperties":
                [
                  { "description": elem[i].faq_details_desc[j].description },
                ]
            }
          this.data.push(dataObject)
          }
          
        }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
      this.dataOptions=this.data;
  }
  filter(event){
    var inputString = event.target.value.toLowerCase();
    var check=[]
    for(var i=0;i<this.data.length;i++){
      check.push(this.data[i].title.toLowerCase())
    }
    var test;
    this.dataOptions=[];
    for(var i=0;i<this.data.length;i++){
    test=check[i].search(inputString)
    if(test>-1){
      this.dataOptions.push(this.data[i])
    }
    }

  }
  toggleAccordian(event, index) {
    var element = event.target;
    element.classList.toggle("active");
    if(this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }      
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
}
}
