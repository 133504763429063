<div class="page-home">
  <app-banner></app-banner>

  <div class="container">
    <section class="tab-product featured-collections m-0">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="content-title">
            <div class="title-box">
              <i><h1 class="title main-title" *ngIf="config.title === 'BBold'" style="text-shadow: none; color: #793660;">
                EXPLORE OUR CLASSIFIED CATEGORIES
              </h1></i>
              <h2 class="title main-title" *ngIf="config.title != 'LuckMe' && config.title != 'ShubhCards' && config.title != 'Deera' && config.title != 'Aiema' && config.title != 'BombayHardware' &&config.title !='BBold'">
               Explore Our Classifed Categories
              </h2>
              <h2 class="title main-title" *ngIf="config.title === 'Aiema'" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; color: #d57c30;">
                Explore AIEMA
               </h2>
               <h2 class="title main-title" *ngIf="config.title === 'BombayHardware'" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; color: #AD342A;">
                <!-- Explore Bombay Hardware Pipes -->
                Pipes
               </h2>
              <div *ngIf="config.title == 'ShubhCards'">
                <!-- <p class="welcom-text">Welcome to Shubh Cards !!</p> -->
               <h3 class="text-primary">Shop from Top Categories</h3>
              </div >
              <div *ngIf="config.title == 'Deera'">
                <!-- <p class="welcom-text">Welcome to Shubh Cards !!</p> -->
               <h3 class="text-primary">Shop From Top Categories</h3>
              </div >
              <h2 class="title main-title" *ngIf="config.title == 'LuckMe'" >
                Explore Our Classifed Brands
               </h2>
              <h4 class="sub-title submain-title" *ngIf="config.title !== 'ShubhCards' && config.title !== 'Aiema' && config.title !== 'Deera' && config.title !== 'BBold'">Save Time in search of Suppliers</h4>
              <h4 class="sub-title submain-title" *ngIf="config.title === 'Aiema'" style="font-family: 'proxima-regular','sans-serif'!important;">Save Time in search of Suppliers</h4>
              <h4 class="sub-title submain-title" *ngIf="config.title === 'BBold'" style="font-family: 'proxima-regular','sans-serif'!important; color:#c9a365">Save Time in search of Suppliers</h4>
            </div>
          </div>
          <app-featured-collections *ngIf="config.title == 'EarthyWorthy'"
            [collections]="featuredCollections"
          ></app-featured-collections>
          <app-featured-collections *ngIf="config.title == 'BBold'"
            [collections]="featuredCollectionsBBold"
          ></app-featured-collections>
          <app-featured-collections *ngIf="config.title == 'Aiema'"
            [collections]="featuredCollections1"
          ></app-featured-collections>
          <app-featured-collections *ngIf="config.title == 'BombayHardware'"
          [collections]="featuredCollections2"
        ></app-featured-collections>
        <h2 class="content-title title-box title main-title" *ngIf="config.title === 'BombayHardware'" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; color: #AD342A;">
          <!-- Explore Bombay Hardware Pipes -->
          Supreme Pipes
         </h2>
        <app-featured-collections *ngIf="config.title == 'BombayHardware'"
        [collections1]="featuredCollections3"
      ></app-featured-collections>
      <h2 class="content-title title-box title main-title" *ngIf="config.title === 'BombayHardware'" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; color: #AD342A;">
        <!-- Explore Bombay Hardware Pipes -->
        Other Categories
       </h2>
      <app-featured-collections *ngIf="config.title == 'BombayHardware'"
      [collections2]="featuredCollections4"
    ></app-featured-collections>
          <app-featured-collections *ngIf="config.title == 'LuckMe'"
          [collections]="featuredCollectionsLuckme"
        ></app-featured-collections>
        <app-featured-collections *ngIf="config.title == 'ShubhCards'"
        [collections]="featuredCollectionsShubhCards"
      ></app-featured-collections>
      <app-featured-collections *ngIf="config.title == 'Deera'"
      [collections]="featuredCollectionsDeera"
    ></app-featured-collections>
        </div>
      </div>
    </section>
  </div>
  <div *ngIf="recommededProducts.length != 0">
    <div class="container">
      <section class="featured-recommendations pb-50">
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="content-title">
              <div class="title-box">
                <h2 class="title main-title" *ngIf="config.title !== 'LuckMe' && config.title !== 'ShubhCards' && config.title !== 'Deera' && config.title !== 'Aiema'&& config.title !== 'BombayHardware'">Our Collections & Featured Design</h2>
                <h2 class="title main-title" *ngIf="config.title === 'Aiema'" style="text-shadow: none !important;font-family: 'proxima-regular','sans-serif'!important;">Popular Listings</h2>
                <h2 class="title main-title" *ngIf="config.title === 'BombayHardware'" style="text-shadow: none !important;font-family: 'proxima-regular','sans-serif'!important;color: #AD342A!important">Popular Listings</h2>
                <h2 class="title main-title" *ngIf="config.title == 'LuckMe'">Our Featured Products</h2>
                <h4 class="sub-title submain-title" *ngIf="config.title != 'LuckMe' && config.title !== 'ShubhCards' && config.title !== 'Deera' && config.title !== 'Aiema' && config.title !== 'BombayHardware'">
                  Passion for Designing
                </h4>
              </div>
              <div *ngIf="config.title == 'ShubhCards'" class="text-center mb-5">
                <h3 class="text-primary"> Our Featured Cards</h3>
            </div>
            <section *ngIf="config.title == 'Deera'">
              <div class="text-center mb-2">
                  <h3 class="text-primary"> WHAT DO OUR CUSTOMERS SAY</h3>
                  <!--<h3 class="text-primary">Shop from Top Categories</h3>-->
              </div>
              <owl-carousel-o [options]="ProductSliderConfig" class="slider rounder-corner-nav">
                <ng-container
                  *ngFor="let review of reviews1; let i = index"
                >
                  <ng-template carouselSlide>
                    <p><i>{{review.comment}}</i></p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>--{{review.user}}</b></p>
                  </ng-template>                 
                </ng-container>
              </owl-carousel-o>
          </section>
            <div *ngIf="config.title == 'Deera'" class="text-center mb-5" style="margin-top: 35px;">
              <h3 class="text-primary"> Our Featured Products</h3>
          </div>
            </div>
  
            <owl-carousel-o [options]="ProductSliderConfig" class="slider rounder-corner-nav">
              <ng-container
                *ngFor="let product of recommededProducts; let i = index">
                <ng-template carouselSlide>
                    <app-product-box-one [product]="product" [index]="i"></app-product-box-one>                    
                </ng-template>                 
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </section>
    </div>
  </div>
  <section *ngIf="config.title == 'ShubhCards'">
    <div class="text-center mb-2">
        <h3 class="text-primary"> WHAT DO OUR CUSTOMERS SAY</h3>
        <!--<h3 class="text-primary">Shop from Top Categories</h3>-->
    </div>
    <owl-carousel-o [options]="ProductSliderConfig" class="slider rounder-corner-nav">
      <ng-container
        *ngFor="let review of reviews; let i = index"
      >
        <ng-template carouselSlide>
          <p><i>{{review.comment}}</i></p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>--{{review.user}}</b></p>
        </ng-template>                 
      </ng-container>
    </owl-carousel-o>
</section>
  <!-- <div class="container-fluid best-products">
    <div class="container">
      <div class="title">
        <h1 class="main-title">Best Of Tyres</h1>
      </div>
      <app-best-products></app-best-products>
    </div>
  </div> -->
  <!----->
  
</div>
