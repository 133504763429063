<section>
  <div class="profile-page">
    <h2 class="heading">Change Password</h2>
    <form class="customer-registartion-form" [formGroup]="changePasswordForm" novalidate>
      <!-- <div class="form-group">
        <label for="current_password" class="form-label">Current Password</label>
        <div class="input-group col-sm-12">
          <input [type]="showCurrPswd ? 'text' : 'password'" class="form-control" formControlName="current_password"
            [class.is-invalid]="f.current_password.invalid && validateForm" placeholder="******" required>
          <div class="input-group-append">
            <span class="input-group-text" (click)="showPassword('curr')"><i
                [class]="showCurrPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
          </div>
          <div class="invalid-feedback" *ngIf="f.current_password.errors">
            <div *ngIf="f.current_password.errors.required">
              Current Password is required.
            </div>
          </div>
        </div>
      </div> -->

      <div class="form-group">
        <label for="new_password" class="form-label">New Password</label>
        <div class="input-group col-sm-12">
          <input [type]="showNewPswd ? 'text' : 'password'" class="form-control" formControlName="new_password"
            [class.is-invalid]="f.new_password.invalid && validateForm" placeholder="******" required>
          <div class="input-group-append">
            <span class="input-group-text" (click)="showPassword('new')"><i
                [class]="showNewPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
          </div>

          <div class="invalid-feedback" *ngIf="f.new_password.errors">
            <div *ngIf="f.new_password.errors.required">
              New Password is required.
            </div>
            <div *ngIf="f.new_password.errors.minlength">
              New password can't be less than 6 characters long.
            </div>
            <div *ngIf="f.new_password.errors.maxlength">
              New password can't be more than 20 characters long.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="confirm_password" class="form-label">Confirm Password</label>

        <div class="input-group col-sm-12">
          <input [type]="showconfirmPswd ? 'text' : 'password'" class="form-control" formControlName="confirm_password"
            [class.is-invalid]="f.confirm_password.invalid && validateForm" placeholder="******" required>
          <div class="input-group-append">
            <span class="input-group-text" (click)="showPassword('confirm')"><i
                [class]="showconfirmPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
          </div>

          <div class="invalid-feedback" *ngIf="f.confirm_password.errors">
            <div *ngIf="f.confirm_password.errors.required">
              Confirm Password is required.
            </div>
            <div *ngIf="f.confirm_password.errors.minlength">
              Confirm password can't be less than 6 characters long.
            </div>
            <div *ngIf="f.confirm_password.errors.maxlength">
              Confirm password can't be more than 20 characters long.
            </div>
          </div>
        </div>
      </div>

      <button *ngIf="show" class="btn btn-primary btn-block forgot-button" (click)="formCheck()"
        [disabled]="this.noPasswd">Reset Password</button><br>

    </form>

    <div *ngIf="otpForm">
      <form [formGroup]="otpLoginForm" class="otp-form">
        <div class="form-group">
          <input type="text" class="form-control" formControlName="otp" placeholder="Enter OTP"
            [class.is-invalid]="vaidateotpLoginForm && f4.otp.errors">
          <div class="invalid-feedback" *ngIf="f4.otp.errors">
            <div *ngIf="f4.otp.errors.required">Please enter the OTP.</div>
            <div *ngIf="f4.otp.errors.incorrect">Please enter the valid OTP</div>
          </div>
        </div>
      </form>
      <div class="mt-3 mb-3">
        <p class="pl-4">Didn't get the code? <strong><a class="loginLink" (click)=resendCode()>Resend Code</a>
          </strong>
        </p>
      </div>
      <div style="margin-right: 150px;margin-left: 150px;">
        <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="OTPLogin()">Confirm</button>
      </div>
    </div>

  </div>

</section>