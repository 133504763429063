<!-- content -->
<!-- <div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="text-center">
            <h1>Products</h1>
            <div><span class="home-breadcrumb" routerLink="">Home</span>
                <span> <i class="fa fa-long-arrow-right px-2"></i> Products</span>
            </div>
        </div>
    </div>
</div> -->

<div class="container inq-mt-st">
    <section class="row" id="productRow">
        <div class="checkout-alerts">
            <app-alerts *ngIf="checkoutAlerts"></app-alerts>
        </div>
        <div class="col-md-3">
            <div class="inq-menu-left-st">
                <div class="collection-filter">
                    <div class="total-items" id="totalItems">
                        <p *ngIf="CONFIG.title !== 'Aiema' && CONFIG.title" class="background-filter fcolor"><span ><b>Items found</b></span> - {{totalItemsOrProducts}} </p>
                        <!-- <p *ngIf="CONFIG.title === 'BBold'" style =" background-color: #010101; color:#DCA8AC;"><span><b>Items found</b></span> - {{totalItemsOrProducts}} </p> -->
                        <p style="font-family: 'proxima-regular','sans-serif' !important;" *ngIf="CONFIG.title === 'Aiema'"><span><b>Companies found</b></span> - {{totalItemsOrProducts}} </p>  
                        <!-- <p style="font-family: 'proxima-regular','sans-serif' !important;" *ngIf="CONFIG.title === 'Aiema'"><span><b>Companies found</b></span> - {{filterItemsOfType().length}} </p> -->
                    </div>
                    <app-product-filter [finish]="finish" [features]="features" [prices]="prices" [themes]="themes" [filters]="facets" [priceRange]="pricefilters" [size]="size" [thickness]="thickness" [color]="color" [type]="type" [material]="material" [categoriesList]="categoryList" [configTitle]="CONFIG.title">
                    </app-product-filter>
                </div>
            </div>
        </div>
        <div class="col-md-9" *ngIf="CONFIG.title !== 'Aiema' && CONFIG.title !== 'BombayHardware' && CONFIG.title !== 'Deera' && CONFIG.title !== 'BBold'">
            <div>
                <div class="inq-menu-right-st">
                    <h2 class="text-primary" id="productListHead" *ngIf="CONFIG.title === 'ShubhCards' || CONFIG.title === 'Deera' || CONFIG.title === 'BBold'">Products &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p style="display: inline;font-size: 16px;">"INCLUDING TEXT PRINT CHARGES"</p></h2>
                    <h2 class="text-primary" id="productListHead" *ngIf="CONFIG.title !== 'ShubhCards' && CONFIG.title !== 'Deera' && CONFIG.title !== 'BBold'">Products</h2>
                    <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                        <span><i class="px-2">/</i> Products</span>
                        <span> </span>
                    </div>
                    <div *ngIf="totalLength>0" class="thumb">
                        <div *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                            <div class="row inq-pro-sec-st listProduct">
                                <div *ngIf="CONFIG.title !== 'Murgasen' && CONFIG.title !== 'LuckMe' && CONFIG.title !== 'ShubhCards' && CONFIG.title !== 'Deera' && CONFIG.title !== 'Aiema'  && CONFIG.title !== 'BombayHardware'">
                                    <div class="earthy-card">
                                        <div class="earthy-image">
                                            <span class="wishlist-heart" ><i class="fa fa-heart fa-lg"(click)="addWishList(product)" [ngStyle]="{'color':product.select ? '#BDC62B' : '#dcdcdc' }"  aria-hidden="true"></i></span>   
                                            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                <img [src]="product.assets[0].image_source" id="img-list"
                                                    (click)="productDetails(product.id)"
                                                    onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                    alt="{{ product.name}}" class="img-fluid product-image">
                                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                    <img src="assets/images/No_Image_old.png"  id="img-list"
                                                        (click)="productDetails(product.id)" class="img-fluid product-image">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="earthy-description text-center productTitleList" >
                                            <div class="product-description earthy-product-title"  id="img-list"
                                            (mouseenter)="mouseEnter(product.id) "
                                            (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                            ngbPopover="{{metaDataMapService.findData(product,
                                                    fields[1].nestedinfo)}}" container="body"
                                            *ngIf="fields[1].visibility">
                                                <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                                <div>
                                                    <ngb-rating style="color: #373b3f !important; font-size: 11px !important;" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a style="color: #373b3f !important; font-size: 11px !important;">432 ratings|123 reviews</a>
        
                                                    <!-- <ngb-rating   class="rating" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a class="reviewListing"style="color: #373b3f !important; font-size: 11px !important;" >432 ratings|123 reviews</a> -->
                                                </div>
                                                <div class="earthy-worthy-price" id="priceListing" >
                                                    <div *ngIf="fields[3].visibility && product.product_type!='Variable'">₹ {{metaDataMapService.findData(product,
                                                        fields[3].nestedinfo)}}</div>
        
                                                    <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">₹ {{product.pricing.raw.price_range
                                                        ? product.pricing.raw.price_range:
                                                        product.pricing.raw.price}}</div>
        
                                                </div>
                                               
                                            </div>
                                            <div class="earthy-cart-btn">
                                                <button    *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary" (click)="addToCart(product)" >Add to Cart</button>
                                                <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary earthy-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                <!-- <button     class="btn btn-secondary listing"   *ngIf="(appService.productIdinCart.indexOf(product.id) != -1 && product.product_type!='Variable' && appService.productIdinCart.indexOf(product.id) > -1)"  routerLink="/cart">View Cart</button> -->
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                     
                                        
                                                    <span>
                                                        <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                    <span class="inq-sp-2"
                                                        style="color: white;">{{cart.quantity}}</span>
                                                    <span>
                                                        <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                               </div>
                                               </div>
                                                
                                            </div>
                                         </div>
                                       
                                    </div>
                                </div>
                                <div *ngIf="CONFIG.title === 'LuckMe'">
                                    <div class="luckme-card">
                                        <div class="luckme-image">
                                            <span class="wishlist-heart" ><i class="fa fa-heart fa-lg"(click)="addWishList(product)" [ngStyle]="{'color':product.select ? '#BDC62B' : '#dcdcdc' }"  aria-hidden="true"></i></span>   
                                            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                <img [src]="product.assets[0].image_source" id="img-list"
                                                    (click)="productDetails(product.id)"
                                                    onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                    alt="{{ product.name}}" class="product-image">
                                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                    <img src="assets/images/No_Image_old.png"  id="img-list"
                                                        (click)="productDetails(product.id)" class="product-image">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="luckme-description text-center productTitleList" >
                                            <div class="product-description luckme-product-title"  id="img-list"
                                            (mouseenter)="mouseEnter(product.id) "
                                            (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                            ngbPopover="{{metaDataMapService.findData(product,
                                                    fields[1].nestedinfo)}}" container="body"
                                            *ngIf="fields[1].visibility">
                                                <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                                <div>
                                                    <ngb-rating style="color: #373b3f !important; font-size: 11px !important;" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a style="color: #373b3f !important; font-size: 11px !important;">432 ratings|123 reviews</a>
        
                                                    <!-- <ngb-rating   class="rating" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a class="reviewListing"style="color: #373b3f !important; font-size: 11px !important;" >432 ratings|123 reviews</a> -->
                                                </div>
                                                <div class="luckme-worthy-price" id="priceListing" >
                                                    <div *ngIf="fields[3].visibility && product.product_type!='Variable'">SR {{metaDataMapService.findData(product,
                                                        fields[3].nestedinfo)}}</div>
        
                                                    <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">SR {{product.pricing.raw.price_range
                                                        ? product.pricing.raw.price_range:
                                                        product.pricing.raw.price}}</div>
        
                                                </div>
                                               
                                            </div>
                                            <div class="luckme-cart-btn">
                                                <button    *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary luckme-btn" (click)="addToCart(product)" >Add to Cart</button>
                                                <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary luckme-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                <!-- <button     class="btn btn-secondary listing"   *ngIf="(appService.productIdinCart.indexOf(product.id) != -1 && product.product_type!='Variable' && appService.productIdinCart.indexOf(product.id) > -1)"  routerLink="/cart">View Cart</button> -->
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                     
                                        
                                                    <span>
                                                        <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                    <span class="inq-sp-2"
                                                        style="color: white;">{{cart.quantity}}</span>
                                                    <span>
                                                        <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                               </div>
                                               </div>
                                                
                                            </div>
                                         </div>
                                       
                                    </div>
                                </div>
                                <div *ngIf="CONFIG.title === 'ShubhCards'">
                                    <div class="luckme-card">
                                        <div class="luckme-image">
                                            <!-- <span class="wishlist-heart" ><i class="fa fa-heart fa-lg"(click)="addWishList(product)" [ngStyle]="{'color':product.select ? '#BDC62B' : '#dcdcdc' }"  aria-hidden="true"></i></span>    -->
                                            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                <img [src]="product.assets[0].image_source" id="img-list"
                                                    (click)="productDetails(product.id)"
                                                    onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                    alt="{{ product.name}}" class="product-image">
                                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                    <img src="assets/images/No_Image_old.png"  id="img-list"
                                                        (click)="productDetails(product.id)" class="product-image">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="luckme-description text-center productTitleList" style="display:table-caption" >
                                            <div class="product-description luckme-product-title"  id="img-list"
                                            (mouseenter)="mouseEnter(product.id) "
                                            (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                            ngbPopover="{{metaDataMapService.findData(product,
                                                    fields[1].nestedinfo)}}" container="body"
                                            *ngIf="fields[1].visibility">
                                                <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                                <p style="color: #373b3f">MOQ : {{product.pricing.raw.minimumorderquantity}}</p>
                                                <div>
                                                    <!-- <ngb-rating style="color: #373b3f !important; font-size: 11px !important;" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a style="color: #373b3f !important; font-size: 11px !important;">432 ratings|123 reviews</a> -->
        
                                                    <!-- <ngb-rating   class="rating" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a class="reviewListing"style="color: #373b3f !important; font-size: 11px !important;" >432 ratings|123 reviews</a> -->
                                                </div>
                                                <div class="luckme-worthy-price" id="priceListing" >
                                                    <div *ngIf="fields[3].visibility && product.product_type!='Variable'">₹ {{metaDataMapService.findData(
                                                    product,fields[3].nestedinfo)}}</div>
        
                                                    <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">₹ {{product.pricing.raw.price_range
                                                        ? product.pricing.raw.price_range:
                                                        product.pricing.raw.price}}</div>
        
                                                </div>
                                               
                                            </div>
                                            <div class="shubhcards-cart-bt">
                                                <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)" style=" background-color: #150985 !important;">Add to Cart</button>
                                                <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary shubhcards-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                <!-- <button     class="btn btn-secondary listing"   *ngIf="(appService.productIdinCart.indexOf(product.id) != -1 && product.product_type!='Variable' && appService.productIdinCart.indexOf(product.id) > -1)"  routerLink="/cart">View Cart</button> -->
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                <div class="btn btn-primary inq-btn-st ml-1 viewCart" style=" width: 70%;display: inline-block;"     *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                     
                                        
                                                    <span>
                                                        <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                            <input type="number" style="width:40%;text-align:center;" *ngIf="CONFIG.title == 'ShubhCards'" (change)="ChangeValue(i)"[(ngModel)]="cart.quantity">
                                                            <span class="inq-sp-2" *ngIf="CONFIG.title != 'ShubhCards'"
                                                                style="color: white;">{{cart.quantity}}</span>
                                                            <span> 
                                                
                                                        <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                               </div>
                                               </div>
                                                
                                            </div>
                                         </div>
                                       
                                    </div>
                                </div>


                                <div *ngIf="CONFIG.title === 'Murgasen'" class="col-md-12" style="margin-bottom: 20px;">
                                    <div class="murugesan-card1">
                                        <div>
                                            <img [src]="product.assets[0].image_source" id="img-list"
                                                (click)="productDetails(product.id)"
                                                onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                alt="{{ product.name}}" class="murugasen-list-image">
                                        </div>
                                        <div class="murugesan-description">
                                            <div class="murugesan-card-title1">
                                                {{ prodName(product.name)}}
                                            </div>
                                            <div class="murugesan-price" id="priceListing" >
                                                <div class="murugesan-price" *ngIf="fields[3].visibility && product.product_type!='Variable'">SR {{metaDataMapService.findData(product,
                                                    fields[3].nestedinfo)}}</div>
    
                                                <div class="murugesan-price" *ngIf="product.product_type=='Variable' && product.type=='Variable'">SR {{product.pricing.raw.price_range
                                                    ? product.pricing.raw.price_range:
                                                    product.pricing.raw.price}}</div>
                                                    <div class="d-flex justify-content-between mt-1">
                                                        <div class="inner-star-icon pd-1">
                                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <a routerLink="/product-review/{{product.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                        </div>
                                                        <div>
                                                            <i class="fa fa-info-circle fa-lg" style="color:#E48C8C" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div style="padding-top:15px">
                                            <button class="murugesan-addcart" (click)="addToCart(product)" *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">Add to Cart</button>
                                            <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                    <div class="murugesan-addcart" style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;" *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart">
                                                        <span><i class="fa fa-minus mr-3" (click)="decreaseItem(i)" style="font-size:12px;cursor:pointer;"></i></span>
                                                        <span class="inq-sp-2" style="color: white;">{{cart.quantity}}</span>
                                                        <span> <i class="fa fa-plus ml-3" (click)="increaseItem(i)"style="font-size:12px;cursor:pointer;"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div *ngIf="totalLength < 1">
                        <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                    </div>
                </div>
                <pagination-controls *ngIf="totalLength>0 && CONFIG.title === 'Murgasen'" class="my-pagination" (pageChange)="pageChange($event)"
                    style="float: right;" id="pagination-controls"></pagination-controls>
                
                    <pagination-controls *ngIf="totalLength>0 && CONFIG.title === 'LuckMe'" (pageChange)="pageChange($event)"
                        style="margin-top: 14px;float: right;" id="pagination-controls"></pagination-controls>
            </div>
        </div>
        <div class="col-md-9" *ngIf="CONFIG.title === 'Aiema'" style="padding-right: 0px !important;padding-left: 0px !important;">
            <div style="font-family: 'proxima-regular','sans-serif' !important;">
                <h2 class="text-primary" id="productListHead">Companies</h2>
                <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                    <span><i class="px-2">/</i> Companies</span> 
                </div>
                <div *ngIf="totalLength>0">
                    <div class="row inq-pro-sec-st listProduct" style="margin-right: 0px !important;padding-right: 0px !important;">
                        <div *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                            <div id="aiema-listing" >
                                <div id="aiemacards" style="cursor: auto;">
                                    <div class="aiema text-center">
                                        <div class="aiema-img-holder" style="cursor: pointer;" (click)="productDetails(product.id)">
                                            <div class="aiema-img text-center">
                                                <img style="height: 100px !important;width: 100px !important;object-fit: contain !important;" [src]="product.company_profile.logo">
                                            </div>
                                        </div>
                                        <div class="aiema-description-holder">
                                            <!-- <div class="aiema-title" style="font-size: 18px;">{{product.name | titlecase}}</div> -->
                                            <div class="aiema-title" style="font-size: 18px;">{{product.name}}</div>
                                            <div class="aiema-subtitle">{{product.categories[0].categories}}</div>
                                            <div class="aiema-tabs">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Profile"><a  data-toggle="tab" href="#profile{{i}}" role="tab"><i class="fa fa-info-circle" aria-hidden="true"></i></a></li>
                                                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Contacts"><a data-toggle="tab" href="#contacts{{i}}" role="tab"><i class="fa fa-globe" aria-hidden="true"></i></a></li>
                                                    <li data-toggle="tooltip" data-placement="top" title="Company Address"><a data-toggle="tab" href="#location{{i}}" role="tab"><i class="fa fa-map-marker" aria-hidden="true"></i></a></li>
                                                </ul>
                                            </div>
                                            <div class="tab-content">
                                                <div class="aiema-profile tab-pane fade show active" id="profile{{i}}">
                                                    <div class="row">
                                                        <div class="col-sm-3 profile-title">Services:</div>
                                                        <div class="col-sm-9 profile-description">{{product.company_profile.type_of_services ? product.company_profile.type_of_services : '-'}}</div>
                                                    </div>
                                                    <div class="row" style="padding-top: 5px;">
                                                        <div class="col-sm-3 profile-title">Business:</div>
                                                        <div class="col-sm-9 profile-description">{{product.company_profile.nature_of_business}}</div>
                                                    </div>
                                                </div>
                                                <div class="aiema-contact tab-pane fade" id="contacts{{i}}">
                                                    <div class="row">
                                                        <div class="col-sm-3 contact-title">Mobile:</div>
                                                        <div class="col-sm-9 contact-description"><a href="tel:{{product.contact_details.mobile}}">{{product.contact_details.mobile}}</a></div>
                                                    </div>
                                                    <div class="row" style="padding-top: 5px;">
                                                        <div class="col-sm-3 contact-title">Email:</div>
                                                        <div class="col-sm-9 contact-description"><a href = "mailto: {{product.contact_details.email}}">{{product.contact_details.email}}</a></div>
                                                    </div>
                                                    <div class="row" style="padding-top: 5px;">
                                                        <div class="col-sm-3 contact-title">Website:</div>
                                                        <div class="col-sm-9 contact-description"><a [attr.href]="'//' + product.contact_details.website" target="_blank" target="_blank">{{product.contact_details.website}}</a></div>
                                                    </div>
                                                </div>
                                                <div class="aiema-contact tab-pane fade" id="location{{i}}">
                                                    <div class="row">
                                                        <div class="col-sm-4 contact-title">Company Location:</div>
                                                        <div class="col-sm-8 contact-description">{{product.company_details.location}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="aiema-submit-btn">
                                                <!-- <button class="btn btn-block aiema-btn" (click)="submitEnquiry(product)">Submit Enquiry</button> -->
                                                <button class="btn btn-block aiema-btn" (click)="productDetails(product.id)">View Details</button>

                                            </div>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="totalLength < 1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                </div>
                
                <pagination-controls *ngIf="totalLength>0 && CONFIG.title === 'Aiema'" class="my-pagination" (pageChange)="pageChange($event)"
                    style="float: right;" id="pagination-controls"></pagination-controls>
            </div>
        </div>



        <div class="col-md-9" *ngIf="CONFIG.title === 'Deera'" style="padding-right: 0px !important;padding-left: 0px !important;">
            <div>
                <div class="inq-menu-right-st">
                    <h2 class="text-primary" id="productListHead">Products</h2>
                    <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                        <span><i class="px-2">/</i> Products</span>
                        <span> </span>
                    </div>
                    <div *ngIf="totalLength>0" class="thumb1">
                        <div *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                            <div class="row inq-pro-sec-st listProduct">
                                <div *ngIf="CONFIG.title === 'Deera'">
                                    <!-- <div class="luckme-card">
                                        <div class="luckme-image">
                                            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                <img [src]="product.assets[0].image_source" id="img-list"
                                                    (click)="productDetails(product.id)"
                                                    onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                    alt="{{ product.name}}" class="product-image">
                                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                    <img src="assets/images/No_Image_old.png"  id="img-list"
                                                        (click)="productDetails(product.id)" class="product-image">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="luckme-description text-center productTitleList" style="display:table-caption" >
                                            <div class="product-description luckme-product-title"  id="img-list"
                                            (mouseenter)="mouseEnter(product.id) "
                                            (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                            ngbPopover="{{metaDataMapService.findData(product,
                                                    fields[1].nestedinfo)}}" container="body"
                                            *ngIf="fields[1].visibility">
                                                <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                                <p style="color: #373b3f">MOQ : {{product.pricing.raw.minimumorderquantity}}</p>
                                                <div>
                                                </div>
                                                <div class="luckme-worthy-price" id="priceListing" >
                                                    <div *ngIf="fields[3].visibility && product.product_type!='Variable'">₹ {{metaDataMapService.findData(
                                                    product,fields[3].nestedinfo)}}</div>
        
                                                    <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">₹ {{product.pricing.raw.price_range
                                                        ? product.pricing.raw.price_range:
                                                        product.pricing.raw.price}}</div>
        
                                                </div>
                                               
                                            </div>
                                            <div class="shubhcards-cart-bt">
                                                <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)" style=" background-color: #150985 !important;">Add to Cart</button>
                                                <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary shubhcards-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                <div class="btn btn-primary inq-btn-st ml-1 viewCart" style=" width: 70%;display: inline-block;"     *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                     
                                        
                                                    <span>
                                                        <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                            <span class="inq-sp-2">
                                                                style="color: white;">{{cart.quantity}}</span>
                                                            <span> 
                                                
                                                        <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                               </div>
                                               </div>
                                                
                                            </div>
                                         </div>
                                       
                                    </div> -->
                                            <!-- <div class="product-list-cards" style="color: lightgray;"> -->
                                                <div class="product-list-cards1" style="color: lightgray;">
                                              
                                                <div style="text-align: center;">
                                                    <!-- <div> -->
                                                    <!------------tams img------>
                                                    <img [src]="product.assets[0].image_source" id="img-list"
                                                                        (click)="productDetails(product.id)"
                                                                        onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                                        alt="{{ product.name}}" class="product-list-image1">
                                                </div>
                                                <div class="product-list-description1" id="img-list"
                                                (mouseenter)="mouseEnter(product.id) "
                                                (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                                ngbPopover="{{metaDataMapService.findData(product,
                                                        fields[1].nestedinfo)}}" container="body"
                                                *ngIf="fields[1].visibility">
                                                    <div class="d-flex justify-content-between">
                                                        <!-- <div> -->
                                                        <div>
                                                            <p  (click)="productDetails(product.id)" class="product-list-tile1">{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</p>
                                                        </div>
                                                        <!-- <div> -->
                                                               <!-- <p [class]="CONFIG.title=='Deera' ? 'product-list-price' : 'mayan-product-list-price'">₹{{product.pricing.raw.price}}.00</p> -->
                                                        <!-- </div> -->
                                                    </div>

                                                    <div class="d-flex justify-content-between mt-1" >
                                                        
                                                        <div> 
                                                               <p *ngIf="showPricekey==='Sale Price'" class="product-list-price1">₹{{product.pricing.raw.price}}<span *ngIf="priceStrikeOutInListScreen==='true' && strikeOutPriceKey==='MSRP'" style="font-size: 14px !important;margin-left:5px !important;text-decoration: line-through;color: grey!important;">₹{{product.pricing.raw.msrp}}</span></p>
                                                         </div> 
                                                         <div class="inner-star-icon pd-1">
                                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                            <!-- <a  routerLink="/product-review/{{product.id}}" style="color:#000000 !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a> -->
                
                                                        </div>
                                                    </div>
                                                   
                
                                                    <!---------------------reviews---------------->
                                                    <div class="d-flex justify-content-between">
                                                  
                                                        <!-- <div> -->
                                                            
                
                                                    <!-------------Add to cart-->
                                                    <button class="cartButton" style="font-size: small;font-weight: bold;width:45%;margin-left:25%;margin-top:4px;background-color: transparent !important;border:3px solid #3c707c !important;color: #091214 !important;background-color:grey;"
                                                    (click)="addToCart(product)"
                                                   *ngIf="((product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  ))">ADD TO CART
                                                   <span> <i class="fa fa-shopping-cart"></i></span></button>
                                                 
                                               <!---------view cart button-->
                                               <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                               <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                               <!-- <div [class]="CONFIG.title=='Deera'?'viewCart':'mayanviewCart'"   style="font-size: small;width:130%;margin-top:4px;margin-left:104%;font-weight: bold;padding-left:4px;padding-right:4px;text-align: center;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" > -->
                                                <div [class]="CONFIG.title=='Deera'?'viewCart':'mayanviewCart'"   style="font-size: small;font-weight: bold;width:146%;margin-left:99%;margin-top:4px;text-align: center;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >

                
                                                   <span>
                                                       <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                           style="font-size:12px;cursor:pointer;"></i></span>
                                                   <span class="inq-sp-2"
                                                       style="color: white;">{{cart.quantity}}</span>
                                                   <span>
                                                       <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                           style="font-size:12px;cursor:pointer;"></i></span>
                                              </div>
                
                                               </div>
                                               </div>
                
                
                                                        <!-- </div> -->
                                                    </div>
                
                                                   
                                                </div>
                                            </div>
                                         
                                </div>
                          
                            </div>
                        </div>
                    </div>
                    <div *ngIf="totalLength < 1">
                        <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                    </div>
                </div>
                <pagination-controls *ngIf="totalLength>0" class="my-pagination" (pageChange)="pageChange($event)"
                    style="float: right;" id="pagination-controls"></pagination-controls>
                
                   
            </div>
            </div>


            <div class="col-md-9" *ngIf="CONFIG.title === 'BBold'" style="padding-right: 0px !important;padding-left: 0px !important;">
                <div>
                    <div class="inq-menu-right-st">
                        <h2 class="text-primary" id="productListHead">Products</h2>
                        <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                            <span><i class="px-2">/</i> Products {{ category }} </span>
                            <span> </span>
                        </div>
                        <div *ngIf="totalLength>0" class="thumb1">
                            <div *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                                <div class="row inq-pro-sec-st listProduct">
                                    <div *ngIf="CONFIG.title === 'BBold'">
                                        <!-- <div class="luckme-card">
                                            <div class="luckme-image">
                                                <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                    <img [src]="product.assets[0].image_source" id="img-list"
                                                        (click)="productDetails(product.id)"
                                                        onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                        alt="{{ product.name}}" class="product-image">
                                                    <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                        <img src="assets/images/No_Image_old.png"  id="img-list"
                                                            (click)="productDetails(product.id)" class="product-image">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="luckme-description text-center productTitleList" style="display:table-caption" >
                                                <div class="product-description luckme-product-title"  id="img-list"
                                                (mouseenter)="mouseEnter(product.id) "
                                                (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                                ngbPopover="{{metaDataMapService.findData(product,
                                                        fields[1].nestedinfo)}}" container="body"
                                                *ngIf="fields[1].visibility">
                                                    <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                                    <p style="color: #373b3f">MOQ : {{product.pricing.raw.minimumorderquantity}}</p>
                                                    <div>
                                                    </div>
                                                    <div class="luckme-worthy-price" id="priceListing" >
                                                        <div *ngIf="fields[3].visibility && product.product_type!='Variable'">₹ {{metaDataMapService.findData(
                                                        product,fields[3].nestedinfo)}}</div>
            
                                                        <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">₹ {{product.pricing.raw.price_range
                                                            ? product.pricing.raw.price_range:
                                                            product.pricing.raw.price}}</div>
            
                                                    </div>
                                                   
                                                </div>
                                                <div class="shubhcards-cart-bt">
                                                    <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)" style=" background-color: #150985 !important;">Add to Cart</button>
                                                    <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary shubhcards-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                    <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                    <div class="btn btn-primary inq-btn-st ml-1 viewCart" style=" width: 70%;display: inline-block;"     *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                         
                                            
                                                        <span>
                                                            <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                                style="font-size:12px;cursor:pointer;"></i></span>
                                                                <span class="inq-sp-2">
                                                                    style="color: white;">{{cart.quantity}}</span>
                                                                <span> 
                                                    
                                                            <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                                style="font-size:12px;cursor:pointer;"></i></span>
                                                   </div>
                                                   </div>
                                                    
                                                </div>
                                             </div>
                                           
                                        </div> -->
                                                <!-- <div class="product-list-cards" style="color: lightgray;"> -->
                                                    <div class="bbold-product-list-cards" style="color: lightgray;">
                                                  
                                                    <div style="text-align: center;">
                                                        <!-- <div> -->
                                                        <!------------tams img------>
                                                        <img [src]="product.assets[0].image_source" id="img-list"
                                                                            (click)="productDetails(product.id)"
                                                                            onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                                            alt="{{ product.name}}" class="product-list-image1">
                                                    </div>
                                                    <div class="product-list-description1" id="img-list"
                                                    (mouseenter)="mouseEnter(product.id) "
                                                    (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                                     container="body"
                                                    *ngIf="fields[1].visibility">
                                                        <div class="d-flex justify-content-between">
                                                            <!-- <div> -->
                                                            <div>
                                                                <p  (click)="productDetails(product.id)" class="bbold-product-list-tile">{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</p>
                                                            </div>
                                                            <!-- <div> -->
                                                                   <!-- <p [class]="CONFIG.title=='Deera' ? 'product-list-price' : 'mayan-product-list-price'">₹{{product.pricing.raw.price}}.00</p> -->
                                                            <!-- </div> -->
                                                        </div>
    
                                                        <div class="d-flex justify-content-between mt-1" >
                                                            
                                                            <div> 
                                                                   <p class="bbold-product-list-price">₹{{product.pricing.raw.price}}</p>
                                                             </div> 
                                                             <div class="inner-star-icon pd-1">
                                                                <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                                <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                                <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                                <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                                <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'" style="font-size:15px!important;color: orange!important;"></span>
                                                                <!-- <a  routerLink="/product-review/{{product.id}}" style="color:#000000 !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a> -->
                    
                                                            </div>
                                                        </div>
                                                       
                    
                                                        <!---------------------reviews---------------->
                                                        <div class="d-flex justify-content-between">
                                                      
                                                            <!-- <div> -->
                                                                
                    
                                                        <!-------------Add to cart-->
                                                        <button class="BboldcartButton" 
                                                        (click)="addToCart(product)"
                                                       *ngIf="((product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  ))">ADD TO CART
                                                       <span> <i class="fa fa-shopping-cart"></i></span></button>
                                                     
                                                   <!---------view cart button-->
                                                   <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                   <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                   <!-- <div [class]="CONFIG.title=='Deera'?'viewCart':'mayanviewCart'"   style="font-size: small;width:130%;margin-top:4px;margin-left:104%;font-weight: bold;padding-left:4px;padding-right:4px;text-align: center;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" > -->
                                                    <div [class]="CONFIG.title=='BBold'?'bboldviewCart':'viewCart'"   style="font-size: small;font-weight: bold;text-align: center; justify-content: center;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
    
                    
                                                       <span>
                                                           <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                               style="font-size:12px;cursor:pointer;"></i></span>
                                                       <span class="inq-sp-2"
                                                           style="color: white;">{{cart.quantity}}</span>
                                                       <span>
                                                           <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                               style="font-size:12px;cursor:pointer;"></i></span>
                                                  </div>
                    
                                                   </div>
                                                   </div>
                    
                    
                                                            <!-- </div> -->
                                                        </div>
                    
                                                       
                                                    </div>
                                                </div>
                                             
                                    </div>
                              
                                </div>
                            </div>
                        </div>
                        <div *ngIf="totalLength < 1">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                        </div>
                    </div>
                    <pagination-controls *ngIf="totalLength>0" class="my-pagination" (pageChange)="pageChange($event)"
                        style="float: right;" id="pagination-controls"></pagination-controls>
                    
                       
                </div>
                </div>
        <!-- <div class="col-md-9" *ngIf="CONFIG.title === 'BombayHardware'" style="padding-right: 0px !important;padding-left: 0px !important;">
            <div style="font-family: 'proxima-regular','sans-serif' !important;">
                <h2 class="text-primary" id="productListHead">Products</h2>
                <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                    <span><i class="px-2">/</i> Products</span> 
                </div>
                <div *ngIf="totalLength>0">
                    <div class="row inq-pro-sec-st listProduct" style="margin-right: 0px !important;padding-right: 0px !important;">
                        <div *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                            <div id="bombayhardware-listing" >
                                <div id="bombayhardwarecards" style="cursor: auto;">
                                    <div class="bombayhardware text-center">
                                        <div class="bombayhardware-img-holder" style="cursor: pointer;" (click)="productDetails(product.id)">
                                            <div class="bombayhardware-img text-center">
                                                <img style="height: 100px !important;width: 100px !important;" [src]="product.assets[0].image_source">
                                            </div>
                                        </div>
                                        <div class="bombayhardware-description-holder">
                                            <div class="bombayhardware-title" style="font-size: 18px;">{{product.name | titlecase}}</div>
                                            <div class="bombayhardware-subtitle">{{product.categories[0].categories}}</div>
                                          
                                            <div class="tab-content">
                                                <div class="bombayhardware-profile tab-pane fade show active" id="profile{{i}}">
                                                    <div class="row">
                                                        <div class="col-sm-3 profile-title">SKU:</div>
                                                        <div class="col-sm-9 profile-description">{{product.sku ? product.sku : '-'}}</div>
                                                    </div>
                                                    <div class="row" style="padding-top: 5px;">
                                                        <div class="col-sm-3 profile-title">Brand:</div>
                                                        <div class="col-sm-9 profile-description">{{product.brand.name ? product.brand.name : '-'}}</div>
                                                    </div>
                                                    <div class="row" style="padding-top: 5px;">
                                                        <div class="col-sm-3 profile-title">Description:</div>
                                                        <div class="col-sm-9 profile-description"></div>
                                                    </div>
                                                </div>
                                
                                            </div>
                                            <div class="bombayhardware-submit-btn">
                                                <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)">Add to Cart</button>
                                                <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary earthy-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                     
                                        
                                                    <span>
                                                        <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                    <span class="inq-sp-2"
                                                        style="color: white;">{{cart.quantity}}</span>
                                                    <span>
                                                        <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                               </div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="totalLength < 1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                </div>
                <pagination-controls *ngIf="totalLength>0 && CONFIG.title === 'BombayHardware'" class="my-pagination" (pageChange)="pageChange($event)"
                    style="float: right;" id="pagination-controls"></pagination-controls>
            </div>
        </div> -->

        
        <div class="col-md-9" *ngIf="CONFIG.title === 'BombayHardware'" style="padding-right: 0px !important;padding-left: 0px !important;">
            <div style="font-family: 'proxima-regular','sans-serif' !important;">
                <h2 class="text-primary" style="color: #000!important" id="productListHead">Products</h2>
                <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                    <span><i class="px-2">/</i> Products</span> 
                </div>
                <div *ngIf="totalLength>0">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="collection-product-wrapper" id="products">
                            <div class="col-grid-box" *ngFor="let product of filterItemsOfType() |  paginate: { id:'pagination-controls',itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                                <div class="product-box">
                         
                            <div class="product-box-inner" >
  
                                <div class="img-wrapper" style="width:150px !Important">
                                  
                                  <div class="front">
                                    <a (click)="productDetails(product.id)">
                                      <!-- <img src="assets/images/urn_aaid_sc_US_02039d96-010f-462d-b486-65a7bdf1d33a-.png" class="img-fluid product-image" *ngIf="product.assets.image_source=='' || product.assets.image_source==undefined || product.assets.image_source==null">  -->
                                      <div *ngIf="product.assets.length==0 || !product.assets[0].image_source">
                                        <img src="assets/images/product-noimage.jpeg" class="img-fluid product-image" style="height: 150px!important;object-fit: contain!important;">
                                    </div>
                                    <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                      <img [src]="product.assets[0].image_source" style="height: 150px!important;object-fit: contain!important;" class="img-fluid product-image" alt="{{ product.name}}"> 
                                    </div>
                                       
                                      
                                  </a>
                                  </div>
                                 
                                  
                              
                                </div>
                                <div class="product-detail">
                                  <div >
                                    <!-- <h5 class="sku" style="font-size: 13px;">{{product.sku?.toString() | uppercase}}</h5> -->
                                    <a (click)="productDetails(product.id)">
                                      <h2 style="font-size: 16px;font-weight: 600;color:#212529">{{ product.name | titlecase }}</h2>
                                      <h6 class="brand" style="font-size: 13px;">{{product.brand.name ? product.brand.name : '-'}}</h6>
                                      <h6 class="product-name" style="font-size: 13px;">{{product.sku ? product.sku : '-'}}</h6>
                                    </a>
                                    
                                <!-- <div class="price-wrapper">
                                    <h4 class="product-price">
                                        <span class="price">&#8377;{{ product?.pricing.raw.price }}</span>
                                        <span class="tax-includes">Includes of taxes</span>
                                    </h4>
                                </div> -->
                                
                                <div class="add-to">
                                    <!-- <button class="btn btn-solid" (click)="addToCart(product)" style="font-size: 14px;"><i class="fa-shopping-cart fa"></i>&nbsp; Add to Cart</button> -->
                                    <div class="bombayhardware-submit-btn">
                                                                  <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)">Add to Cart</button>
                                                                  <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary earthy-btn" (click)='productDetails(product.id)'>Select Options</button>
                                                                  <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                                                  <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                                       
                                                          
                                                                      <span>
                                                                          <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                                              style="font-size:12px;cursor:pointer;"></i></span>
                                                                      <span class="inq-sp-2"
                                                                          style="color: white;">{{cart.quantity}}</span>
                                                                      <span>
                                                                          <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                                              style="font-size:12px;cursor:pointer;"></i></span>
                                                                 </div>
                                                                 </div>               
                                                                 </div>
                                </div>
                                
                                    
                                  </div>
                                  
                                </div>
                                <div class="verified" >
                                 
                                  <span class="isverified" *ngIf="product.stock_status == 'Available'" style="font-size: 14px;color: #212529!important;"><i class="fa fa-check-circle" style="font-size:20px;color:#04AA6D"></i>In Stock</span>
                                  <span class="isverified" *ngIf="product.stock_status != 'Available' && product.stock_status != ''" style="font-size: 14px;color: #212529!important;"><i class="fa fa-times" style="font-size:20px;color:#FF0000"></i>Out Of Stock</span>
                                  
                                  
                               </div>
                                
                              </div>
                         </div>     
                        </div>
                    </div>
                   </div>
                        </div>
                </div>
                <div *ngIf="totalLength < 1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                </div>
                <pagination-controls *ngIf="totalLength>0 && CONFIG.title === 'BombayHardware'" class="my-pagination" (pageChange)="pageChange($event)"
                    style="float: right;" id="pagination-controls"></pagination-controls>
            </div>
        </div>
    </section>
</div>
<!-- content -->


<button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
    Launch demo modal
</button>
<div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 640px !important;position: relative !important;bottom: -30px !important; -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;-moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important; -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;">
        <div class="modal-content status-model-container">
            <div class="modal-body" style="border: none !important;">
                <div class="model-content m-content">
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container">
                        <div class="m-title">Enquiry Form</div>
                    </div>
                    <form [formGroup]="enquiryForm" class="gift-form" novalidate>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="name" class="form-label">Name <span class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="name"
                                    [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                                    <div class="invalid-feedback" *ngIf="f.name.errors">
                                        <div *ngIf="f.name.errors.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="f.name.errors.maxlength">
                                            Name can't be more than 80 characters long.
                                          </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="content" class="form-label">Email<span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" class="form-control" id="email"
                                    [class.is-invalid]="(f.email.invalid && validateForm)"
                                    formControlName="email" maxlength="50" email>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Seneder Email can't be more than 50 characters long.
                                    </div>
                                    <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="phone_number" class="form-label">Mobile<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="phone_number"
                                    [class.is-invalid]="f.phone_number.invalid && validateForm" formControlName="phone_number"
                                    required>
                                <div class="invalid-feedback" *ngIf="f.phone_number.errors">
                                    <div *ngIf="f.phone_number.errors.required">
                                        Mobile Number is required.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.maxlength">
                                        Mobile Number can't be more than 15 characters long.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.pattern">
                                        Mobile Number is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="category" class="form-label">Category<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="category"
                                    [class.is-invalid]="f.category.invalid && validateForm" formControlName="category"
                                    required readonly>
                                    <div class="invalid-feedback" *ngIf="f.category.errors">
                                        <div *ngIf="f.category.errors.required">
                                            Category is required.
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="category" class="form-label">Message<span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <textarea class="form-control" id="message"
                                    [class.is-invalid]="f.message.invalid && validateForm" formControlName="message"
                                    required rows="5"></textarea>
                                    <div class="invalid-feedback" *ngIf="f.message.errors">
                                        <div *ngIf="f.message.errors.required">
                                            Message is required.
                                        </div>
                                        <div *ngIf="f.message.errors.maxlength">
                                            Message can't be more than 600 characters long.
                                          </div>
                                    </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 45px;">
                            <button class="btn btn-primary" style="border-radius: 5px !important; font-size: 1rem; padding: 5px 25px;margin-top: 5px;float: right;" (click)="saveForm()">SUBMIT</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



