import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { HomeService} from '../home/home.service';
import {ORG_DETAILS} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private localService: LocalStorageService,
    private appService: AppService,
    private homeService: HomeService
  ) { }

  addToCart(product:any){
    var quantity;
    if(ORG_DETAILS.NAME==='ShubhCards')
    {
      var moq;
      if(product.pricing.raw.minimumorderquantity==null)
      {
        moq=1;
      }
      else
      {
        moq=product.pricing.raw.minimumorderquantity;
      }
      quantity =moq;
    }
    else{
      quantity=1;
    }
    let produstData = {
      product_id: product.id,
      product_name: product.name,
      sku: product.sku,
      brand: product.brand.name,
      quantity: quantity,
      cost : product.pricing.raw.price,
      sub_total : product.pricing.raw.price,
      category : "",
      category_id : "",
      product: product
    }

    if(product.categories.length > 0)
    {
      produstData.category = product.categories[0].categories;
      produstData.category_id = product.categories[0].id;
    }
    else{
      produstData.category = product.parentcategories[0].name;
    }

    if(product.assets.length > 0){
      produstData["image_source"] = product.assets[0].image_source;
    }
    if(this.localService.get("cart")){
      let cartItems = JSON.parse(this.localService.get("cart"));
      let productInCart = false;
      cartItems.forEach(element => {
        if(element.product_id === produstData.product_id){
          productInCart = true;
        }
      });

      if(!productInCart){
        cartItems.push(produstData);
      }

      let cartItemsString = JSON.stringify(cartItems);
      this.localService.set("cart", cartItemsString);
    }
    else{
      this.localService.set("cart", JSON.stringify([produstData]));
    }
    this.appService.cartProducts.results = JSON.parse(this.localService.get('cart'));
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    this.updateCartDetails();
  }

  removeFromCart(product_id:any){
    let cartItems = JSON.parse(this.localService.get("cart"));
    let tempCartItems = cartItems;
    cartItems.forEach(function (element, index) {
      if(element.product_id === product_id){
        tempCartItems.splice(index, 1);
      }
    });
    this.localService.set("cart", JSON.stringify(tempCartItems));
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    this.updateCartDetails();
  }

  updateCartItem(cart:any){
    
    this.localService.set("cart", JSON.stringify(cart));
    this.appService.totalCartItems = this.totalCartItems();
    //THIS IS LIKE REASSIGN BCZ IN CHECKOUT PAGA ALSO USING SAME FUNCTION
    this.appService.cartProducts.results = cart;
    this.homeService.updateAdminFormEvent('cartUpdate');
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    this.updateCartDetails();
  }

  totalCartItems(){
    let totalItems = 0
    if(this.localService.get("cart")){

      if(this.localService.get('cart_icon_option'))
      {
        
        if(this.localService.get('cart_icon_option') === "Show Total Quantity")
        {
          let cartItems = JSON.parse(this.localService.get("cart"));
          cartItems.forEach(element => {
            totalItems = totalItems + element.quantity;
          });
        }
        else
        {
          totalItems = this.appService.cartProducts.results.length;
        }
      }
      else
        {
          totalItems = this.appService.cartProducts.results.length;
        }
    }
    

    return totalItems;
  }

  clearCart(){
    this.localService.remove("cart");
    this.appService.totalCartItems = 0;
    this.calculateSubTotal();
  }

  calculateSubTotal()
  {
    let subTotal = 0;
    if(this.localService.get("cart")){
      let cartItems = JSON.parse(this.localService.get("cart"));
          cartItems.forEach(element => {
            
            subTotal = subTotal + Number(element.cost) * Number(element.quantity);
          
          });
    }
    this.appService.cartSubTotal = subTotal;
  }

  //UPDATE CART IN DATABASE
  updateCartDetails()
  {
    if(this.localService.get('cart') !== null && this.localService.get('session_id') !== null)
    {
      
      let cartItems = JSON.parse(this.localService.get("cart"));
      let session_id = this.localService.get("session_id");

      if(session_id !== "")
      {
        let sub_total = 0
        let total_quantity = 0;

        cartItems.forEach(element => {
          sub_total += Number(element.quantity) * Number(element.cost)
          total_quantity += Number(element.quantity)
        });

        let cart_data = {products : cartItems,session_id : session_id,status : 'active',total_quantity : total_quantity,total_items : cartItems.length,sub_total : sub_total, userId: ""}

        if(this.localService.get("UserId"))
        {
          
          cart_data.userId = this.localService.get("UserId");
          
        }

        this.appService.productIdinCart = [];
        this.appService.cartProducts.results.forEach((element)=>
        {
          this.appService.productIdinCart.push(element.product_id);
        })

        let formDataJson = {domain_name : ORG_DETAILS.DOMAIN_NAME,
        user_id : ORG_DETAILS.USER_ID,
        payload : {cart_creation : cart_data}
        }

        this.appService.sendCartDetails(formDataJson).subscribe(resp=>
          {

          },err=>
          {
            
          })
      }
    }
  }

}
