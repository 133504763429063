import { Injectable } from '@angular/core';

import { CustomerLoginStorageService } from './checkout-login/customer-login-storage.service';
import { CheckoutApiService } from './checkout-api.service';
import { CheckoutLocalStorageService } from './checkout-local-storage.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { ENDPOINTS } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutInitialdataService {

  customerData = {
    type:"",
    userid:"",
    userName:"",
    mobileNo:"",
    subscribed:false
  }

  cartProducts:any;

  INDIVISUAL_USER = "INDIVIDUAL CUSTOMER";
  BUSINESS_USER = "BUSINESS CUSTOMER";

  constructor(
    private customerStorageService: CustomerLoginStorageService,
    private checkoutApiService: CheckoutApiService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private localStorageService: LocalStorageService
  ) { }

  // GET CUSTOMER DETAILS
    getCustomerDetails(){
      if(this.customerStorageService.getCustomerData() !== null){
        return this.customerData = this.customerStorageService.getCustomerData()
      }
      else{
        return this.customerData
      }
    }

  // GET CHECKOUT FLOW
    getCheckoutFlow(userType){
      return new Promise((resolve, reject) =>{
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.CHECKOUT_FLOW).then(res =>
          {
            let resp:any = res;
            if(resp.success){
              let respData = resp.data;
              if(respData.length > 0){
                respData.forEach(element => {
                  if(userType === this.INDIVISUAL_USER && element.name.toUpperCase() === "INDIVIDUAL CUSTOMER"){
                    resolve(element.steps);
                  }
                  else if(userType === this.BUSINESS_USER &&element.name.toUpperCase() === "BUSINESS CUSTOMER"){
                    resolve(element.steps);
                  }
                });
              }
            }
            else{
              resolve("")
            }
          }
        )
      }
      )
    }
  
  // GET CART ITEMS
    getCartItems(){
      return new Promise((resolve, reject) =>{
        if(this.checkoutLocalStorageService.getLocalCartItems()){
          this.cartProducts = this.checkoutLocalStorageService.getLocalCartItems()
          resolve(true);
        }
        else{
          resolve(false);
        }
      })
    }

  // GET ORDER AMOUNT AND QUANTITY VALIDATIONS
  getOrderValidations(){
    return new Promise ((resolve, reject) => {
      let orderValidationData = {
        minOrderAmount:0,
        maxOrderQuantity:0,
        minOrderQuantity:0
      }
      if(this.localStorageService.get('min_order_amt')){
        orderValidationData.minOrderAmount = Number(this.localStorageService.get('min_order_amt'))
      }
      if(this.localStorageService.get('max_cart_qty')){
        orderValidationData.maxOrderQuantity = Number(this.localStorageService.get('max_cart_qty'))
      }
      if(this.localStorageService.get('min_order_qty')){
        orderValidationData.minOrderQuantity = Number(this.localStorageService.get('min_order_qty'))
      }
      resolve(orderValidationData);
    })
  }
}
