import { Component, OnInit, Input,SimpleChanges,HostListener } from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params,NavigationExtras } from '@angular/router';

// import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { ProductFilterService } from './product-filter.service';
import { ProductListingService } from '../product-listing/product-listing.service';
import { LocalStorageService } from '../auth-service/local-storage.service'
import { LoaderService } from '../shared/loader/loader.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html'
})
export class ProductFilterComponent implements OnInit {

  public value;
  // active = 'dontcheck';
  active = 'dontcheck';
  active1 = '';
  public  allData:any;
  public facet:any;
  public day90;
  public day60;
  public outOfStock;
  stockDetails:any=[];
  isChecked =true;
  public values1;
  public values2;
  public values3;
   intialVal:boolean = true;
    public categoryName;
    public categoryName1;
    public categoryName2;
    public categoryName3;
    public categoryName4;
    public subCategory;
    allCategories: any;
    testValue;
    categoryname;
    public categoryname1;
    public parentCategory;


// categories:any = 
// [
//               {
//                   "name": "category1",
//                   "subcategories": [
//                       {
//                           "name": "subcategory1",
//                           "subcategories": [
//                               {
//                                   "name": "subcategory1.2",
//                                   "subcategories": [
//                                     {
//                                         "name": "subcategory1.2.1",
//                                         "subcategories": [
//                                           {
//                                               "name": "subcategory1.2.1.1" ,
//                                               "subcategories": [
//                                                 {
//                                                     "name": "subcategory1.2.1.1.1",                                               
//                                                 }
//                                               ] 
                                                                                            
//                                           }
//                                         ]
//                                     }
//                                   ]
//                               }
//                           ]
//                       },
//                       {
//                           "name": "subcategory2"
//                       }
//                   ]
//               },
//               {
//                   "name": "category2"
//               },
//               {
//                   "name": "category3"
//               }
//           ]
  @Input() filters: any;
  @Input() themes: any;
  @Input() finish: any;
  @Input() features: any;
  @Input() prices: any;
  @Input() priceRange: any;
  @Input() size: any;
  @Input() thickness: any;
  @Input() color: any;
  @Input() type: any;
  @Input() material: any;
  @Input() categoriesList: any;
  @Input() categoryValue: any;
  @Input() days: any;
  @Input() configTitle: any;
  _categoriesList: any;
// get categoriesList(): any {
//     return this._categoriesList;
// }
// @Input() set categoriesList(value: any) {
//   if(value.length>0)
//   {
//     this.categoriesList=value;
//   }
 
   
// }

ngAfterViewInit() {
  //get your element here
}

ngAfterViewChecked(){
  if(this.categoriesList.length > 0){
          this.categoriesList.forEach(element => {
            let a = document.getElementById(element.name)as HTMLInputElement
            // if(this.configTitle!=="BombayHardware"){
            //   a.classList.remove('active')
            // }
            if(this.configTitle!=="Aiema"){
            a.classList.remove('active')
            }
            //  a.classList.remove('active')
          });
          
        }
        if(this.themesarr.length > 0){
          this.themesarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        
        if(this.pricesarr.length > 0){
          this.pricesarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.featuresarr.length > 0){
          this.featuresarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.finisharr.length > 0){
          this.finisharr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }  
        if(this.sizearr.length > 0){
          this.sizearr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.thicknessarr.length > 0){
          this.thicknessarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.colorarr.length > 0){
          this.colorarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.typearr.length > 0){
          this.typearr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        if(this.materialarr.length > 0){
          this.materialarr.forEach(element => {
            let a = document.getElementById(element)as HTMLInputElement
            if(a.checked){
              a.checked = false;
            }
          });
          
        }
        this.themesarr=[];
        this.pricesarr=[];
        this.finisharr=[];
        this.featuresarr=[];
        this.sizearr=[];
        this.thicknessarr=[];
        this.colorarr=[];
        this.typearr=[];
        this.materialarr=[];
  var queryparams;
  this.route.queryParamMap.subscribe(params => queryparams = params.getAll('terms'));
  if(queryparams[1]!='')
  {
    this.cate=queryparams[1];
    this.categoryname=queryparams[1];
  }
  
  //category
//  this.localService.set('is_category_filter','true');
//   this.localService.set('category_filter_name','Floral');
// if(this.configTitle==="BombayHardware"){
//   let c = document.getElementById(queryparams[1])as HTMLInputElement
//   console.log("ccccc"+c);
//   if(c!=undefined)
//   {
//     c.classList.add('active')
//   }
// } else {
  console.log("queryparams[1]"+queryparams[1]);

  let b = document.getElementById(queryparams[1])as HTMLInputElement
console.log("bbbbbbb"+b);
if(b!=undefined)
{
  b.classList.add('active')
}
// }

if(this.configTitle!=='Aiema'){
  let isthemefilter=false
  queryparams[3].forEach(element=>{
    let a = document.getElementById(element)as HTMLInputElement
    if(a!=undefined)
  {
  
  
  a.checked=true;
  isthemefilter=true;
  if ( this.themesarr.includes(element) === false) this.themesarr.push(element);
  //this.localService.set('is_theme_filter','false')
  
  }
  })
  if(isthemefilter)
  {
    let c = document.getElementById('collapseThree')as HTMLInputElement
 c.classList.remove('collapse')
  }
 let isfinishfilter=false;
  queryparams[4].forEach(element=>{
    let a = document.getElementById(element)as HTMLInputElement
    if(a!=undefined)
  {
  
  
  a.checked=true;
   isfinishfilter=true;
  if ( this.finisharr.includes(element) === false) this.finisharr.push(element);
  //this.localService.set('is_theme_filter','false')
  
  }
  })
  if(isfinishfilter)
  {
    let c = document.getElementById('collapseFour')as HTMLInputElement
 c.classList.remove('collapse')
  }
  let isfeaturefilter=false;
 
  queryparams[5].forEach(element=>{
    let a = document.getElementById(element)as HTMLInputElement
    if(a!=undefined)
  {
  
     isfeaturefilter=true;
  a.checked=true;
  if ( this.featuresarr.includes(element) === false) this.featuresarr.push(element);
  //this.localService.set('is_theme_filter','false')
  
  }
  })
  if(isfeaturefilter)
  {
    let c = document.getElementById('collapseFive')as HTMLInputElement
 c.classList.remove('collapse')
  }
  let ispricefilter=false;
  queryparams[6].forEach(element=>{
    let a = document.getElementById(element)as HTMLInputElement
    if(a!=undefined)
  {
  
   ispricefilter=true;
  a.checked=true;
  if ( this.pricesarr.includes(element) === false) this.pricesarr.push(element);
  //this.localService.set('is_theme_filter','false')
  
  }
  })
  if(ispricefilter)
  {
    let c = document.getElementById('collapseSix')as HTMLInputElement
 c.classList.remove('collapse')
  }

  let issizefilter=false;

  if(this.configTitle!=='ShubhCards'){
    queryparams[7].forEach(element=>{
      let a = document.getElementById(element)as HTMLInputElement
      if(a!=undefined)
    {
    
       issizefilter=true;
    a.checked=true;
    if ( this.sizearr.includes(element) === false) this.sizearr.push(element);
    //this.localService.set('is_theme_filter','false')
    
    }
    })
    if(issizefilter)
    {
      let c = document.getElementById('collapseSeven')as HTMLInputElement
   c.classList.remove('collapse')
    }
  }
  
  if(this.configTitle!=='ShubhCards' && this.configTitle!=='Deera'  && this.configTitle!=='BBold'){
    let isthicknessfilter=false;
   
    queryparams[8].forEach(element=>{
      let a = document.getElementById(element)as HTMLInputElement
      if(a!=undefined)
    {
    
      isthicknessfilter=true;
    a.checked=true;
    if ( this.thicknessarr.includes(element) === false) this.thicknessarr.push(element);
    //this.localService.set('is_theme_filter','false')
    
    }
    })
    if(isthicknessfilter)
    {
      let c = document.getElementById('collapseEight')as HTMLInputElement
   c.classList.remove('collapse')
    }

  }

  let iscolorfilter=false;
   
    queryparams[9].forEach(element=>{
      let a = document.getElementById(element)as HTMLInputElement
      if(a!=undefined)
    {
    
      iscolorfilter=true;
    a.checked=true;
    if ( this.colorarr.includes(element) === false) this.colorarr.push(element);
    //this.localService.set('is_theme_filter','false')
    
    }
    })
    if(iscolorfilter)
    {
      let c = document.getElementById('collapseNine')as HTMLInputElement
   c.classList.remove('collapse')
    }

    let istypefilter=false;
   
    queryparams[10].forEach(element=>{
      let a = document.getElementById(element)as HTMLInputElement
      if(a!=undefined)
    {
    
      istypefilter=true;
    a.checked=true;
    if ( this.typearr.includes(element) === false) this.typearr.push(element);
    //this.localService.set('is_theme_filter','false')
    
    }
    })
    if(istypefilter)
    {
      let c = document.getElementById('collapseTen')as HTMLInputElement
   c.classList.remove('collapse')
    }

    
  let ismaterialfilter=false;
   
  queryparams[11].forEach(element=>{
    let a = document.getElementById(element)as HTMLInputElement
    if(a!=undefined)
  {
  
    ismaterialfilter=true;
  a.checked=true;
  if ( this.materialarr.includes(element) === false) this.materialarr.push(element);
  //this.localService.set('is_theme_filter','false')
  
  }
  })
  if(ismaterialfilter)
  {
    let c = document.getElementById('collapseEleven')as HTMLInputElement
 c.classList.remove('collapse')
  }

}
  //this.them=queryparams[3];
 
//   let iscategory=this.localService.get('is_category_filter');
//   let istheme=this.localService.get('is_theme_filter');
//   let iscleardata=this.localService.get('is_clear_data');
//   if(iscleardata=='true')
//   {
//     this.cate=['']
//     this.localService.set('is_clear_data','false')
//     if(this.categoriesList.length > 0){
//       this.categoriesList.forEach(element => {
//         let a = document.getElementById(element.name)as HTMLInputElement
//         a.classList.remove('active')
//       });
      
//     }
//     if(this.themesarr.length > 0){
//       this.themesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
    
//     if(this.pricesarr.length > 0){
//       this.pricesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.featuresarr.length > 0){
//       this.featuresarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.finisharr.length > 0){
//       this.finisharr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }  
//     this.themesarr=[];
//     this.pricesarr=[];
//     this.finisharr=[];
//     this.featuresarr=[];
    
//   }
 
 
//   if(iscategory=='true')
//   {
    
    
//     if(this.categoriesList.length > 0){
//       this.categoriesList.forEach(element => {
//         let a = document.getElementById(element.name)as HTMLInputElement
//         a.classList.remove('active')
//       });
      
//     }
//     if(this.themesarr.length > 0){
//       this.themesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
    
//     if(this.pricesarr.length > 0){
//       this.pricesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.featuresarr.length > 0){
//       this.featuresarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.finisharr.length > 0){
//       this.finisharr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }  
//     this.themesarr=[];
//     this.pricesarr=[];
//     this.finisharr=[];
//     this.featuresarr=[];
    
//     let b = document.getElementById(this.categoryname)as HTMLInputElement
//     if(b!=undefined)
//     {
//       b.classList.remove('active')
//     }
//     this.categoryname=this.localService.get('category_filter_name');

//     this.cate=[this.categoryname]
//       let a = document.getElementById(this.categoryname)as HTMLInputElement
//   if(a!=undefined)
//   {
//     a.classList.add('active');
//     this.localService.set('is_category_filter','false')
//   }

    
    
    

//   }
//   if(istheme=='true')
//   {
// //          let c = document.getElementById('collapseThree')as HTMLInputElement
// // c.classList.remove('collapse')
// // c.classList.add('collapse show')
    
//     if(this.categoriesList.length > 0){
//       this.categoriesList.forEach(element => {
//         let a = document.getElementById(element.name)as HTMLInputElement
//         a.classList.remove('active')
//       });
      
//     }
//     if(this.themesarr.length > 0){
//       this.themesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
    
//     if(this.pricesarr.length > 0){
//       this.pricesarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.featuresarr.length > 0){
//       this.featuresarr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }
//     if(this.finisharr.length > 0){
//       this.finisharr.forEach(element => {
//         let a = document.getElementById(element)as HTMLInputElement
//         if(a.checked){
//           a.checked = false;
//         }
//       });
      
//     }  
//     this.themesarr=[];
//     this.pricesarr=[];
//     this.finisharr=[];
//     this.featuresarr=[];
    
//     let themename=this.localService.get('theme_filter_name');


//       //this.themesarr.push(facet.name);
//       let a = document.getElementById(themename)as HTMLInputElement
//       if(a!=undefined)
// {
  
  
//   a.checked=true;
//   this.themesarr.push(themename);
//  this.localService.set('is_theme_filter','false')

// }


//   }
 
}


  public total;
  public collapse: boolean = false;
  public checked: boolean = false;
  public tempArr: any = { "brands": [] };
  public brands:any = [];
  public stock;
  public cate = [];
  selectedIndex: number = null;
  subCategory1 =[];
  subCategory2=[];
  subCategory3=[];
  subCategory4=[];
  subCategory5=[];
  subCategory6=[];
  subCategory7=[];
  subCategory8=[];
  subCategory9=[];
  subCategory10=[];
  allCategories1: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private breadCrumbsService: BreadcrumbsService,
    private productFilterService: ProductFilterService,
    private listingService: ProductListingService,
    public appService:AppService,
    private localService: LocalStorageService,
    private loaderService: LoaderService
  ) { 
  //   alert(JSON.stringify(this.route.snapshot.queryParamMap))
  //   var queryparams;
  //   this.route.queryParamMap.subscribe(params => queryparams = params.getAll('terms'));
  //   this.loadcat=queryparams[1];
  //  this.checkedevt1('','Classic')
  //   this.loadthemes=queryparams[3];
  // this.localService.set('is_theme_filter','true');
  // this.localService.set('theme_filter_name','Indian Patterns')
  // let a = document.getElementById('Classic')as HTMLInputElement
  //       a.classList.add('active')
  }
  ngOnDestroy()
  {
   // alert(this.router.url)
    // if(this.route.snapshot.queryParamMap){
     
    //   alert(JSON.stringify(this.route.snapshot.queryParamMap))
    //   var queryparams;
    //   this.route.queryParamMap.subscribe(params => queryparams = params.getAll('terms'));

    //}
  }
  ngOnInit(): void {
    window.scrollTo(0,0);
    
    
      console.log("CATEGORIES", this.categoriesList)
      if (this.days=='yes') {
       this.values2=true;
      }
      console.log("configTitle"+this.configTitle);
      if(this.filters){
        this.total=this.filters.length;
        console.log("this.totalll"+this.total);
      }

      // this.route.queryParams.subscribe(params => {
      //   if (params.type==='combine') {
      //     console.log("this.categoryname1"+params.terms[1]);
      //     this.route.queryParamMap.subscribe(params => {
      //     this.categoryname1 = params.getAll('terms')[1];
      //     console.log("this.categoryname1"+this.categoryname1);
      //     // if(this.categoryname1 === 'GI'){
      //       if(params.getAll('terms')[1] === 'GI'){
      //       console.log("sucessss")
      //       this.parentCategory = 'Pipes';
      //      }
      //     this.getAllCategories();  
      //     });
      //   }
      // });

    if(this.configTitle==="BombayHardware"){
      this.route.queryParams.subscribe(params => {
        if (params.type==='combine') {
          console.log("params.terms[1]"+params.terms[1]);
          if(params.terms[1]== 'GI' || params.terms[1]== 'GP' || params.terms[1]== 'MS' || params.terms[1]== 'RHS&SHS' || params.terms[1]== 'Seamless' || params.terms[1]== 'SpiralWeilded'){
            console.log("sucessss")
            this.active = "";
            this.parentCategory = 'Pipes';
            this.getAllCategories(); 
          } 
          else if(params.terms[1]== 'UPVC' || params.terms[1]== 'CPVC' || params.terms[1]== 'PVC' || params.terms[1]== 'SWR'){
            this.active = "";
            this.parentCategory = 'Supreme Pipes';
            this.getAllCategories(); 
          } 
          else if(params.terms[1]== 'Flats' || params.terms[1]== 'Angles' || params.terms[1]== 'Pumps'){
            this.active = "";
            this.parentCategory = 'Other Categories';
            this.getAllCategories(); 
          }
        }
      });
    }
     
  //  if(this.filters.length > 0){
  //   this.filters.facets.forEach(element => {
  //     this.brands.push(element.name)
  //   })
  // }....

  // this.productFilterService.currentData.subscribe(currentData => {
  //   if(this.brands.length === 0){
  //     this.brands = currentData
  //   }        
  // })
  
     
  }

  getAllCategories() {
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "sort_order",
          "sortorder": +1
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
      resp => {
        if(resp.status_code === 200 && resp.success === 1){
          this.allCategories = resp.result.data;
          this.active = "";
          if(this.configTitle==="BombayHardware"){
            //  this.active = this.categoryname1
            // this.active = 'Pipes'
            
            if(this.allCategories.length > 1){
              //FIRST LEVEL SUBCATEGORY
              // this.active = this.categoryValue;
              // this.testValue = this.categoryValue;
              for(let i =0;i<this.allCategories.length;i++){
                // if(this.allCategories[i].name == 'Pipes'){
                  if(this.allCategories[i].name === this.parentCategory){
                    // if(this.allCategories[i].name === this.categoryname1){
                  this.categoryName='';
                  console.log("insid11111")
                  if(this.allCategories[i].subcategories!==undefined){
                    console.log("insid22222")
                    this.categoryName=this.allCategories[i].name;
                   
                    this.subCategory2 = this.subCategory1.concat(this.allCategories[i].subcategories);
                  }
                }
                //SECOND LEVEL SUBCATEGORY
                  // for(let i =0;i<this.subCategory2.length;i++){
                  //   if(this.subCategory2[i].name == name){
                  //      this.categoryName1='';
                  //      if(this.subCategory2[i].subcategories!==undefined){
                      
                  //       this.categoryName1=this.subCategory2[i].name;
                      
                  //       this.subCategory4 = this.subCategory3.concat(this.subCategory2[i].subcategories);
                  //     }
                  //   }
                    //THIRD LEVEL SUBCATEGORY
                    // for(let i =0;i<this.subCategory4.length;i++){
                    //   if(this.subCategory4[i].name == name){
                    //     this.categoryName2='';
                    //     if(this.subCategory4[i].subcategories!==undefined){
                        
                    //       this.categoryName2=this.subCategory4[i].name;
                    //       this.subCategory6 = this.subCategory5.concat(this.subCategory4[i].subcategories);
                    //     }
                    //   }
                       //FOURTH LEVEL SUBCATEGORY
                      // for(let i =0;i<this.subCategory6.length;i++){
                      //   if(this.subCategory6[i].name == name){
                      //     this.categoryName3='';
                      //     if(this.subCategory6[i].subcategories!==undefined){
                      //       this.categoryName3=this.subCategory6[i].name;
                      //       this.subCategory8 = this.subCategory7.concat(this.subCategory6[i].subcategories);
                      //     }
                      //   }
                        //FIFTH LEVEL SUBCATEGORY
                  //       for(let i =0;i<this.subCategory8.length;i++){
                  //         if(this.subCategory8[i].name == name){
                  //           this.categoryName4='';
                           
                  //             this.categoryName4=this.subCategory8[i].name;
                  //             this.subCategory10 = this.subCategory9.concat(this.subCategory8[i].subcategories);
                          
                  //         }
                  //       }
                  //     } 
    
                  //   }
                  // }
                  // }
                
              }
            }
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  appliedFilter(values:any,facet) {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      this.brands.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.brands.length;i++){
       if(this.brands[i]==facet.name){
        this.brands.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }
  themesarr=[];
  appliedFilterTheme(values:any,facet)  {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.themesarr.includes(facet.name) === false) this.themesarr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.themesarr.length;i++){
       if(this.themesarr[i]==facet.name){
        this.themesarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }
  finisharr=[];
  appliedFilterFinish(values:any,facet) {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.finisharr.includes(facet.name) === false) this.finisharr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.finisharr.length;i++){
       if(this.finisharr[i]==facet.name){
        this.finisharr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }
  featuresarr=[];
  appliedFilterFeature(values:any,facet) {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.featuresarr.includes(facet.name) === false) this.featuresarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.featuresarr.length;i++){
       if(this.featuresarr[i]==facet.name){
        this.featuresarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }
  pricesarr=[];
  appliedFilterPrice(values:any,facet) {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.pricesarr.includes(facet.name) === false) this.pricesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.pricesarr.length;i++){
       if(this.pricesarr[i]==facet.name){
        this.pricesarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  sizearr=[];
  appliedFilterSize(values:any,facet)  {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.sizearr.includes(facet.name) === false) this.sizearr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.sizearr.length;i++){
       if(this.sizearr[i]==facet.name){
        this.sizearr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  thicknessarr=[];
  appliedFilterThickness(values:any,facet)  {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.thicknessarr.includes(facet.name) === false) this.thicknessarr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.thicknessarr.length;i++){
       if(this.thicknessarr[i]==facet.name){
        this.thicknessarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  colorarr=[];
  appliedFilterColor(values:any,facet)  {
    console.log("colorrrrr"+values.currentTarget.checked);
    console.log("color"+facet.name);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.colorarr.includes(facet.name) === false) this.colorarr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.colorarr.length;i++){
       if(this.colorarr[i]==facet.name){
        this.colorarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  typearr=[];
  appliedFilterType(values:any,facet)  {
   
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.typearr.includes(facet.name) === false) this.typearr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.typearr.length;i++){
       if(this.typearr[i]==facet.name){
        this.typearr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  materialarr=[];
  appliedFilterMaterial(values:any,facet)  {
    this.value = values.currentTarget.checked;
    if(this.value==true){
      if ( this.materialarr.includes(facet.name) === false) this.materialarr.push(facet.name);
      //this.themesarr.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.materialarr.length;i++){
       if(this.materialarr[i]==facet.name){
        this.materialarr.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  onChange(values:any,name):void {
    console.log(values.currentTarget.checked);
    this.stock = values.currentTarget.checked;
    if(this.stock==true){
      if(name=='Last 90 days'){
        this.stockDetails.push(name);
        if(this.stockDetails.length > 1){
          for(let i =0;i<this.stockDetails.length;i++){
            if(this.stockDetails[i]=='Last 30 days'){
              this.stockDetails.splice(i,1)
            }
          }
        }
      }else if(name == 'Last 30 days'){
        this.stockDetails.push(name);
        if(this.stockDetails.length > 1){
          for(let i =0;i<this.stockDetails.length;i++){
            if(this.stockDetails[i]=='Last 90 days'){
              this.stockDetails.splice(i,1)
            }
          }
          // this.stockDetails.forEach(element => {
          //   if(element == 'Last 90 days')
          //   this.stockDetails.splice(element)
          // });
        }
      }else{
        this.stockDetails.push(name);
      }
      
      this.callListingScreen()
    }else{
      for(var i =0;i<this.stockDetails.length;i++){
        if(this.stockDetails[i]==name){
         this.stockDetails.splice(i,1);
        }
      }
      this.callListingScreen()
       
    }
   
    
  }
  

  openContent() {
    this.collapse = !this.collapse;
    this.checked = true;
  }


  updateFilter(event){

  }

  checkedevt(values:any, name){
    // console.log(values,name)
    // this.breadCrumbsService.updateAdminFormEvent(values);
    this.intialVal = false;
    this.active = name;
 
   this.cate=[name]
   console.log("in set data***"+this.cate);
   this.callListingScreen()
  }

  checkedevt1(values:any, name){
    // if(this.configTitle=== 'ShubhCards'){
    // let a = document.getElementById(name)as HTMLInputElement
    let b = document.getElementById(this.categoryname)as HTMLInputElement
    if(b!=undefined)
    {
      b.classList.remove('active')
    }

    // a.classList.add('active');
    // }
    // console.log(values,name)
    // this.breadCrumbsService.updateAdminFormEvent(values);
    this.intialVal = false;
    this.active = "dontcheck";
    this.active = name;
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
      resp => {
        this.allCategories1 = resp.result.data;
        this.subCategory = '';
        if(this.allCategories1.length > 1){
          //FIRST LEVEL SUBCATEGORY
          for(let i =0;i<this.allCategories1.length;i++){
            if(this.allCategories1[i].name == name){
              this.categoryName='';
              // for(let i =0;i<this.allCategories1[i].length;i++){
              if(this.allCategories1[i].subcategories!==undefined){
              
                this.categoryName=this.allCategories1[i].name;
               
                this.subCategory2 = this.subCategory1.concat(this.allCategories1[i].subcategories);
              }
            }
            //SECOND LEVEL SUBCATEGORY
              for(let i =0;i<this.subCategory2.length;i++){
                if(this.subCategory2[i].name == name){
                   this.categoryName1='';
                   if(this.subCategory2[i].subcategories!==undefined){
                  
                    this.categoryName1=this.subCategory2[i].name;
                  
                    this.subCategory4 = this.subCategory3.concat(this.subCategory2[i].subcategories);
                  }
                }
                //THIRD LEVEL SUBCATEGORY
                for(let i =0;i<this.subCategory4.length;i++){
                  if(this.subCategory4[i].name == name){
                    this.categoryName2='';
                    if(this.subCategory4[i].subcategories!==undefined){
                    
                      this.categoryName2=this.subCategory4[i].name;
                      this.subCategory6 = this.subCategory5.concat(this.subCategory4[i].subcategories);
                    }
                  }
                   //FOURTH LEVEL SUBCATEGORY
                  for(let i =0;i<this.subCategory6.length;i++){
                    if(this.subCategory6[i].name == name){
                      this.categoryName3='';
                      if(this.subCategory6[i].subcategories!==undefined){
                        this.categoryName3=this.subCategory6[i].name;
                        this.subCategory8 = this.subCategory7.concat(this.subCategory6[i].subcategories);
                      }
                    }
                    //FIFTH LEVEL SUBCATEGORY
                    for(let i =0;i<this.subCategory8.length;i++){
                      if(this.subCategory8[i].name == name){
                        this.categoryName4='';
                        // if(this.subCategory8[i].subcategories!==undefined){
                          this.categoryName4=this.subCategory8[i].name;
                          this.subCategory10 = this.subCategory9.concat(this.subCategory8[i].subcategories);
                        // }
                      }
                    }
                  } 

                }
              }
              // }
            
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
   this.cate=[name]
   console.log("in set data***"+this.cate);
   this.callListingScreen()
  }

callListingScreen(){

this.allData=[{
  brand:this.brands,
  
  categories1:this.cate,


  stock:this.stockDetails
}
  
  
  
  
  
]
console.log("jk "+JSON.stringify(this.cate))
  this.router.navigate([`/productListing`], {queryParams: { terms:[[this.brands],[this.cate],[this.stockDetails],[this.themesarr],[this.finisharr],[this.featuresarr],[this.pricesarr],[this.sizearr],[this.thicknessarr],[this.colorarr],[this.typearr],[this.materialarr]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
}

clearCheckBox(){
  
  let a = document.getElementById('day90')as HTMLInputElement
      if(a.checked){
        a.checked = false;
      }
      let b = document.getElementById('day30')as HTMLInputElement
      if(b.checked){
        b.checked = false;
      }
      let c = document.getElementById('outOfStock')as HTMLInputElement
      if(c.checked){
        c.checked = false;
      }
    
}
check90Days(){
  let a = document.getElementById('day90')as HTMLInputElement
 
   a.checked=true;
  this.stockDetails.push('Last 90 days');
}
changeCheckecd(values:any,name){
  console.log(values.currentTarget.checked);
  this.value = values.currentTarget.checked;
  if(this.value == true){
    if(name == 'Last 90 days'){
      let c = document.getElementById('day30')as HTMLInputElement
     
        c.checked = false;
     // }
    }else{
      let b = document.getElementById('day90')as HTMLInputElement
     
        b.checked = false;
    }
   
   
   
  }
 

}

setIndex(index: number) {
  this.selectedIndex = index;
}

clearAll(){
  this.cate=[];
//   console.log(this.brands)
  // if(this.brands.length > 0){
  //   this.brands.forEach(element => {
  //     let a = document.getElementById(element)as HTMLInputElement
  //     if(a.checked){
  //       a.checked = false;
  //     }
  //   });
    
  // }
  // if(this.themesarr.length > 0){
  //   this.themesarr.forEach(element => {
  //     let a = document.getElementById(element)as HTMLInputElement
  //     if(a.checked){
  //       a.checked = false;
  //     }
  //   });
    
  // }
  // if(this.pricesarr.length > 0){
  //   this.pricesarr.forEach(element => {
  //     let a = document.getElementById(element)as HTMLInputElement
  //     if(a.checked){
  //       a.checked = false;
  //     }
  //   });
    
  // }
  // if(this.featuresarr.length > 0){
  //   this.featuresarr.forEach(element => {
  //     let a = document.getElementById(element)as HTMLInputElement
  //     if(a.checked){
  //       a.checked = false;
  //     }
  //   });
    
  // }
  // if(this.finisharr.length > 0){
  //   this.finisharr.forEach(element => {
  //     let a = document.getElementById(element)as HTMLInputElement
  //     if(a.checked){
  //       a.checked = false;
  //     }
  //   });
    
  // }
  // if(this.configTitle=== 'ShubhCards'){
  //   if(this.categoriesList.length > 0){
  //     this.categoriesList.forEach(element => {
  //       let a = document.getElementById(element.name)as HTMLInputElement
  //       if(a.classList[2])
  //       {
  //         a.classList.remove('active');
  //       }
    
       
  //     });
      
  //   }
  // }
  
   this.brands=[];
   this.themesarr=[];
   this.pricesarr=[];
   this.featuresarr=[];
   this.finisharr=[];
   this.sizearr=[];
   this.thicknessarr=[];
   this.colorarr=[];
   this.typearr=[];
   this.materialarr=[];
   this.stockDetails=[];
   if(this.configTitle=== 'BombayHardware'){
    this.categoryName="";
    this.categoryName1="";
    this.categoryName2="";
    this.categoryName3="";
    this.categoryName4="";
   }
    for(let i =0;i < this.filters.length;i++){
      this.filters[i].value = false; 
  }
  this.values1 = false;
  this.values2 = false;
  this.values3 = false;

// CLEAR ALL EVENT
this.listingService.updateAdminFormEvent('clearAll');
// this.breadCrumbsService.updateAdminFormEvent('');
this.active = '';
// if(this.configTitle=== 'Aiema' || this.configTitle=== 'BombayHardware'){
  if(this.configTitle=== 'Aiema'){
  this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });
  // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
}
if(this.configTitle=== 'BombayHardware'){
  this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
if(this.configTitle=== 'ShubhCards' || this.configTitle=== 'Deera' || this.configTitle=== 'BBold'){
this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
}
}
}
