
<ul class="nav-mobile" *ngIf="config.title != 'EarthyWorthy'">
    <li></li>
    <li class="menu-container" *ngIf="currentUrl !== 'knowily'">
      <input id="menu-toggle" type="checkbox">
      <label for="menu-toggle" class="menu-button">
        <svg class="icon-open" viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
        <svg class="icon-close" viewBox="0 0 100 100">
          <path d="M83.288 88.13c-2.114 2.112-5.575 2.112-7.69 0L53.66 66.188c-2.113-2.112-5.572-2.112-7.686 0l-21.72 21.72c-2.114 2.113-5.572 2.113-7.687 0l-4.693-4.692c-2.114-2.114-2.114-5.573 0-7.688l21.72-21.72c2.112-2.115 2.112-5.574 0-7.687L11.87 24.4c-2.114-2.113-2.114-5.57 0-7.686l4.842-4.842c2.113-2.114 5.57-2.114 7.686 0l21.72 21.72c2.114 2.113 5.572 2.113 7.688 0l21.72-21.72c2.115-2.114 5.574-2.114 7.688 0l4.695 4.695c2.112 2.113 2.112 5.57-.002 7.686l-21.72 21.72c-2.112 2.114-2.112 5.573 0 7.686L88.13 75.6c2.112 2.11 2.112 5.572 0 7.687l-4.842 4.84z"/>
        </svg>
      </label>
      <ul class="menu-sidebar">
        <li><a [routerLink]="['/']">HOME</a></li>
        <li><a routerLink="product">MENUS</a></li>
        <li><a routerLink="table-booking">TABLE BOOKING</a></li>
        <li><a routerLink="gift-meal">GIFT A Experience</a></li>
        <li><a href="#">REWARD</a></li>
        <li><a routerLink="contact-us">CONTACT</a></li>
        <li><a [routerLink]="['/product']">PRODUCT</a></li>
        <!-- <li><a [routerLink]="['/checkout']">CHECKOUT</a></li> -->

      </ul>
    </li>
  </ul>
<!-- <app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer> -->
<app-header  [item]='navURL' *ngIf="(config.title == 'Tams' || config.title == 'Dosapark' || config.title == 'Murgasen'  || config.title == 'Mayan') && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<!-- <app-header  [item]='navURL' *ngIf="config.title == 'Murgasen'"></app-header> -->
<app-header  [item]='navURL' *ngIf="(config.title == 'Haven' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<app-two-header *ngIf="config.title == 'EarthyWorthy'  && currentUrl !== 'checkout' && currentUrl !== 'knowily' "></app-two-header>
<app-two-header *ngIf="config.title == 'BBold'  && currentUrl !== 'checkout' && currentUrl !== 'knowily' "></app-two-header>
<app-two-header *ngIf="(config.title == 'LuckMe' || config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'Aiema'|| config.title == 'BombayHardware') && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-two-header>
<main>
    <router-outlet></router-outlet>
    <button (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up"></i></button>
</main>
<app-footer *ngIf="(config.title == 'Tams' || config.title == 'Murgasen' || config.title == 'Mayan' )  && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-footer *ngIf="(config.title == 'Haven' || config.title == 'Dosapark' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-two-footer *ngIf="config.title == 'EarthyWorthy' || config.title == 'BBold' || config.title == 'BombayHardware' || config.title == 'Aiema' && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-two-footer>
<app-loader></app-loader>
<app-luckme-footer *ngIf="config.title == 'LuckMe' || config.title == 'ShubhCards' || config.title == 'Deera'"></app-luckme-footer>
