import { Injectable } from '@angular/core';

import { LocalStorageService } from '../auth-service/local-storage.service';
import { CustomerLoginStorageService } from './checkout-login/customer-login-storage.service';
import { CheckoutDeliveryStorageService } from './checkout-delivery/checkout-delivery-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutLocalStorageService {

  CART_LABEL = "cart";
  ORDERID_LALBEL = "orderID"

  constructor(
    private localStorageService: LocalStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private checkoutDeliveryStorageService: CheckoutDeliveryStorageService
  ) { }

  getLocalCartItems(){
    if(this.localStorageService.get(this.CART_LABEL)){
      return (JSON.parse(this.localStorageService.get(this.CART_LABEL)))
    }
    else{
      return null;
    }
  }

  setOrderId(orderId){
    // this.localStorageService.set(this.ORDERID_LALBEL, JSON.stringify(orderId))
    this.localStorageService.setSesstionVal(this.ORDERID_LALBEL, JSON.stringify(orderId));
  }

  getOrderId(){
    // if(this.localStorageService.get(this.ORDERID_LALBEL)){
    //   return JSON.parse(this.localStorageService.get(this.ORDERID_LALBEL)).orderId;
    // }
    // else{
    //   return null;
    // }
    if(this.localStorageService.getSessionVal(this.ORDERID_LALBEL)){
      return JSON.parse(this.localStorageService.getSessionVal(this.ORDERID_LALBEL)).orderId;
    }
    else{
      return null;
    }
  }

  removeOrderId(){
    // if(this.localStorageService.get(this.ORDERID_LALBEL)){
    //   this.localStorageService.remove(this.ORDERID_LALBEL);
    // }
    if(this.localStorageService.getSessionVal(this.ORDERID_LALBEL)){
      this.localStorageService.removeSessionVal(this.ORDERID_LALBEL);
    }
  }

  clearDataonLogout(){
    // CUSTOMER DATA
    if(this.customerLoginStorageService.getCustomer()){
      this.customerLoginStorageService.removeCustomer();
    }
    if(this.customerLoginStorageService.getCustomerData()){
      this.customerLoginStorageService.removeCustomerData();
    }
    // DELIVERY DATA
    if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
      this.checkoutDeliveryStorageService.removeDeliveryInfo()
    }
    // ORDER DATA
    // if(this.getOrderId()){
    //   this.removeOrderId();
    // }
  }

  clearOrderConfirmation(){
    // DELIVERY DATA
    if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
      this.checkoutDeliveryStorageService.removeDeliveryInfo()
    }
    // ORDER DATA
    if(this.getOrderId()){
      this.removeOrderId();
    }
  }
}
