import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  setSesstionVal(key: string, value: any){
    sessionStorage.setItem(key, value);
  }

  getSessionVal(key: string){
    return sessionStorage.getItem(key);
  }

  removeSessionVal(key: string) {
    sessionStorage.removeItem(key);
  }

}
