<section>
    <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus.jpg" class="aboutus-img">
    <div class="ew-wrapper">
        <div class="row">
            <div class="col-md-6">
                <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus2.jpg" class="aboutus-img">
            </div>
            <div class="col-md-6">
                <h2 class="ew-header">Welcome to Earthy Worthy!</h2>
                <p class="para-container">Like how a T Shirt starts from a seed, earthy worthy brand originated from  our Earthy Worthy End to End Sustainability <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/E3S.jpg" class="e3s-img">  Program. We have done enormous amount of research to bring Sustainability and fashion and product value together and create something which each one of you should genuinely feel good about wearing and equally take pride wearing.</p>
                <p class="para-container">Our belief is that what you wear represents you as an individual research we know that a number of you are willing to and are sustainable, where to start and which one to choose is always a question?</p>
                <p class="para-container">If you are reading this then you would have learnt enough about e and we are we different, we welcome you to join our “Moto”.</p>
                <p class="para-container">Happy Living! Happy Shopping!<br>Team Earthy Worthy</p>
            </div>
        </div>
        <div class="approach-container">
            <h2 class="ew-header text-center">Our Approach</h2>
            <p class="para-container text-center">We are the first ever Sustainable products marketplace who bring Manufacturing, Artist and Consumers on the same platform. Earthy Worthy was built to deliver simple, ethical and sustainable clothing at a fair price. As a direct to consumer rand by cutting out middlemen, we avoid cost like whole-selling, storefronts etc. that have no bearing on the quality. And we pass all the savings to you!</p>
            <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus3.jpg" class="aboutus-img" style="padding-top: 10px;">
        </div>
        <div class="cv-container">
            <div>
                <h2 class="ew-header text-center" style="margin-bottom: 30px;">Our Core Values</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="left-cv-text">
                            <h4>Fantastic Product</h4>
                            <p class="para-container">We are driven by passion with Quality at the heart of everything that we do, not only our philosophy of being natural, Organic and Sustainable brings in natural luxury in our products we also ensure through the production life cycle of the product quality is of utmost priority.</p>
                        </div>
                        <div class="left-cv-text">
                            <h4>Zerowaste, Multiuse</h4>
                            <p class="para-container">In today’s world we are finding ways to clear the issues that we have created, our vision was to creatively think how we can prevent first creating a problem before solving it. Hence, for all our products we aim to make it in a way that it doesn’t generate unnecessary waste rather what can become a possible waste can be a potential use.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus5.jpg" class="aboutus-img" style="width: 100%; height: 95px;padding:0px 30px"><br>
                        <div class="d-flex justify-content-center">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ewarrow.jpg" class="aboutus-img">
                        </div>
                        <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus6.jpg" class="aboutus-img" style="width: 100%; height: 95px;padding:0px 30px"><br>
                        <div class="d-flex justify-content-center">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ewarrow.jpg" class="aboutus-img">
                        </div>
                        <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus7.jpg" class="aboutus-img" style="width: 100%; height: 95px;padding:0px 30px"><br> 
                        <div class="d-flex justify-content-center">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ewarrow.jpg" class="aboutus-img">
                        </div>
                        <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus8.jpg" class="aboutus-img" style="width: 100%; height: 95px;padding:0px 30px"><br>
                        <div class="d-flex justify-content-center">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ewarrow.jpg" class="aboutus-img">
                        </div>
                        <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/aboutus9.jpg" class="aboutus-img" style="width: 100%; height: 95px;padding:0px 30px"><br> 
                    </div>
                    <div class="col-md-4">
                        <div class="right-cv-text">
                            <h4>Harmonious Living</h4>
                            <p class="para-container">We are obsessed with sustainability and our products will aim to bring a balance between us and the environment that we live in, our products will be made from sustainable materials which are either organic, natural or recycled which are free from chemicals, pesticides and insecticides which are healthier for you and for the earth that we live in.</p>
                        </div>
                        <div class="right-cv-text">
                            <h4>Outstanding Experience</h4>
                            <p class="para-container">We know that choosing products for sustainable living can be daunting in today’s world, hence, we will do all the hard work and Our aim is to make it simple to you for easily choosing the products for sustainable living.</p>
                        </div>
                        <div class="right-cv-text">
                            <h4>Efficient Supply Chain</h4>
                            <p class="para-container">Our aim is to offer products which are reasonably priced but of supreme quality and the way we do that is by efficiency managing our supply chain by cutting through all the middle men and directly reaching out to our passionate consumers, did you know that through the traditional supply chain model products can cost up to 5X or more and that’s what we pass it on to you.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="bootom-caertificate">
        <h2 class="ew-header text-white text-center" style="padding-bottom: 20px;">Our Certification</h2>
        <div class="d-flex justify-content-between">
            <div>
                <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/footer1.jpg" class="aboutus-img">
            </div>
            <div>
                <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/footr2.jpg" class="aboutus-img">
            </div>
            <div>
                <img src="https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/footer3.jpg" class="aboutus-img">
            </div>
        </div>
    </div>
</section>
