import { Injectable } from '@angular/core';

import { CheckoutSettingsService } from './checkout-settings.service';
import {ORG_DETAILS, ENDPOINTS} from '../app.config';
import { AppService } from 'src/app/app.service';
import { config } from '../../configs/config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutTaxService {

  constructor(
    private checkoutSettingsService : CheckoutSettingsService,
    private appService: AppService,
  ) { }

  productTaxList = [];
  CONFIG = config;

  getProductTaxList(productList, taxClasses){
    return new Promise((resolve, reject) => 
    {
     if(this.CONFIG.title === "Nesma"){
      this.getAllProducts().then(
        resp => {
          let res:any = resp;
          if(res.length > 0){
            let allProducts = res;
            this.productTaxList = [];
            productList.forEach((product,index) => {
              // MAIN PRODUCT
              if(product.product.tax_class_id){
                let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(product.product.tax_class_id));
                this.productTaxList.push({
                  pid: product.id,
                  taxId: taxClass.tax_class_id,
                  taxClassName: taxClass.tax_class_name,
                  taxrate: taxClass.tax_class_rate_percent,
                  quantity: product.package_quantity,
                  price: product.product_cost,
                  package_id:index,
                  free:false,
                  type: product.customized ? "package": "normal",
                  package_product: product.id
                })
              }
              else{
                this.productTaxList.push({
                  pid: product.id,
                  taxId: this.checkoutSettingsService.productTax.id,
                  taxClassName: this.checkoutSettingsService.productTax.name,
                  taxrate: this.checkoutSettingsService.productTax.rate,
                  quantity: product.package_quantity,
                  price: product.product_cost,
                  package_id:index,
                  free:false,
                  type: product.customized ? "package": "normal",
                  package_product: product.id
                })
              }
              // BUNDLE PRODUCTS
              // if(product.bundle_products && product.bundle_products.length > 0){
              //   product.bundle_products.forEach(bproductid => {
              //     let bundleProduct = allProducts.find(ap => ap.id === bproductid);
              //     if(bundleProduct.tax_class_id){
              //       let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(bundleProduct.tax_class_id));
              //       this.productTaxList.push({
              //         pid: bundleProduct.id,
              //         taxId: taxClass.tax_class_id,
              //         taxClassName: taxClass.tax_class_name,
              //         taxrate: taxClass.tax_class_rate_percent,
              //         quantity: product.package_quantity,
              //         price: bundleProduct.pricing.raw.price,
              //         package_id:index,
              //         free:product.bundle_chargable,
              //         type: "bundle",
              //         package_product: product.id
              //       })
              //     }
              //     else{
              //       this.productTaxList.push({
              //         pid: bundleProduct.id,
              //         taxId: this.checkoutSettingsService.productTax.id,
              //         taxClassName: this.checkoutSettingsService.productTax.name,
              //         taxrate: this.checkoutSettingsService.productTax.rate,
              //         quantity: product.package_quantity,
              //         price: bundleProduct.pricing.raw.price,
              //         package_id:index,
              //         free:product.bundle_chargable,
              //         type: "bundle",
              //         package_product: product.id
              //       })
              //     }
                  
              //   });
              // }
              // // ADD ON PRODUCTS
              // if(product.add_onproducts && product.add_onproducts.length > 0){
              //   product.add_onproducts.forEach(bproductid => {
              //     let addonProduct = allProducts.find(ap => ap.id === bproductid);
              //     if(addonProduct.tax_class_id){
              //       let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(addonProduct.tax_class_id));
              //       this.productTaxList.push({
              //         pid: addonProduct.id,
              //         taxId: taxClass.tax_class_id,
              //         taxClassName: taxClass.tax_class_name,
              //         taxrate: taxClass.tax_class_rate_percent,
              //         quantity: product.package_quantity,
              //         price: addonProduct.pricing.raw.price,
              //         package_id:index,
              //         free:false,
              //         type: "addon",
              //         package_product: product.id
              //       })
              //     }
              //     else{
              //       this.productTaxList.push({
              //         pid: addonProduct.id,
              //         taxId: this.checkoutSettingsService.productTax.id,
              //         taxClassName: this.checkoutSettingsService.productTax.name,
              //         taxrate: this.checkoutSettingsService.productTax.rate,
              //         quantity: product.package_quantity,
              //         price: addonProduct.pricing.raw.price,
              //         package_id:index,
              //         free:false,
              //         type: "addon",
              //         package_product: product.id
              //       })
              //     }
              //   });
              // }
            })
            resolve(this.productTaxList);
          }
        }
      )
     }
     else{
      this.productTaxList = [];
      productList.forEach(product => {
        let productTax = {
          pid:0,
          taxId:0,
          taxClassName:"",
          taxrate:0,
          quantity:0,
          price:0
        };
        if(product.product.tax_class_id){
          let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(product.product.tax_class_id));
          if(taxClass){
            productTax.pid = product.product.id;
            productTax.taxId = taxClass.tax_class_id;
            productTax.taxClassName = taxClass.tax_class_name;
            productTax.taxrate = taxClass.tax_class_rate_percent;
            productTax.quantity = product.quantity;
            productTax.price = product.cost;
            this.productTaxList.push(productTax)
          }
        }
        else{
          productTax.pid = product.product.id;
          productTax.taxId = this.checkoutSettingsService.productTax.id;
          productTax.taxClassName = this.checkoutSettingsService.productTax.name;
          productTax.taxrate = this.checkoutSettingsService.productTax.rate;
          productTax.quantity = product.quantity;
          productTax.price = product.cost;
          this.productTaxList.push(productTax)
        }
         
      });
      resolve(this.productTaxList);
     }
    })
  }

  getBundledProductTaxList(productList, taxClasses){
    return new Promise((resolve, reject) => 
    {
      this.getAllProducts().then(
        resp => {
          let res:any = resp;
          if(res.length > 0){
            let allProducts = res;
            this.productTaxList = [];
            productList.forEach((product,index) => {
              // MAIN PRODUCT
              if(product.product.tax_class_id){
                let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(product.product.tax_class_id));
                this.productTaxList.push({
                  pid: product.id,
                  taxId: taxClass.tax_class_id,
                  taxClassName: taxClass.tax_class_name,
                  taxrate: taxClass.tax_class_rate_percent,
                  quantity: product.package_quantity,
                  price: product.product_cost,
                  package_id:index,
                  free:false,
                  type: product.customized ? "package": "normal",
                  package_product: product.id
                })
              }
              else{
                this.productTaxList.push({
                  pid: product.id,
                  taxId: this.checkoutSettingsService.productTax.id,
                  taxClassName: this.checkoutSettingsService.productTax.name,
                  taxrate: this.checkoutSettingsService.productTax.rate,
                  quantity: product.package_quantity,
                  price: product.product_cost,
                  package_id:index,
                  free:false,
                  type: product.customized ? "package": "normal",
                  package_product: product.id
                })
              }
              // BUNDLE PRODUCTS
              if(product.bundle_products && product.bundle_products.length > 0){
                product.bundle_products.forEach(bproductid => {
                  let bundleProduct = allProducts.find(ap => ap.id === bproductid);
                  if(bundleProduct.tax_class_id){
                    let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(bundleProduct.tax_class_id));
                    this.productTaxList.push({
                      pid: bundleProduct.id,
                      taxId: taxClass.tax_class_id,
                      taxClassName: taxClass.tax_class_name,
                      taxrate: taxClass.tax_class_rate_percent,
                      quantity: product.package_quantity,
                      price: bundleProduct.pricing.raw.price,
                      package_id:index,
                      free:product.bundle_chargable,
                      type: "bundle",
                      package_product: product.id
                    })
                  }
                  else{
                    this.productTaxList.push({
                      pid: bundleProduct.id,
                      taxId: this.checkoutSettingsService.productTax.id,
                      taxClassName: this.checkoutSettingsService.productTax.name,
                      taxrate: this.checkoutSettingsService.productTax.rate,
                      quantity: product.package_quantity,
                      price: bundleProduct.pricing.raw.price,
                      package_id:index,
                      free:product.bundle_chargable,
                      type: "bundle",
                      package_product: product.id
                    })
                  }
                  
                });
              }
              // ADD ON PRODUCTS
              if(product.add_onproducts && product.add_onproducts.length > 0){
                product.add_onproducts.forEach(bproductid => {
                  let addonProduct = allProducts.find(ap => ap.id === bproductid);
                  if(addonProduct.tax_class_id){
                    let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(addonProduct.tax_class_id));
                    this.productTaxList.push({
                      pid: addonProduct.id,
                      taxId: taxClass.tax_class_id,
                      taxClassName: taxClass.tax_class_name,
                      taxrate: taxClass.tax_class_rate_percent,
                      quantity: product.package_quantity,
                      price: addonProduct.pricing.raw.price,
                      package_id:index,
                      free:false,
                      type: "addon",
                      package_product: product.id
                    })
                  }
                  else{
                    this.productTaxList.push({
                      pid: addonProduct.id,
                      taxId: this.checkoutSettingsService.productTax.id,
                      taxClassName: this.checkoutSettingsService.productTax.name,
                      taxrate: this.checkoutSettingsService.productTax.rate,
                      quantity: product.package_quantity,
                      price: addonProduct.pricing.raw.price,
                      package_id:index,
                      free:false,
                      type: "addon",
                      package_product: product.id
                    })
                  }
                });
              }
            })
            resolve(this.productTaxList);
          }
        }
      )
      
    })
  }

  getAllProducts(){
    return new Promise((resolve, reject) => {
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: null,
        extras: {
          find:{},
          pagination: false,
            paginationDetails: {
              limit:10,
              pagesize: 20
            },
          sorting:false,
          sortingDetails: {
            sortfield: "id",
            sortorder: -1
          }
        }
        
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
        resp => {
          let res:any = resp;
          if(res.status_code === 200 && res.success === 1){
            resolve(res.result.data);
          }
          else{
            resolve([]);
          }
        }
      )
    })
  }
}
