import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AlertsService } from '../shared/alerts/alerts.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AES, enc, mode } from 'crypto-js';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import * as crypto from 'crypto-js';
import { CheckoutLocalStorageService } from '../checkout-new/checkout-local-storage.service';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';
import { config } from '../../configs/config';


@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: ElementRef;
  CONFIG = config;
  productId:any;
  product:any;
  owner:any = "";
  memberId:any = "";
  establised:any = "";
  enquiryForm:FormGroup;
  closeResult: string;
  validateForm:boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  checkoutAlerts:boolean = false;
  companyName;
  customer_id;
  customerLogin:boolean = false;

  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private router: Router,
    private modalService: NgbModal,
    private localService: LocalStorageService,
    private checkoutApiService: CheckoutApiService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      let queryParams = this.route.snapshot;
      this.productId = queryParams.params['id'];
      this.getProductDetails(this.productId)
    })
    this.route.queryParams.subscribe(params => {
      if (params.fromLogin=='fromLogin') {
        this.customerLogin = true;
      }
    });
    this.customer_id = this.localService.get('UserId');
    this.checkoutAlerts = true;
    this.enquiryForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required,Validators.maxLength(80)]),
      email: this.formBuilder.control('', [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      category: this.formBuilder.control('', [Validators.required]),
      message: this.formBuilder.control('', [Validators.required,Validators.maxLength(600)])
    })
  }

  
  get f() { return this.enquiryForm.controls; }

  getProductDetails(id){
    let requestData ={
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": Number(id)
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 2
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "",
          "sortorder": 1
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.PRODUCT_DETAILS).subscribe(
      resp => {
        if (resp.success == 1){
          if(resp.result.data.length > 0){
            window.scrollTo(0,0)
            this.product = resp.result.data[0]
            this.owner = this.product.company_profile['ceo/md/owner name']
            this.establised = this.product.company_profile['established_since']
            this.memberId = this.product.memberId;
            if(this.customerLogin===true){
                this.submitEnquiry(this.product);
            }
          }
        }
      })
  }

  saveForm() {  
    if(this.enquiryForm.controls.name.value === ""){
      this.validateForm = true;
      // this.enquiryForm.controls['name'].setErrors({'incorrect':true});
    } else if (this.enquiryForm.controls.message.value === ""){
  
      this.validateForm = true;
    } else if(this.enquiryForm.controls.email.value === ""){
  
      this.validateForm = true;
    } else if(this.enquiryForm.controls.phone_number.value === ""){
  
      this.validateForm = true;
    }
   
    if (this.enquiryForm.valid) {
      this.validateForm = false;
      var formData = {}
      var formDataJson;
      let session_id = this.localService.get("session_id");

      
    
  // let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
  // let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
  // let encrypted =AES.encrypt (
  // JSON.stringify({
  //   "domain_name": ORG_DETAILS.DOMAIN_NAME,
  //   "user_id": ORG_DETAILS.USER_ID,
  //   "timestamp": new Date().getTime(),
  //   "payload": {
  //     "order_creation":{  
  //     "name":this.enquiryForm.value.name,
  //     "email":this.enquiryForm.value.email,
  //     "phone_number":this.enquiryForm.value.phone_number,
  //     "category":this.enquiryForm.value.category,
  //     "message":this.enquiryForm.value.message,
  //     "member_id":this.memberId,
  //     "status":"New",
  //     "system_label":"New"
  //   }  
  //   },
  //   "extras": {
  //     "find": {
  //       "id": ""
  //     }
  //   }
  // }), _key, {
  //     iv: _iv,
  //     format: crypto.format.Hex,
  //     mode: crypto.mode.CTR,
  //     padding: crypto.pad.NoPadding
  // }).toString();
  // let requestData = {
  //   payload: encrypted
  // }
 
  let enquiry = {
    "name":this.enquiryForm.value.name,
    "email":this.enquiryForm.value.email,
    "phone_number":this.enquiryForm.value.phone_number,
    "category":this.enquiryForm.value.category,
    "message":this.enquiryForm.value.message,
    "member_id":this.memberId,
    "customer_id" : this.customer_id,
    "status":"New",
    "system_label":"New",
    "companyName":this.companyName,
  }
  let requestData = {
    domain_name: ORG_DETAILS.DOMAIN_NAME,
    user_id: ORG_DETAILS.USER_ID,
    payload: {
      order_creation: enquiry
    },
    extras: {
      find: {
        id: ""
      }
    }
  }

  // let requestData = {
  //   payload: encrypted
  // }
  this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
    respData => {
      let res:any = respData;
      if(res.success){
        let OrderId = res.data[0].id;
        this.closeModal.nativeElement.click();   
        this.checkoutLocalStorageService.setOrderId({orderId:OrderId});
        this.alertService.success('Request Submitted', this.options);
        this.enquiryForm.reset({  name:"",
        email:"",
        phone_number:"",
        category:"",
        message:""})
      }
    }
  )
    }
   
      // formData["name"] = this.enquiryForm.value.name;
      // formData["email"] = this.enquiryForm.value.email;
      // formData["phone_number"] = this.enquiryForm.value.phone_number;
      // formData["category"] = this.enquiryForm.value.category;
      // formData["message"] = this.enquiryForm.value.message;
      // formData["member_id"] = this.memberId;
      // formData['status'] = 'New'
      // formDataJson = {
      //   domain_name: ORG_DETAILS.DOMAIN_NAME,
      //   user_id: ORG_DETAILS.USER_ID,
      //   payload: {
      //     enquiry_creation: formData
      //   }
      // }
      // MAKE A SERVICE CALL HERE...
      // this.appService.postApiCall(formDataJson, ENDPOINTS.ADD_ENQUIRY).subscribe(
      //   resp => {
      //     if (resp) {
      //       if (resp.success === 1 && resp.status_code === 200) {
      //         this.alertService.success('Request Received', this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});

      //       }
      //       else if (resp.success === 0) {
      //         if (resp.message) {
      //           this.alertService.error(resp.message, this.options);
      //           window.scrollTo({top: 0, behavior: 'smooth'});
      //         }
      //       }
      //       else if (resp.message && resp.status_code !== 200) {
      //         this.alertService.error(resp.message, this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});
      //       }
      //       else {
      //         this.alertService.error('Something bad happened. Please try again!', this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});
      //       }
      //     }
      //   },
      //   err => {
      //     if (err.error.statusCode === 403) {
      //       this.alertService.error('Session Time Out! Please login Again', this.options)
      //       this.router.navigate([`/login-new`], { skipLocationChange: false });
      //     }
      //     else if (err.error.message) {
      //       this.alertService.error(err.error.message, this.options)
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //     else if (err.error.error.message) {
      //       this.alertService.error(err.error.error.message, this.options)
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //     else {
      //       this.alertService.error('Something bad happened. Please try again!', this.options);
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //   }
      // )
      //   ;
    
  }

  submitEnquiry(product){
    // console.log("this.memberId"+this.memberId);
    // this.enquiryForm.controls['category'].setValue(this.product.categories[0].categories)
    // document.getElementById('launch-model').click();

    let userId = this.localService.get('UserId');
    let aiemaUserName = this.localService.get('AiemaUserName');
    this.memberId = product.memberId;
    this.companyName = product.name;
    if(userId){
      this.enquiryForm.reset({name:"", message:"",})
      if(aiemaUserName){
      this.enquiryForm.controls['name'].setValue(aiemaUserName)
      }
      this.enquiryForm.controls['email'].setValue(this.customerLoginStorageService.getCustomerData().email)
      this.enquiryForm.controls['phone_number'].setValue(this.customerLoginStorageService.getCustomerData().mobileNo)
      this.enquiryForm.controls['category'].setValue(product.categories[0].categories)
      document.getElementById('launch-model').click();
  
    } else {
      this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
  }

}

