<!DOCTYPE html>
<html>

<head>
    <title>Return and Policy</title>
</head>

<body>
    <div class="paddingTB60  background-filter">
        <div class="container-fluid text-center">
            <h3 class="fcolor">Return & Exchange Policy</h3>
            <hr>
        </div>
        <!-- <div style="margin-left: 5px;" id="return-content"></div> -->
        <!-- <h3 style="margin-left: 5px;" id="return-content">Return & Exchange Policy</h3> -->
    </div>
</body>

</html>