
<!-- <section class="container">
    <p class="logo-header mt-2">Table Booking</p> 
    <p>Book your table here</p>
     <br> 
    <div class="row">
        <div class="col-sm-7">
            <form [formGroup]="tableBookingForm" class="gift-form" novalidate>  -->
                <!-- Banner name -->
                 <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="name" class="form-label">Name <span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" id="name"
                            [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                        <div class="invalid-feedback" *ngIf="f.name.errors">
                            <div *ngIf="f.name.errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="f.name.errors.maxlength">
                                Name can't be more than 80 characters long.
                            </div>
                            <div *ngIf="f.name.errors.pattern">
                                Name is invalid.
                            </div>
                        </div>
                    </div>
                </div>  -->

                <!--Content-->
                 <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="content" class="form-label">Email Address </label>
                    </div>
                    <div class="col-md-7">
                        <input type="email" class="form-control" id="email_address"
                            [class.is-invalid]="(f.email_address.invalid && validateForm)"
                            formControlName="email_address" maxlength="50" email>
                        <div class="invalid-feedback" *ngIf="f.email_address.errors">
                            <div *ngIf="f.email_address.errors.maxlength">
                                Seneder Email can't be more than 50 characters long.
                            </div>
                            <div *ngIf="f.email_address.errors.email || f.email_address.errors.pattern">
                                Email must be a valid email address
                            </div>
                        </div>
                    </div>
                </div>  -->

                <!-- Show On Page -->
                 <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="mobile_number" class="form-label">Mobile Number<span
                                class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" id="mobile_number"
                            [class.is-invalid]="f.mobile_number.invalid && validateForm" formControlName="mobile_number"
                            required>
                        <div class="invalid-feedback" *ngIf="f.mobile_number.errors">
                            <div *ngIf="f.mobile_number.errors.required">
                                Mobile Number is required.
                            </div>
                            <div *ngIf="f.mobile_number.errors.maxlength">
                                Mobile Number can't be more than 15 characters long.
                            </div>
                            <div *ngIf="f.mobile_number.errors.pattern">
                                Mobile Number is invalid.
                            </div>
                        </div>
                    </div>
                </div>  -->

                <!-- Banner Location -->
                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="location" class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">  -->
                         <!-- <input type="date" class="form-control" id="date"
                            [class.is-invalid]="f.date.invalid  && validateForm" formControlName="date" [min]="mindeliveryDate" required> -->
                             <!-- <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && f.date.invalid" ></dp-date-picker>
                        <div class="invalid-feedback" *ngIf="f.date.errors">
                            <div *ngIf="f.date.errors.required">
                                Date is required.
                            </div>
                            <div *ngIf="f.date.errors.previous_date">Previous date is not allowed.</div>

                        </div>
                    </div>
                </div>  -->

                <!--Valid from-->
                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="number" class="form-label">Time<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" formControlName="time"
                            [class.is-invalid]="validateForm && f.time.errors" (click)="pickTime()"
                            placeholder="HH:MM" />
                        <div class="invalid-feedback" *ngIf="f.time.errors">
                            <div *ngIf="f.time.errors.required"> Time is required.</div>
                            <div *ngIf="f.time.errors.previous_time">Can not book for past timings.</div>
                            <div *ngIf="f.time.errors.unavailable_time">Booking available only between {{start_time}}
                                and
                                {{end_time}}.</div>
                        </div>
                    </div>
                </div>  -->

                <!--Valid To-->
                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="no_of_persons" class="form-label">No.Of Persons <span class="required">*</span> </label>
                    </div>
                    <div class="col-md-7">
                        <input type="number" class="form-control" id="no_of_persons"
                            [class.is-invalid]="f.no_of_persons.invalid && validateForm" min=1
                            formControlName="no_of_persons">
                        <div class="invalid-feedback" *ngIf="f.no_of_persons.errors">
                            <div *ngIf="f.no_of_persons.errors.max">
                                Cannot accomodate.
                            </div>
                            <div *ngIf="f.no_of_persons.errors.min">
                                Number of Persons can't be less than 1.
                            </div>
                            <div *ngIf="f.no_of_persons.errors.required">
                                Number of Persons is required.
                            </div>
                        </div>
                    </div>
                </div>  -->

                 <!-- <div class="form-group row">
                    <div class="col-md-4">
                      <label for="add_outlet" class="form-label">Add Outlet<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <select class="custom-select" id="add_outlet"
                        [class.is-invalid]="f.add_outlet.invalid  && validateForm" formControlName="add_outlet" (change)="handleOutlet($event)">
                        <option value="">Please Select</option>
                        <option *ngFor="let outlet of outletList;" [value]="outlet.name">{{outlet.name}}</option>
                      </select>
                      <div class="invalid-feedback" *ngIf="f.add_outlet.errors">
                        <div *ngIf="f.add_outlet.errors.required">Please select the outlet.</div>
                    </div>
                    <div *ngIf="addOutlet===false">Table Booking Is Not Allowed In This Outlet.Please Select Some Other Outlet</div>
                    </div>
                  </div>  -->

                 <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="optional_message" class="form-label">Optional Message</label>
                    </div>
                    <div class="col-md-7">
                        <textarea type="text" class="form-control" id="optional_message"
                            [class.is-invalid]="f.optional_message.invalid  && validateForm"
                            formControlName="optional_message"></textarea>
                        <div class="invalid-feedback" *ngIf="f.optional_message.errors">
                            <div *ngIf="f.optional_message.errors.maxlength">
                                Optional Message can't be more than 1000 characters long.
                            </div>
                        </div>
                    </div>
                </div>  -->
                 <!-- <div class="text-center">
                    <button class="btn btn-primary mb-1 buttons" (click)="saveForm()">RESERVE</button>
                </div> 
            </form>
            
        </div> -->
        <!-- <div class="col-md-5">
            <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Reservation Discounts</h2>
                <li style="color:#4d4d4d !important; margin-bottom: 10px;">Restaurant Timings: {{restaurentTimings}} (all days).</li>
                <ol style="color: #4d4d4d; padding-left: 10px;" >
                    <li><span class="card-txt">1. </span>Those reserving a table before 5 hrs in advance, will be eligible for 5% off on their dining bill. Table reservation must be paid for with a minimum amount of Rs.500.</li>
                    <li><span class="card-txt">2. </span>Those reserving a table before a day in advance, will be eligible for 5% off on their dining ball. Table reservation must be paid for with a minimum amount of Rs.500.</li>
                </ol>
            </div>
        </div> -->
    <!-- </div>
</section> -->

 <section class="container">
    <p class="logo-header mt-2">Table Booking</p>
    <p class="text-black"> {{description}}</p>
    <br>
    <div class="row">
        <div class="col-md-8">
            <!-- <img class="d-block w-100" src="https://ecbee-labes-images.s3.ap-south-1.amazonaws.com/Haven/StoreGallery/1624599039241%20-%20havenhome1.jpg" alt="" style="height:160px!important;"> -->
            <!-- <img class="d-block w-100" src="assets/images/havenSampoorna/TB1.jpg" alt="" style="height:10%!important;"> -->
            <!-- <img class="d-block w-100" src={{image}} alt="" style="height:160px!important;"> -->
            <img class="d-block w-100" src={{image}} alt="" style="height:240px!important;object-fit:cover!important;">

            <app-alerts *ngIf="showmsg"></app-alerts>
            <form [formGroup]="tableBookingForm" class="gift-form" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="add_outlet" class="form-label">ChooseOutlet<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4"> 
                        <select class="custom-select" id="add_outlet" style="background-color:rgba(0, 0, 0, 0.125);"
                        [class.is-invalid]="f.add_outlet.invalid  && validateForm" formControlName="add_outlet" (change)="handleOutlet($event)">
                        <option value="">Please Select</option>
                        <option *ngFor="let outlet of outletList;" [value]="outlet.name">{{outlet.name}}</option>
                      </select>
                      <div class="invalid-feedback" *ngIf="f.add_outlet.errors">
                        <div *ngIf="f.add_outlet.errors.required">Please select the outlet.</div>
                    </div>
                    <div *ngIf="addOutlet===false">Table Booking Is Not Allowed In This Outlet.Please Select Some Other Outlet</div>
             
                    </div>

                    <div class="col-md-2">
                        <label for="location" class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <input type="date" class="form-control" id="date" style="background-color:rgba(0, 0, 0, 0.125)!important;"
                        [class.is-invalid]="f.date.invalid  && validateForm" formControlName="date" [min]="mindeliveryDate" [max]="maxDate"  (change)="handleDate($event)" required>
                             <!-- <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && f.date.invalid" ></dp-date-picker> -->
                        <div class="invalid-feedback" *ngIf="f.date.errors">
                            <div *ngIf="f.date.errors.required">
                                Date is required.
                            </div>
                            <div *ngIf="f.date.errors.previous_date">Previous date is not allowed.</div>
            
                        </div>
                    </div>
            </div> 

            
            <div class="form-group row">
                <div class="col-md-2">
                    <label for="number" class="form-label">ChooseSlot<span class="required">*</span></label>
                </div>
                <!-- <div class="col-md-7" style="overflow-x: auto;
                white-space: nowrap;"> -->
                        <div class="col-md-9">
                    <span *ngFor="let settings of tableBookingSlot;" >
                        <!-- <button *ngIf="slotTime!==settings.slot_time" class="btn-timeslot" (click)="setSlot(settings.slot_time)" style="margin-bottom: 8px;">{{settings.slot_time}}</button> -->
                        <!-- <button *ngIf="slotTime===settings.slot_time" class="btn-timeslot1" (click)="setSlot(settings.slot_time)" style="margin-bottom: 8px;">{{settings.slot_time}}</button> -->

                        <button *ngIf="slotTime!==settings" class="btn-timeslot" (click)="setSlot(settings)" style="margin-bottom: 8px;">{{settings}}</button>
                        <button *ngIf="slotTime===settings" class="btn-timeslot1" (click)="setSlot(settings)" style="margin-bottom: 8px;">{{settings}}</button>
                    </span>    
                    
                    <!-- <select class="custom-select" id="add_outlet"
                    [class.is-invalid]="f.add_outlet.invalid  && validateForm" formControlName="add_outlet" (change)="handleOutlet($event)">
                    <option value="">Please Select</option> -->
                    <!-- <option *ngFor="let outlet of outletList;" [value]="outlet.name">{{outlet.name}}</option> -->
                    <!-- <option value="11.30">11.30</option>
                    <option value="12.00">12.00</option>
                  </select> -->
                 
                  <!-- <button class="btn btn-timeslot " style="margin-bottom: 8px;">12.00</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">12.30</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">1.00</button>
                  <button class="btn btn-timeslot" style="margin-bottom: 8px;">1.30</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">2.00</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">2.30</button>
                  <button class="btn btn-timeslot" style="margin-bottom: 8px;">3.00</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">3.30</button>
                  <button class="btn btn-timeslot " style="margin-bottom: 8px;">4.00</button>
                  <button class="btn btn-timeslot" style="margin-bottom: 8px;">4.30</button> -->
                <!-- <div class="invalid-feedback" *ngIf="f.time.errors"> -->
                    <!-- <div *ngIf="f.time.errors.required"> Time is required.</div> -->
                    <!-- <div *ngIf="f.time.errors.previous_time">Can not book for past timings.</div>
                    <div *ngIf="f.time.errors.unavailable_time">Booking available only between {{start_time}}
                        and
                        {{end_time}}.</div> -->
                <!-- </div> -->
                <div *ngIf="showmsg2===true" style="color:red!important;">Please Select Time Slot</div>
                </div>
            </div> 

            <div class="form-group row">
                <div class="col-md-2">
                    <label for="no_of_persons" class="form-label">No.Of Person<span class="required">*</span></label>
                </div>
                <div class="col-md-4"> 
                    <!-- <input type="number" class="form-control" id="no_of_persons"
                    [class.is-invalid]="f.no_of_persons.invalid && validateForm" min=1
                    formControlName="no_of_persons">
                <div class="invalid-feedback" *ngIf="f.no_of_persons.errors">
                    <div *ngIf="f.no_of_persons.errors.max">
                        Cannot accomodate.
                    </div>
                    <div *ngIf="f.no_of_persons.errors.min">
                        Number of Persons can't be less than 1.
                    </div>
                    <div *ngIf="f.no_of_persons.errors.required">
                        Number of Persons is required.
                    </div>
                </div> -->
              <input type="text" class="form-control" id="no_of_persons"
              [class.is-invalid]="f.no_of_persons.invalid && validateForm" style="background-color:rgba(0, 0, 0, 0.125);" formControlName="no_of_persons" required>
              <div class="invalid-feedback" *ngIf="f.no_of_persons.errors">
                <div *ngIf="f.no_of_persons.errors.required">Please Add Person.</div>
                <div *ngIf="showmsg1===true">only for {{noOfPerson}} number's reservation is left</div>
            </div>
            <!-- <div *ngIf="showmsg1===true" style="color:red!important;">only for {{noOfPerson}} number's reservation is left</div> -->
                </div>

                <div class="col-md-2">
                    <label for="name" class="form-label">Name<span class="required">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control" id="name"
                    [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                <div class="invalid-feedback" *ngIf="f.name.errors">
                    <div *ngIf="f.name.errors.required">
                        Name is required.
                    </div>
                    <div *ngIf="f.name.errors.maxlength">
                        Name can't be more than 80 characters long.
                    </div>
                    <div *ngIf="f.name.errors.pattern">
                        Name is invalid.
                    </div>
                </div>
                </div>
        </div> 

        <div class="form-group row">
            <div class="col-md-2">
                <label for="content" class="form-label">Email<span class="required">*</span></label>
            </div>
            <div class="col-md-4"> 
                <input type="email" class="form-control" id="email_address"
                [class.is-invalid]="(f.email_address.invalid && validateForm)"
                formControlName="email_address" maxlength="50" email>
            <div class="invalid-feedback" *ngIf="f.email_address.errors">
                <div *ngIf="f.email_address.errors.required">
                    Email is required.
                </div>
                <div *ngIf="f.email_address.errors.maxlength">
                    Seneder Email can't be more than 50 characters long.
                </div>
                <div *ngIf="f.email_address.errors.email || f.email_address.errors.pattern">
                    Email must be a valid email address
                </div>
            </div>
            </div>
    </div> 


    <div class="form-group row">
        <div class="col-md-2">
            <label for="mobile_number" class="form-label">Mobile<span
                class="required">*</span></label>
        </div>
        <div class="col-md-4"> 
            <input type="text" class="form-control" id="mobile_number"
            [class.is-invalid]="f.mobile_number.invalid && validateForm" formControlName="mobile_number">
        <div class="invalid-feedback" *ngIf="f.mobile_number.errors">
            <div *ngIf="f.mobile_number.errors.required">
                Mobile Number is required.
            </div>
            <div *ngIf="f.mobile_number.errors.maxlength">
                Mobile Number can't be more than 15 characters long.
            </div>
            <div *ngIf="f.mobile_number.errors.pattern">
                Mobile Number is invalid.
            </div>
        </div>
        <div *ngIf="showmsg3===true" style="color:red!important;">Mobile Number is invalid.</div>
        </div>

        <div class="col-md-2">
            <button class="btn btn-block btn-primary" (click)="verifyMobileNumber()">Send OTP</button>
        </div>
</div> 


<div class="form-group row">
    <div class="col-md-2">
        <label for="otp" class="form-label">OTP<span
            class="required">*</span></label>
    </div>
    <div class="col-md-4"> 
        <input type="text" class="form-control" id="otp"
        [class.is-invalid]="f.otp.invalid && validateForm" formControlName="otp">
        <div class="invalid-feedback" *ngIf="f.otp.errors">
            <div *ngIf="f.otp.errors.required">Please enter OTP.</div>
        </div>
    </div>
</div>   

                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="optional_message" class="form-label">Request Note</label>
                    </div>
                    <div class="col-md-7">
                        <textarea type="text" class="form-control" id="optional_message"
                            [class.is-invalid]="f.optional_message.invalid  && validateForm"
                            formControlName="optional_message"></textarea>
                        <div class="invalid-feedback" *ngIf="f.optional_message.errors">
                            <div *ngIf="f.optional_message.errors.maxlength">
                                Optional Message can't be more than 1000 characters long.
                            </div>
                        </div>
                    </div>
                </div> 


                 <div class="text-center">
                    <button class="btn btn-primary mb-1 buttons" (click)="saveForm()">Book A Table</button>
                </div>
            </form>
            
        </div>
        <div class="col-md-4">
            <!-- <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Reservation Procedure</h2>
                <button class="btn btn-primary mb-1 buttons" (click)="termsAndCondition()">Terms And Condition</button>
            </div> -->
            <div class="card">
                <div class="card-header" id="delivery-card" style="height: 100% !important;">
                    <h2 class="card-txt" style="color: #7e8552;">Reservation Procedure</h2>
                    <button class="btn btn-primary mb-1 buttons" (click)="termsAndCondition()">Terms And Condition</button>
                    <h4 class="card-txt" style="margin-top: 10px;" style="color: #7e8552;">Rules For Booking Table</h4>
                        <p class="text-black"> {{rules}}</p>
                </div>
            </div>
        </div>
    </div>
</section>


<button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
    Launch demo modal
</button>
<div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 640px !important;position: relative !important;bottom: -30px !important; -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;-moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important; -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;">
        <div class="modal-content status-model-container">
            <div class="modal-body" style="border: none !important;">
                <div class="model-content m-content">
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container">
                        <div class="m-title" style="color: #7e8552;">Terms And Conditions</div>
                    </div>
                    <div class="form-group row">
                         <p class="text-black"  style="margin-top: 10px;"> {{termsAndConditions}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 