<div (click)="closeVideo()">
    <!-- Modal -->
<div class="modal fade" style="text-align: center;padding-top: 60px;" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg " style="width: auto;" role="document">
      <div class="modal-content" style="background-color: transparent; border:none;box-shadow: none;">
        <div class="modal-header" style="border:none">
        
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeVideo()">
            <span aria-hidden="true" style="font-size: xx-large;">&times;</span>
          </button>
        </div>
        <div class="modal-body" >
            <div class="modalResp" style="text-align: center;">
            <iframe id="player" class="responsive-iframe" [src]="modalVideo" style="width:400px;height:300px;top: 0; left: 0;
            bottom: 0;right: 0;width: 100%;border: none;"
             title="YouTube video player" frameborder="0"
            allow="autoplay; accelerometer; autoplay; clipboard-write; 
            encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
            </div>
        </div>
      
      </div>
    </div>
  </div>
  <header>
    <div class="row">
        <div class="col-md-2">
            <img [src]="logoImage" class="logo-image custom-pointer" style="padding-left: 40px;" height="50" 
                [routerLink]="['/']">
                
        </div>
        <div class="col-md-8 product-name" id="prodName" style="text-align: center;padding-top: 15px;">
            Our Products
        </div>
       
  </div>
   
</header>
<hr>
    <div class="container">
    <div class="row row-centered pos" >
        <div class="col-lg-5 col-xs-12 col-centered box"  *ngFor="let product of productList;let i = index">
            <div class="well">
                <!---------product title------>
                <div class="qr-product-title" style="font-weight: 600;text-transform: uppercase;text-align: center;" *ngIf="fields[1].visibility"  >
                    {{metaDataMapService.findData(product, fields[1].nestedinfo)}}
                </div>
           
                <div class="d-flex justify-content-center mt-2 mb-1">                          
                    <!------image-------->
                    &nbsp; <div class="cold-md-3"*ngIf="!product.showVideo">
                    <img   class="img-fluid product-image"  style="width: 120px;height: 100px;"   [src]="product.assets[0].image_source" >
                    </div>
                    <hr>
                    <!------------video---->
                    <div>
                        <div *ngIf="product.showVideo">
                            <div style="height: auto; width: 200px; box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px; padding: 10px 18px; margin-bottom: 15px;">
                                <div id="carouselExampleIndicators" class="carousel slide  d-flex justify-content-center" data-ride="carousel" style="margin-bottom: 5px;">
                                    <div class="carousel-inner">
                                        <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of product.videos; let i=index;">
                                            <div>
                                                <div *ngIf="product.showVideo"  (click)="videoPreview(carousel1)" data-toggle="modal" data-target="#exampleModal">
                                                    <iframe  [src]="carousel1.safeLink"   width="200px"  height="120px"
                                                    style="pointer-events: none;padding-bottom: 0px;"
                                                    title="YouTube video player" frameborder="0"
                                                    allow="autoplay; accelerometer; autoplay; clipboard-write; 
                                                    encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                    </div>
                                            </div>
                                            <div class="text-center" style="font-size: 15px;">{{carousel1.language ? carousel1.language:"" }}</div>
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev mr-2" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                        <span style="background-color: #c7c2c2; border-radius: 50%; padding: 10px 15px; opacity: 1 !important; margin-right: 120px !important; height: 40px;width: 40px;"><i class="fa fa-lg fa-angle-left" aria-hidden="true"></i></span>
                                        <span class="sr-only">Previous</span>
                                      </a>
                                      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                          <span style="background-color: #c7c2c2; border-radius: 50%; padding: 12px; opacity: 1 !important;margin-left: 120px !important; height: 40px;width: 40px;"><i class="fa fa-lg fa-angle-right" aria-hidden="true"></i></span>
                                        <span class="sr-only">Next</span>
                                      </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div  *ngIf="product.showVideo"  (click)="videoPreview(product.videos)" data-toggle="modal" data-target="#exampleModal">
                            <iframe  [src]="product.videos[0].safeLink"   width="140"  height="120px"
                            style="pointer-events: none;padding-bottom: 20px;"
                            title="YouTube video player" frameborder="0"
                            allow="autoplay; accelerometer; autoplay; clipboard-write; 
                            encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                            </div> -->
                    </div>
                    <hr>
                    </div>

                    <!--------how to prepare-->
                    <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="product.showPrepration"> 

                            <div class="card-header qr-instruction" style="font-weight: 300;font-style:bold;text-transform: uppercase;">
                            
                                    {{product.description.instruction_title}}
                                </div>
                                <div class="card-body">
                                <div class="inner-html-list"  [innerHTML]="product.description.instruction_description"></div>
                                </div>
                                
                            
                        </div>
                    </div>
                    </div>

                    <!--------how to store-->
                    <div class="row">
                        <div class="col-lg-12">
                            <div *ngIf="product.showStorage"> 

                                
                                    <div class="card-header qr-instruction" style="font-weight: 300;font-style:bold;text-transform: uppercase;">
                                        How To Store
                                    </div>
                                    <div class="card-body">
                                        <p>{{product.additional_info.how_to_store}}</p>
                                    </div>
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
        </div>
       
        
    </div>
    </div>
    <footer>
        <div class="container">
            
             
                <div class="inq-footql-st">
                    
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="row">
                             
                                <div class="col-md-12" style="color:white;text-align: center;">
                                Click<a href="https://play.google.com/store"><span style="color:#BDC62B;"> here</span></a> to download our mobile app for better experience
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    
    </footer>
</div>
