import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckoutInitialdataService } from './checkout-initialdata.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import {CheckoutLocalStorageService  } from './checkout-local-storage.service';
import { CustomerLoginEventsService } from './checkout-login/customer-login-events.service';
import { CheckoutDeliveryEventsService } from './checkout-delivery/checkout-delivery-events.service';
import { CheckoutShippingEventsService } from './checkout-shipping/checkout-shipping-events.service';
import { CheckoutOrderdetailsEventsService } from './checkout-orderdetails/checkout-orderdetails-events.service';
import { OrderInitiationApiService } from './order-related/order-initiation-api.service';
import { OrderUpdationApiService } from './order-related/order-updation-api.service'; 
import { OrderSummaryInitialService } from './order-summary/order-summary-initial.service';
import { CheckoutPaymentApiService } from './checkout-payment/checkout-payment-api.service';
import { CheckoutDeliveryOrderService } from './checkout-delivery/checkout-delivery-order.service';
import { CheckoutDeliveryService } from './checkout-delivery/checkout-delivery.service';
import { CheckoutSettingsService } from './checkout-settings.service';

@Component({
  selector: 'app-checkout-new',
  templateUrl: './checkout-new.component.html'
})
export class CheckoutNewComponent implements OnInit {

  checkoutAlerts:boolean = false;

  INDIVISUAL_USER = "INDIVIDUAL CUSTOMER";
  BUSINESS_USER = "BUSINESS CUSTOMER";

  offerOrderId = "";
  checkoutFlowObj = {
    customer:{
      sequence:0,
      label:"",
      id:"customer-card",
      href:"customerId",
      name:"CUSTOMER"
    },
    delivery:{
      sequence:0,
      label:"",
      id:"delivery-card",
      href:"deliveryId",
      name:"DELIVERY"
    },
    shipping:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"SHIPPING"
    },
    orderinfo:{
      sequence:0,
      label:"",
      id:"order-card",
      href:"orderId",
      name:"REVIEW"
    },
    payment:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"PAYMENT"
    }
  }

  checkoutFlowObjI = {
    customer:{
      sequence:0,
      label:"",
      id:"customer-card",
      href:"customerId",
      name:"CUSTOMER"
    },
    delivery:{
      sequence:0,
      label:"",
      id:"delivery-card",
      href:"deliveryId",
      name:"DELIVERY"
    },
    shipping:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"SHIPPING"
    },
    orderinfo:{
      sequence:0,
      label:"",
      id:"order-card",
      href:"orderId",
      name:"REVIEW"
    },
    payment:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"PAYMENT"
    }
  }

  checkoutFlowObjB = {
    customer:{
      sequence:0,
      label:"",
      id:"customer-card",
      href:"customerId",
      name:"CUSTOMER"
    },
    delivery:{
      sequence:0,
      label:"",
      id:"delivery-card",
      href:"deliveryId",
      name:"DELIVERY"
    },
    shipping:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"SHIPPING"
    },
    orderinfo:{
      sequence:0,
      label:"",
      id:"order-card",
      href:"orderId",
      name:"REVIEW"
    },
    payment:{
      sequence:0,
      label:"",
      id:"shipping-card",
      href:"shippingId",
      name:"PAYMENT"
    }
  }

  enablingCardsObj = {
    customer:true,
    delivery:false,
    shipping:false,
    orderdetails:false,
    payment:false
  }

  dataAddedObj = {
    customer:true,
    delivery:false,
    shipping:false,
    orderdetails:false,
    payment:false
  }

  pickupinstore:boolean = false;

  offerEvent:any;
  showPaymentSpinner:boolean = false;
  // hidePaymentSection:boolean = false;

  constructor(
    private router: Router,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private alertService: AlertsService,
    private customerLoginEventsService: CustomerLoginEventsService,
    private checkoutDeliveryEventsService: CheckoutDeliveryEventsService,
    private checkoutShippingEventsService: CheckoutShippingEventsService,
    private checkoutOrderdetailsEventsService: CheckoutOrderdetailsEventsService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private orderInitiationApiService: OrderInitiationApiService,
    private orderUpdationApiService: OrderUpdationApiService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private checkoutPaymentApiService: CheckoutPaymentApiService,
    private checkoutDeliveryOrderService: CheckoutDeliveryOrderService,
    private checkoutDeliveryService: CheckoutDeliveryService,
    private checkoutSettingsService: CheckoutSettingsService,
  ) { }

  ngOnInit(): void {
    this.checkoutAlerts = true;
    this.getPaymentMethods();
    this.checkoutInitialdataService.getCheckoutFlow(this.INDIVISUAL_USER).then(respData => {
      let flowData:any = respData;
      if(flowData !== ""){
        this.enablingCardsObj.customer = true;
        flowData.forEach(flow => {
          switch(flow.step_name.toUpperCase()){
            case this.checkoutFlowObjI.customer.name:
              this.checkoutFlowObjI.customer.sequence = flow.sequence;
              this.checkoutFlowObjI.customer.label = flow.step_label;
              break;
            case this.checkoutFlowObjI.delivery.name:
              this.checkoutFlowObjI.delivery.sequence = flow.sequence;
              this.checkoutFlowObjI.delivery.label = flow.step_label;
              break;
            case this.checkoutFlowObjI.shipping.name:
              this.checkoutFlowObjI.shipping.sequence = flow.sequence;
              this.checkoutFlowObjI.shipping.label = flow.step_label;
              break;
            case this.checkoutFlowObjI.orderinfo.name:
              this.checkoutFlowObjI.orderinfo.sequence = flow.sequence;
              this.checkoutFlowObjI.orderinfo.label = flow.step_label;
              break;
            case this.checkoutFlowObjI.payment.name:
              this.checkoutFlowObjI.payment.sequence = flow.sequence;
              this.checkoutFlowObjI.payment.label = flow.step_label;
              break;
          }
        });
      }
    })
    this.checkoutInitialdataService.getCheckoutFlow(this.BUSINESS_USER).then(respData => {
      let flowData:any = respData;
      if(flowData !== ""){
        this.enablingCardsObj.customer = true;
        flowData.forEach(flow => {
          switch(flow.step_name.toUpperCase()){
            case this.checkoutFlowObjB.customer.name:
              this.checkoutFlowObjB.customer.sequence = flow.sequence;
              this.checkoutFlowObjB.customer.label = flow.step_label;
              break;
            case this.checkoutFlowObjB.delivery.name:
              this.checkoutFlowObjB.delivery.sequence = flow.sequence;
              this.checkoutFlowObjB.delivery.label = flow.step_label;
              break;
            case this.checkoutFlowObjB.shipping.name:
              this.checkoutFlowObjB.shipping.sequence = flow.sequence;
              this.checkoutFlowObjB.shipping.label = flow.step_label;
              break;
            case this.checkoutFlowObjB.orderinfo.name:
              this.checkoutFlowObjB.orderinfo.sequence = flow.sequence;
              this.checkoutFlowObjB.orderinfo.label = flow.step_label;
              break;
            case this.checkoutFlowObjB.payment.name:
              this.checkoutFlowObjB.payment.sequence = flow.sequence;
              this.checkoutFlowObjB.payment.label = flow.step_label;
              break;
          }
        });
      }
    })
    this.getCheckoutFlow();
    this.initiateOrder();
  }

  initiateOrder(){
    if(this.checkoutLocalStorageService.getOrderId() === null){
      this.orderInitiationApiService.getorderInitationObject();
    }
  }

  getCheckoutFlow(){
    if(this.checkoutInitialdataService.getCustomerDetails().type === ""){
      this.checkoutFlowObj = this.checkoutFlowObjI;
    }
    else{
      if(this.checkoutInitialdataService.getCustomerDetails().type.toUpperCase() === this.BUSINESS_USER){
        this.checkoutFlowObj = this.checkoutFlowObjB;
      }
      else if(this.checkoutInitialdataService.getCustomerDetails().type.toUpperCase() === this.INDIVISUAL_USER){
        this.checkoutFlowObj = this.checkoutFlowObjI;
      }
    }
  }
  
  customerEvent(event){
    if(event.name === "customer-btn"){
      document.getElementById('customer-btn').click();
    }
    else if(event.name === "closeRemaining"){
      this.closeAllTabs();
      this.resetReaming("customer")
    }
    else if(event.type === "alert"){
      this.showAlerts(event.msgType, event.msg);
    }
    else if(event.step && !event.name){
      this.clickTab(event.step);
    }
    else if(event.name === "checkoutflow"){
      if(event.offer === false){
        if(event.type === this.INDIVISUAL_USER){
          this.checkoutFlowObj = this.checkoutFlowObjI;
          this.clickTab(event.step);
        }
        else if(event.type === this.BUSINESS_USER){
          this.checkoutFlowObj = this.checkoutFlowObjB;
          this.clickTab(event.step);
        }
      }
      else{
        this.openMsgModel1();
        this.offerEvent = event
        this.offerOrderId = event.orderId;
      }
      
    }
  }

  clickTab(step){
    if(this.checkoutFlowObj.delivery.sequence === step){
      if(this.checkDataAddedforsequenceTab(step)){
        // this.clickTab(step + 1)
        this.checkoutDeliveryEventsService.updateAdminFormEvent("editDelivery")
      }
      else{
        this.enablingCardsObj.delivery = true;
        if(!this.dataAddedObj.orderdetails){
          this.enablingCardsObj.orderdetails = false;
        }
        if(!this.dataAddedObj.shipping){
          this.enablingCardsObj.shipping = false;
        }
        if(!this.dataAddedObj.payment){
          this.enablingCardsObj.payment = false;
        }
      }
    }
    else if(this.checkoutFlowObj.shipping.sequence === step){
      if(this.checkDataAddedforsequenceTab(step)){
        // this.clickTab(step + 1)
        this.checkoutShippingEventsService.updateAdminFormEvent("editShipping")
      }
      else{
        this.enablingCardsObj.shipping = true;
        if(!this.dataAddedObj.delivery){
          this.enablingCardsObj.delivery = false;
        }
        if(!this.dataAddedObj.orderdetails){
          this.enablingCardsObj.orderdetails = false;
        }
        if(!this.dataAddedObj.payment){
          this.enablingCardsObj.payment = false;
        }
      }
    }
    else if(this.checkoutFlowObj.orderinfo.sequence === step){
      if(this.checkDataAddedforsequenceTab(step)){
        // this.clickTab(step + 1)
        this.checkoutOrderdetailsEventsService.updateAdminFormEvent("editOrder")
      }
      else{
        this.enablingCardsObj.orderdetails = true;
        if(!this.dataAddedObj.delivery){
          this.enablingCardsObj.delivery = false;
        }
        if(!this.dataAddedObj.shipping){
          this.enablingCardsObj.shipping = false;
        }
        if(!this.dataAddedObj.payment){
          this.enablingCardsObj.payment = false;
        }
      }
    }
    else if(this.checkoutFlowObj.payment.sequence === step){
      this.enablingCardsObj.payment = true;
      if(!this.dataAddedObj.delivery){
        this.enablingCardsObj.delivery = false;
      }
      if(!this.dataAddedObj.orderdetails){
        this.enablingCardsObj.orderdetails = false;
      }
      if(!this.dataAddedObj.shipping){
        this.enablingCardsObj.shipping = false;
      }
    }
  }

  checkDataAddedforsequenceTab(step){
    if(this.checkoutFlowObj.delivery.sequence === step){
      if(this.dataAddedObj.delivery){
        return true;
      }
      else {
        return false;
      }
    }
    else if(this.checkoutFlowObj.shipping.sequence === step){
      if(this.dataAddedObj.shipping){
        return true;
      }
      else {
        return false;
      }
    }
    else if(this.checkoutFlowObj.orderinfo.sequence === step){
      if(this.dataAddedObj.orderdetails){
        return true;
      }
      else {
        return false;
      }
    }
    else if(this.checkoutFlowObj.payment.sequence === step){
      if(this.dataAddedObj.payment){
        return true;
      }
      else {
        return false;
      }
    }
  }

  closeAllTabs(){
    if(!this.dataAddedObj.delivery){
      this.enablingCardsObj.delivery = false;
    }
    if(!this.dataAddedObj.orderdetails){
      this.enablingCardsObj.orderdetails = false;
    }
    if(!this.dataAddedObj.shipping){
      this.enablingCardsObj.shipping = false;
    }
    if(!this.dataAddedObj.payment){
      this.enablingCardsObj.payment = false;
    }
    
  }

  showAlerts(messageType, message){
    let options = {
      autoClose: true,
      keepAfterRouteChange: false
    };
    if(messageType === "success"){
      this.alertService.success(message, options);
    }
    else if(messageType === "warn"){
      this.alertService.warn(message, options);
    }
    else{
      this.alertService.error(message, options);
    }
  }

  deliveryInfoEvent(event){
    if(event.type === "alert"){
      this.showAlerts(event.msgType, event.msg);
    }
    else if(event.step === "next"){
      this.enablingCardsObj.delivery = true;
      this.dataAddedObj.delivery = true;
      if(event.type === "Delivery"){
        this.pickupinstore = false;
        this.clickTab(this.checkoutFlowObj.delivery.sequence + 1);
      }
      if(event.type === "PickUp In Store"){
        this.orderSummaryInitialService.updateAdminFormEvent({
          name: 'removeShipping'
        })
        this.pickupinstore = true;
        this.clickTab(this.checkoutFlowObj.delivery.sequence + 2);
      }
    }
    else if(event.name === "closeRemaining"){
      this.closeAllTabs();
      this.resetReaming("delivery")
    }
  }

  ShippingEvent(event){
    if(event.type === "alert"){
      this.showAlerts(event.msgType, event.msg);
    }
    else if(event.step === "next"){
      this.showAlerts(event.msgType, event.msg);
      this.enablingCardsObj.shipping = true;
      this.dataAddedObj.shipping = true;
      this.assignDefaultOutletValues().then(
        respData => {
          let res:any = respData;
          if(res){
            this.clickTab(this.checkoutFlowObj.shipping.sequence + 1);
          }
        }
      )
      
    }
    else if(event.name === "closeRemaining"){
      this.closeAllTabs();
      this.resetReaming("shipping")
    }
  }

  assignDefaultOutletValues(){
    return new Promise((resolve, reject) =>{
      this.checkoutInitialdataService.getCheckoutFlow('INDIVIDUAL CUSTOMER').then(
        respData => {
          let res:any = respData;
         var isdelivery=false;
          res.forEach(element=>{
            if(element.step_name=='Delivery')
            {
                 isdelivery=true;
                 
            }
           

          })
          if(isdelivery)
          {
            resolve(true);
          }
 
            else{
              this.checkoutDeliveryService.getOutletData().then(
    respData => {
      let res:any = respData;
      if(res.length === 1){
        let outletData = {
          outlet_id : res[0].id,
          outlet_name : res[0].name,
          outlet_latitude: res[0].latitude,
          outlet_longitude: res[0].longitude,
          delivery_preferred_time:"",
          delivery_date:"",
          delivery_time:"",
          delivery_type: this.checkoutSettingsService.deliveryType.delivery
        }
        this.checkoutDeliveryOrderService.setDeliveryObject(outletData);
        resolve(true)
      }
      else{
        resolve(true);
      }
    }
  )
        }
          
          
  
         
      })
      // this.checkoutDeliveryService.getOutletData().then(
      //   respData => {
      //     let res:any = respData;
      //     if(res.length === 1){
      //       let outletData = {
      //         outlet_id : res[0].id,
      //         outlet_name : res[0].name,
      //         outlet_latitude: res[0].latitude,
      //         outlet_longitude: res[0].longitude,
      //         delivery_preferred_time:"",
      //         delivery_date:"",
      //         delivery_time:"",
      //         delivery_type: this.checkoutSettingsService.deliveryType.delivery
      //       }
      //       //this.checkoutDeliveryOrderService.setDeliveryObject(outletData);
      //       resolve(true)
      //     }
      //     else{
      //       resolve(true);
      //     }
      //   }
      // )
    })
  }

  orderSummaryEvent(event){
    if(event === "emptyCart"){
      this.openMsgModel();
    }
    else if(event.type === "alert"){
      this.showAlerts(event.msgType, event.msg);
    }
    else if(event.step === "next"){
      this.enablingCardsObj.orderdetails = true;
      this.dataAddedObj.orderdetails = true;
      this.showPaymentSpinner = false;
      this.clickTab(this.checkoutFlowObj.orderinfo.sequence + 1);
      // this.showPaymentSpinner = true;
      // this.orderUpdationApiService.getOrderUpdationObj().then(
      //   respData => {
      //     let res:any = respData;
      //     this.showPaymentSpinner = false;
      //     if(res){
      //       this.clickTab(this.checkoutFlowObj.orderinfo.sequence + 1);
      //     }
      //   }
      // );
    }
    else if(event.name === "closeRemaining"){
      this.closeAllTabs();
      this.resetReaming("order")
    }
  }

  resetReaming(currentSection){
    if(currentSection !== "customer" && this.dataAddedObj.customer){
      this.customerLoginEventsService.updateAdminFormEvent("resetLogin")
    }
    if(currentSection !=="delivery" && this.dataAddedObj.delivery){
      this.checkoutDeliveryEventsService.updateAdminFormEvent("resetDelivery")
    }
    if(currentSection !=="order" && this.dataAddedObj.orderdetails){
      this.checkoutOrderdetailsEventsService.updateAdminFormEvent("resetOrder")
    }
    if(currentSection !== "shipping" && this.dataAddedObj.shipping){
      this.checkoutShippingEventsService.updateAdminFormEvent("resetShipping")
    }
  }

  backtoHome(){
    document.getElementById('cancelBtn').click();
    this.router.navigate([`/`], { skipLocationChange: false });
  }

  openMsgModel(){
    document.getElementById('launch-model').click();
  }

  openMsgModel1(){
    document.getElementById('launch-surprise').click();
  }

  closeModel1(){
    document.getElementById('cancelBtn2').click();
    this.customerLoginEventsService.updateAdminFormEvent("clearRegistrationData");
    if(this.offerEvent.type === this.INDIVISUAL_USER){
      this.checkoutFlowObj = this.checkoutFlowObjI;
      this.clickTab(this.offerEvent.step);
    }
    else if(this.offerEvent.type === this.BUSINESS_USER){
      this.checkoutFlowObj = this.checkoutFlowObjB;
      this.clickTab(this.offerEvent.step);
    }
  }

  getPaymentMethods(){
    this.checkoutPaymentApiService.getPaymentMethods().then(
      respData => {
        let paymentMethods = [];
        let res:any = respData[0];
        if(res.offline_methods && res.offline_methods.length > 0){
          res.offline_methods.forEach(element => {
            paymentMethods.push(element);
          });
        }
        if(res.online_methods && res.online_methods.length > 0){
          res.online_methods.forEach(element => {
            paymentMethods.push(element);
          });
        }

        if(paymentMethods.length === 1){
          // this.hidePaymentSection = true;
        }

      })
  }

}
