import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { MyOrderDetailsService } from './my-order-details.service';
import { NavbarService } from '../navbar/navbar.service';
import * as moment from 'moment';
import { AlertsService } from '../shared/alerts/alerts.service';
import {config} from '../../configs/config';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';

@Component({
  selector: 'app-my-order-details',
  templateUrl: './my-order-details.component.html'
})
export class MyOrderDetailsComponent implements OnInit {
  CONFIG = config;
  customer_id;
  parameters: any;
  orderdetails: any=[];
  payment_information: any=[];
  summaryShippingInfo;
  productList: any = [{ name: 'prod', price: 200 }];
  productListArray: any =[];
  products: boolean = false;
  total: any;
  subtotal: any;
  shipping: any;
  tax: any;
  orderedOn: any;
  orderId: any;
  orderStatus: any;
  id : any;
  trackingDetails: any = [];
  openTime;
  assignedTime;
  pickedUpTime;
  deliveredTime;
  showOpenTime: boolean=false;
  showAssignedTime: boolean=false;
  showPickedUpTime: boolean=false;
  showDeliveredTime: boolean=false;
  showTrackDetails: boolean = false;
  resultMessage: string = "";
  showalertmsg : boolean = true;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  trackDetails = [];
  orderDateTime:any;
  orderCancelled:boolean = false;
  ordercancelledLabel = "";
  cancelledTime = "";

  constructor(
    private localService: LocalStorageService,
    private myOrderDetailsService: MyOrderDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private alertService: AlertsService,
    private appService: AppService,
    private navbarService: NavbarService,
    private customerLoginStorageService: CustomerLoginStorageService
    ) { 
      if(this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null){
        if (this.router.getCurrentNavigation().extras.state){
        
          this.resultMessage = this.router.getCurrentNavigation().extras.state.result;
         }
    
        }
    }

  ngOnInit(): void {
    console
    this.route.queryParams.subscribe(params => {
      this.parameters = this.route.snapshot.params;
      this.id = this.parameters['id']
    }
    )
    if(this.customerLoginStorageService.getCustomerData()){
      this.customer_id = this.customerLoginStorageService.getCustomerData().userid;
    }
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getAllOrderDetails(this.parameters);
  }

  ngAfterViewInit(): void{
    this.alertService.clear();
    if(this.resultMessage !== ""){
      this.alertService.success(this.resultMessage, this.options);
      this.cdr.detectChanges();
      // this.resultMessage = "";
    }
    
  }
  getAllOrderDetails(params: any) {
    let requestBody = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id": this.customer_id,
          "id": Number(params['id'])
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETALL_ORDERS).subscribe(
      resp => {
        var elem = resp.result.data[0];
        let productListArray=[];
        this.orderedOn = moment(elem.created_on).format('DD MMM, YYYY');
        this.orderId = elem.id
        this.orderStatus = elem.status
        this.productList = resp.result.data[0].product_details.products;
        this.payment_information = resp.result.data[0].payment.payment_method;
        this.orderdetails = resp.result.data[0].order_review.order_summary;
        if(resp.result.data[0].orderConfirmDate){
          this.orderDateTime = new Date(resp.result.data[0].orderConfirmDate).toLocaleString();
        }
        if(resp.result.data[0].status.toUpperCase() === "CANCELLED"){
          this.orderCancelled = true;
          this.ordercancelledLabel = resp.result.data[0].status
          this.cancelledTime = new Date(resp.result.data[0].modified_on).toLocaleString();
        }
        if(resp.result.data[0].shipping.deliveries.length > 0 && !this.orderCancelled){
          if(resp.result.data[0].shipping.deliveries[0].status_history.length > 0){
            let deliveryDetails = resp.result.data[0].shipping.deliveries[0].status_history;
            let shippingTrackDetails = [];
            deliveryDetails.forEach(tc => {
              if(tc.sequence){
                if(tc.sequence > 3){
                  tc.created_date = new Date(tc.created_date).toLocaleString()
                  shippingTrackDetails.push(tc)
                }
              }
            });
            shippingTrackDetails.sort(function(a, b) {return a.sequence - b.sequnce })
            this.trackDetails = shippingTrackDetails;
          }
          
        }
        if(resp.result.data[0].shipping.deliveries.length > 0){this.trackingDetails = resp.result.data[0].shipping.deliveries[0];
          this.trackingDetails["status_history"].forEach(element => {
            if(element.package_status=='Open'){
              if(element.created_date!='')
              {this.openTime=moment(element.created_date).format("DD MMM, YYYY");
              this.showOpenTime=true;}
            }
            if(element.package_status=='Assigned'){
              if(element.created_date!='')
              {this.assignedTime=moment(element.created_date).format("DD MMM, YYYY");
              this.showAssignedTime=true;}
            }
            if(element.package_status=='PickedUP'){
              if(element.created_date!='')
              {this.pickedUpTime=moment(element.created_date).format("DD MMM, YYYY");
              this.showPickedUpTime=true;}
            }
            if(element.package_status=='Delivered'){
              if(element.created_date!='')
              {this.deliveredTime=moment(element.created_date).format("DD MMM, YYYY");
              this.showDeliveredTime=true;}
            }
          });}
        if(resp.result.data[0].shipping.shipping_addresses.length > 0){
          this.summaryShippingInfo = resp.result.data[0].shipping.shipping_addresses[0];
        }
        this.subtotal = 0;
        for (let i = 0; i < resp.result.data[0].product_details.products.length; i++) {
          this.subtotal += resp.result.data[0].product_details.products[i].price;
          productListArray.push(this.productList[i].product_id);
        }
        this.getProductArray(productListArray);
        if (resp.result.data[0].product_details.products.length > 0) {
          this.products = true;
        }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }
  getProductArray(productArray) {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "sorting": true,
        "sortingDetails": {
          "sortorder": 1,
          "sortfield": "name"
        },
        "find": {
          "id": productArray
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.Get_PRODUCT_ARRAY).subscribe(
      resp => {
        this.productListArray=resp.result.data
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }
}
